import { CloseIcon, DownloadIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import {
  Box, Button,
  Flex, Grid, Heading, Image, Input, Progress, Spacer, Text, Tooltip
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import FileUploadIcon from "../../../../../../../assets/images/file-upload-icon.png";
import DefaultImageIcon from "../../../../../../../assets/images/image-default-icon.png";
import { TEXT_COLOR } from "../../../../../../../layout/constant/MenuList";

import FileSaver from 'file-saver';

export function UploadThumbnailPreview({ values, setFieldValue, setErrors, errors, onDrop }) {
  const [fileURL, setFileURL] = useState('')

  useEffect(() => {
    if (values && values.thumbnailURL) {
      const file  = values.thumbnailURL;
      const getFileURL =  file && `${process.env.REACT_APP_API_URL}/uploads/${file?.filename}`;
      setFileURL(getFileURL)
      setErrors('thumbnailURL', {});
    }
  }, [values])

  const acceptImageFiles = {
    "image/png": [".png"],
    "image/jpeg": [".jpg"],
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptImageFiles,
    maxSize: 30 * 1024 * 1024,
    disabled: values.thumbnailURL && true,
  });

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  return (
    <>
      <Grid border='2px'
        borderColor={(errors.thumbnailURL) ? 'red.500' : 'gray.200'}
        padding="20px" borderStyle="dashed">
        <Grid>
          <div {...getRootProps()}>
            <Input {...getInputProps()} />

            <div className="file-upload-content">
              <Image
                boxSize="100px"
                objectFit="cover"
                src={FileUploadIcon}
                alt="upload-icon"
              />
              <Heading
                fontSize="xl"
                mb={1}
                color={TEXT_COLOR}
                textAlign="center"
              >
                Drag and drop thumbnail
                <Tooltip label={
                  <Box>
                    <Text color="#000"><b>Image file type:</b> JPG or PNG</Text>
                    <Text color="#000"><b>Resolution:</b> At least 1080 x 1080 pixels</Text>
                    <Text color="#000"><b>Image maximum file size:</b> 30 MB</Text>
                  </Box>} closeDelay={500} bg='#e2e8f0'>
                  <InfoOutlineIcon marginLeft="2" height="15px" width="15px" marginBottom="3px" />
                </Tooltip>
              </Heading>
              <Heading
                fontSize="sm"
                mb={1}
                color={TEXT_COLOR}
              >
                or
              </Heading>
              <Button size="small" disabled={values.thumbnailURL && true}>Browse</Button>
            </div>
          </div>
        </Grid>
      </Grid>
      {(errors.thumbnailURL) &&
        <Text color="red.500" marginTop="1">
          Thumbnail File is required
        </Text>
      }
      {values.thumbnailURL &&
        <Grid className="file-box">
          <Flex marginTop="5" marginBottom="5">
            <Image
              boxSize="20px"
              objectFit="cover"
              src={DefaultImageIcon}
              alt="Dan Abramov"
            />
            <Grid marginLeft="2">
              <Grid>
                <Text
                  fontSize="15px"
                  textOverflow="ellipsis"
                  overflow='hidden'
                  whiteSpace="nowrap"
                  className="file-name"
                >
                  {values.thumbnailURL.originalname}
                </Text>
              </Grid>
              <Text fontSize="sm">
                {formatBytes(values.thumbnailURL.size)}
              </Text>
            </Grid>
            <Spacer />
            <Grid>
              <Grid justifyContent="center">
                <CloseIcon
                  cursor="pointer"
                  className="close-icon"
                  size="small"
                  onClick={() => {
                    setFieldValue('thumbnailURL', '')
                    setFieldValue('thumbnailFile', '')
                  }}
                />
              </Grid>
              <Grid>
                <DownloadIcon
                  onClick={() => FileSaver.saveAs(fileURL, values.thumbnailURL.originalname)}
                  cursor="pointer"
                  className="close-icon"
                  size="small"
                  w={6}
                  h={6}
                />
              </Grid>
            </Grid>
          </Flex>
          <Progress
            className="loading"
            value={100}
          />
        </Grid >
      }

    </>
  );
}
