import {Box, Button, Grid, GridItem, Heading} from "@chakra-ui/react";
import React, {useEffect} from "react";
import {Form, FormikProvider, useFormik} from "formik";
import * as yup from "yup";
import {checkIfFilesAreCorrectType, checkIfFilesAreTooBig} from "../../../../../../../helpers/fileValidation";
import DV360ImageAdFileUpload from "./DV360ImageAdFileUpload";
import {Flex} from "@chakra-ui/layout";
import {useDispatch, useSelector} from "react-redux";
import {settingFormMedia, settingHandlePreviewData} from "../../../../../../../store/adUploads/index"
import {TEXT_COLOR} from "../../../../../../../layout/constant/MenuList";
import {useParams} from "react-router-dom";


function DV360ImageAdMedia({activeStep, steps, prevStep, nextStep, label}) {

    const { adUploadId } = useParams()

    const { mediaDetails } = useSelector((state) => state.adUpload)

    const schema = yup.object().shape({
        imageURL: yup.array()
            .nullable()
            .required("You need to provide a file")
            .test("fileSize", "The file is too large", checkIfFilesAreTooBig)
            .test(
                "type", "Only the following formats are accepted: .jpeg, .jpg, .gif, .png",
                checkIfFilesAreCorrectType
            ).min(1).max(12).required(),
    });

    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            imageURL: []
        },
        validationSchema: schema,
        onSubmit: (values => {
            dispatch(settingFormMedia(values))
            if (values && values.imageURL) {
                const files = values.imageURL.map((file) => {
                    if (file && file.fieldname) {
                        return {
                            imageUrl: `${process.env.REACT_APP_API_URL}/uploads/${file.filename}`,
                            filename: file.originalname
                        }
                    } else {
                        return {
                            imageUrl: URL.createObjectURL(file),
                            filename: file.name
                        }
                    }
                })
                dispatch(settingHandlePreviewData(files));
            }
            nextStep()
        })
    });


    const {
        errors,
        values,
        touched,
        handleSubmit,
        isValid,
        setFieldValue,
    } = formik;

    useEffect(() => {
        setFieldValue("imageURL", mediaDetails?.imageURL ? mediaDetails?.imageURL : [])
    }, [mediaDetails])


   return (
       <Box className="file-upload-box">
           {
               adUploadId && (
                   <Box display="flex" mb={6} justifyContent="space-between" alignItems="center">
                       <Heading fontSize="xl" color={"#757998"}>
                           Please review the ad details carefully.
                       </Heading>
                       <Heading fontSize="md" color="gray.400">
                           2 steps to complete
                       </Heading>
                   </Box>
               )
           }

           {
               !adUploadId && (
                   <Box display="flex" mb={6} justifyContent="space-between" alignItems="center">
                       <Heading fontSize="xl" color={"#757998"}>
                           Please upload your ad creative.
                       </Heading>
                       <Heading fontSize="md" color="gray.400">
                           2 steps to complete
                       </Heading>
                   </Box>
               )
           }

           <FormikProvider value={formik}>
               <Form autoComplete="off" onSubmit={handleSubmit}>
           <DV360ImageAdFileUpload
               getHashArray={(value) => {
                   setFieldValue('imageURL', value)
               }}
               setFieldValue={setFieldValue}
               errors={errors}
               touched={touched}
               type="dv360"
               values={values}
           />
               <Flex width="100%" justify="flex-end" py={10}>
                   <Button
                       mr={4}
                       onClick={prevStep}
                       colorScheme='blue' variant='outline' borderRadius={0} px={10}
                   >
                       Back
                   </Button>
                           <Button mr={4} colorScheme='green' type="submit" variant='solid' borderRadius={0} px={10}>
                               Next
                           </Button>
               </Flex>
               </Form>

           </FormikProvider>

       </Box>
   )
}

export default DV360ImageAdMedia