import { createSlice } from "@reduxjs/toolkit";
import {AD_UPLOAD_TYPE} from "../../../../../../../../constant";

const initialState = {
    formDetails: {},
    mediaDetails: null,
    handlePreviewData: null,
};

export const pinterestVideoSlice = createSlice({
    name: AD_UPLOAD_TYPE.PINTEREST_VIDEO,
    initialState,
    reducers: {
        settingPinterestVideoFormDetail: (state, action) => {
            state.formDetails = action.payload
        },
        settingPinterestVideoFormMedia: (state, action) => {
            state.mediaDetails = action.payload
        },
        settingPinterestVideoHandlePreview: (state, action) => {
            state.handlePreviewData = action.payload
        },
        deleteAllPinterestVideoFields: (state, action) => {
            state.formDetails = {};
            state.mediaDetails = null;
            state.handlePreviewData = null;
        }
},
});

export const { deleteAllPinterestVideoFields,settingPinterestVideoFormDetail, settingPinterestVideoFormMedia, settingPinterestVideoHandlePreview } = pinterestVideoSlice.actions;

// The reducer
export const pinterestVideoReducer = pinterestVideoSlice.reducer;
