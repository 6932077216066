import {useMutation, useQuery} from "react-query";
import axios from "../../helpers/axios";

export const useGetSingleCampaign = (clientId, campaignId) => {
    return useQuery(['campaign-brief'], async () => {
        if(clientId && campaignId){
            return axios
                .get(`/client/${clientId}/campaign-brief/${campaignId}`, {
                    withCredentials: false,
                })
                .then((res) => {
                    if (res.status === 200) {
                        return res.data.data
                    }
                })
                .catch((err) => {
                    return err.response
                })
        }
    })
}