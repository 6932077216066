import React, {useEffect, useMemo, useState} from "react";
import {
    Box,
    Button,
    Heading,
    HStack,
    Icon, Image,
    Input,
    InputGroup,
    InputLeftElement,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Stack, Tag, TagLeftIcon,
    Text, useToast,
} from "@chakra-ui/react";
import {BiPlusCircle} from "react-icons/bi";
import {useNavigate, Link} from "react-router-dom";
import {CheckCircleIcon, SearchIcon, SmallAddIcon, SmallCloseIcon, WarningIcon} from "@chakra-ui/icons";
import {FiEye} from "react-icons/fi";
import Datatable from "../../../../components/Datatable";
import {useAdUploadList} from "../../../../hooks/campaign-briefs/useAdUploadList";
import {useParams} from "react-router-dom";
import "../../style/AdUploadList.css";
import {useSelector} from "react-redux";
import moment from "moment";
import {
    AD_UPLOAD_STATUS,
    AD_UPLOAD_STATUS_COLOR,
    AD_UPLOAD_TYPE,
    AD_UPLOAD_TYPE_COLOR,
} from "../../../../constant";
import _ from "lodash";
import {TagLabel} from "@chakra-ui/tag";
import DeleteModel from "../../../../components/PopupModal/DeleteModel";
import {useDeleteAdUpload} from "../../../../hooks/campaign-briefs/useDeleteAdUpload";
import {titleCase} from "../../../../helpers/utils";
import FullPageLoader from "../../../../components/FullPageLoader";
import "./StepByStepAdUploadComponent/tooltipstyle.css";
import {useRecoilValue} from "recoil";
import {profile} from "../../../../atoms/authAtom";

const StepByStepAdUpload = ({SetTabIndex}) => {
    const navigate = useNavigate();
    const {id} = useParams();
    const clientId = useSelector((state) => state.client.clientId);
    const [isClient, setIsClient] = useState(true)
    const {data, refetch} = useAdUploadList(clientId, id);

    const [search, setSearch] = useState();
    const [adUploadList, setAdUploadList] = useState();
    const {
        access_info: {roles},
    } = useRecoilValue(profile);

    useEffect(() => {
        setIsClient(roles.includes("Client"));
    }, [roles]);

    useEffect(() => {
        refetch();
    }, [clientId]);

    useEffect(() => {
        setAdUploadList(data?.adUploads);
    }, [data]);

    useEffect(() => {
        if (search?.trim()) {
            const searchedAdUploads = adUploadList.filter((el) => {
                if (el?.ad_upload_type?.toLowerCase().includes(search.trim())) {
                    return true;
                } else if (el?.description?.toLowerCase().includes(search.trim())) {
                    return true;
                } else if (el?.message?.toLowerCase().includes(search.trim())) {
                    return true;
                } else if (el?.name?.toLowerCase().includes(search.trim())) {
                    return true;
                } else if (el?.status?.toLowerCase().includes(search.trim())) {
                    return true;
                } else if (el?.created_at?.toLowerCase().includes(search.trim())) {
                    return true;
                }
            });
            setAdUploadList(searchedAdUploads);
        } else {
            setAdUploadList(data?.adUploads);
        }
    }, [search]);


    const handleRedirectUrl = (data) => {
        const adUploadType = data?.row.original?.ad_upload_type;
        const adUploadId = data?.row.original.id;
        // /campaign-briefs/:id/edit-adUpload/:adUploadId
        const url = `/campaign-briefs/${id}/edit-adUpload/${adUploadId}`;

        return url

        // if (AD_UPLOAD_TYPE.DV360 === adUploadType) {
        //     return `${url}dv360/${data.row.original.id}`;
        // } else if (AD_UPLOAD_TYPE.DV360AUDIO === adUploadType) {
        //     return `${url}dv360-audio/${data.row.original.id}`;
        // } else if (AD_UPLOAD_TYPE.DV360YOUTUBEVIDEO === adUploadType) {
        //     return `${url}dv360-youtube-video/${data.row.original.id}`;
        // } else if (AD_UPLOAD_TYPE.DV360VIDEO === adUploadType) {
        //     return `${url}dv360-video/${data.row.original.id}`;
        // } else if (AD_UPLOAD_TYPE.FBCAROUSEL === adUploadType) {
        //     return `${url}fb/carousel/${data.row.original.id}`;
        // } else if (AD_UPLOAD_TYPE.FBVIDEO === adUploadType) {
        //     return `${url}fb/video/${data.row.original.id}`;
        // } else if (AD_UPLOAD_TYPE.FACEBOOK === adUploadType) {
        //     return `${url}fb/${data.row.original.id}`;
        // } else if (AD_UPLOAD_TYPE.INSTAGRAM_IMAGE === adUploadType) {
        //     return `${url}instagram/image/${data.row.original.id}`;
        // } else if (AD_UPLOAD_TYPE.INSTAGRAM_VIDEO === adUploadType) {
        //     return `${url}instagram/video/${data.row.original.id}`;
        // } else if (AD_UPLOAD_TYPE.INSTAGRAM_CAROUSEL === adUploadType) {
        //     return `${url}instagram/carousel/${data.row.original.id}`;
        // } else if (AD_UPLOAD_TYPE.PINTEREST_IMAGE === adUploadType) {
        //     return `${url}pinterest/${data.row.original.id}`;
        // } else if (AD_UPLOAD_TYPE.PINTEREST_VIDEO === adUploadType) {
        //     return `${url}pinterest-video/${data.row.original.id}`;
        // } else if (AD_UPLOAD_TYPE.PINTEREST_CAROUSEL === adUploadType) {
        //     return `${url}pinterest-carousel/${data.row.original.id}`;
        // } else if (AD_UPLOAD_TYPE.TIKTOK_VIDEO === adUploadType) {
        //     return `${url}tiktok-video/${data.row.original.id}`;
        // } else if (AD_UPLOAD_TYPE.GOOGLE_CSV === adUploadType) {
        //     return `${url}google/${data.row.original.id}`;
        // }else {
        //     return '/NotFound';
        // }
    };

    const [isDeleteModalOpen, setDeleteModal] = useState(false);
    const [adsName, setAdsName] = useState(null)
    const [deleteAdUploadId, setDeleteAdUploadId] = useState(null);

    function handleDeleteModel(adName, adId) {
        setDeleteModal(true)
        setAdsName(adName)
        setDeleteAdUploadId(adId)
    }

    function onDestroyModel() {
        setDeleteModal(false)
    }

    const {data: deleteData, refetch: refetchDelete} = useDeleteAdUpload(clientId, id, deleteAdUploadId)
    let toast = useToast();

    const onCloseDeleteModal = () => {
        refetchDelete().then((resp) => {
            if (resp?.data?.status === 200) {
                refetch();
                toast({
                    isClosable: true,
                    status: "success",
                    variant: "top-accent",
                    position: "top-right",
                    description: "Deleted Successfully!"
                });
                setDeleteModal(false)
            } else {
                setDeleteModal(false)
                toast({
                    isClosable: true,
                    status: "error",
                    variant: "top-accent",
                    position: "top-right",
                    description: resp?.data?.data?.message || "Something goes wrong!"
                });
            }
        }).catch((err) => {
            setDeleteModal(false)
        })
    }

    const columns = useMemo(
        () => [
            {
                Header: "Ad Name",
                accessor: "name",
            },
            {
                Header: "Channel",
                accessor: "ad_upload_type",
                Cell: (data) => {
                    const arr = ['pinterest', 'google', 'instagram', 'facebook', 'fb', 'tiktok', 'dv360'];
                    if (data.value && arr) {
                        for (let el of arr) {
                            if (data.value.toLowerCase().includes(el)) {
                                const title = el === 'fb' ? 'facebook' : el === 'dv360' ? "display" : el;
                                return (
                                    <div className="channel-type">
                                        <Image src={`/images/${title}.png`}
                                               boxSize="22px"
                                               objectFit="cover"
                                               alt="upload-icon"/>
                                        <span>{titleCase(title)}</span>
                                    </div>
                                );
                            }
                        }
                    }
                },
            },
            {
                Header: "Ad Type",
                accessor: "type",
                Cell: (data) => {
                    return <>{data.row.original.ad_upload_type === "DV360"
                        ? "DisplayImage" : data.row.original.ad_upload_type === "DV360Video" ? "DisplayVideo" : data.row.original.ad_upload_type === "DV360Audio" ? "DisplayAudio" : data.row.original.ad_upload_type === "DV360YoutubeVideo" ? "DisplayYoutubeVideo"
                        : data.row.original.ad_upload_type}</>
                },
            },
            {
                Header: "Status",
                accessor: "status",
                Cell: (data) => {
                    return (
                        <>
                            {AD_UPLOAD_STATUS.CREATED === data.value.toUpperCase() &&
                            <Tag size="lg" variant='subtle' color={"white"} backgroundColor={"#FFC108"} w="100%"
                                 borderRadius={100}>
                                <TagLeftIcon boxSize='16px' as={WarningIcon}/>
                                <TagLabel>Action Needed</TagLabel>
                            </Tag>
                            }
                            {AD_UPLOAD_STATUS.APPROVED === data.value.toUpperCase() &&
                            <Tag size="lg" variant='subtle' color="white" backgroundColor={"#63C68B"}  w="100%"
                                 borderRadius={100}>
                                <TagLeftIcon boxSize='16px' as={CheckCircleIcon}/>
                                <TagLabel>Approved</TagLabel>
                            </Tag>
                            }
                            {AD_UPLOAD_STATUS.REJECTED === data.value.toUpperCase() &&
                            <Tag size="lg" variant='subtle' backgroundColor="#A7A9BD" color="white"  w="100%"
                                 borderRadius={100}>
                                <TagLeftIcon boxSize='16px' as={SmallCloseIcon} backgroundColor={"white"}
                                             color={"#A7A9BD"} borderRadius={100}/>
                                <TagLabel>Closed</TagLabel>
                            </Tag>
                            }
                        </>
                    );
                },
            },
            {
                Header: "Added on",
                accessor: "created_at",
                Cell: (data) => {
                    return moment(data.row.original.created_at).format("YYYY-MM-DD")
                },
            },
            {
                Header: () => <Text>Actions</Text>,
                accessor: "actions",
                Cell: (data) => {
                    const url = handleRedirectUrl(data);
                    const status = data.row.values.status;
                    const adName = data.row.values?.name;
                    const adId = data?.row?.original?.id;
                    return (
                        <HStack>
                            <Button
                                as={Link}
                                size="sm"
                                variant="outline"
                                aria-label="View details"
                                icon={<FiEye/>}
                                to={url}
                                color="#3B82F6"
                                py={5}
                                w={100}

                            >
                                {AD_UPLOAD_STATUS.CREATED === status.toUpperCase() ? 'View/Edit' : 'View'}
                            </Button>
                            <Button
                                size="sm"
                                variant="outline"
                                aria-label="View details"
                                icon={<FiEye/>}
                                color="#FF8189"
                                py={5}
                                w={100}
                                // disabled={"true"}
                                onClick={() => handleDeleteModel(adName, adId)}
                            >
                                Delete
                            </Button>
                        </HStack>
                    )
                },
            },
        ],
        []
    );

    return (
        <div className="ad-upload-list">
            <DeleteModel heading={"Ad set"} name={adsName} isOpen={isDeleteModalOpen}
                         onClose={() => onCloseDeleteModal()} onDestory={() => onDestroyModel()}/>
            {/*<Heading color={"gray"} fontSize="lg" my={5} mb={7}>*/}
            {/*    Step By Ad Uploads*/}
            {/*</Heading>*/}
            <div className="search scrollable">
                <Stack direction="row" justifyContent="space-between" spacing={4} mt={6}>
                    <InputGroup width={880}>
                        <InputLeftElement
                            pointerEvents="none"
                            children={<SearchIcon color="gray.300" ml={8}/>}
                        />
                        <Input
                            ml={2}
                            borderRadius={100}
                            name="search"
                            type="tel"
                            placeholder="Search"
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </InputGroup>
                    {/*<Button onClick={() => navigate(`/campaign-briefs/${id}/ad-upload/new`)}>*/}
                    {/*    Create Campaign*/}
                    {/*</Button>*/}
                    <Button style={{minWidth: "inherit"}}
                            backgroundColor={"#3B82F6"}
                            borderRadius={0}
                            leftIcon={<SmallAddIcon boxSize={8}/>}
                            onClick={() => SetTabIndex(isClient ? 3 : 4)}
                    >
                        Create new ad set
                    </Button>
                </Stack>
            </div>
            <Datatable data={adUploadList || []} columns={columns} headerColor="#E598C2"/>
        </div>
    );
};

export default StepByStepAdUpload;
