import {WrapContext} from "./store/WrapContext";
import GoogleCSV from "./GoogleCSV";
import React from "react";

function GoogleAdDetails({SetTabIndex, activeStep, steps, prevStep, nextStep, label}) {
    return (
        <WrapContext>
            <GoogleCSV activeStep={activeStep} SetTabIndex={SetTabIndex} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label}/>
        </WrapContext>
    )
}

export default GoogleAdDetails