import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Divider,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  useToast,
} from "@chakra-ui/react";
import { TEXT_COLOR } from "../../layout/constant/MenuList";
import axios from "../../helpers/axios";
import Datatable from "../../components/Datatable";
import { useGetSheetData } from "../../hooks/spreadsheet-config/useGetSheetData";
import SearchAndButton from "../../components/SearchAndButton";

function SpreadSheetConfig(props) {
  const [url, setUrl] = useState("");
  const [sheetData, setSheetData] = useState([]);
  const toast = useToast();
  const { data, refetch } = useGetSheetData();

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    setSheetData(data);
  }, [data]);

  function getSpreadsheetIdFromLink(link) {
    const regex = /\/spreadsheets\/d\/([a-zA-Z0-9-_]+)/;
    const match = link?.match(regex);
    if (match && match[1]) {
      return match[1];
    } else {
      setUrl("");
      toast({
        isClosable: true,
        status: "error",
        variant: "top-accent",
        position: "top-right",
        title: "Error",
        description: "Invalid spreadsheet URL",
      });
      return null;
    }
  }

  function handleSyncData() {
    const sheetId = getSpreadsheetIdFromLink(url);

    if (url && sheetId) {
      axios.post(`/spreadsheet-config/${sheetId}`).then((res) => {
        setUrl("");
        refetch();
        toast({
          isClosable: true,
          status: "success",
          variant: "top-accent",
          position: "top-right",
          title: "Success",
          description: "Data successfully synced",
        });
      });
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Channel",
        accessor: "channel",
      },
      {
        Header: "Category",
        accessor: "category",
      },
      {
        Header: "Targeting Method",
        accessor: "targetingMethod",
      },
      {
        Header: "Minimum Monthly Budget",
        accessor: "minimumMonthlyBudget",
      },
      {
        Header: "Minimum Daily",
        accessor: "minimumDaily",
      },
      {
        Header: "Industry",
        accessor: "industry",
      },
      {
        Header: "Awareness",
        accessor: "REACH",
      },
      {
        Header: "Traffic",
        accessor: "TRAFFIC",
      },
      {
        Header: "Engagement",
        accessor: "ENGAGEMENT",
      },
      {
        Header: "Video Views",
        accessor: "VIDEO_VIEWS",
      },
      {
        Header: "App Download",
        accessor: "APP_DOWNLOAD",
      },
      {
        Header: "Lead Generation",
        accessor: "LEAD_GENERATION",
      },
      {
        Header: "Conversions",
        accessor: "CONVERSIONS",
      },
      {
        Header: "Revenue",
        accessor: "REVENUE",
      },
    ],
    []
  );

  return (
    <div className="ad-upload-list">
      <Heading color={TEXT_COLOR} fontWeight="500" size="lg" my={5} mb={7}>
        SpreadSheet Config
      </Heading>

      <HStack
        mb="4"
        flexDir={{
          base: "column-reverse",
          md: "row",
        }}
        align={{
          base: "end",
          md: "center",
        }}
        spacing={4}
      >
        <InputGroup>
          <Input
            type="url"
            placeholder="Enter spreadsheet URL"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
        </InputGroup>

        <Box>
          <Button
            size={{
              base: "sm",
              md: "md",
            }}
            onClick={() => handleSyncData()}
          >
            {sheetData?.length !== 0 ? "Re-sync" : "Sync"}
          </Button>
        </Box>
      </HStack>

      <Divider />
      {sheetData?.length !== 0 ? (
        <Datatable
          data={sheetData || []}
          columns={columns}
          headerColor={"#3B82F6"}
          paginationDataCount={15}
        />
      ) : (
        <p
          style={{
            textAlign: "center",
            marginTop: "260px",
            fontSize: "1.4rem",
          }}
        >
          Please Enter the URL of spreadsheet to sync the data
        </p>
      )}
    </div>
  );
}

export default SpreadSheetConfig;
