import {useSelector} from "react-redux";
import DV360AdDetails from "../DV360/DV360AdDetails";
import {useGetAdUpload} from "../../../../../hooks/campaign-briefs/useGetAdUpload";
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import DV360ImageAdDetails from "./DV360/DV360Image/DV360ImageAdDetails";
import DV360AudioAdDetails from "./DV360/DV360Audio/DV360AudioAdDetails";
import DV360YoutubeVideoDetails from "./DV360/DV360YoutubeVideo/DV360YoutubeVideoDetails";
import DV360VideoAdDetails from "./DV360/DV360Video/DV360VideoAdDetails";
import PinterestImageAdDetails from "./Pintrest/PinterestImage/PinterestImageAdDetails";
import PinterestVideoAdDetails from "./Pintrest/PinterestVideo/PinterestVideoAdDetails";
import TiktokVideoAdDetails from "./Tiktok/TiktokVideo/TiktokVideoAdDetails";
import PinterestCarouselAdDetails from "../StepByStepAdUploadComponent/Pintrest/PinterestCarousel/PinterestCarouselAdDetails";
import GoogleAdDetails from "./Google/GoogleCsv/GoogleAdDetails";
import FacebookImageAdDetails from "./Facebook/FacebookImage/FacebookImageAdDetails";
import FacebookVideoAdDetails from "./Facebook/FacebookVideo/FacebookVideoAdDetails";
import InstagramImageAdDetails from "./Instagram/InstagramImage/InstagramImageAdDetails";
import FacebookCarouselAdDetails from "./Facebook/FacebookCarousel/FacebookCarouselAdDetails";
import InstagramVideoAdDetails from "./Instagram/InstagramVideo/InstagramVideoAdDetails";
import InstagramCarouselAdDetails from "./Instagram/InstagramCarousel/InstagramCarouselAdDetails";

function AdDetailsForm({SetTabIndex, activeStep, steps, prevStep, nextStep, label}) {

    const {mediaType, editMediaType} = useSelector((state) => state.adUpload);

    if(editMediaType === "DV360" || mediaType === "dv360") {
        return (
            <DV360ImageAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(editMediaType === "PinterestImage" || mediaType === "PINTEREST_IMAGE") {
        return (
            <PinterestImageAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(editMediaType === "PinterestVideo" || mediaType === "PINTEREST_VIDEO") {
        return (
            <PinterestVideoAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(editMediaType === "PinterestCarousel" || mediaType === "PINTEREST_CAROUSEL") {
        return (
            <PinterestCarouselAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(editMediaType === "TikTokVideo" || mediaType === "TIKTOK_VIDEO") {
        return (
            <TiktokVideoAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(editMediaType === "InstagramVideo" || mediaType === "INSTAGRAM_VIDEO") {
        return (
            <InstagramVideoAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(editMediaType === "InstagramImage" || mediaType === "INSTAGRAM_IMAGE") {
        return (
            <InstagramImageAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(editMediaType === "InstagramCarousel" || mediaType === "INSTAGRAM_CAROUSEL") {
        return (
            <InstagramCarouselAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }

    // skipping for now
    if(mediaType === "DV360_AUDIO") {
        return <DV360AudioAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
    }

    if(editMediaType === "DV360YoutubeVideo" || mediaType === "DV360_YOUTUBE_VIDEO") {
        return <DV360YoutubeVideoDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
    }

    if( editMediaType === "DV360Video" || mediaType === "DV360_VIDEO") {
        return <DV360VideoAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
    }

    if(editMediaType === "DV360Audio" || mediaType === "DV360_AUDIO") {
        return <DV360AudioAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
    }

    if(editMediaType === "FACEBOOK" || mediaType === "FACEBOOK_IMAGE") {
        return <FacebookImageAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
    }

    if(mediaType === "FACEBOOK_VIDEO" || editMediaType === "FBVideo") {
        return <FacebookVideoAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
    }

    if(mediaType === "FACEBOOK_CAROUSEL" || editMediaType === "FBCarousel") {
        return <FacebookCarouselAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
    }


    // Google

    if(mediaType === "display ads" || editMediaType === "Google") {
        return <GoogleAdDetails SetTabIndex={SetTabIndex} activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
    }
}

export default AdDetailsForm