import {Button, HStack, ModalCloseButton, useToast} from "@chakra-ui/react";
import {FiEye} from "react-icons/fi";
import {useEffect, useState} from "react";
import ReportDialog from "./ReportDialog";
import instance from "../../helpers/axios";
import axios from "../../helpers/axios";
import {useSelector} from "react-redux";
import {useGetLookerStudioReports} from "../../hooks/looker-studio/useGetLookerStudioReports";

const Actions = ({to, row, getAllReports}) => {
    const toast = useToast()
    const [open, setOpen] = useState(false)
    const [reports, setReports] = useState([])
    const [report, setReport] = useState({
        name: "",
        report_url: ""
    })
    const clientId = useSelector((state) => state.client.clientId);

    const {data, refetch} = useGetLookerStudioReports(clientId);

    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        setReports(data)
    }, [data]);

    function handleOpen() {
        setOpen(true)
        getSingleReport()
    }

    function getSingleReport() {
        axios
            .get(`/client/${clientId}/looker-studio/${row.id}`, {
                withCredentials: false,
            })
            .then((res) => {
                if (res.status === 200) {
                    setReport(res.data.data.report)
                }
            })
            .catch((err) => {
                return err.response
            })
    }


    function handleClose() {
        setOpen(false)
    }

    const onSubmit = async (values) => {
        await instance({
            method: "PUT",
            url: `client/${clientId}/looker-studio/${row.id}`,
            data: {
                name: values.name,
                report_url: values.report_url,
            },
        })
            .then((response) => {
                handleClose()
                toast({
                    isClosable: true,
                    status: "success",
                    variant: "top-accent",
                    position: "top-right",
                    title: "Success",
                    description: response.data?.data?.message,
                });
                refetch()
            })
            .catch((error) => {
                toast({
                    isClosable: true,
                    status: "error",
                    variant: "top-accent",
                    position: "top-right",
                    description: error.response.data.message,
                });
            });
    };

    const handleDelete = async (values) => {
        await instance({
            method: "DELETE",
            url: `client/${clientId}/looker-studio/${row.id}`
        })
            .then((response) => {
                handleClose()
                toast({
                    isClosable: true,
                    status: "success",
                    variant: "top-accent",
                    position: "top-right",
                    title: "Success",
                    description: response.data?.data?.message,
                });
                refetch()
            })
            .catch((error) => {
                toast({
                    isClosable: true,
                    status: "error",
                    variant: "top-accent",
                    position: "top-right",
                    description: error.response.data.message,
                });
            });
    };

    return (
        <>
            <HStack>
                <Button
                    size="sm"
                    variant="outline"
                    aria-label="View details"
                    icon={<FiEye/>}
                    py={5}
                    onClick={() => handleOpen(row.id)}
                >
                    Edit Report
                </Button>
                <Button py={5} size="sm" variant="outline" colorScheme="red" onClick={handleDelete}>
                    Delete
                </Button>
            </HStack>
            <ReportDialog handleClose={handleClose} reportData={report} buttonType={"Update"} open={open}
                          onSubmit={onSubmit} dialogTitle={"Update Report"}/>
        </>
    );
};


export default Actions;
