import {
    Box, Button,
    Container, Flex,
    Grid,
    GridItem,
    Heading,
    Image, Spacer, Text
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {PREVIEW_BG_COLOR, TEXT_COLOR, INDICATOR_BG_COLOR} from "../../../../../../../layout/constant/MenuList";
import {ArrowBackIcon, ArrowForwardIcon} from "@chakra-ui/icons";
import {useDispatch, useSelector} from "react-redux";
import {AD_UPLOAD_TYPE} from "../../../../../../../constant";
import {useUploadImage} from "../../../../../../../hooks/campaign-briefs/useUploadImage";
import instance from "../../../../../../../helpers/axios";
import {useNavigate, useParams} from "react-router-dom";
import WithAppContext from "../../../../../../../helpers/appContext";
import SuccessModal from "../../../../../../../components/PopupModal/SuccessModal";
import ErrorModal from "../../../../../../../components/PopupModal/ErrorModal";
import FullPageLoader from "../../../../../../../components/FullPageLoader";
import {deleteTheField} from "../../../../../../../store/adUploads/index"
import {useGetAdUpload} from "../../../../../../../hooks/campaign-briefs/useGetAdUpload";
import {deleteAllTiktokVideoFields,settingTiktokVideoFormDetail,settingTiktokVideoFormMedia,settingTiktokVideoHandlePreview} from "./store";
import ReactPlayer from "react-player";
import _ from "lodash";
import {useRecoilValue} from "recoil";
import {profile} from "../../../../../../../atoms/authAtom";

const TiktokVideoAdPreview = ({SetTabIndex, activeStep, steps, prevStep, nextStep, label, context}) => {

    const [index, setIndex] = useState(0);
    const [ads, setAds] = useState([]);
    const [method, setMethod] = useState();
    const [url, setUrl] = useState();
    const { id: campaignId, adUploadId } = useParams();
    const clientId = useSelector((state) => state.client.clientId);
    const {setAppLoading, appLoading} = context;
    const [isSuccessModalOpen, setSuccessModal] = useState(false);
    const [isErrorModalOpen, setErrorModal] = useState(false);
    const [description, setDescription] = useState("");
    const {
        access_info: { roles, clients: userClients },
    } = useRecoilValue(profile);
    const [isClient, setIsClient] = useState(false)

    useEffect(() => {
        setIsClient(roles.includes("Client"));
    }, [roles]);

    // const { data, refetch } = useGetAdUpload(clientId, campaignId, adUploadId);
    useEffect(() => {
        // refetch()
    }, [])

    useEffect(() => {
        if (adUploadId) {
            setMethod("PUT");
            setUrl(
                `/client/${clientId}/campaign-brief/${campaignId}/ad-upload/${adUploadId}`
            );
        } else {
            setMethod("POST");
            setUrl(`/client/${clientId}/campaign-brief/${campaignId}/ad-upload`);
        }
    }, [adUploadId, clientId]);

    // const {handlePreviewData, formDetails, mediaDetails} = useSelector((state) => state.adUpload)
    const {handlePreviewData, formDetails, mediaDetails} = useSelector((state) => state.tiktokVideoAd)

    const { fileInfoList} = formDetails


    useEffect(() => {
        setAds(handlePreviewData)
    }, [handlePreviewData]);



    const {mutateAsync} = useUploadImage();


    const onHandleSubmit = async (values) => {
        let videoData;
        let mediaInfoList = [];
        let mediaIds = [];
        let mediaDimensions = [];
        if (_.isEqual(fileInfoList, values.fileURL)) {
            // const { detail } = data;
            videoData = { audio: formDetails.audio };
        } else {
            const audioFile = await mutateAsync({
                clientId: clientId,
                campaignBriefId: campaignId,
                adFile: values.fileURL,
                type: "tiktok",
            });

            mediaInfoList =
                audioFile &&
                audioFile.fileInfoList.map((el) => {
                    return {
                        ...el,
                        metadata: [{ height: "", width: "" }],
                    };
                });
            mediaIds = audioFile && audioFile.fileInfoList.map((el) => el.imageHash);
            mediaDimensions =
                audioFile &&
                audioFile.fileInfoList.map(() => {
                    return {
                        width: "",
                        height: "",
                    };
                });

            videoData = {
                audio: {
                    mediaIds: mediaIds,
                    fileInfoList: mediaInfoList,
                    dimensions: mediaDimensions,
                },
            };
        }
        return {
            name: formDetails.adName,
            displayName: formDetails.displayName,
            description: formDetails.description,
            detail: {
                name: formDetails.adName,
                displayName: formDetails.displayName,
                description: formDetails.description,
                ...videoData,
            },
        };
    };


    const navigate = useNavigate();
    const dispatch = useDispatch()


    const onCloseSuccessModal = () => {
        setSuccessModal(false)

        if(adUploadId) {
            navigate(`/campaign-briefs/${campaignId}`, {
                state: {isShowUpload: true}
            })
            dispatch(deleteAllTiktokVideoFields())
            dispatch(deleteTheField())
        }
        else {
            SetTabIndex(isClient ? 0 : 3);
            // navigate(`/campaign-briefs/${campaignId}`, {
            //     state: {isShowUpload: true}
            // })
            navigate(`/campaign-briefs/${campaignId}`)
            dispatch(deleteAllTiktokVideoFields())
            dispatch(deleteTheField())
        }
    }

    async function handleSubmit() {
        try {
            setAppLoading(true);
            let payload = await onHandleSubmit(mediaDetails);
            if (!adUploadId) {
                payload = {
                    ...payload,
                    ad_upload_type: AD_UPLOAD_TYPE.TIKTOK_VIDEO,
                };
            }
            await instance({
                method: method,
                url: url,
                withCredentials: false,
                data: payload,
            })
                .then((res) => {
                    if (res.status === 200) {
                        setSuccessModal(true);
                    }
                    setAppLoading(false);
                })
                .catch((error) => {
                    setErrorModal(true);
                    setDescription(error.response.data.message);
                    setAppLoading(false);
                });
        } catch (error) {
            setAppLoading(false);
        }
    }

    return (
        <>
            {
                adUploadId && (
                    <Box display="flex" mb={6} justifyContent="space-between" alignItems="center">
                        <Heading fontSize="xl" color={"#757998"}>
                            Please review the ad details carefully.
                        </Heading>
                        <Heading fontSize="md" color="gray.400">
                            1 steps to complete
                        </Heading>
                    </Box>
                )
            }

            {
                !adUploadId && (
                    <Box display="flex" mb={6} justifyContent="space-between" alignItems="center">
                        <Heading fontSize="xl" color={"#757998"}>
                            Please review your ad.
                        </Heading>
                        <Heading fontSize="md" color="gray.400">
                            1 steps to complete
                        </Heading>
                    </Box>
                )
            }

            <Grid templateColumns="repeat(6, 1fr)" gap={4} className="fb-upload">
                {appLoading && <FullPageLoader />}
                <GridItem w="full" colSpan={{base: 6, lg: 6}}>
                    <Heading fontSize="lg" my={4} color={TEXT_COLOR}>
                        {ads?.[index]?.filename}
                    </Heading>
                    <Box
                    borderWidth="2px"
                    borderColor="gray"
                    p={10}
                    background={PREVIEW_BG_COLOR}
                    >
                    <Container height="100%">
                        <Flex alignItems="center" flexDirection="row" justify="center">
                            <div className="containers">
                                <ReactPlayer
                                    className='react-player videos'
                                    url={handlePreviewData}
                                    width={400}
                                    height={700}
                                    // playing={playingPreview}
                                    playing={true}
                                    loop={true}
                                    // onPlay={() => {
                                    //     setPlayingPreview(true)
                                    // }}
                                />
                                <div className="overlay-box">

                                </div>
                                <div className="rights">
                                    <div className="icons-items right-icons">
                                        <span className="icons">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/images/heart.png`}
                                                alt=""
                                                id="likes-icons"
                                            />
                                        </span>
                                        <span className="icon-labels likes right-labels" data-likes="71.1k">71.1k</span>
                                    </div>
                                    <div className="icons-items right-icons">
                                        <span className="icons">
                                            <img src={`${process.env.PUBLIC_URL}/images/chat.png`} alt=""
                                                 id="comments-icons"/>
                                        </span>
                                        <span className="icon-labels comments right-labels"
                                              data-likes="1281">1281</span>
                                    </div>
                                    <div className="icons-items right-icons">
                                        <span className="icons">
                                            <img src={`${process.env.PUBLIC_URL}/images/share.png`}
                                                 alt=""/>
                                        </span>
                                        <span className="icon-labels shares right-labels">232</span>
                                    </div>
                                </div>
                                <div className="ads-details">
                                    <h2>{formDetails.displayName}</h2>
                                    <span className="sponsored">
                                        <p> {formDetails?.description}...&nbsp;
                                            <button>Sponsored</button></p>
                                    </span>
                                    <span className="promoted-music">
                                        <span className="music-icon">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/images/music.png`}
                                                alt=""
                                                id="music-icons"
                                            />
                                        </span>
                                            <h3>Promoted music</h3>
                                    </span>
                                    <button>Sign up</button>
                                    <span className="sign-up-button"><img
                                        src={`${process.env.PUBLIC_URL}/images/right-arrow.png`}/></span>
                                </div>
                                <div className="bottoms">
                                    {/*    <span className="progress-durations"></span>*/}
                                    {/*    <div className="progress-ranges" title="seek">*/}
                                    {/*        <div className="progress-bars"></div>*/}
                                    {/*    </div>*/}
                                    <div className="iconss">
                                        <div className="bottom-icons-items">
                                            <span className="icons-bottom">
                                                <img src={`${process.env.PUBLIC_URL}/images/home.png`} alt=""/>
                                            </span>
                                            <span className="icon-labels">Home</span>
                                        </div>
                                        <div className="bottom-icons-items">
                                            <span className="icons-bottom">
                                                <img src={`${process.env.PUBLIC_URL}/images/search_1.png`} alt=""/>
                                            </span>
                                            <span className="icon-labels">Discover</span>
                                        </div>
                                        <div className="bottom-icons-items">
                                            <div className="icons-item-adds">
                                                <span className="icons-img" style={{display: "flex"}}>
                                                    <img src={`${process.env.PUBLIC_URL}/images/add.png`} alt=""/>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="bottom-icons-items">
                                            <span className="icons-bottom">
                                                <img src={`${process.env.PUBLIC_URL}/images/chat.png`} alt=""/>
                                            </span>
                                            <span className="icon-labels">Notification<span
                                                className="notification-badge"><button>9</button></span></span>
                                        </div>
                                        <div className="bottom-icons-items">
                                            <span className="icons-bottom">
                                                <img src={`${process.env.PUBLIC_URL}/images/user+(1).png`} alt=""/>
                                            </span>
                                            <span className="icon-labels">Me</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Flex>
                    </Container>
                </Box>

                    <Flex width="100%" justify="flex-end" py={10}>
                        <Button
                            mr={4}
                            onClick={prevStep}
                            colorScheme='blue' variant='outline' borderRadius={0} px={10}
                        >
                            Back
                        </Button>
                        <Button mr={4} colorScheme='blue' variant='solid' borderRadius={0} px={10}>
                            Save Draft
                        </Button>
                        <Button mr={4} onClick={handleSubmit} colorScheme='green' variant='solid' borderRadius={0} px={10}>
                            { adUploadId ? 'Update' : 'Submit'}
                        </Button>
                    </Flex>

                </GridItem>
                <SuccessModal
                    isOpen={isSuccessModalOpen}
                    onClose={() => onCloseSuccessModal()}
                />
                <ErrorModal
                    isOpen={isErrorModalOpen}
                    onClose={() => setErrorModal(false)}
                    description={description}
                />
            </Grid>
        </>
    );
};

export default WithAppContext(TiktokVideoAdPreview);
