import {
  Heading,
  Button,
  Divider,
  Text,
  Input,
  InputLeftElement,
  InputGroup,
  Stack,
  Icon,
  TagLeftIcon,
  Tag,
  HStack,
  useToast,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
} from "@chakra-ui/react";

import Datatable from "../../components/Datatable";
import React, { useEffect, useMemo, useState } from "react";
import { useGetCampaignList } from "../../hooks/campaign-briefs/useGetCampaignList";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { IoMdAddCircle } from "react-icons/io";
import {
  CheckCircleIcon,
  CheckIcon,
  SearchIcon,
  SmallCloseIcon,
  WarningIcon,
} from "@chakra-ui/icons";
import { TEXT_COLOR } from "../../layout/constant/MenuList";
import { useCreateClone } from "../../hooks/campaign-briefs/useCreateClone";
import { FiCopy, FiEye } from "react-icons/fi";
import { TagLabel } from "@chakra-ui/tag";
import { AD_UPLOAD_STATUS } from "../../constant";
import { deleteAllDV360AudioField } from "./components/AdUpload/StepByStepAdUploadComponent/DV360/DV360Audio/store";
import { deleteTheField } from "../../store/adUploads";
import DeleteModel from "../../components/PopupModal/DeleteModel";
import SuccessModal from "../../components/PopupModal/SuccessModal";
import { useDeleteCampaign } from "../../hooks/campaign-briefs/useDeleteCampaign";
import InputBox from "../../components/InputBox";
import { useRecoilState, useRecoilValue } from "recoil";
import { profile } from "../../atoms/authAtom";
import { BsThreeDotsVertical } from "react-icons/bs";
import SearchAndButton from "../../components/SearchAndButton";

const CampaignBriefs = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState();
  const {
    access_info: { roles, clients: userClients },
  } = useRecoilValue(profile);
  const [isClient, setIsClient] = useState(false);
  const [campaignData, setCampaignData] = useState([]);
  const clientId = useSelector((state) => state.client.clientId);

  const { data, refetch } = useGetCampaignList(clientId);
  const { mutate, isLoading } = useCreateClone();

  useEffect(() => {
    if (roles?.length === 1) {
      setIsClient(
        roles.includes("CampaignManager") || roles.includes("Client")
      );
    } else {
      setIsClient(false);
    }
  }, [roles]);

  useEffect(() => {
    refetch();
    setTimeout(() => {
      refetch();
    }, 2000);
  }, []);

  useEffect(() => {
    setCampaignData(data?.campaigns);
  }, [data]);

  useEffect(() => {
    if (search?.trim()) {
      const searchedCampaign = campaignData.filter((el) => {
        if (el?.name?.toLowerCase().includes(search.trim())) {
          return true;
        } else if (el?.end_date?.toLowerCase().includes(search.trim())) {
          return true;
        } else if (el?.start_date?.toLowerCase().includes(search.trim())) {
          return true;
        }
      });
      setCampaignData(searchedCampaign);
    } else {
      setCampaignData(data?.campaigns);
    }
  }, [search]);

  const checkStatusByTag = (value) => {
    return AD_UPLOAD_STATUS[value.toUpperCase()];
  };

  const [isDeleteModalOpen, setDeleteModal] = useState(false);
  const [briefName, setBriefName] = useState(null);
  const [campaignId, setCampaignId] = useState(null);

  const { data: deleteData, refetch: refetchDelete } = useDeleteCampaign(
    clientId,
    campaignId
  );
  let toast = useToast();

  const onCloseDeleteModal = () => {
    refetchDelete()
      .then((resp) => {
        if (resp?.data?.status === 200) {
          refetch();
          toast({
            isClosable: true,
            status: "success",
            variant: "top-accent",
            position: "top-right",
            description: "Deleted Successfully!",
          });
          setDeleteModal(false);
        } else {
          setDeleteModal(false);
          toast({
            isClosable: true,
            status: "error",
            variant: "top-accent",
            position: "top-right",
            description: resp?.data?.data?.message || "Something goes wrong!",
          });
        }
      })
      .catch((err) => {
        setDeleteModal(false);
      });
  };

  function handleDeleteModel(briefName, campId) {
    setDeleteModal(true);
    setBriefName(briefName);
    setCampaignId(campId);
  }

  function onDestroyModel() {
    setDeleteModal(false);
  }

  const columns = useMemo(
    () => [
      {
        Header: "Campaign Name",
        accessor: "name",
        Cell: (data) => {
          return (
            <Box maxW="150px">
              <Text noOfLines={1} fontSize="xs">
                {data.value}
              </Text>
            </Box>
          );
        },
      },
      {
        Header: "Business Name",
        accessor: "detail.businessDetails.name",
        Cell: (data) => {
          return (
            <Box maxW="150px">
              <Text noOfLines={1} fontSize="xs">
                {data.value}
              </Text>
            </Box>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (data) => {
          return (
            <Box>
              {AD_UPLOAD_STATUS.CREATED === data.value.toUpperCase() && (
                <Tag
                  backgroundColor={"#FFC108"}
                  size="md"
                  color={"white"}
                  borderRadius={100}
                >
                  <TagLeftIcon boxSize="3" as={WarningIcon} />
                  <TagLabel fontSize="xx-small">Action Needed</TagLabel>
                </Tag>
              )}
              {AD_UPLOAD_STATUS.APPROVED === data.value.toUpperCase() && (
                <Tag
                  backgroundColor={"#63C68B"}
                  size="md"
                  color={"white"}
                  borderRadius={100}
                >
                  <TagLeftIcon boxSize="3" as={CheckCircleIcon} />
                  <TagLabel fontSize="xx-small">Approved</TagLabel>
                </Tag>
              )}
              {AD_UPLOAD_STATUS.REJECTED === data.value.toUpperCase() && (
                <Tag
                  backgroundColor="#A7A9BD"
                  size="md"
                  color={"white"}
                  borderRadius={100}
                >
                  <TagLeftIcon
                    boxSize="3"
                    as={SmallCloseIcon}
                    backgroundColor={"white"}
                    color={"#A7A9BD"}
                    borderRadius={100}
                  />
                  <TagLabel fontSize="xx-small">Declined</TagLabel>
                </Tag>
              )}
            </Box>
          );
        },
      },
      {
        Header: "Start Date",
        accessor: "start_date",
        Cell: (data) => {
          return (
            <Box maxW="150px">
              <Text noOfLines={1} fontSize="xs">
                {data.value}
              </Text>
            </Box>
          );
        },
      },
      {
        Header: "End Date",
        accessor: "end_date",
        Cell: (data) => {
          return (
            <Box maxW="150px">
              <Text noOfLines={1} fontSize="xs">
                {data.value}
              </Text>
            </Box>
          );
        },
      },
      {
        Header: "Action",
        accessor: "actions",
        Cell: (data) => {
          const status = data.row.values.status;
          const name = data.row.values.name;
          const campId = data.row.original.id;
          return (
            <HStack justify="end" pr="4">
              <Menu>
                <MenuButton
                  variant="ghost"
                  maxW="20px"
                  as={IconButton}
                  size="sm"
                >
                  <Icon as={BsThreeDotsVertical} boxSize="4" />
                </MenuButton>
                <MenuList>
                  <MenuItem
                    as={Link}
                    to={`/campaign-briefs/${data.row.original.id}`}
                  >
                    {AD_UPLOAD_STATUS.CREATED === status.toUpperCase()
                      ? "View/Edit"
                      : "View"}
                  </MenuItem>
                  {!roles.includes("Client") && (
                    <>
                      <MenuItem onClick={() => handleDeleteModel(name, campId)}>
                        Delete
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          mutate({
                            clientId: data.row.original.client_id,
                            campaignBriefId: data.row.original.id,
                          });
                        }}
                      >
                        Clone
                      </MenuItem>
                    </>
                  )}
                </MenuList>
              </Menu>

              {/* <Button
                as={Link}
                size="sm"
                variant="outline"
                aria-label="View details"
                icon={<FiEye />}
                to={`/campaign-briefs/${data.row.original.id}`}
                color="#3B82F6"
                py={5}
                px="2"
                w={100}
              >
                {AD_UPLOAD_STATUS.CREATED === status.toUpperCase()
                  ? "View/Edit"
                  : "View"}
              </Button> */}
              {/* {!roles.includes("Client") && (
                <>
                  <Button
                    size="sm"
                    variant="outline"
                    aria-label="View details"
                    icon={<FiEye />}
                    color="#FF8189"
                    py={5}
                    w={100}
                    // disabled={"true"}
                    onClick={() => handleDeleteModel(name, campId)}
                  >
                    Delete
                  </Button>
                  <Button
                    size="sm"
                    variant="outline"
                    isLoading={isLoading}
                    onClick={() => {
                      mutate({
                        clientId: data.row.original.client_id,
                        campaignBriefId: data.row.original.id,
                      });
                    }}
                    colorScheme="yellow"
                    py={5}
                  >
                    Clone
                  </Button>
                </>
              )} */}
            </HStack>
          );
        },
      },
    ],
    []
  );

  return (
    <div className="ad-upload-list">
      <DeleteModel
        heading={"Delete Brief"}
        name={briefName}
        isOpen={isDeleteModalOpen}
        onClose={() => onCloseDeleteModal()}
        onDestory={() => onDestroyModel()}
      />
      <Heading color="brand.heading" fontWeight="600" size="lg" my={5} mb={7}>
        Campaigns
      </Heading>

      <SearchAndButton
        buttonText="Create new brief"
        btnClick={() => navigate("/campaign-briefs/new")}
        setSearch={(e) => setSearch(e.target.value)}
      />

      <Divider />
      <Datatable
        data={campaignData || []}
        columns={columns}
        headerColor={"brand.primary"}
      />
    </div>
  );
};

export default CampaignBriefs;
