import { createSlice } from "@reduxjs/toolkit";
import {adUploadSlice} from "../../../../../../../../store/adUploads";
import {AD_UPLOAD_TYPE} from "../../../../../../../../constant";

const initialState = {
    formDetails: {},
    mediaDetails: [],
    handlePreviewData: null,
};

export const instagramImageSlice = createSlice({
    name: AD_UPLOAD_TYPE.INSTAGRAM_IMAGE,
    initialState,
    reducers: {
        settingInstagramFormDetails: (state, action) => {
            state.formDetails = action.payload
        },
        settingInstagramMedia: (state, action) => {
            state.mediaDetails = action.payload
        },
        settingInstagramPreview: (state, action) => {
            state.handlePreviewData = action.payload
        },
        deleteAllInstagramImageFields: (state, action) => {
            state.formDetails = {};
            state.mediaDetails = null;
            state.handlePreviewData = null;
        }
    },
});

export const { deleteAllInstagramImageFields,settingInstagramFormDetails,settingInstagramMedia,settingInstagramPreview} = instagramImageSlice.actions;

// The reducer
export const instagramImageReducer = instagramImageSlice.reducer;
