import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetAdUpload } from "../../../../../../../hooks/campaign-briefs/useGetAdUpload";
import { Form, FormikProvider, useFormik } from "formik";
import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  css,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
} from "@chakra-ui/react";
import { InputControl, SelectControl, TextareaControl } from "formik-chakra-ui";
import {
  dv360AdUploadalueLengths,
  dv360AdUploadInitialValues,
  dv360AdVideoInitialValue,
  pinterestAdUploadvalueLengths,
} from "../../../../../constant/InitialValues";
import {
  CreativeType,
  hostingSource,
} from "../../../../../constant/SelectValues";
import * as yup from "yup";
import { Flex } from "@chakra-ui/layout";
import {
  settingEditMediaType,
  settingFormDetails,
  settingFormMedia,
} from "../../../../../../../store/adUploads";
import { TEXT_COLOR } from "../../../../../../../layout/constant/MenuList";
import {
  settingPinterestVideo,
  settingPinterestVideoFormDetail,
  settingPinterestVideoFormMedia,
} from "./store";
import TooltipComponent from "../../TooltipComponent";

const initialValue = {
  adName: "",
  title: "",
  description: "",
  link: "",
};

function PinterestVideoAdDetails({
  activeStep,
  steps,
  prevStep,
  nextStep,
  label,
}) {
  const { id, adUploadId } = useParams();

  const clientId = useSelector((state) => state.client.clientId);
  const { formDetails } = useSelector((state) => state.pinterestVideoAd);

  const { data, refetch } = useGetAdUpload(clientId, id, adUploadId);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (adUploadId) {
      dispatch(
        settingPinterestVideoFormDetail({
          ...data?.adUpload?.detail,
          adName: data?.adUpload?.detail?.name,
          title: data?.adUpload?.detail?.title,
          description: data?.adUpload?.detail?.description,
          link: data?.adUpload?.detail?.link,
        })
      );
      dispatch(
        settingPinterestVideoFormMedia({
          fileURL: data?.adUpload?.detail?.audio?.fileInfoList,
        })
      );
    }
  }, [data]);

  const dispatch = useDispatch();

  const schema = yup.object().shape({
    adName: yup.string().trim().min(3).required(100).label("Ad name"),
    title: yup.string().trim().min(3).max(100).required().label("Title"),
    description: yup.string().trim().min(3).required(500).label("Description"),
    link: yup
      .string()
      .trim()
      .required("Landing page url is required")
      .max(255)
      .matches(
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
        "Landing page url is not valid!"
      )
      .label("Landing page url"),
  });

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: schema,
    onSubmit: (values) => {
      dispatch(
        settingPinterestVideoFormDetail({
          fileInfoList: data?.adUpload?.detail?.audio?.fileInfoList,
          ...data?.adUpload?.detail,
          ...values,
        })
      );
      nextStep();
    },
  });

  const { errors, values, touched, handleSubmit, isValid, setFieldValue } =
    formik;

  useEffect(() => {
    setFieldValue("adName", formDetails.adName || "");
    setFieldValue("title", formDetails.title || "");
    setFieldValue("description", formDetails.description || "");
    setFieldValue("link", formDetails.link || "");
  }, [formDetails]);

  return (
    <>
      {adUploadId && (
        <Box
          display="flex"
          mb={6}
          justifyContent="space-between"
          alignItems="center"
        >
          <Heading fontSize="xl" color={"#757998"}>
            Please review the ad details carefully.
          </Heading>
          <Heading fontSize="md" color="#757998">
            3 steps to complete
          </Heading>
        </Box>
      )}

      {!adUploadId && (
        <Box
          display="flex"
          mb={6}
          justifyContent="space-between"
          alignItems="center"
        >
          <Heading fontSize="xl" color={"#757998"}>
            Please fill in your ad details.
          </Heading>
          <Heading fontSize="md" color="#757998">
            3 steps to complete
          </Heading>
        </Box>
      )}

      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <Grid gap={4} className="fb-upload">
            <GridItem w="full" colSpan={{ base: 6, lg: 4 }}>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel
                    htmlFor="adName"
                    color="gray"
                    fontSize="sm"
                    display="none"
                  >
                    Ad Name - max 100 characters ( Your first 40 characters are
                    what usually show up in feeds. )
                  </FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="adName"
                      name="adName"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder:
                          "Ad Name - max 100 characters ( Your first 40 characters are what usually show up in feeds. )",
                      }}
                      onChange={(e) => setFieldValue("adName", e.target.value)}
                    />
                    <TooltipComponent
                      text={
                        "The ad name helps you distinguish between ads and won’t appear in the final ad. Pick something memorable but relevant to your ad."
                      }
                    />

                    <CircularProgress
                      max={pinterestAdUploadvalueLengths.adName}
                      value={values.adName.length}
                      color={
                        values.adName.length >
                        pinterestAdUploadvalueLengths.adName
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values.adName.length >
                        pinterestAdUploadvalueLengths.adName
                          ? pinterestAdUploadvalueLengths.adName -
                            values.adName.length
                          : values.adName.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel
                    htmlFor="title"
                    color="gray"
                    fontSize="sm"
                    display={"none"}
                  >
                    Title - max 100 characters ( Your first 40 characters are
                    what usually show up in feeds. )
                  </FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="title"
                      name="title"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder:
                          "Title - max 100 characters ( Your first 40 characters are what usually show up in feeds. )",
                      }}
                      onChange={(e) => setFieldValue("title", e.target.value)}
                    />
                    <CircularProgress
                      max={pinterestAdUploadvalueLengths.adName}
                      value={values.adName.length}
                      color={
                        values.adName.length >
                        pinterestAdUploadvalueLengths.adName
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values.adName.length >
                        pinterestAdUploadvalueLengths.adName
                          ? pinterestAdUploadvalueLengths.adName -
                            values.adName.length
                          : values.adName.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="description" display={"none"}>
                    Description - max 500 characters ( People will usually see
                    the first 50 characters when they click on your Pin)
                  </FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="description"
                      name="description"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder:
                          "Description - max 500 characters ( People will usually see the first 50 characters when they click on your Pin)",
                      }}
                      onChange={(e) =>
                        setFieldValue("description", e.target.value)
                      }
                    />
                    <CircularProgress
                      max={pinterestAdUploadvalueLengths.description}
                      value={values.description.length}
                      color={
                        values.description.length >
                        pinterestAdUploadvalueLengths.description
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values.description.length >
                        pinterestAdUploadvalueLengths.description
                          ? pinterestAdUploadvalueLengths.description -
                            values.description.length
                          : values.description.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="link" display={"none"}>
                    Landing Page URL - max 255 characters
                  </FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="link"
                      name="link"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "Landing Page URL - max 255 characters",
                      }}
                      onChange={(e) => setFieldValue("link", e.target.value)}
                    />
                    <CircularProgress
                      max={pinterestAdUploadvalueLengths.link}
                      value={values.link.length}
                      color={
                        values.link.length > pinterestAdUploadvalueLengths.link
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values.link.length > pinterestAdUploadvalueLengths.link
                          ? pinterestAdUploadvalueLengths.link -
                            values.link.length
                          : values.link.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
            </GridItem>
          </Grid>
          <Flex width="100%" justify="flex-end" py={10}>
            {!adUploadId && (
              <Button
                mr={4}
                onClick={prevStep}
                colorScheme="blue"
                variant="outline"
                borderRadius={0}
                px={10}
              >
                Back
              </Button>
            )}
            {label === "Ad Details" && !(activeStep === steps.length - 1) && (
              <Button
                mr={4}
                colorScheme="green"
                type="submit"
                variant="solid"
                borderRadius={0}
                px={10}
              >
                Next
              </Button>
            )}
          </Flex>
        </Form>
      </FormikProvider>
    </>
  );
}

export default PinterestVideoAdDetails;
