import { CloseIcon, DownloadIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import {
    Box, Button,
    Flex, Grid, Heading, Input, Image, Progress, Spacer, Text, Tooltip, useToast
} from "@chakra-ui/react";
import React, {useCallback, useEffect, useState} from "react";
import { useDropzone } from "react-dropzone";
import FileSaver from 'file-saver';
import {FACEBOOK_IMAGE_HEIGHT, FACEBOOK_IMAGE_WIDTH, FB_IMAGE_SIZE} from "../../../../../../../constant";
import {BLUE_BUTTON_COLOR, GREY_TEXT_COLOR, TEXT_COLOR} from "../../../../../../../layout/constant/MenuList";
import WithAppContext from "../../../../../../../helpers/appContext";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {useUploadImage} from "../../../../../../../hooks/campaign-briefs/useUploadImage";
import {useCreateAdPreview} from "../../../../../../../hooks/campaign-briefs/useCreateAdPreview";
import DefaultIcon from "../../../../../../../assets/images/jpg.svg";
import FileUploadIcon from "../../../../../../../assets/images/upload.svg";



const FacebookImagAdFileUpload = ({ values, setFieldValue, setErrors, errors, context, onDrop }) => {
    const [fileURL, setFileURL] = useState('')
    const {ErrorToaster} = context;


    useEffect(() => {
        if (values && values.thumbnailURL) {
            const file  = values.thumbnailURL;
            const getFileURL =  file && `${process.env.REACT_APP_API_URL}/uploads/${file?.filename}`;
            setFileURL(getFileURL)
            setErrors('thumbnailURL', {});
        }
    }, [values])

    const acceptImageFiles = {
        "image/png": [".png"],
        "image/jpeg": [".jpg"],
    };

    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        onDrop,
        accept: acceptImageFiles,
        maxSize: FB_IMAGE_SIZE,
        disabled: values.thumbnailURL,
    });

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    // dropZone Errors
    useEffect(() => {
        fileRejections && fileRejections.map(({errors}) => {
            errors.map((err) => ErrorToaster(err.code + ',' + err.message));
        })
    }, [fileRejections])

    return (
        <>
            <Box className="upload-main" >
            <Grid border='2px'
                  borderColor={(errors.thumbnailURL) ? 'red.500' : 'gray.200'}
                  padding="20px" borderStyle="dashed">
                <Grid>
                    <div {...getRootProps()}>
                        <Input {...getInputProps()} />

                        <div className="file-upload-content">
                            <Image
                                boxSize="100px"
                                objectFit="cover"
                                src={FileUploadIcon}
                                alt="upload-icon"
                            />
                            <Heading
                                fontSize="xl"
                                display="flex"
                                alignItems="center"
                                color={GREY_TEXT_COLOR}
                                my={4}
                            >
                                Drag image files to upload
                                <Tooltip label={
                                    <Box>
                                        <Text color="#000"><b>Image file type:</b> jpg, png and jpeg</Text>
                                        <Text color="#000"><b>Image maximum file size:</b> 30 MB</Text>
                                        {/*<Text color="#000"><b>Image maximum files:</b> 12</Text>*/}
                                    </Box>} closeDelay={500} bg='#e2e8f0'>
                                    <InfoOutlineIcon marginLeft="2" height="15px" width="15px" marginTop="2px"/>
                                </Tooltip>
                            </Heading>
                            <Text
                                fontSize="sm"
                                mb={4}
                                color={GREY_TEXT_COLOR}
                            >
                                - OR -
                            </Text>
                            <Button bgColor={BLUE_BUTTON_COLOR} color="white" size="small" px={10} py={2} borderRadius={100}>Browse Files</Button>
                        </div>
                    </div>
                </Grid>
                {(errors.thumbnailURL) &&
                <Text color="red.500" marginTop="2" textAlign="center">
                    Image File is required
                </Text>
                }
            </Grid>

                <div className="image-listing">

            {values.thumbnailURL &&
            <Grid className="file-box">
                <Flex marginTop="5" marginBottom="5">
                    <Box>

                    <Image
                        boxSize="50px"
                        objectFit="cover"
                        src={DefaultIcon}
                        alt="Dan Abramov"
                    />
                    </Box>
                    <Grid marginLeft="2">
                        <Grid>
                            <Text
                                fontSize="15px"
                                textOverflow="ellipsis"
                                overflow='hidden'
                                whiteSpace="nowrap"
                                className="file-name"
                            >
                                {values.thumbnailURL.originalname}
                            </Text>
                        </Grid>
                        <Text fontSize="sm">
                            {formatBytes(values.thumbnailURL.size)}
                        </Text>
                    </Grid>
                    <Spacer />
                    <Grid>
                        <Grid justifyContent="center">
                            <CloseIcon
                                cursor="pointer"
                                className="close-icon"
                                size="small"
                                onClick={() => {
                                    setFieldValue('thumbnailURL', '')
                                    setFieldValue('thumbnailFile', '')
                                }}
                            />
                        </Grid>
                        <Grid>
                            <DownloadIcon
                                onClick={() => FileSaver.saveAs(fileURL, values.thumbnailURL.originalname)}
                                cursor="pointer"
                                className="close-icon"
                                size="small"
                                w={6}
                                h={6}
                            />
                        </Grid>
                    </Grid>

                </Flex>
                <Box marginTop={"-20px"}>
                    <Text textAlign={"right"} display={"block"} color={"green"}>Completed</Text>
                    <Progress
                        className="loading"
                        value={100}
                        colorScheme='green'
                        size='sm'
                    />
                </Box>

            </Grid >
            }
                </div>

            </Box>

        </>
    );
}

export default WithAppContext(FacebookImagAdFileUpload);
