import { useDropzone } from 'react-dropzone';
import { CloseIcon, DownloadIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import {
    Button,
    Flex, Grid, Heading, Image, Input, Progress, Spacer, Text, Tooltip, Box
} from "@chakra-ui/react";
import React from "react";
import { TEXT_COLOR } from "../../../../../../layout/constant/MenuList";
import FileUploadIcon from "../../../../../../assets/images/file-upload-icon.png";
import DefaultImageIcon from "../../../../../../assets/images/default-image-icon.png";
import FileSaver from 'file-saver';

export function UploadAudioThumbnail({ files, onDelete, onDrop, errors, touched }) {

    const acceptImageFiles = {
        "image/png": [".png"],
        "image/jpeg": [".jpg", ".jpeg"],
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: acceptImageFiles,
        maxSize: 10 * 1024 * 1024,
        disabled: files.length !== 0 && true,
    });

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };


    return (
        <>
            <Grid border='2px' borderColor={(errors.audioThumbnail && touched.audioThumbnail) ? 'red.500' : 'gray.200'} padding="20px" borderStyle="dashed" marginTop="20px">
                <div {...getRootProps()}>
                    <Input {...getInputProps()} />
                    <div className="file-upload-content">
                        <Image
                            boxSize="100px"
                            objectFit="cover"
                            src={FileUploadIcon}
                            alt="upload-icon"
                        />
                        <Heading fontSize="xl" display="flex" alignItems="center" mb={1} color={TEXT_COLOR}>
                            Drag and drop audio thumbnail
                            <Tooltip label={
                                <Box>
                                    <Text color="#000"><b>Image file type:</b> jpg, png and jpeg</Text>
                                    <Text color="#000"><b>Image maximum file size:</b> 10 MB</Text>
                                </Box>} closeDelay={500} bg='#e2e8f0'>
                                <InfoOutlineIcon marginLeft="2" height="15px" width="15px" marginTop="2px" />
                            </Tooltip>
                        </Heading>
                        <Heading fontSize="sm" mb={1} color={TEXT_COLOR}>
                            or
                        </Heading>
                        <Button size="small" onClick={() => {
                        }} disabled={files.length !== 0 && true}>Browse</Button>
                    </div>
                </div>
            </Grid>
            {(errors.audioThumbnail && touched.audioThumbnail) &&
                <Text color="red.500" marginTop="1">
                    Audio thumbnail is required
                </Text>
            }
            {
                files.map((fileWrapper, index) => {
                    let videoURL = fileWrapper?.imageUrl ? `${process.env.REACT_APP_API_URL}/uploads${fileWrapper?.imageUrl}` : URL.createObjectURL(fileWrapper.file);
                    const filename = fileWrapper?.filename || fileWrapper?.file?.name
                    return (
                        <Grid className="file-box" key={index}>
                            <Flex marginTop="5" marginBottom="5">
                                <Image
                                    boxSize="50px"
                                    objectFit="cover"
                                    src={DefaultImageIcon}
                                    alt="Dan Abramov"
                                />
                                <Grid marginLeft="2">
                                    <Grid>
                                        <Text
                                            fontSize="lg"
                                            className="file-name"
                                        >
                                            {filename}
                                        </Text>
                                    </Grid>
                                    <Text fontSize="sm">
                                        {formatBytes(fileWrapper?.file?.size)}
                                    </Text>
                                </Grid>
                                <Spacer />
                                <Grid>
                                    <Grid justifyContent="center">
                                        <CloseIcon
                                            cursor="pointer"
                                            className="close-icon"
                                            size="small"
                                            onClick={() => {
                                                if (fileWrapper.file) {
                                                    onDelete(fileWrapper.file)
                                                } else {
                                                    onDelete(fileWrapper)
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid>
                                        <DownloadIcon
                                            onClick={() => FileSaver.saveAs(videoURL, filename)}
                                            cursor="pointer"
                                            className="close-icon"
                                            size="small"
                                            w={6}
                                            h={6}
                                        />
                                    </Grid>
                                </Grid>
                            </Flex>
                            <Progress
                                className="loading"
                                value={100}
                            />
                        </Grid>
                    );
                })
            }
        </>
    );
}
