import {Flex} from "@chakra-ui/layout";
import {Button} from "@chakra-ui/react";
import {Form} from "formik";
import React from "react";

function DV360YoutubeVideoAdMedia({activeStep, steps, prevStep, nextStep, label}) {

    return (
        <>
            <h1>Youtube Video Media</h1>
        <Flex width="100%" justify="flex-end" py={10}>
            <Button
                isDisabled={activeStep === 0}
                mr={4}
                onClick={() => prevStep(2)}
                colorScheme='blue' variant='outline' borderRadius={0} px={10}
            >
                Back
            </Button>
            <Button mr={4} onClick={nextStep} colorScheme='green'  variant='solid' borderRadius={0} px={10}>
                Next
            </Button>
        </Flex>
            </>
    )
}

export default DV360YoutubeVideoAdMedia