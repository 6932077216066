import {Box, HStack, useToast, VStack, Button, Stack, Image, Heading, Text} from "@chakra-ui/react";
import React from "react";
import {TEXT_COLOR} from "../../../../layout/constant/MenuList";

function SuccessSubmittedUI() {
    return (
        <>
            <Box display="flex" justifyContent="space-between" mb={6}>
                <Heading fontSize="xl" color={TEXT_COLOR}>
                    Additional Questions
                </Heading>
                <Heading fontSize="md" color="gray.400">
                    0 step to complete
                </Heading>
            </Box>
        <Box p={10} border={"20px solid rgba(239, 246, 255, 0.5)"} mb={10} py={20} display="flex" flexDirection={"column"} gap={4} justifyContent={"center"} alignItems={"center"}>

            <Box display="flex" flexDirection={"column"} alignItems={"center"} gap={"4"}>
                <Image
                    src={`${process.env.PUBLIC_URL}/images/success.png`}
                    height="80px"
                />
                <Heading size='lg' color="blue.500">Success!</Heading>
                <Text w={"50%"} textAlign={"center"}>Your ad has been saved. Your ad set will not go live until you approve the ad previews.</Text>
            </Box>
        </Box>
            </>
    )
}

export default SuccessSubmittedUI