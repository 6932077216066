import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  HStack,
  Heading,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Datatable from "../../components/Datatable";
import { useGetClientList } from "../../hooks/clients/useGetClientList";
import Actions from "./components/Actions";
import "../../pages/CampaignBriefs/style/AdUploadList.css";
import { TEXT_COLOR } from "../../layout/constant/MenuList";
import { IoMdAddCircle } from "react-icons/io";
import UserTableNameWithProfile from "../Users/components/UserTableNameWithProfile";
import { BsThreeDotsVertical } from "react-icons/bs";
import SearchAndButton from "../../components/SearchAndButton";

const Clients = () => {
  const { data, refetch } = useGetClientList();
  const [clients, setClients] = useState([]);
  const [search, setSearch] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    setClients(data?.clients);
  }, [data]);

  useEffect(() => {
    if (search?.trim()) {
      const searchedClients = clients.filter((el) => {
        if (el?.description?.toLowerCase().includes(search.trim())) {
          return true;
        } else if (el?.name?.toLowerCase().includes(search.trim())) {
          return true;
        } else if (
          el?.detail?.industry?.toLowerCase().includes(search.trim())
        ) {
          return true;
        }
      });
      setClients(searchedClients);
    } else {
      setClients(data?.clients);
    }
  }, [clients, data?.clients, search]);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: (data) => <UserTableNameWithProfile data={data} />,
      },
      {
        Header: "Industry",
        accessor: "detail.industry",
      },
      {
        Header: "State",
        accessor: "state",
        Cell: (data) => {
          return (
            <>
              <Icon
                viewBox="0 0 200 200"
                mr={2}
                color={
                  data.row.original.state === "ACTIVE"
                    ? "#3F7EE6"
                    : data.row.original.state === "INACTIVE"
                    ? "#B5B7C8"
                    : data.row.original.state === "ON_HOLD"
                    ? "#59AB9E"
                    : "#3F7EE6"
                }
              >
                <path
                  fill="currentColor"
                  d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
                />
              </Icon>
              {data.row.original.state}
            </>
          );
        },
      },
      {
        Header: "Action",
        accessor: "actions",
        Cell: (data) => {
          return (
            <VStack justify="end" maxW="20px">
              <Menu>
                <MenuButton as={IconButton} variant="ghost" size="sm">
                  <Icon as={BsThreeDotsVertical} />
                </MenuButton>
                <MenuList>
                  <MenuItem
                    as={Link}
                    to={`/client/${data.row.original.id}`}
                    aria-label="View details"
                  >
                    View/Edit
                  </MenuItem>
                  <MenuItem>Delete</MenuItem>
                </MenuList>
              </Menu>
            </VStack>
          );
        },
      },
    ],
    []
  );

  return (
    <div className="ad-upload-list">
      <Heading
        color={TEXT_COLOR}
        fontWeight="500"
        fontSize={{
          base: "xl",
          md: "3xl",
        }}
        my={5}
        mb={7}
      >
        Clients
      </Heading>
      <SearchAndButton
        buttonText="Add Client"
        btnClick={() => navigate("/client/new")}
        setSearch={(e) => setSearch(e.target.value)}
      />

      <Divider />
      <Datatable data={clients || []} columns={columns} />
    </div>
  );
};

export default Clients;
