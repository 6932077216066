import {Flex} from "@chakra-ui/layout";
import {Button} from "@chakra-ui/react";
import React from "react";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";

function AdUploadFormStepButton({ activeStep, steps, prevStep, nextStep, label}) {
    const { id } = useParams();
    const {selectedSocialMedia, mediaType} = useSelector((state) => state.adUpload)

    if(label === "Ad Type") {
        return (
            <Flex width="100%" justify="flex-end" py={10}>
                <Button
                    isDisabled={activeStep === 0}
                    mr={4}
                    onClick={prevStep}
                    colorScheme='blue' variant='outline' borderRadius={0} px={10}
                >
                    Back
                </Button>
                <Button mr={4} isDisabled={true} onClick={nextStep} colorScheme='green' variant='solid' borderRadius={0} px={10}>
                        Next
                </Button>
            </Flex>
        )
    }

    return null

}

export default AdUploadFormStepButton