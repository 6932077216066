import {useQuery} from "react-query";
import axios from "../../helpers/axios";

export const useGetClientBusiness = (clientId) => {

    return useQuery(['users'], async () => {
       if(clientId){
           return axios
               .get(`/client/${clientId}/business/all`, {
                   withCredentials: false,
               })
               .then((res) => {
                   if (res.status === 200) {
                       return res.data.data
                   }
               })
               .catch((err) => {
                   return err.response
               })
       }
    })
}