import { createSlice } from "@reduxjs/toolkit";
import {adUploadSlice} from "../../../../../../../../store/adUploads";
import {AD_UPLOAD_TYPE} from "../../../../../../../../constant";

const initialState = {
    formDetails: {},
    mediaDetails: [],
    handlePreviewData: null,
};

export const pinterestImageSlice = createSlice({
    name: AD_UPLOAD_TYPE.PINTEREST_IMAGE,
    initialState,
    reducers: {
        settingPinterestFormDetails: (state, action) => {
            state.formDetails = action.payload
        },
        settingPinterestMedia: (state, action) => {
            state.mediaDetails = action.payload
        },
        settingPinterestPreview: (state, action) => {
            state.handlePreviewData = action.payload
        },
        deleteAllPinterestImageFields: (state, action) => {
            state.formDetails = {};
            state.mediaDetails = null;
            state.handlePreviewData = null;
        }
    },
});

export const { deleteAllPinterestImageFields,settingPinterestFormDetails,settingPinterestMedia,settingPinterestPreview} = pinterestImageSlice.actions;

// The reducer
export const pinterestImageReducer = pinterestImageSlice.reducer;
