import React, { useEffect, useState } from "react";
import { Heading } from "@chakra-ui/layout";
import "../../pages/AdCampaigns/adCampaign.css";
import {
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Select,
} from "@chakra-ui/react";
import { Geography } from "../../pages/CampaignBriefs/constant/SelectValues";
import { Input } from "@chakra-ui/input";
import axios from "../../helpers/axios";
import MultiSelectInputBox from "../MultiSelectInputBox";

function CampaignGeography({
  selectedGeography,
  handleSelectedGeography,
  setLocation,
  location,
}) {
  const [showCountry, setShowCountry] = useState(false);
  const [showProvince, setShowProvince] = useState(false);
  const [showCity, setShowCity] = useState(false);
  const [showZipCode, setShowZipCode] = useState(false);
  const [countries, setCountries] = useState([]);
  const [province, setProvince] = useState([]);
  const [city, setCity] = useState([]);

  useEffect(() => {
    if (selectedGeography) {
      if (selectedGeography === "Country") {
        setShowCountry(true);
        setShowProvince(false);
        setShowCity(false);
        setShowZipCode(false);
      } else if (selectedGeography === "Province") {
        setShowCountry(true);
        setShowProvince(true);
        setShowCity(false);
        setShowZipCode(false);
      } else if (selectedGeography === "City") {
        setShowCountry(true);
        setShowProvince(false);
        setShowCity(true);
        setShowZipCode(false);
      } else {
        setShowCountry(true);
        setShowProvince(false);
        setShowCity(false);
        setShowZipCode(true);
      }
    }
  }, [selectedGeography]);

  const method = "get";
  const headers = {
    "X-CSCAPI-KEY": process.env.REACT_APP_GEOGRAPHY_API_KEY,
  };

  const config = { method, headers };

  useEffect(() => {
    axios("https://api.countrystatecity.in/v1/countries", config).then(
      (res) => {
        setCountries(res.data);
      }
    );
  }, []);

  useEffect(() => {
    if (location.country) {
      if (selectedGeography.toLowerCase() === "city") {
        axios(
          `https://api.countrystatecity.in/v1/countries/${location.country}/cities`,
          config
        ).then((res) => {
          setCity(res.data);
        });
      }
      if (selectedGeography.toLowerCase() === "province") {
        axios(
          `https://api.countrystatecity.in/v1/countries/${location.country}/states`,
          config
        ).then((res) => {
          setProvince(res.data);
        });
      }
    }
  }, [config, location.country, selectedGeography]);

  return (
    <div>
      <div style={{ margin: "8px 0" }}>
        <Heading fontSize={"lg"} fontWeight="500">
          Campaign Geography
        </Heading>
        <FormControl maxW="460px" mt={6} isRequired>
          <FormLabel>Add Location</FormLabel>
          <Select
            id="location"
            name="location"
            placeholder="Select"
            value={selectedGeography}
            onChange={handleSelectedGeography}
          >
            {Geography.map((objective) => (
              <option
                className="campaign-option"
                key={objective.label}
                value={objective.value}
              >
                {objective.label}
              </option>
            ))}
          </Select>
        </FormControl>
      </div>
      <Grid
        templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
        gap={6}
      >
        {showCountry && (
          <GridItem>
            <FormControl maxWidth={500} mt={4} isRequired>
              <FormLabel>Country</FormLabel>
              <Select
                id="country"
                name="country"
                placeholder="Select Country"
                value={location.country}
                onChange={(e) =>
                  setLocation({
                    ...location,
                    country: e.target.value,
                    city: [],
                    province: [],
                  })
                }
              >
                {countries.map((objective) => (
                  <option key={objective.id} value={objective.iso2}>
                    {objective.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </GridItem>
        )}
        {showProvince && (
          <GridItem>
            <FormControl maxWidth={500} mt={4} isRequired>
              <FormLabel>Province</FormLabel>
              <MultiSelectInputBox
                // label="Province"
                name="province"
                value={location.province}
                options={province?.map((el) => {
                  return {
                    label: el.name,
                    value: el.name,
                  };
                })}
                placeholder="-- Select Province --"
                onChange={(e) => {
                  setLocation({ ...location, province: e.map((v) => v) });
                }}
              />
            </FormControl>
          </GridItem>
        )}
        {showCity && (
          <GridItem>
            <FormControl maxWidth={500} mt={4} isRequired>
              <FormLabel>City</FormLabel>
              <MultiSelectInputBox
                // label="City"
                name="city"
                value={location.city}
                options={city?.map((el) => {
                  return {
                    label: el.name,
                    value: el.name,
                  };
                })}
                placeholder="-- Select City --"
                onChange={(e) => {
                  setLocation({ ...location, city: e.map((v) => v) });
                }}
              />
            </FormControl>
          </GridItem>
        )}
        {showZipCode && (
          <GridItem>
            <FormControl isRequired maxWidth={500} mt={4}>
              <FormLabel>Zip Code</FormLabel>
              <Input
                name="zipCode"
                borderColor="#e7e7e9"
                value={location.zipCode}
                onChange={(e) =>
                  setLocation({ ...location, zipCode: e.target.value })
                }
                borderRadius={1}
                color={"#757998"}
                fontWeight={500}
              />
            </FormControl>
          </GridItem>
        )}
      </Grid>
    </div>
  );
}

export default CampaignGeography;
