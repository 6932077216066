import { useDropzone } from 'react-dropzone';
import { CloseIcon, DownloadIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import {
    Button,
    Flex, Grid, Heading, Image, Input, Progress, Spacer, Text, Tooltip, Box
} from "@chakra-ui/react";
import React from "react";
import FileUploadIcon from "../../../../../../../assets/images/upload.svg";
import {BLUE_BUTTON_COLOR, GREY_TEXT_COLOR, TEXT_COLOR} from "../../../../../../../layout/constant/MenuList";
import JPGIcon from "../../../../../../../assets/images/jpg.svg"
import PNGIcon from "../../../../../../../assets/images/png.svg"
import FileSaver from 'file-saver';
import {useParams} from "react-router-dom";

export function UploadDV360AudioThumbnail({ files, onDelete, onDrop, errors, touched }) {

    const {id, adUploadId} = useParams()

    const acceptImageFiles = {
        "image/png": [".png"],
        "image/jpeg": [".jpg", ".jpeg"],
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: acceptImageFiles,
        maxSize: 10 * 1024 * 1024,
        disabled: files.length !== 0 && true,
    });

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };


    return (
        <>
            <Box className="upload-main">
            <Grid border='2px' borderColor={(errors.audioThumbnail && touched.audioThumbnail) ? 'red.500' : 'gray.200'} paddingY="40px" borderStyle="dashed" marginTop="20px">
                <div {...getRootProps()}>
                    <Input {...getInputProps()} />
                    <div className="file-upload-content">
                        <Image
                            boxSize="100px"
                            objectFit="cover"
                            src={FileUploadIcon}
                            alt="upload-icon"
                        />
                        <Heading
                            fontSize="xl"
                            display="flex"
                            alignItems="center"
                            color={GREY_TEXT_COLOR}
                            my={4}
                        >
                            Drag thumbnail files to upload
                            <Tooltip label={
                                <Box>
                                    <Text color="#000"><b>Thumbnail file type:</b> jpg, png and jpeg</Text>
                                    <Text color="#000"><b>Thumbnail maximum file size:</b> 10 MB</Text>
                                    <Text color="#000"><b>Thumbnail maximum files:</b> 12</Text>
                                </Box>} closeDelay={500} bg='#e2e8f0'>
                                <InfoOutlineIcon marginLeft="2" height="15px" width="15px" marginTop="2px"/>
                            </Tooltip>
                        </Heading>
                        <Text
                            fontSize="sm"
                            mb={4}
                            color={GREY_TEXT_COLOR}
                        >
                            - OR -
                        </Text>
                        <Button
                            bgColor={BLUE_BUTTON_COLOR} color="white" size="small" px={10} py={2}
                            disabled={files.length !== 0 && true} borderRadius={100}>Browse Files</Button>
                    </div>
                </div>
                {(errors.audioThumbnail && touched.audioThumbnail) &&
                <Text color="red.500" marginTop="2" textAlign={"center"}>
                    Audio thumbnail is required
                </Text>
                }
            </Grid>
                <div className="image-listing">

            {
                files.map((fileWrapper, index) => {
                    let videoURL = fileWrapper?.imageUrl ? `${process.env.REACT_APP_API_URL}/uploads${fileWrapper?.imageUrl}` : URL.createObjectURL(fileWrapper.file);
                    const filename = fileWrapper?.filename || fileWrapper?.file?.name
                    return (
                        <Box className="file-box" display={"flex"} alignItems={"center"} gap={"16px"} key={index} >
                            {
                                fileWrapper?.files?.mimetype === "image/jpeg" && <Image
                                    boxSize="50px"
                                    objectFit="cover"
                                    src={JPGIcon}
                                    alt="Dan Abramov"
                                />
                            }
                            {
                                fileWrapper?.file?.type === "image/jpeg" && <Image
                                    boxSize="50px"
                                    objectFit="cover"
                                    src={JPGIcon}
                                    alt="Dan Abramov"
                                />
                            }
                            {
                                fileWrapper?.file?.type === "image/png" && <Image
                                    boxSize="50px"
                                    objectFit="cover"
                                    src={PNGIcon}
                                    alt="Dan Abramov"
                                />
                            }
                            {
                                fileWrapper?.files?.mimetype  === "image/png" && <Image
                                    boxSize="50px"
                                    objectFit="cover"
                                    src={PNGIcon}
                                    alt="Dan Abramov"
                                />
                            }

                            <Box flexGrow={1}>
                                <Flex marginTop="5" marginBottom="5">
                                <Grid marginLeft="2">
                                    <Grid>
                                        <Text
                                            fontSize="lg"
                                            className="file-name"
                                        >
                                            {filename}
                                        </Text>
                                    </Grid>
                                    <Text fontSize="sm">
                                        { !adUploadId && formatBytes(fileWrapper?.file?.size)}
                                    </Text>
                                </Grid>
                                <Spacer />
                                <Grid>
                                    <Grid justifyContent="center">
                                        <CloseIcon
                                            cursor="pointer"
                                            className="close-icon"
                                            size="small"
                                            onClick={() => {
                                                if (fileWrapper.file) {
                                                    onDelete(fileWrapper.file)
                                                } else {
                                                    onDelete(fileWrapper)
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid>
                                        <DownloadIcon
                                            onClick={() => FileSaver.saveAs(videoURL, filename)}
                                            cursor="pointer"
                                            className="close-icon"
                                            size="small"
                                            w={6}
                                            h={6}
                                        />
                                    </Grid>
                                </Grid>
                            </Flex>
                                <Box marginTop={"-20px"}>
                                <Text textAlign={"right"} display={"block"} color={"green"}>100% Completed</Text>
                                <Progress
                                    className="loading"
                                    value={100}
                                    colorScheme='green'
                                    size='sm'
                                />
                            </Box>
                            </Box>
                        </Box>
                    );
                })
            }
                </div>
            </Box>
        </>
    );
}
