import {
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
  Flex,
} from "@chakra-ui/react";
import DetailsTab from "./DetailsTab";
import NotificationUpdate from "./NotificationUpdate";
import PasswordUpdate from "./PasswordUpdate";
import { useState } from "react";

const Settings = () => {
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <Flex w="full">
      <VStack alignItems="stretch" spacing={6} w="full" maxW="800px">
        <Heading
          color="brand.heading"
          fontWeight="600"
          fontSize={{
            base: "xl",
            md: "2xl",
          }}
        >
          Profile
        </Heading>
        <Tabs color="brand.text" onChange={(index) => setTabIndex(index)}>
          <TabList>
            <Tab
              fontSize={{ base: "xs", sm: "sm", md: "md" }}
              color="brand.label"
            >
              Personal Details
            </Tab>
            <Tab
              fontSize={{ base: "xs", sm: "sm", md: "md" }}
              color="brand.label"
            >
              {" "}
              Password
            </Tab>
            <Tab
              fontSize={{ base: "xs", sm: "sm", md: "md" }}
              color="brand.label"
            >
              Notifications
            </Tab>
          </TabList>
          <TabPanels px={{ base: "0", md: 0 }} mt="4">
            <TabPanel>
              <DetailsTab />
            </TabPanel>
            <TabPanel>
              <PasswordUpdate />
            </TabPanel>
            <TabPanel>
              <NotificationUpdate />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
    </Flex>
  );
};

export default Settings;
