import {useQuery} from "react-query";
import axios from "../../helpers/axios";
import {useSelector} from "react-redux";

export const useGetIndustriesCategories = () => {

    const {businessDetails} = useSelector((state)=> state.adCampaign)

    return useQuery(['industry-categories'], async () => {
        return axios
            .get(`/spreadsheet-config/industries/${businessDetails.industry}/categories`, {
                withCredentials: false,
            })
            .then((res) => {
                if (res.status === 200) {
                    return res.data
                }
            })
            .catch((err) => {
                return err.response
            })
    })
}