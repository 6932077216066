import DV360AdFileUpload from "../../../DV360/DV360AdFileUpload";
import {Box, Button, Grid, GridItem, Heading} from "@chakra-ui/react";
import React, {useEffect} from "react";
import {Form, FormikProvider, useFormik} from "formik";
import {dv360AdUploadInitialValues} from "../../../../../constant/InitialValues";
import {AD_UPLOAD_TYPE} from "../../../../../../../constant";
import instance from "../../../../../../../helpers/axios";
import * as yup from "yup";
import {checkIfFilesAreCorrectType, checkIfFilesAreTooBig} from "../../../../../../../helpers/fileValidation";
import {Flex} from "@chakra-ui/layout";
import {useDispatch, useSelector} from "react-redux";
import {settingFormMedia, settingHandlePreviewData} from "../../../../../../../store/adUploads/index"
import {TEXT_COLOR} from "../../../../../../../layout/constant/MenuList";
import {useParams} from "react-router-dom";
import {TiktokVideoAdFileUpload} from "./TiktokVideoAdFileUpload";
import {settingTiktokVideoFormMedia, settingTiktokVideoHandlePreview} from "./store";


function TiktokVideoAdMedia({activeStep, steps, prevStep, nextStep, label}) {

    const { adUploadId } = useParams()

    const { mediaDetails } = useSelector((state) => state.tiktokVideoAd)

    const schema = yup.object().shape({
        fileURL: yup.array().required().label('Video'),
    });

    const dispatch = useDispatch();


    const formik = useFormik({
        initialValues: {
            fileURL: null
        },
        validationSchema: schema,
        onSubmit: (values => {
            dispatch(settingTiktokVideoFormMedia(values))
            if (values.fileURL) {
                let videoURL = values?.fileURL[0]?.imageUrl ? `${process.env.REACT_APP_API_URL}/uploads${values.fileURL[0]?.imageUrl}` : null;
                if (!videoURL) {
                    videoURL = URL.createObjectURL(values.fileURL[0])
                } else {
                    // setErrors({})
                }
                dispatch(settingTiktokVideoHandlePreview(videoURL));
            }
            // if (values && values.fileURL) {
            //             //     const files = values?.fileURL?.map((file) => {
            //             //         if (file && file.fileURL) {
            //             //             return {
            //             //                 fileURL: `${process.env.REACT_APP_API_URL}/uploads/${file.filename}`,
            //             //                 filename: file.originalname
            //             //             }
            //             //         } else {
            //             //
            //             //             return {
            //             //                 fileURL: URL.createObjectURL(file),
            //             //                 filename: file.name
            //             //             }
            //             //         }
            //             //     })
            //             //     dispatch(settingDV360VideoHandlePreview(files));
            //             // }
            nextStep()
        })
    });


    const {
        errors,
        values,
        touched,
        handleSubmit,
        isValid,
        setFieldValue,
    } = formik;


    useEffect(() => {
        setFieldValue("fileURL", mediaDetails?.fileURL ? mediaDetails?.fileURL : null)
    }, [mediaDetails])

    return (
        <Box className="file-upload-box">
            {
                adUploadId && (
                    <Box display="flex" mb={6} justifyContent="space-between" alignItems="center">
                        <Heading fontSize="xl" color={"#757998"}>
                            Please review the ad details carefully.
                        </Heading>
                        <Heading fontSize="md" color="gray.400">
                            2 steps to complete
                        </Heading>
                    </Box>
                )
            }

            {
                !adUploadId && (
                    <Box display="flex" mb={6} justifyContent="space-between" alignItems="center">
                        <Heading fontSize="xl" color={"#757998"}>
                            Please upload your ad creative.
                        </Heading>
                        <Heading fontSize="md" color="gray.400">
                            2 steps to complete
                        </Heading>
                    </Box>
                )
            }

            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit}>
                    <TiktokVideoAdFileUpload
                        // getHashArray={(value) => {
                        //     setFieldValue('fileURL', value)
                        // }}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        type="tiktok"
                        values={values}
                    />
                    <Flex width="100%" justify="flex-end" py={10}>
                        <Button
                            mr={4}
                            onClick={prevStep}
                            colorScheme='blue' variant='outline' borderRadius={0} px={10}
                        >
                            Back
                        </Button>
                        <Button mr={4} colorScheme='green' type="submit" variant='solid' borderRadius={0} px={10}>
                            Next
                        </Button>
                    </Flex>
                </Form>

            </FormikProvider>

        </Box>
    )
}

export default TiktokVideoAdMedia