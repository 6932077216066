import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  css,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
} from "@chakra-ui/react";
import { Form, FormikProvider, useFormik } from "formik";
import { InputControl } from "formik-chakra-ui";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import ErrorModal from "../../../../../components/PopupModal/ErrorModal";
import SuccessModal from "../../../../../components/PopupModal/SuccessModal";
import { AD_UPLOAD_STATUS_COLOR } from "../../../../../constant";
import WithAppContext from "../../../../../helpers/appContext";
import instance from "../../../../../helpers/axios";
import { useUpdateAdUploadStatus } from "../../../../../hooks/campaign-briefs/useUpdateAdUploadStatus";
import { useUploadImage } from "../../../../../hooks/campaign-briefs/useUploadImage";
import {
  dv360AdUploadalueLengths,
  dv360AdVideoInitialValue,
} from "../../../constant/InitialValues";
import VideoAdPreview from "./VideoAdPreview";

const DV360AdVideoDetails = ({
  data,
  setTabIndex,
  tabIndex,
  handlePreviewData,
  url,
  method,
  campaignId,
  context,
}) => {
  const { id } = useParams();
  const clientId = useSelector((state) => state.client.clientId);
  const navigate = useNavigate();
  const [isSuccessModalOpen, setSuccessModal] = useState(false);
  const [isErrorModalOpen, setErrorModal] = useState(false);
  const [hashArray, setHashArray] = useState([]);
  const [landingPageURL, setLandingPageURL] = useState("");
  const { mutateAsync } = useUploadImage();
  const { mutate } = useUpdateAdUploadStatus();
  const { setAppLoading } = context;

  const schema = yup.object().shape({
    adName: yup.string().trim().min(3).required("Ad name is required."),
    landingPageURL: yup
      .string()
      .trim()
      .required("Landing page url is required.")
      .matches(
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
        "Landing page url is not valid! for example https://example.com"
      ),
    youtubeURL: yup
      .string()
      .trim()
      .required("Youtube url is required.")
      .matches(
        /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/,
        "Youtube url is not valid! for example https://example.com"
      ),
  });
  const formik = useFormik({
    initialValues: dv360AdVideoInitialValue,
    validationSchema: schema,
    onSubmit: async (values) => {
      try {
        setAppLoading(true);
        let payload = {
          name: values.adName,
          detail: {
            name: values.adName,
            landingPageURL: values.landingPageURL,
            youtubeURL: values.youtubeURL,
          },
        };
        if (!data?.id) {
          payload = {
            ...payload,
            ad_upload_type: "DV360YoutubeVideo",
          };
        }
        await instance({
          method: method,
          url: url,
          withCredentials: false,
          data: payload,
        })
          .then((res) => {
            if (res.status === 200) {
              setSuccessModal(true);
            }
            setAppLoading(false);
          })
          .catch((error) => {
            setLandingPageURL(error.response.data.message);
            setErrorModal(true);
            setAppLoading(false);
          });
      } catch (error) {
        setAppLoading(false);
      }
    },
  });

  const { errors, values, isValid, handleSubmit, handleBlur, setFieldValue } =
    formik;

  useEffect(() => {
    if (data && data.id) {
      const { name, detail } = data;
      setFieldValue("adName", name);
      setFieldValue("youtubeURL", detail.youtubeURL);
      setFieldValue("landingPageURL", detail.landingPageURL);
    }
  }, [data]);

  // show preview
  useEffect(() => {
    if (tabIndex === 1) {
      handlePreviewData(values.youtubeURL);
    }
  }, [tabIndex]);

  const adUploadStatusColor = (item) => {
    return item
      ? AD_UPLOAD_STATUS_COLOR[_.toUpper(item)]
      : AD_UPLOAD_STATUS_COLOR["OTHER"];
  };

  const handleStatus = (status) => {
    setAppLoading(true);
    mutate(
      {
        clientId,
        campaignId,
        adUploadId: data?.id,
        status,
      },
      {
        onSuccess: () => {
          navigate(`/campaign-briefs/${campaignId}`, {
            state: { isShowUpload: true },
          });
        },
      }
    );

    setAppLoading(false);
  };

  const onCloseSuccessModal = () => {
    setSuccessModal(false);
    navigate(`/campaign-briefs/${campaignId}`, {
      state: { isShowUpload: true },
    });
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        my={4}
        mb={10}
      >
        <Heading color={"gray"} fontSize="xl">
          Current status:
          <span style={{ marginLeft: "10px" }}>
            <Icon
              viewBox="0 0 200 200"
              mr={2}
              color={adUploadStatusColor(data && data.status)}
            >
              <path
                fill="currentColor"
                d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
              />
            </Icon>
            {(data && data.status) || "Draft"}
          </span>
        </Heading>
        {data && _.toUpper(data.status) === "CREATED" && (
          <Box display="flex">
            <Button
              size="sm"
              mr={2}
              colorScheme="green"
              backgroundColor="green.400"
              borderRadius={4}
              disabled={!clientId || !campaignId || !data?.id}
              onClick={() => handleStatus("Approved")}
            >
              Approve
            </Button>
            <Button
              size="sm"
              colorScheme="red"
              backgroundColor="red.400"
              borderRadius={4}
              disabled={!clientId || !campaignId || !data?.id}
              onClick={() => handleStatus("Rejected")}
            >
              Reject
            </Button>
          </Box>
        )}
      </Box>
      <Grid className="fb-upload-detail-form">
        <FormikProvider value={formik}>
          <Form autoComplete="off" onSubmit={handleSubmit}>
            <Grid
              templateColumns="repeat(6, 1fr)"
              gap={4}
              className="fb-upload"
            >
              <GridItem w="full" colSpan={{ base: 6, lg: 4 }}>
                <GridItem>
                  <FormControl>
                    <FormLabel htmlFor="adName">
                      Ad Name - max 512 characters
                    </FormLabel>
                    <div className="input-box">
                      <InputControl
                        id="adName"
                        name="adName"
                        placeholder=""
                        inputprops={{
                          variant: "outline",
                          type: "text",
                        }}
                        onBlur={handleBlur}
                      />
                      <CircularProgress
                        max={dv360AdUploadalueLengths.adName}
                        value={values.adName.length}
                        color={
                          values.adName.length > dv360AdUploadalueLengths.adName
                            ? "red.400"
                            : "green.400"
                        }
                      >
                        <CircularProgressLabel>
                          {values.adName.length >
                          dv360AdUploadalueLengths.adName
                            ? dv360AdUploadalueLengths.adName -
                              values.adName.length
                            : values.adName.length}
                        </CircularProgressLabel>
                      </CircularProgress>
                    </div>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel htmlFor="youtubeURL">Youtube URL</FormLabel>
                    <div className="input-box">
                      <InputControl
                        id="youtubeURL"
                        name="youtubeURL"
                        inputprops={{
                          variant: "outline",
                          type: "text",
                        }}
                      />
                    </div>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel
                      htmlFor="landingPageURL"
                      color="gray"
                      fontSize="sm"
                    >
                      Landing Page URL
                    </FormLabel>
                    <div className="input-box">
                      <InputControl
                        id="landingPageURL"
                        name="landingPageURL"
                        placeholder=""
                        inputprops={{
                          variant: "outline",
                          type: "text",
                        }}
                      />
                    </div>
                  </FormControl>
                </GridItem>
                <GridItem
                  css={css({
                    float: "right",
                    marginRight: "68px",
                  })}
                >
                  <Button
                    size="small"
                    css={css({
                      background: "#24a0ed !important",
                      borderRadius: "32px",
                      width: "134px",
                      height: "33px",
                      marginRight: "10px",
                    })}
                    disabled={!isValid}
                    onClick={() => setTabIndex(1)}
                  >
                    Preview
                  </Button>
                  <Button
                    size="small"
                    css={css({
                      background: "#4CAF50 !important",
                      borderRadius: "32px",
                      width: "134px",
                      height: "33px",
                    })}
                    type="submit"
                    disabled={!isValid}
                  >
                    {data?.id ? "Update Ad" : "Submit Ad"}
                  </Button>
                </GridItem>
              </GridItem>
              {values.youtubeURL && (
                <GridItem w="full" colSpan={{ base: 6, lg: 2 }}>
                  <Box className="file-upload-box">
                    <VideoAdPreview url={values.youtubeURL} vidWidth="100%" />
                  </Box>
                </GridItem>
              )}
            </Grid>
          </Form>
        </FormikProvider>
      </Grid>

      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={() => onCloseSuccessModal()}
      />
      <ErrorModal
        isOpen={isErrorModalOpen}
        onClose={() => setErrorModal(false)}
        description={landingPageURL}
      />
    </>
  );
};

export default WithAppContext(DV360AdVideoDetails);
