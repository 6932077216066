import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID,
        authority: `https://login.microsoftonline.com/common`,
        redirectUri: process.env.REACT_APP_MICROSOFT_AUTH_REDIRECT_URL,
    },
    cache: {
        cacheLocation: process.env.REACT_APP_MICROSOFT_CREDENTIAL_STORE_OPTION,
        storeAuthStateInCookie: false,
        tokenCache: {
            enableCache: false,
        }
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    },
};

export const loginRequest = {
    prompt: 'select_account',
    scopes: ["User.Read"],
};

export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
