import { createSlice } from "@reduxjs/toolkit";
import {AD_UPLOAD_TYPE} from "../../../../../../../../constant";

const initialState = {
    formDetails: {},
    mediaDetails: [],
    handlePreviewData: null,
};

export const facebookVideoSlice = createSlice({
    name: AD_UPLOAD_TYPE.FBVIDEO,
    initialState,
    reducers: {
        settingFacebookVideoFormDetail: (state, action) => {
            state.formDetails = action.payload
        },
        settingFacebookVideoFormMedia: (state, action) => {
            state.mediaDetails = action.payload
        },
        settingFacebookVideoPreviewData: (state, action) => {
            state.handlePreviewData = action.payload
        },
        deleteAllFacebookVideoField: (state, action) => {
            state.formDetails = {};
            state.mediaDetails = [];
            state.handlePreviewData = null
        }
    },
});

export const { settingFacebookVideoFormDetail, settingFacebookVideoFormMedia, settingFacebookVideoPreviewData, deleteAllFacebookVideoField} = facebookVideoSlice.actions;

// The reducer
export const facebookVideoReducer = facebookVideoSlice.reducer;
