import {
  Heading,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import Datatable from "./components/Datatable";
import { useGetConfigList } from "../../hooks/config-management/useGetConfigList";
import CreateConfigModal from "./components/CreateConfigModal";
import { TEXT_COLOR } from "../../layout/constant/MenuList";
import SearchAndButton from "../../components/SearchAndButton";
import { Link } from "react-router-dom";
import { BsThreeDotsVertical } from "react-icons/bs";

const ConfigManagement = () => {
  const { data, refetch } = useGetConfigList();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [search, setSearch] = useState();
  const [configData, setConfigData] = useState([]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    setConfigData(data?.configs);
  }, [data]);

  useEffect(() => {
    if (search?.trim()) {
      const searchedConfigs = configData.filter((el) => {
        if (el?.description?.toLowerCase().includes(search.trim())) {
          return true;
        } else if (el?.name?.toLowerCase().includes(search.trim())) {
          return true;
        } else if (
          el?.detail?.industry?.toLowerCase().includes(search.trim())
        ) {
          return true;
        }
      });
      setConfigData(searchedConfigs);
    } else {
      setConfigData(data?.configs);
    }
  }, [search]);

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Key",
        accessor: "key",
      },
      {
        Header: "Value",
        accessor: "value",
        Cell: (data) => {
          return (
            <Text>{JSON.stringify(data.row.original.value, null, 2)}</Text>
          );
        },
      },
      {
        Header: "State",
        accessor: "state",
        Cell: (data) => {
          return (
            <>
              <Icon
                viewBox="0 0 200 200"
                mr={2}
                color={
                  data.row.original.state === "ACTIVE"
                    ? "#3F7EE6"
                    : data.row.original.state === "INACTIVE"
                    ? "red"
                    : data.row.original.state === "ON_HOLD"
                    ? "yellow.500"
                    : "#3F7EE6"
                }
              >
                <path
                  fill="currentColor"
                  d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
                />
              </Icon>
              {data.row.original.state}
            </>
          );
        },
      },
      {
        Header: "",
        accessor: "actions",
        Cell: (data) => {
          return (
            <VStack justify="end" maxW="20px">
              <Menu>
                <MenuButton as={IconButton} variant="ghost" size="sm">
                  <Icon as={BsThreeDotsVertical} />
                </MenuButton>
                <MenuList>
                  <MenuItem
                    as={Link}
                    to={`/config/${data.row.original.id}`}
                    aria-label="View details"
                  >
                    View/Edit
                  </MenuItem>
                  <MenuItem>Delete</MenuItem>
                </MenuList>
              </Menu>
            </VStack>
          );
        },
      },
    ],
    []
  );

  return (
    <div className="ad-upload-list">
      <Heading color={TEXT_COLOR} fontWeight="500" size="lg" my={5} mb={7}>
        Config management
      </Heading>
      <SearchAndButton
        buttonText=" Create config"
        btnClick={onOpen}
        setSearch={(e) => setSearch(e.target.value)}
      />

      <CreateConfigModal isOpen={isOpen} onClose={onClose} />
      <Datatable data={configData || []} columns={columns} />
    </div>
  );
};

export default ConfigManagement;
