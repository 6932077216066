import React, {useEffect, useState} from "react";
import {
    Center,
    css,
    Flex,
    Grid,
    GridItem,
    Heading,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
} from "@chakra-ui/react";
import {BiArrowBack} from "react-icons/bi";
import {useNavigate, useParams} from "react-router-dom";
import {TEXT_COLOR} from "../../../../../../layout/constant/MenuList";
import "../../../../style/FacebookAdUpload.css";
import PinterestCarouselAdDetails from "./PinterestCarouselAdDetails";
import AdPreview from "../../AdPreview";
import {useGetAdUpload} from "../../../../../../hooks/campaign-briefs/useGetAdUpload";
import PinterestCarouselComments from "./PinterestCarouselComments";
import {useSelector} from "react-redux";
import AuditLogsList from "../../AuditLogsList";
import FullPageLoader from "../../../../../../components/FullPageLoader";
import WithAppContext from "../../../../../../helpers/appContext";
import PinterestAdPreview from "../PinterestImage/PinterestAdPreview";

const PinterestCarouselAdUpload = ({context}) => {
    const [method, setMethod] = useState();
    const [url, setUrl] = useState();
    const [previewData, setPreviewData] = useState([]);
    const [tabIndex, setTabIndex] = useState(0);


    const navigate = useNavigate();
    const {id, pinterestId} = useParams();
    const clientId = useSelector((state) => state.client.clientId);

    const {data, refetch} = useGetAdUpload(clientId, id, pinterestId);

    const {appLoading} = context

    useEffect(() => {
        refetch()
    }, [])

    useEffect(() => {
        if (pinterestId) {
            setMethod("PUT");
            setUrl(
                `/client/${clientId}/campaign-brief/${id}/ad-upload/${pinterestId}`
            );
        } else {
            setMethod("POST");
            setUrl(`/client/${clientId}/campaign-brief/${id}/ad-upload`);
        }
    }, [pinterestId, clientId]);

    return (
        <>
            {appLoading && <FullPageLoader/>}
            <Grid templateColumns="repeat(6, 1fr)" gap={4} className="fb-upload">
                <GridItem
                    w="full"
                    colSpan={{base: 6, lg: 6}}
                    mb={{base: 3, lg: 0}}
                >
                    <Heading fontSize="sm" mb={7} color={TEXT_COLOR}>
                        <Flex
                            onClick={() => navigate(`/campaign-briefs/${id}`)}
                            cursor={"pointer"}
                        >
                            <Center marginRight={2}>
                                <BiArrowBack color="#A7A9BD"/>
                            </Center>
                            <Center>
                                <Text
                                    css={css({
                                        fontFamily: "Poppins",
                                        fontStyle: "normal",
                                        fontWeight: "600",
                                        fontSize: "12px",
                                        lineHeight: "15px",
                                        color: "#A7A9BD",
                                    })}
                                >
                                    Back to Campaign Brief
                                </Text>
                            </Center>
                        </Flex>
                    </Heading>
                    <Heading fontSize="4xl" mb={4} color={TEXT_COLOR}>
                        Pinterest Carousel Ad Upload
                    </Heading>
                    <Tabs
                        onChange={(index) => setTabIndex(index)}
                        index={tabIndex}
                        size="sm"
                        w="full"
                        colorScheme="black"
                        overflow="hidden"
                    >
                        <TabList
                            overflowX="auto"
                            css={css({
                                scrollbarWidth: "none",
                                "::-webkit-scrollbar": {display: "none"},
                                WebkitOverflowScrolling: "touch",
                                boxShadow: "inset 0 -2px 0 rgba(0, 0, 0, 0.1)",
                                border: "0 none",
                            })}
                        >
                            <Tab whiteSpace="nowrap">Details</Tab>
                            <Tab whiteSpace="nowrap" isDisabled={previewData.length < 2}>Preview</Tab>
                            <Tab whiteSpace="nowrap" isDisabled={!pinterestId}>Comments</Tab>
                            <Tab whiteSpace="nowrap">Audit Logs</Tab>
                        </TabList>

                        <TabPanels>
                            <TabPanel>
                                <PinterestCarouselAdDetails
                                    handlePreviewData={setPreviewData}
                                    data={data?.adUpload}
                                    details={data}
                                    url={url}
                                    method={method}
                                    clientId={clientId}
                                    campaignId={id}
                                    adUploadId={pinterestId}
                                    tabIndex={tabIndex}
                                    setTabIndex={setTabIndex}
                                />
                            </TabPanel>
                            <TabPanel>
                                <PinterestAdPreview data={previewData} details={data}/>
                            </TabPanel>
                            <TabPanel>
                                <PinterestCarouselComments
                                    clientId={clientId}
                                    campaignId={id}
                                    facebookAdId={pinterestId}
                                />
                            </TabPanel>
                            <TabPanel>
                                <AuditLogsList/>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </GridItem>
            </Grid>
        </>
    );
};

export default WithAppContext(PinterestCarouselAdUpload);
