import { createSlice } from "@reduxjs/toolkit";
import {AD_UPLOAD_TYPE} from "../../../../../../../../constant";

const initialState = {
    formDetails: {},
    mediaDetails: [],
    handlePreviewData: null,
};

export const facebookCarouselSlice = createSlice({
    name: AD_UPLOAD_TYPE.FBCAROUSEL,
    initialState,
    reducers: {
        settingFacebookCarouselForm: (state, action) => {
            state.formDetails = action.payload
        },
        settingFacebookCarouselMedia: (state, action) => {
            state.mediaDetails = action.payload
        },
        settingFacebookCarouselPreview: (state, action) => {
            state.handlePreviewData = action.payload
        },
        deleteAllFacebookCarouselField: (state, action) => {
            state.formDetails = {};
            state.mediaDetails = [];
            state.handlePreviewData = null
        }
    },
});

export const { settingFacebookCarouselForm, settingFacebookCarouselMedia, settingFacebookCarouselPreview, deleteAllFacebookCarouselField} = facebookCarouselSlice.actions;

// The reducer
export const facebookCarouselReducer = facebookCarouselSlice.reducer;
