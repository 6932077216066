import { CloseIcon, InfoOutlineIcon, DownloadIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Image,
  Input,
  Progress,
  Spacer,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { InputControl } from "formik-chakra-ui";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import FileUploadIcon from "../../../../../../../assets/images/file-upload-icon.png";
import { TEXT_COLOR } from "../../../../../../../layout/constant/MenuList";
import { fbCarouselAdUploadValueLengths } from "../../../../../constant/InitialValues";
import DefaultVideoIcon from "../../../../../../../assets/images/video-default-icon.png";
import FileSaver from "file-saver";
import WithAppContext from "../../../../../../../helpers/appContext";
import { FB_VIDEO_SIZE } from "../../../../../../../constant";
import InstagramThumbFileUpload from "./InstagramThumbFileUpload";

const InstagramVideoFileUpload = ({
  onDrop,
  errors,
  touched,
  values,
  setFieldValue,
  context,
}) => {
  const [fileURL, setFileURL] = useState("");
  const [fileName, setFileName] = useState("");
  const { ErrorToaster } = context;
  const acceptVideoFiles = {
    "video/mp4": [".mp4"],
    "video/mov": [".mov"],
    "image/gif": [".gif"],
  };

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    accept: acceptVideoFiles,
    maxSize: FB_VIDEO_SIZE,
    disabled: values.videoURL,
  });

  useEffect(() => {
    if (values.videoURL && values.videoURL) {
      const file = values.videoURL;
      const videoURL =
        file && `${process.env.REACT_APP_API_URL}/uploads/${file?.filename}`;
      const getFileName = file?.originalname;
      setFileURL(videoURL);
      setFileName(getFileName);
    }
  }, [values]);

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  useEffect(() => {
    fileRejections &&
      fileRejections.map(({ errors }) => {
        errors.map((err) => ErrorToaster(err.code + "," + err.message));
      });
  }, [fileRejections]);

  return (
    <>
      <Flex justifyContent="space-between">
        <Grid width="48%" height="min-content">
          <Grid
            border="2px"
            borderColor={
              errors.videoURL && touched.videoURL ? "red.500" : "gray.200"
            }
            padding="20px"
            borderStyle="dashed"
          >
            <div {...getRootProps()}>
              <Input {...getInputProps()} />
              <Box
                className="file-upload-content"
                justifyContent="center"
                display="flex"
                flexDirection="column"
                alignItems="center"
                textAlign="center"
              >
                <Image
                  boxSize="100px"
                  objectFit="cover"
                  src={FileUploadIcon}
                  alt="upload-icon"
                />
                <Heading fontSize="xl" mb={1} color={TEXT_COLOR}>
                  Drag and drop video file
                  <Tooltip
                    label={
                      <Box>
                        <Text color="#000">
                          <b>Video file type:</b> MP4, MOV or GIF
                        </Text>
                        <Text color="#000">
                          <b>Ratio:</b> 1:1
                        </Text>
                        <Text color="#000">
                          <b>Video maximum file size:</b> 4 GB
                        </Text>
                        <Text color="#000">
                          <b>Video duration:</b> 1 second to 240 minutes
                        </Text>
                      </Box>
                    }
                    closeDelay={500}
                    bg="#e2e8f0"
                  >
                    <InfoOutlineIcon
                      marginLeft="2"
                      height="15px"
                      width="15px"
                      marginBottom="3px"
                    />
                  </Tooltip>
                </Heading>
                <Heading fontSize="sm" mb={1} color={TEXT_COLOR}>
                  or
                </Heading>
                <Button
                  size="small"
                  onClick={() => {}}
                  disabled={values.videoFile && true}
                >
                  Browse
                </Button>
              </Box>
            </div>
          </Grid>
          {errors.videoURL && touched.videoURL && (
            <Text color="red.500" marginTop="1">
              Card video is required
            </Text>
          )}
          {values.videoURL && (
            <Grid className="file-box">
              <Flex marginTop="5" marginBottom="5">
                <Image
                  boxSize="20px"
                  objectFit="cover"
                  src={DefaultVideoIcon}
                  alt="Dan Abramov"
                />
                <Grid marginLeft="2">
                  <Grid width="100%" wordBreak="break-word">
                    <Text
                      fontSize="15px"
                      className="file-name"
                      textOverflow="ellipsis"
                      overflow="hidden"
                      whiteSpace="nowrap"
                    >
                      {values.videoURL.originalname}
                    </Text>
                  </Grid>
                  <Text fontSize="sm">{formatBytes(values.videoURL.size)}</Text>
                </Grid>
                <Spacer />
                <Grid>
                  <Grid justifyContent="center">
                    <CloseIcon
                      cursor="pointer"
                      className="close-icon"
                      size="small"
                      onClick={() => {
                        setFieldValue("videoURL", "");
                      }}
                    />
                  </Grid>
                  <Grid>
                    <DownloadIcon
                      onClick={() => FileSaver.saveAs(fileURL, fileName)}
                      cursor="pointer"
                      className="close-icon"
                      size="small"
                      w={6}
                      h={6}
                    />
                  </Grid>
                </Grid>
              </Flex>
              <Progress className="loading" value={100} />
            </Grid>
          )}
        </Grid>
        <Grid width="48%" height="min-content">
          <InstagramThumbFileUpload
            onDrop={onDrop}
            touched={touched}
            errors={errors}
            values={values}
            setFieldValue={setFieldValue}
          />
        </Grid>
      </Flex>

      <Grid gap={4} className="fb-upload" marginTop="20px">
        <GridItem w="100%">
          <FormControl>
            <FormLabel htmlFor="link">Landing page URL</FormLabel>
            <Flex className="input-box" marginRight="15px">
              <InputControl
                id="link"
                name="link"
                value={values.link}
                placeholder=""
                inputprops={{
                  variant: "outline",
                  type: "text",
                }}
                onChange={(e) => setFieldValue("link", e.target.value)}
              />
              <Box h="48px" w="48px" />
            </Flex>
          </FormControl>
        </GridItem>
        <GridItem w="100%">
          <FormControl>
            <FormLabel htmlFor="name">Headline - max 32 characters</FormLabel>
            <Flex className="input-box">
              <InputControl
                id="name"
                name="name"
                value={values.name}
                inputprops={{
                  variant: "outline",
                  type: "text",
                }}
                onChange={(e) => setFieldValue("name", e.target.value)}
              />
              <CircularProgress
                marginLeft="15px"
                max={fbCarouselAdUploadValueLengths?.headline}
                value={values?.name?.length}
                color={
                  values?.name?.length >
                  fbCarouselAdUploadValueLengths?.headline
                    ? "red.400"
                    : "green.400"
                }
              >
                <CircularProgressLabel>
                  {values?.name?.length >
                  fbCarouselAdUploadValueLengths?.headline
                    ? fbCarouselAdUploadValueLengths?.headline -
                      values?.name?.length
                    : values?.name?.length}
                </CircularProgressLabel>
              </CircularProgress>
            </Flex>
          </FormControl>
        </GridItem>
        <GridItem w="100%">
          <FormControl>
            <FormLabel htmlFor="description">
              Description - max 18 characters
            </FormLabel>
            <Flex
              className="input-box"
              justifyContent="center"
              alignItems="center"
            >
              <InputControl
                id="description"
                name="description"
                value={values.description}
                placeholder=""
                inputprops={{
                  variant: "outline",
                  type: "text",
                }}
                onChange={(e) => setFieldValue("description", e.target.value)}
              />
              <CircularProgress
                marginLeft="15px"
                max={fbCarouselAdUploadValueLengths?.description}
                value={values?.description?.length}
                color={
                  values?.description?.length >
                  fbCarouselAdUploadValueLengths?.description
                    ? "red.400"
                    : "green.400"
                }
              >
                <CircularProgressLabel>
                  {values?.description?.length >
                  fbCarouselAdUploadValueLengths?.description
                    ? fbCarouselAdUploadValueLengths?.description -
                      values?.description?.length
                    : values?.description?.length}
                </CircularProgressLabel>
              </CircularProgress>
            </Flex>
          </FormControl>
        </GridItem>
      </Grid>
    </>
  );
};
export default WithAppContext(InstagramVideoFileUpload);
