import { HStack, useToast, VStack, Button, Stack } from "@chakra-ui/react";
import { Step, Steps, useSteps } from 'chakra-ui-steps';
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import instance from "../../../../helpers/axios";
import AdditionalQuestions from "../CampaignDetails/AdditionalQuestions";
import CampaignBudget from "../CampaignDetails/CampaignBudget";
import CampaignDetails from "../CampaignDetails/CampaignDetails";
import CampaignMetrics from "../CampaignDetails/CampaignMetrics";
import ClientDetails from "../CampaignDetails/ClientDetails";
import Demographics from "../CampaignDetails/Demographics";
import MediaStrategies from "../CampaignDetails/MediaStrategies";
import validationSchema from "../../../../validations/CampaignBrief/CampaignForm";
import { campaignInitialValue } from "../../constant/InitialValues";
import { updateCampaignFbObjective } from "../../../../store/campaign/campaignThunk";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom'
import Creative from "../CampaignDetails/Creative";
import {Flex, Heading} from "@chakra-ui/layout";
import "./createFormStepByStep.css";
import FormStepButton from "./FormStepButton";
import SuccessSubmittedUI from "./SuccessSubmittedUI";
import AdditionalQuestionsStep from "./AdditionalQuestionsStep";
import CreativeStep from "./CreativeStep";
import ClientDetailsStep from "./ClientDetailsStep";
import CampaignDetailsStep from "./CampaignDetailsStep";
import CampaignBudgetStep from "./CampaignBudgetStep";
import CampaignMetricsStep from "./CampaignMetricsStep";
import DemographicsStep from "./DemographicsStep";
import MediaStrategiesStep from "./MediaStrategiesStep";

const CreateFormStepByStep = ({ id, clientDetails, campaignDetails }) => {
    const toast = useToast();
    let navigate = useNavigate()
    const dispatch = useDispatch();
    const [method, setMethod] = useState();
    const [url, setURL] = useState();
    const [fbObjective, setFbObjective] = useState();
    const { nextStep, prevStep, reset, activeStep, setStep } = useSteps({
        initialStep: 0,
    });

    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        if (id && clientDetails?.id) {
            setMethod("PUT");
            setURL(`/client/${clientDetails.id}/campaign-brief/${id}`);
        } else {
            setMethod("POST");
            setURL(`/client/${clientDetails.id}/campaign-brief`);
        }
    }, [id, clientDetails]);

    useEffect(() => {
        if (fbObjective) {
            dispatch(updateCampaignFbObjective(fbObjective));
        }
    }, [fbObjective]);

    async function handleSubmit(values, actions) {
        await instance({
            method: method,
            url: url,
            withCredentials: false,
            data: values,
        })
            .then((res) => {
                if (res.status === 200) {
                    setFbObjective(values?.detail?.objective);
                    toast({
                        isClosable: true,
                        status: "success",
                        variant: "top-accent",
                        position: "top-right",
                        title: "Success",
                        description: res.data.message,
                    });
                    setIsSubmitted(true)
                    setTimeout(() => {
                        navigate('/campaign-briefs')
                    }, 2000)
                }
            })
            .catch((error) => {
                toast({
                    isClosable: true,
                    status: "error",
                    variant: "top-accent",
                    position: "top-right",
                    description: error.response.data.message,
                });
            });

    }

    return (
        <div className="form-step-main">
        <Formik
            enableReinitialize
            initialValues={campaignInitialValue(clientDetails, campaignDetails)}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ values, errors, setFieldValue, resetForm }) => {
                const steps = [
                    { label: "Client Detail", content: <ClientDetailsStep /> },
                    { label: "Campaign Details", content: <CampaignDetailsStep setFieldValue={setFieldValue} values={values} /> },
                    { label: "Campaign Budget", content: <CampaignBudgetStep setFieldValue={setFieldValue} values={values} /> },
                    { label: "Campaign Metrics", content: <CampaignMetricsStep /> },
                    { label: "Demographics", content: <DemographicsStep setFieldValue={setFieldValue} values={values} /> },
                    { label: "Media Strategies", content: <MediaStrategiesStep setFieldValue={setFieldValue} values={values} /> },
                    { label: "Creative", content: <CreativeStep setFieldValue={setFieldValue} values={values} /> },
                    { label: "Additional Questions", content: <AdditionalQuestionsStep setFieldValue={setFieldValue} values={values} /> },
                ];
                return (
                    <VStack as={Form} mt={4} align="stretch" spacing={6}>
                        <Flex flexDir="column" width="100%">
                            <Steps activeStep={activeStep}>
                                {steps.map(({ label, content }) => {
                                        return (
                                            <Step key={label} pb={5}>
                                                {
                                                    isSubmitted ? <SuccessSubmittedUI/> : (
                                                        <>
                                                            {content}
                                                            <FormStepButton errors={errors} activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} />
                                                        </>
                                                    )
                                                }
                                            </Step>
                                        )
                                    }
                                )}
                            </Steps>
                            {
                                isSubmitted &&  <Button mr={4}
                                                        alignSelf={"flex-end"}
                                                        colorScheme='blue' variant='outline' borderRadius={0} px={10} onClick={() => navigate(-1)}>Back</Button>
                            }
                            {
                                /*
                                {activeStep === steps.length ? (
                                <Flex p={4}>
                                    <Button mx="auto" size="sm" onClick={reset}>
                                        Reset
                                    </Button>
                                </Flex>
                            ) : (
                                <Flex width="100%" justify="flex-end" py={10}>
                                    <Button
                                        isDisabled={activeStep === 0}
                                        mr={4}
                                        onClick={prevStep}
                                        colorScheme='blue' variant='outline' borderRadius={0} px={10}
                                    >
                                        Prev
                                    </Button>
                                    {
                                        !(activeStep === steps.length - 1) && (
                                            <Button mr={4} onClick={nextStep} colorScheme='green' variant='solid' borderRadius={0} px={10}>
                                                Next
                                            </Button>
                                        )
                                    }
                                    {
                                        activeStep === steps.length - 1 && (
                                            <>
                                                <Button
                                                    mr={4}
                                                    colorScheme='blue' variant='solid' borderRadius={0} px={10}
                                                >
                                                    Save Draft
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    mr={4}
                                                    colorScheme='green' variant='solid' borderRadius={0} px={10}
                                                >
                                                    Submit
                                                </Button>
                                            </>
                                        )
                                    }
                                </Flex>
                            )}
                                */
                            }

                        </Flex>
                        {
                            /*
                                                    <Stack direction="row" justifyContent="flex-end">
                            <HStack align="center" justifyContent="flex-end">
                                <Button
                                    type="reset"
                                    px="14"
                                    rounded="full"
                                    colorScheme="red"
                                    size="sm"
                                >
                                    Cancal
                                </Button>
                            </HStack>

                            <HStack align="center" justifyContent="flex-end">
                                <Button
                                    type="submit"
                                    px="14"
                                    rounded="full"
                                    colorScheme="blue"
                                    size="sm"
                                >
                                    {id ? "Update" : "Create"}
                                </Button>
                            </HStack>
                        </Stack>

                             */
                        }
                    </VStack>
                )
            }
            }
        </Formik>
        </div>
    );
};

export default CreateFormStepByStep;
