import { inputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle = definePartsStyle({
  field: {
    fontSize: "sm",
    textColor: "gray.400",
    _placeholder: {
      color: "brand.text",
    },
  },
});

export const Input = defineMultiStyleConfig({
  baseStyle,
  defaultProps: {
    focusBorderColor: "brand.primary",
    borderColor: "brand.text",
  },
});
