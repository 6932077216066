import React from "react";
import {
  Button,
  FormControl,
  FormLabel,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Textarea,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { settingNotes } from "../../store/adCampaign";
import moment from "moment";
import { Flex } from "@chakra-ui/layout";

function AddNotesModal({ onClose, isOpen, note, setNote }) {
  const { notes } = useSelector((state) => state.adCampaign);
  const dispatch = useDispatch();

  function handleNotes() {
    dispatch(settingNotes([...notes, { value: note, time: new Date() }]));
    onClose();
  }
  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered size="4xl">
      <ModalOverlay />
      <ModalContent mx={{ base: "4", md: "0" }}>
        <ModalHeader fontFamily={"poppins"}>Anything Missed?</ModalHeader>
        <ModalCloseButton />
        <ModalBody fontFamily={"poppins"}>
          <List mb={5} overflow={"auto"} maxHeight={200}>
            {!!notes &&
              notes.map((e) => (
                <ListItem fontFamily={"sans-serif"} my={1} key={e.time}>
                  <Flex justifyContent={"space-between"}>
                    <span
                      style={{
                        fontFamily: "poppins",
                        fontWeight: 500,
                        width: "80%",
                      }}
                    >
                      - {e.value}
                    </span>
                    <span
                      style={{
                        fontSize: "16px",
                        width: "20%",
                        textAlign: "right",
                      }}
                    >
                      {moment(e.time).fromNow()}
                    </span>
                  </Flex>
                </ListItem>
              ))}
          </List>
          <FormControl>
            <FormLabel>Notes</FormLabel>
            <Textarea
              fontFamily={"poppins"}
              value={note}
              rows={2}
              onChange={(e) => setNote(e.target.value)}
            />
            <Button
              display={"flex"}
              justifyContent={"flex-end"}
              py={5}
              fontFamily={"poppins"}
              px={8}
              _hover={{ bg: "" }}
              color={"#FFFFFF"}
              bgColor={"#1F95FF"}
              border={"2px"}
              borderColor="#1F95FF"
              style={{ margin: "1.4rem 0 1.4rem auto" }}
              isDisabled={!note}
              onClick={handleNotes}
            >
              Save
            </Button>
          </FormControl>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default AddNotesModal;
