"use client";
import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const baseStyle = defineStyle({});

const solid = defineStyle({
  bg: "brand.primary",
  px: 8,
  boxShadow: "sm",
  borderRadius: "md",
  _hover: {
    bg: "blue.600",
  },
  _active: {
    bg: "brand.700",
  },
});
const outline = defineStyle({
  bg: "white",
  color: "brand.primary",
  px: 8,
  boxShadow: "sm",
  borderRadius: "md",
  border: "1px solid",
  borderColor: "brand.primary",
  _hover: {
    bg: "blue.600",
    color: "white",
  },
  _active: {
    bg: "brand.700",
    color: "white",
  },
  _disabled: {
    bg: "white",
    color: "brand.primary",
    opacity: 0.4,
    _hover: {
      bg: "white",
      color: "black",
    },
  },
});

const button = defineStyleConfig({
  baseStyle,
  variants: { solid, outline },
});

export default button;
