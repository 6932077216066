import React, { useState } from "react";
import {
  Box,
  Heading,
  Image,
  Stack,
  Text,
  useToast,
  VStack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
} from "@chakra-ui/react";
import axios from "axios";
import { Form, Formik, Field } from "formik";
import { SubmitButton } from "formik-chakra-ui";
import Loading from "../../components/Loading";
import "../Login/floatingStyle.css";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import AuthLayout from "../../layout/AuthLayout";

const validationSchema = Yup.object({
  password: Yup.string()
    .min(8)
    .max(20)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    )
    .required(),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Password must match")
    .required(),
});

const resetPasswordInitialValue = {
  password: "",
  confirmPassword: "",
};

const ResetPassword = () => {
  const [isLoading] = useState(false);
  const toast = useToast();
  const [visiblePassword, setVisiblePassword] = useState("password");
  const [visibleConfirmPassword, setVisibleConfirmPassword] =
    useState("password");
  const params = useParams();

  const handleSubmit = async (values, { setSubmitting }) => {
    return await axios({
      method: "POST",
      baseURL: `${process.env.REACT_APP_API_URL}`,
      url: `/change-password/${params.token}`,
      data: { password: values.password },
      withCredentials: false,
    })
      .then((response) => {
        if (+response.status === 200) {
          toast({
            isClosable: true,
            status: "success",
            variant: "top-accent",
            position: "top-right",
            title: "Success",
            description: "Password Reset Successfully",
          });
          window.location = "/login";
        }
      })
      .catch((error) => {
        toast({
          isClosable: true,
          status: "error",
          variant: "top-accent",
          position: "top-right",
          description: error.response?.data?.message || "Something went wrong!",
        });
      });
  };

  return isLoading ? (
    <Loading />
  ) : (
    <AuthLayout>
      <VStack alignItems="start">
        <Image
          src={`${process.env.PUBLIC_URL}/images/Kedet Logo.png`}
          height="80px"
        />
        <Text
          color="brand.primary"
          style={{ marginTop: "-16px" }}
          fontWeight="600"
        >
          Powered by War Room.
        </Text>
        <Stack spacing={4} py={12} w={"full"} maxW={"md"}>
          <Heading fontWeight="700" fontSize={35} color={"#757998"}>
            Reset Password.
          </Heading>
          <Text
            color="#A7A9BD"
            fontWeight="600"
            style={{ marginTop: "0px", marginBottom: "20px" }}
            fontFamily={"poppins"}
            fontSize={14}
          >
            Please enter your new password for the Kedet account.
          </Text>
          <Formik
            initialValues={resetPasswordInitialValue}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit, dirty, errors, touched, isSubmitting }) => {
              return (
                <VStack as={Form} w="full" alignItems="start">
                  <Stack w="full" spacing={3} autoComplete="off">
                    <FormControl variant="floating" isRequired isInvalid>
                      <Box className="login-controller">
                        <Field
                          as={Input}
                          type={visiblePassword}
                          name="password"
                          placeholder=" "
                          className="login-input"
                        />
                        <FormLabel htmlFor="password" className="login-label">
                          Password
                        </FormLabel>
                      </Box>
                      {visiblePassword === "password" && (
                        <ViewIcon
                          onClick={() => setVisiblePassword("text")}
                          zIndex="999"
                          cursor="pointer"
                          position={"absolute"}
                          top="30%"
                          right={"26px"}
                          boxSize={6}
                        />
                      )}
                      {visiblePassword === "text" && (
                        <ViewOffIcon
                          onClick={() => setVisiblePassword("password")}
                          zIndex="999"
                          cursor="pointer"
                          position={"absolute"}
                          top="30%"
                          right={"26px"}
                          boxSize={6}
                        />
                      )}
                      {touched.password && (
                        <FormErrorMessage>{errors.password}</FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl variant="floating" isRequired isInvalid>
                      <Box className="login-controller">
                        <Field
                          as={Input}
                          type={visibleConfirmPassword}
                          name="confirmPassword"
                          placeholder=" "
                          className="login-input"
                        />
                        <FormLabel
                          htmlFor="confirmPassword"
                          className="login-label"
                        >
                          Confirm Password
                        </FormLabel>
                      </Box>
                      {visibleConfirmPassword === "password" && (
                        <ViewIcon
                          onClick={() => setVisibleConfirmPassword("text")}
                          zIndex="999"
                          cursor="pointer"
                          position={"absolute"}
                          top="30%"
                          right={"26px"}
                          boxSize={6}
                        />
                      )}
                      {visibleConfirmPassword === "text" && (
                        <ViewOffIcon
                          onClick={() => setVisibleConfirmPassword("password")}
                          zIndex="999"
                          cursor="pointer"
                          position={"absolute"}
                          top="30%"
                          right={"26px"}
                          boxSize={6}
                        />
                      )}
                      {touched.confirmPassword && (
                        <FormErrorMessage>
                          {errors.confirmPassword}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Stack>
                  <SubmitButton
                    variant="solid"
                    colorScheme="blue"
                    backgroundColor={"brand.primary"}
                    borderRadius={0}
                    style={{ marginTop: "40px", width: "100%" }}
                    px="14"
                  >
                    Submit
                  </SubmitButton>
                </VStack>
              );
            }}
          </Formik>
        </Stack>
      </VStack>
    </AuthLayout>
  );
};

export default ResetPassword;
