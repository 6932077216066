import { CloseIcon, DownloadIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import {
  Box,
  Button, Flex, Grid, Heading, Image, Input, Progress, Spacer, Text, Tooltip
} from "@chakra-ui/react";
import FileSaver from 'file-saver';
import React, { useEffect, useState } from "react";
import { useDropzone } from 'react-dropzone';
import DefaultImageIcon from "../../../../../../../assets/images/image-default-icon.png";
import FileUploadIcon from "../../../../../../../assets/images/file-upload-icon.png";
import { TEXT_COLOR } from "../../../../../../../layout/constant/MenuList";

export function UploadVideoThumbnail({data, onDrop, errors, touched, values, setFieldValue }) {
  const [thumbnailFile, setThumbnailFile] = useState('')
  const [fileName, setFileName] = useState('')

  const acceptImageFiles = {
    "image/png": [".png"],
    "image/jpeg": [".jpg"],
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptImageFiles,
    maxSize: 30 * 1024 * 1024,
    disabled: values.thumbnailURL && true,
  });

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  useEffect(() => {
    if (values && values.thumbnailURL) {
      const file  = values.thumbnailURL;
      const getFileURL =  file && `${process.env.REACT_APP_API_URL}/uploads/${file?.filename}`;
      const getFileName = file?.originalname
      setThumbnailFile(getFileURL)
      setFileName(getFileName)
    }
  }, [values])

  return (
    <>
      <Grid
        border='2px'
        borderColor={(errors.thumbnailURL && touched.thumbnailURL) ? 'red.500' : 'gray.200'}
        padding="20px"
        borderStyle="dashed"
      >
        <div {...getRootProps()}>
          <Input {...getInputProps()} />
          <Box className="file-upload-content" justifyContent="center" display="flex" flexDirection="column" alignItems="center" textAlign="center" >
            <Image
              boxSize="100px"
              objectFit="cover"
              src={FileUploadIcon}
              alt="upload-icon"
            />
            <Heading fontSize="xl" display="flex" alignItems="center" mb={1} color={TEXT_COLOR}>
              Drag and drop thumbnail
              <Tooltip label={
                <Box>
                  <Text color="#000"><b>Image file type:</b> JPG or PNG</Text>
                  <Text color="#000"><b>Resolution:</b> At least 1080 x 1080 pixels</Text>
                  <Text color="#000"><b>Image maximum file size:</b> 30 MB</Text>
                </Box>} closeDelay={500} bg='#e2e8f0'>
                <InfoOutlineIcon marginLeft="2" height="15px" width="15px" marginTop="2px" />
              </Tooltip>
            </Heading>
            <Heading fontSize="sm" mb={1} color={TEXT_COLOR}>
              or
            </Heading>
            <Button size="small" onClick={() => {
            }} disabled={values.thumbnailURL && true}>Browse</Button>
          </Box>
        </div>
      </Grid>
      {(errors.thumbnailURL && touched.thumbnailURL) &&
        <Text color="red.500" marginTop="1">
          Card image is required
        </Text>
      }
      {values.thumbnailURL &&
        <Grid className="file-box">
          <Flex marginTop="5" marginBottom="5">
            <Image
              boxSize="20px"
              objectFit="cover"
              src={DefaultImageIcon}
              alt="Dan Abramov"
            />
            <Grid marginLeft="2">
              <Grid>
                <Text
                  fontSize="15px"
                  textOverflow="ellipsis"
                  overflow='hidden'
                  whiteSpace="nowrap"
                  className="file-name"
                >
                  {values.thumbnailURL.originalname}
                </Text>
              </Grid>
              <Text fontSize="sm">
                {formatBytes(values.thumbnailURL.size)}
              </Text>
            </Grid>
            <Spacer />
            <Grid>
              <Grid justifyContent="center">
                <CloseIcon
                  cursor="pointer"
                  className="close-icon"
                  size="small"
                  onClick={() => {
                    setFieldValue('thumbnailURL', '')
                  }}
                />
              </Grid>
              <Grid>
                <DownloadIcon
                  onClick={() => FileSaver.saveAs(thumbnailFile, fileName)}
                  cursor="pointer"
                  className="close-icon"
                  size="small"
                  w={6}
                  h={6}
                />
              </Grid>
            </Grid>
          </Flex>
          <Progress
            className="loading"
            value={100}
          />
        </Grid >
      }
    </>
  );
}
