import {useQuery} from "react-query";
import axios from "../../helpers/axios";

export const useGetCampaignIndustries = () => {

    return useQuery(['campaign-industries'], async () => {
        return axios
            .get('/spreadsheet-config/industries', {
                withCredentials: false,
            })
            .then((res) => {
                if (res.status === 200) {
                    return res.data
                }
            })
            .catch((err) => {
                return err.response
            })
    })
}