"use client";
import { extendTheme, withDefaultColorScheme } from "@chakra-ui/react";
import * as components from "./components";
import * as foundations from "./foundations";
import { StepsStyleConfig as Steps } from "chakra-ui-steps";
import { Input } from "./components/input";
const activeLabelStyles = {
  transform: "scale(0.85) translateY(-24px)",
};
const theme = extendTheme(
  {
    ...foundations,
    styles: {
      global: {
        body: {
          bg: "#000212",
        },
      },
    },
    components: {
      Steps,

      Form: {
        variants: {
          floating: {
            container: {
              _focusWithin: {
                label: {
                  ...activeLabelStyles,
                },
              },
              "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label":
                {
                  ...activeLabelStyles,
                },
              label: {
                top: "10px",
                left: 0,
                zIndex: 2,
                position: "absolute",
                backgroundColor: "white",
                pointerEvents: "none",
                mx: 3,
                px: 1,
                my: 2,
                transformOrigin: "left top",
              },
            },
          },
        },
      },
      FormLabel: {
        baseStyle: {
          color: "#363849",
          fontSize: "sm",
        },
      },
      FormHelperText: {
        baseStyle: {
          fontSize: "sm",
        },
      },
      ...components,
    },

    config: {
      useSystemColorMode: false,
      initialColorMode: "light",
    },
    fonts: {
      body: '"Poppins", sans-serif',
      heading: "lora, serif",
    },
  },
  withDefaultColorScheme({ colorScheme: "blue" })
);

export default theme;
