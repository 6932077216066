import { useQuery } from 'react-query'
import axios from '../../helpers/axios'

export const useDeleteCampaign = (clientId, campaignId) => {
    return useQuery(['campaign', clientId], async () => {
        return axios
            .delete(`/client/${clientId}/campaign-brief/${campaignId}`, {
                withCredentials: false,
            })
            .then((res) => {
                console.log("res", res);
                // if (res.status === 200) {
                //     return res.data.data
                // }
                return res
            })
            .catch((err) => {
                console.log("err", err);
                return err.response
            })
    })
}
