import {useSelector} from "react-redux";
import FacebookType from "./Facebook/FacebookType";
import InstagramType from "./Instagram/InstagramType";
import DV360Type from "./DV360/DV360Type";
import PintrestType from "./Pintrest/PintrestType";
import TiktokType from "./Tiktok/TiktokType";
import GoogleType from "./Google/GoogleType";

function SocialMediaPlatformType({activeStep, steps, prevStep, nextStep, label}) {

    const {selectedSocialMedia} = useSelector((state) => state.adUpload)

    if(selectedSocialMedia === 'facebook') {
        return <FacebookType activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
    }

    if(selectedSocialMedia === 'instagram') {
        return <InstagramType activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label}/>
    }

    if(selectedSocialMedia === 'dv360') {
        return <DV360Type activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label}/>
    }

    if(selectedSocialMedia === 'pinterest') {
        return <PintrestType activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label}/>
    }

    if(selectedSocialMedia === 'tiktok') {
        return <TiktokType activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label}/>
    }

    if(selectedSocialMedia === 'google') {
        return <GoogleType activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label}/>
    }
}

export default SocialMediaPlatformType