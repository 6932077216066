import { createSlice } from "@reduxjs/toolkit";
import {adUploadSlice} from "../../../../../../../../store/adUploads";

const initialState = {
    formDetails: {},
    mediaDetails: [],
    handlePreviewData: null,
};

export const dv360YoutubeVideoSlice = createSlice({
    name: "dv360YoutubeVideo",
    initialState,
    reducers: {
        settingDV360YoutubeVideo: (state, action) => {
            state.formDetails = action.payload;
        },
        deleteAlLDV360YoutubeVideoField: (state, action) => {
            state.formDetails = {};
            state.mediaDetails = [];
            state.handlePreviewData = null;
        },
    }
});

export const { settingDV360YoutubeVideo, deleteAlLDV360YoutubeVideoField } = dv360YoutubeVideoSlice.actions;

// The reducer
export const dv360YoutubeVideoReducer = dv360YoutubeVideoSlice.reducer;
