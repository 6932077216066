const colors = {
  brand: {
    heading: "#757998",
    text: "#6f707d",
    label: "#363849",
    // primary: "#79A9FF",
    primary: "#93B7E2",
  },
  blue: {
    50: "#F2F8FF",
    100: "#DFEBFF",
    200: "#C3DCFD",
    300: "#B3D1F4",
    400: "#A3B7E2",
    500: "#93B7E2",
    600: "#80A7D6",
    700: "#79A9FF",
    800: "#1565C0",
    900: "#0D47A1",
  },
};

export default colors;
