import {WrapContext} from "./store/WrapContext";
import GoogleCSV from "./GoogleCSV";

function Google() {
    return (
        <WrapContext>
            <GoogleCSV/>
        </WrapContext>
    )
}

export default Google