import React, {useState} from "react"
import {useToast} from "@chakra-ui/react";

const AppContext = React.createContext()

const AppContextProvider = (props) => {
    const [appLoading, setAppLoading] = useState(false)
    const toast = useToast();
    const ErrorToaster = (msg) => {
        toast({
            status: 'error',
            variant: 'top-accent',
            position: 'top-right',
            description: msg || 'Somethings went wrong'
        })
    }

    const SuccessToaster = (msg) => {
        toast({
            status: 'success',
            variant: 'top-accent',
            position: 'top-right',
            description: msg
        })
    }

    return (
        <AppContext.Provider
            value={{
                appLoading,
                setAppLoading,
                ErrorToaster,
                SuccessToaster
            }}
        >
            {props.children}
        </AppContext.Provider>
    );
}

export {AppContextProvider, AppContext};
