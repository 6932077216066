import React, {createContext, useContext} from "react";

const AppContext = createContext();

const initialState = {
    socialPlatformType : ""
}

export const ContextWrapper = ({children}) => {
    return (
        <AppContext.Provider value={"Hello World"}>
            {children}
        </AppContext.Provider>
    )
}

export const useGlobalContextWrapper = () => {
    return useContext(AppContext)
}