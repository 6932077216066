import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Spacer,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  PREVIEW_BG_COLOR,
  TEXT_COLOR,
  INDICATOR_BG_COLOR,
} from "../../../../../../../layout/constant/MenuList";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { AD_UPLOAD_TYPE } from "../../../../../../../constant";
import { useUploadImage } from "../../../../../../../hooks/campaign-briefs/useUploadImage";
import instance from "../../../../../../../helpers/axios";
import { useNavigate, useParams } from "react-router-dom";
import WithAppContext from "../../../../../../../helpers/appContext";
import SuccessModal from "../../../../../../../components/PopupModal/SuccessModal";
import ErrorModal from "../../../../../../../components/PopupModal/ErrorModal";
import FullPageLoader from "../../../../../../../components/FullPageLoader";
import { deleteTheField } from "../../../../../../../store/adUploads/index";
import { useGetAdUpload } from "../../../../../../../hooks/campaign-briefs/useGetAdUpload";
import { useCreateAdPreview } from "../../../../../../../hooks/campaign-briefs/useCreateAdPreview";
import {
  deleteAllFacebookCarouselField,
  settingFacebookCarouselPreview,
} from "./store";
import { settingFacebookVideoPreviewData } from "../FacebookVideo/store";
import { useRecoilValue } from "recoil";
import { profile } from "../../../../../../../atoms/authAtom";

const FacebookCarouselAdPreview = ({
  SetTabIndex,
  activeStep,
  steps,
  prevStep,
  nextStep,
  label,
  context,
}) => {
  const [index, setIndex] = useState(0);
  const [ads, setAds] = useState([]);
  const [method, setMethod] = useState();
  const [url, setUrl] = useState();
  const { id: campaignId, adUploadId } = useParams();
  const clientId = useSelector((state) => state.client.clientId);
  const [isSuccessModalOpen, setSuccessModal] = useState(false);
  const [isErrorModalOpen, setErrorModal] = useState(false);
  const [description, setDescription] = useState("");
  const { mutateAsync } = useCreateAdPreview();
  const { setAppLoading, SuccessToaster, ErrorToaster, appLoading } = context;
  const { handlePreviewData, formDetails, mediaDetails } = useSelector(
    (state) => state.facebookCarouselAd
  );

  const { data, refetch } = useGetAdUpload(clientId, campaignId, adUploadId);
  const {
    access_info: { roles, clients: userClients },
  } = useRecoilValue(profile);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(roles.includes("Client"));
  }, [roles]);

  useEffect(() => {
    if (adUploadId) {
      refetch();
    }
  }, []);

  useEffect(() => {
    if (adUploadId) {
      setMethod("PUT");
      setUrl(
        `/client/${clientId}/campaign-brief/${campaignId}/ad-upload/${adUploadId}`
      );
    } else {
      setMethod("POST");
      setUrl(`/client/${clientId}/campaign-brief/${campaignId}/ad-upload`);
    }
  }, [adUploadId, clientId]);

  useEffect(() => {
    setAds(handlePreviewData);
  }, [handlePreviewData]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onCloseSuccessModal = () => {
    setSuccessModal(false);

    if (adUploadId) {
      navigate(`/campaign-briefs/${campaignId}`, {
        state: { isShowUpload: true },
      });
      dispatch(deleteAllFacebookCarouselField());
    } else {
      SetTabIndex(isClient ? 0 : 3);
      navigate(`/campaign-briefs/${campaignId}`);
      dispatch(deleteAllFacebookCarouselField());
    }
  };

  const [errorSubmit, setErrorSubmit] = useState("");

  async function handleSubmit() {
    try {
      setAppLoading(true);
      // setFromData(values)
      const child_attachments = mediaDetails.carouselCards.map((el) => {
        return {
          name: el.headline || el.name,
          image_hash: el.imageFile?.imageHash || el.image_hash,
          link: el.landingPageURL || el.link,
          description: el.description,
          video_id: el.videoFile?.video_id || el.video_id,
          imageFile: el.imageFile?.file || el.imageFile,
          videoFile: el.videoFile?.file || el.videoFile,
          fileType: el.fileType,
          call_to_action: {
            type: formDetails.facebookAccountId || el.call_to_action.type,
            value: {
              link: el.landingPageURL || el.call_to_action.value.link,
            },
          },
        };
      });
      let payload = {
        name: formDetails.adName,
        description: formDetails.primaryText,
        detail: {
          name: formDetails.headline,
          caption: formDetails.landingPageURL,
          description: formDetails.primaryText,
          link: formDetails.landingPageURL,
          child_attachments,
          multi_share_optimized: true,
        },
      };
      if (!adUploadId) {
        payload = {
          ...payload,
          ad_upload_type: AD_UPLOAD_TYPE.FBCAROUSEL,
        };
      }
      await instance({
        method: method,
        url: url,
        withCredentials: false,
        data: payload,
      })
        .then((res) => {
          if (res.status === 200) {
            setAppLoading(false);
            setSuccessModal(true);
            // navigate(`/campaign-briefs/${campaignId}`, {
            //     state: {isShowUpload: true}
            // })
          }
        })
        .catch((error) => {
          setAppLoading(false);
          setDescription(error.response.data.message);
          setErrorModal(true);
        });
    } catch (error) {
      setAppLoading(false);
    }
  }

  const getSlider = () => {
    if (ads?.length) {
      const slides = ads.map((el, ind) => (
        <Text
          height={1}
          borderRadius={5}
          px={4}
          mx={1}
          mt={8}
          key={ind}
          background={ind === index ? "grey" : "silver"}
          onClick={() => setIndex(ind)}
          cursor="pointer"
        />
      ));
      return slides;
    }
  };

  // show preview
  useEffect(() => {
    if (mediaDetails.carouselCards.length >= 2) {
      const getImages = async () => {
        setAppLoading(true);
        const child_attachments = mediaDetails.carouselCards.map((el) => {
          return {
            name: el.headline || el.name,
            image_hash: el.imageFile?.imageHash || el.image_hash,
            link: el.landingPageURL || el.link,
            description: el.description,
            video_id: el.videoFile?.video_id || el.video_id,
            call_to_action: {
              type: formDetails.facebookAccountId || el.call_to_action.type,
              value: {
                link: el.landingPageURL || el.call_to_action.value.link,
              },
            },
          };
        });

        const payload = {
          name: formDetails.adName,
          object_story_spec: {
            link_data: {
              name: formDetails.headline,
              caption: formDetails.landingPageURL,
              description: formDetails.primaryText,
              link: formDetails.landingPageURL,
              child_attachments,
              multi_share_optimized: true,
            },
          },
        };
        const previewsImages = [];
        await mutateAsync(
          {
            clientId: clientId,
            campaignBriefId: campaignId,
            data: payload,
            type: "fbCarouselAd",
          },
          {
            onSuccess: (data, variables, context) => {
              const previews = data && data.previews;
              if (previews) {
                Object.keys(previews).forEach((value, index) => {
                  previewsImages.push(Object.values(previews)[index]);
                });
              } else {
                ErrorToaster("Please fill all required ad details");
              }
              dispatch(settingFacebookCarouselPreview(previewsImages));
              setAppLoading(false);
            },
            onError: (error) => {
              ErrorToaster(error);
              setAppLoading(false);
            },
          }
        );
        // handlePreviewData(previewsImages);
        dispatch(settingFacebookCarouselPreview(previewsImages));

        setAppLoading(false);
      };
      getImages();
    }
  }, []);

  return (
    <>
      {appLoading && <FullPageLoader />}
      {adUploadId && (
        <Box
          display="flex"
          mb={6}
          justifyContent="space-between"
          alignItems="center"
        >
          <Heading fontSize="xl" color={"#757998"}>
            Please review the ad details carefully.
          </Heading>
          <Heading fontSize="md" color="gray.400">
            1 steps to complete
          </Heading>
        </Box>
      )}

      {!adUploadId && (
        <Box
          display="flex"
          mb={6}
          justifyContent="space-between"
          alignItems="center"
        >
          <Heading fontSize="xl" color={"#757998"}>
            Please review your ad.
          </Heading>
          <Heading fontSize="md" color="gray.400">
            1 steps to complete
          </Heading>
        </Box>
      )}

      <Grid templateColumns="repeat(6, 1fr)" gap={4} className="fb-upload">
        <GridItem w="full" colSpan={{ base: 6, lg: 6 }}>
          {/*<Heading fontSize="lg" my={4} color={TEXT_COLOR}>*/}
          {/*    {ads?.[index]?.filename}*/}
          {/*</Heading>*/}
          <Grid templateColumns="repeat(6, 1fr)" gap={4} className="fb-upload">
            <GridItem w="full" colSpan={{ base: 6, lg: 6 }}>
              <Heading fontSize="lg" my={4} color={TEXT_COLOR}>
                {ads?.[index]?.formatName || ads?.[index]?.filename}
              </Heading>
              <Box
                borderWidth="2px"
                borderColor="gray"
                p={10}
                background={PREVIEW_BG_COLOR}
                position={"relative"}
              >
                <Container height="100%">
                  <Flex
                    alignItems="center"
                    flexDirection="row"
                    justify="center"
                  >
                    <Box border="2px" borderColor="#757998">
                      {data && data.images ? (
                        <iframe
                          src={`${process.env.REACT_APP_API_URL}/uploads/${ads?.[index]?.imageUrl}`}
                          title={ads?.[index]?.filename}
                          width="400px"
                          height={600}
                          scrolling="no"
                        />
                      ) : (
                        <iframe
                          src={ads?.[index]?.src}
                          title={ads?.[index]?.filename}
                          width="400px"
                          height={600}
                        />
                      )}
                    </Box>
                  </Flex>
                </Container>
                <Flex
                  templateColumns="repeat(12, 1fr)"
                  justifyContent="space-between"
                >
                  <Button
                    leftIcon={
                      <ArrowBackIcon
                        color="brand.primarybrand.primary"
                        w={10}
                        h={10}
                      />
                    }
                    py={8}
                    variant="ghost"
                    position={"absolute"}
                    top={"50%"}
                    style={{ transform: "translateY(-50%)" }}
                    onClick={() => setIndex(index > 1 ? index - 1 : 0)}
                  />
                  <Spacer />
                  <Box
                    display={"flex"}
                    position={"absolute"}
                    bottom={"-14px"}
                    left={"50%"}
                    style={{ transform: "translateX(-50%)" }}
                  >
                    {getSlider()}
                  </Box>
                  <Spacer />
                  <Button
                    leftIcon={
                      <ArrowForwardIcon
                        color="brand.primarybrand.primary"
                        w={10}
                        h={10}
                      />
                    }
                    py={8}
                    variant="ghost"
                    position={"absolute"}
                    top={"50%"}
                    right={"0%"}
                    style={{ transform: "translateY(-50%)" }}
                    onClick={() =>
                      setIndex(
                        ads && Object.keys(ads)?.length > index + 1
                          ? index + 1
                          : index
                      )
                    }
                  />
                </Flex>
              </Box>
            </GridItem>
          </Grid>
          <Flex width="100%" justify="flex-end" py={10}>
            <Button
              mr={4}
              onClick={prevStep}
              colorScheme="blue"
              variant="outline"
              borderRadius={0}
              px={10}
            >
              Back
            </Button>
            <Button
              mr={4}
              colorScheme="blue"
              variant="solid"
              borderRadius={0}
              px={10}
            >
              Save Draft
            </Button>
            <Button
              mr={4}
              onClick={handleSubmit}
              colorScheme="green"
              variant="solid"
              borderRadius={0}
              px={10}
            >
              {adUploadId ? "Update" : "Submit"}
            </Button>
          </Flex>
        </GridItem>
        <SuccessModal
          isOpen={isSuccessModalOpen}
          onClose={() => onCloseSuccessModal()}
        />
        <ErrorModal
          isOpen={isErrorModalOpen}
          onClose={() => setErrorModal(false)}
          description={description}
        />
      </Grid>
      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={() => onCloseSuccessModal()}
      />
      <ErrorModal
        isOpen={isErrorModalOpen}
        onClose={() => setErrorModal(false)}
        description={errorSubmit}
      />
    </>
  );
};

export default WithAppContext(FacebookCarouselAdPreview);
