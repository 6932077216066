import {
    Box,
    Container, Flex, Grid, GridItem
} from "@chakra-ui/react";
import React from "react";
import ReactPlayer from 'react-player'
import "../style/tiktok.css";
import _ from "lodash";

const DisplayVideoAdPreview = ({data, setPlayingPreview}) => {
    return (
        <Grid templateColumns="repeat(6, 1fr)" gap={4} className="fb-upload">
            {!_.isEmpty(data) &&
            <GridItem w="full" colSpan={{base: 6, lg: 6}}>
                <Box
                    borderWidth="2px"
                    borderColor="gray"
                    p={10}
                    background="client/src/pages/CampaignBriefs/components/AdUpload/DV360Audio/AudioAdPreview#FFF8F4"
                >
                    <Container height="100%">
                        <Flex alignItems="center" flexDirection="row" justify="center">
                            <div className="containers">
                                <ReactPlayer
                                    className='react-player videos'
                                    url={data.url}
                                    width={400}
                                    height={700}
                                    // playing={playingPreview}
                                    playing={true}
                                    loop={true}
                                    onPlay={() => {
                                        setPlayingPreview(true)
                                    }}
                                />
                                <div className="overlay-box">

                                </div>
                                <div className="rights">
                                    <div className="icons-items right-icons">
                                        <span className="icons">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/images/heart.png`}
                                                alt=""
                                                id="likes-icons"
                                            />
                                        </span>
                                        <span className="icon-labels likes right-labels" data-likes="71.1k">71.1k</span>
                                    </div>
                                    <div className="icons-items right-icons">
                                        <span className="icons">
                                            <img src={`${process.env.PUBLIC_URL}/images/chat.png`} alt=""
                                                 id="comments-icons"/>
                                        </span>
                                        <span className="icon-labels comments right-labels"
                                              data-likes="1281">1281</span>
                                    </div>
                                    <div className="icons-items right-icons">
                                        <span className="icons">
                                            <img src={`${process.env.PUBLIC_URL}/images/share.png`}
                                                 alt=""/>
                                        </span>
                                        <span className="icon-labels shares right-labels">232</span>
                                    </div>
                                </div>
                                <div className="ads-details">
                                    <h2>{data.displayName}</h2>
                                    <span className="sponsored">
                                        <p> {data?.description.slice(0, 50)}...&nbsp;
                                            <button>Sponsored</button></p>
                                    </span>
                                    <span className="promoted-music">
                                        <span className="music-icon">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/images/music.png`}
                                                alt=""
                                                id="music-icons"
                                            />
                                        </span>
                                            <h3>Promoted music</h3>
                                    </span>
                                    <button>Sign up</button>
                                    <span className="sign-up-button"><img
                                        src={`${process.env.PUBLIC_URL}/images/right-arrow.png`}/></span>
                                </div>
                                <div className="bottoms">
                                    {/*    <span className="progress-durations"></span>*/}
                                    {/*    <div className="progress-ranges" title="seek">*/}
                                    {/*        <div className="progress-bars"></div>*/}
                                    {/*    </div>*/}
                                    <div className="iconss">
                                        <div className="bottom-icons-items">
                                            <span className="icons-bottom">
                                                <img src={`${process.env.PUBLIC_URL}/images/home.png`} alt=""/>
                                            </span>
                                            <span className="icon-labels">Home</span>
                                        </div>
                                        <div className="bottom-icons-items">
                                            <span className="icons-bottom">
                                                <img src={`${process.env.PUBLIC_URL}/images/search_1.png`} alt=""/>
                                            </span>
                                            <span className="icon-labels">Discover</span>
                                        </div>
                                        <div className="bottom-icons-items">
                                            <div className="icons-item-adds">
                                                <span className="icons-img" style={{display: "flex"}}>
                                                    <img src={`${process.env.PUBLIC_URL}/images/add.png`} alt=""/>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="bottom-icons-items">
                                            <span className="icons-bottom">
                                                <img src={`${process.env.PUBLIC_URL}/images/chat.png`} alt=""/>
                                            </span>
                                            <span className="icon-labels">Notification<span
                                                className="notification-badge"><button>9</button></span></span>
                                        </div>
                                        <div className="bottom-icons-items">
                                            <span className="icons-bottom">
                                                <img src={`${process.env.PUBLIC_URL}/images/user+(1).png`} alt=""/>
                                            </span>
                                            <span className="icon-labels">Me</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Flex>
                    </Container>
                </Box>
            </GridItem>
            }
        </Grid>
    );
};

export default DisplayVideoAdPreview;
