import DV360AdFileUpload from "../../../DV360/DV360AdFileUpload";
import {Box, Button, Grid, GridItem, Heading} from "@chakra-ui/react";
import React, {useCallback, useEffect} from "react";
import {Form, FormikProvider, useFormik} from "formik";
import {dv360AdUploadInitialValues} from "../../../../../constant/InitialValues";
import {
    AD_UPLOAD_TYPE,
    FACEBOOK_IMAGE_HEIGHT,
    FACEBOOK_IMAGE_WIDTH, INSTAGRAM_IMAGE_HEIGHT,
    INSTAGRAM_IMAGE_WIDTH
} from "../../../../../../../constant";
import instance from "../../../../../../../helpers/axios";
import * as yup from "yup";
import {checkIfFilesAreCorrectType, checkIfFilesAreTooBig} from "../../../../../../../helpers/fileValidation";
import {Flex} from "@chakra-ui/layout";
import {useDispatch, useSelector} from "react-redux";
import {settingFormMedia, settingHandlePreviewData} from "../../../../../../../store/adUploads/index"
import {TEXT_COLOR} from "../../../../../../../layout/constant/MenuList";
import {useParams} from "react-router-dom";
import {settingInstagramMedia, settingInstagramPreview} from "./store";
import InstagramImageAdFileUpload from "./InstagramImageAdFileUpload";
import {useUploadImage} from "../../../../../../../hooks/campaign-briefs/useUploadImage";
import {useCreateAdPreview} from "../../../../../../../hooks/campaign-briefs/useCreateAdPreview";
import FacebookImagAdFileUpload from "../../Facebook/FacebookImage/FacebookoImageAdFileUpload";
import WithAppContext from "../../../../../../../helpers/appContext";
import FullPageLoader from "../../../../../../../components/FullPageLoader";


function InstagramImageAdMedia({activeStep, steps, prevStep, nextStep, label, context}) {
    const { adUploadId } = useParams()

    const { mediaDetails } = useSelector((state) => state.instagramImageAd)

    const schema = yup.object().shape({
        thumbnailURL: yup.object().required().nullable(),
    });

    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            thumbnailURL: null
        },
        validationSchema: schema,
        onSubmit: (values => {
            dispatch(settingInstagramMedia({thumbnailFile: values?.thumbnailFile, thumbnailURL: values?.thumbnailURL}))
            nextStep()
        })
    });
    const {setAppLoading, SuccessToaster, ErrorToaster,appLoading} = context;
    const clientId = useSelector((state) => state.client.clientId);
    const {id} = useParams();
    const {mutateAsync} = useUploadImage();
    const {mutateAsync: mutateAsyncPreview} = useCreateAdPreview();

    const {
        errors,
        values,
        touched,
        handleSubmit,
        isValid,
        setErrors,
        setFieldValue,
    } = formik;


    useEffect(() => {
        setFieldValue("thumbnailURL", mediaDetails?.thumbnailURL ? mediaDetails?.thumbnailURL : null)
        setFieldValue("thumbnailFile", mediaDetails?.thumbnailFile ? mediaDetails?.thumbnailFile: null);
    }, [mediaDetails])


    const onDrop = useCallback(async (accFiles) => {
        accFiles.map((file) => {
            const img = new Image()
            setAppLoading(true)
            img.onload = async () => {
                const {naturalWidth: width, naturalHeight: height} = img
                if (width >= INSTAGRAM_IMAGE_WIDTH && height >= INSTAGRAM_IMAGE_HEIGHT) {
                    await mutateAsync(
                        {
                            clientId: clientId,
                            campaignBriefId: id,
                            adFile: accFiles,
                            type: "fbAdImages",
                        }, {
                            onSuccess: (data) => {
                                setFieldValue('thumbnailURL', data.file)
                                setFieldValue('thumbnailFile', data)
                                setAppLoading(false);
                                SuccessToaster('Image Upload Successfully...')
                            },
                            onError: () => {
                                setAppLoading(false);
                            }
                        });
                } else {
                    setAppLoading(false);
                    ErrorToaster('1080 X 1080 pixel getter then height and width')
                }
            }
            img.src = URL.createObjectURL(file)
        })
    }, []);


    return (
        <>
            <Box className="file-upload-box">
                {appLoading && <FullPageLoader/>}
                {
                    adUploadId && (
                        <Box display="flex" mb={6} justifyContent="space-between" alignItems="center">
                            <Heading fontSize="xl" color={"#757998"}>
                                Please review the ad details carefully.
                            </Heading>
                            <Heading fontSize="md" color="gray.400">
                                2 steps to complete
                            </Heading>
                        </Box>
                    )
                }

                {
                    !adUploadId && (
                        <Box display="flex" mb={6} justifyContent="space-between" alignItems="center">
                            <Heading fontSize="xl" color={"#757998"}>
                                Please upload your ad creative.
                            </Heading>
                            <Heading fontSize="md" color="gray.400">
                                2 steps to complete
                            </Heading>
                        </Box>
                    )
                }

                <FormikProvider value={formik}>
                    <Form autoComplete="off" onSubmit={handleSubmit}>
                        <InstagramImageAdFileUpload
                            getHashArray={(value) => {
                                setFieldValue('thumbnailURL', value)
                            }}
                            setFieldValue={setFieldValue}
                            errors={errors}
                            touched={touched}
                            type="facebookImage"
                            values={values}
                            setErrors={setErrors}
                            onDrop={onDrop}
                        />
                        <Flex width="100%" justify="flex-end" py={10}>
                            <Button
                                mr={4}
                                onClick={prevStep}
                                colorScheme='blue' variant='outline' borderRadius={0} px={10}
                            >
                                Back
                            </Button>
                            <Button mr={4} colorScheme='green' type="submit" variant='solid' borderRadius={0} px={10}>
                                Next
                            </Button>
                        </Flex>
                    </Form>

                </FormikProvider>

            </Box>
        </>
    )
}

export default WithAppContext(InstagramImageAdMedia)