import * as Yup from "yup";

const validationSchema = Yup.object({
  name: Yup.string().required().label("Name"),
  startDate: Yup.string().required().label("Start date"),
  endDate: Yup.string().required().label("End date"),
  detail: Yup.object({
    industryBasic: Yup.object({
      companyName: Yup.string().required().label("Company name"),
      phone: Yup.string().required().min(10).max(13).label("Phone number"),
    })
      .required()
      .label("Industry Basic"),
    campaignBasic: Yup.object({
      campaignName: Yup.string().required().label("Campaign name"),
      startDate: Yup.string().required().label("Start date"),
      endDate: Yup.string().required().label("End date"),
      duration: Yup.number()
        // .positive("Valid duration required")
        // .test(
        //   "Is positive?",
        //   "ERROR: The number must be greater than 0!",
        //   (value) => value > 0
        // )
        .required()
        .label("Duration"),
      websiteUrl: Yup.string()
        .required()
        .label("Website URL")
        .matches(
          /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
          "Website url is not valid!"
        ),
    })
      .required()
      .label("Campaign Basic"),
    socialPage: Yup.string().when("creativeUnit",{
      is: (creativeUnit) => {
        return creativeUnit.includes('Display')
      },
      then: Yup.string().required().label("Social Page"),
    }),
    customMetricsAndGoals: Yup.string()
      .required()
      .label("Custom metrics and goals"),
    objective: Yup.string().required().label("Objective"),
    conversionMeasurement: Yup.string()
      .required()
      .label("Conversion Measurement"),
  })
    .required()
    .label("Details"),
});

export default validationSchema;
