import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  GridItem,
  FormControl,
  FormLabel,
  Select,
  Grid,
  Button,
  Box,
  HStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { SubmitButton } from "formik-chakra-ui";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import InputBox from "../../components/InputBox";
import "../../pages/AdCampaigns/adCampaign.css";
import { useGetCampaignIndustries } from "../../hooks/config-management/useGetCampaignIndustries";

const validationSchema = Yup.object({
  name: Yup.string().required().label("Business Name"),
  contactName: Yup.string().required().label("Contact Name"),
  industry: Yup.string().required().label("Industry"),
  address: Yup.string().required().label("Address"),
  phone: Yup.number().required().label("Phone Number"),
  email: Yup.string().email().required().label("Email Address"),
});

function CampaignModal({
  onClose,
  isOpen,
  dialogTitle,
  buttonType,
  onSubmit,
  reportData,
}) {
  const [initialFormValue, setInitialFormValue] = useState(reportData);

  useEffect(() => {
    setInitialFormValue(reportData);
  }, [reportData]);

  const [industries, setIndustries] = useState([]);
  const { data } = useGetCampaignIndustries();

  useEffect(() => {
    setIndustries(data?.data);
  }, [data]);

  return (
    <>
      <Modal onClose={onClose} isOpen={isOpen} isCentered size="5xl" m="6">
        <ModalOverlay />
        <ModalContent mx="4">
          <ModalHeader color="brand.heading">{dialogTitle}</ModalHeader>
          <ModalCloseButton color="black" />
          <ModalBody>
            <Formik
              initialValues={initialFormValue}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({ values, errors, setFieldValue, touched }) => (
                <Box as={Form} noValidate style={{ color: "#1D1D1D" }}>
                  <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                    <GridItem
                      colSpan={{
                        base: "2",
                        md: 1,
                      }}
                    >
                      <FormControl isRequired>
                        <FormLabel>Business Name</FormLabel>
                        <InputBox name="name" />
                      </FormControl>
                    </GridItem>
                    <GridItem
                      colSpan={{
                        base: "2",
                        md: 1,
                      }}
                    >
                      <FormControl isRequired>
                        <FormLabel>Contact Name</FormLabel>
                        <InputBox name="contactName" />
                      </FormControl>
                    </GridItem>
                    <GridItem
                      colSpan={{
                        base: "2",
                        md: 1,
                      }}
                    >
                      <FormControl isRequired>
                        <FormLabel>Phone Number</FormLabel>
                        <InputBox name="phone" type="number" />
                      </FormControl>
                    </GridItem>
                    <GridItem
                      colSpan={{
                        base: "2",
                        md: 1,
                      }}
                    >
                      <FormControl isRequired>
                        <FormLabel>Email Address</FormLabel>
                        <InputBox name="email" />
                      </FormControl>
                    </GridItem>
                    <GridItem
                      colSpan={{
                        base: "2",
                        md: 1,
                      }}
                    >
                      <FormControl isRequired>
                        <FormLabel>Industry</FormLabel>
                        <Select
                          id="industry"
                          name="industry"
                          placeholder="Select Industry"
                          value={values.industry}
                          onChange={(e) =>
                            setFieldValue("industry", e.target.value)
                          }
                          isInvalid={errors.industry && touched.industry}
                        >
                          {!!industries &&
                            industries.map((objective) => (
                              <option
                                className={"campaign-option"}
                                key={objective.id}
                                value={objective.industry}
                              >
                                {objective.industry}
                              </option>
                            ))}
                        </Select>
                        {errors.industry && touched.industry && (
                          <Text color="red.500" fontSize="sm" mt={2}>
                            {errors.industry}
                          </Text>
                        )}
                      </FormControl>
                    </GridItem>
                    <GridItem colSpan={2}>
                      <FormControl isRequired>
                        <FormLabel>Address</FormLabel>
                        <InputBox name="address" />
                      </FormControl>
                    </GridItem>
                  </Grid>

                  <HStack justify="end">
                    <SubmitButton mt="4">{buttonType}</SubmitButton>
                  </HStack>
                </Box>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default CampaignModal;
