import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/modal";
import { Button, useToast } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import { facebookCarouselFileInitialValues } from "../../../../../constant/InitialValues";
import calculateAspectRatios from 'calculate-aspect-ratio';
import { useUploadImage } from "../../../../../../../hooks/campaign-briefs/useUploadImage";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
    FB_VIDEO_DURATION, INSTAGRAM_VIDEO_THUMBNAIL_HEIGHT,
    INSTAGRAM_VIDEO_THUMBNAIL_WIDTH, INSTAGRAM_VIDEO_WIDTH_MIN
} from "../../../../../../../constant";
import WithAppContext from "../../../../../../../helpers/appContext";
import InstagramVideoFileUpload from "./InstagramVideoFileUpload";
import InstagramImageFileUpload from "./InstagramImageFileUpload";

const imageValidationSchema = yup.object().shape({
    imageURL: yup.object().required().nullable(),
});
const Main = ({
    cardModalType,
    isShowCardModal,
    setIsShowCardModal,
    createCarouselAd,
    initialModalData,
    setInitialModalData,
    activeCarouselCardId,
    setActiveCarouselCardId,
    context
}) => {
    const [formData, setFromData] = useState(initialModalData);
    const { mutateAsync } = useUploadImage();
    const clientId = useSelector((state) => state.client.clientId);
    const { setAppLoading, appLoading, ErrorToaster, SuccessToaster } = context
    const { id } = useParams();

    return (
        <Formik
            enableReinitialize
            initialValues={initialModalData}
            validationSchema={imageValidationSchema}
        >
            {({ dirty, isValid, isSubmitting, values, errors, touched, setFieldValue, resetForm, validateForm  }) => {
                const onDrop = async (accFiles) => {
                    accFiles.map((file) => {
                        const fileType = file.type.split("/").shift();
                        if (fileType === "image") {
                            const img = new Image()
                            img.onload = async () => {
                                const { naturalWidth: width, naturalHeight: height } = img
                                const ratio = calculateAspectRatios(width, height);
                                if (width <= INSTAGRAM_VIDEO_THUMBNAIL_WIDTH && height <= INSTAGRAM_VIDEO_THUMBNAIL_HEIGHT) {
                                    ErrorToaster('Please valid Image Minimum Width: 500 pixels')
                                } else if (!ratio >= parseInt('1:1') && !ratio <= parseInt('4:5')) {
                                    ErrorToaster('Please valid aspect ratio.')
                                } else {
                                    setAppLoading(true);
                                    await mutateAsync(
                                        {
                                            clientId: clientId,
                                            campaignBriefId: id,
                                            adFile: accFiles,
                                            type: "pinterest",
                                        }, {
                                        onSuccess: (data) => {
                                            setFieldValue('imageURL', data.fileInfoList[0].files)
                                            setFieldValue('imageFile', data.fileInfoList[0])
                                            const el = cardModalType === 'video' ? 'Thumbnail' : 'Image';
                                            SuccessToaster(`${el} Upload Successfully.`)
                                            setAppLoading(false);
                                        },
                                        onError: (error) => {
                                            ErrorToaster(error)
                                            setAppLoading(false);
                                        }
                                    });
                                }
                            }
                            img.src = URL.createObjectURL(file)
                        } else {
                            const objectURL = URL.createObjectURL(file);
                            const mySound = new Audio([objectURL]);

                            let video = document.createElement('video');
                            video.preload = 'metadata';
                            video.onloadedmetadata = function () {
                                window.URL.revokeObjectURL(video.src);
                                return video
                            }
                            video.src = URL.createObjectURL(file);
                            mySound.addEventListener("canplaythrough", async () => {
                                const ratio = calculateAspectRatios(video.videoHeight, video.videoWidth);
                                if (!ratio >= parseInt('1:1') && !ratio <= parseInt('4:5')) {
                                    ErrorToaster('Please valid aspect ratio.')
                                } else if (mySound.duration > FB_VIDEO_DURATION) {
                                    ErrorToaster('Please valid Video Duration: 1 second to 60 minutes.')
                                } else if (video.videoWidth <= INSTAGRAM_VIDEO_WIDTH_MIN) {
                                    ErrorToaster('Please valid Video Minimum Width: 500 pixels')
                                } else {
                                    setAppLoading(true);
                                    await mutateAsync(
                                        {
                                            clientId: clientId,
                                            campaignBriefId: id,
                                            adFile: accFiles,
                                            type: 'fbAdVideos',
                                        }, {
                                        onSuccess: (data) => {
                                            setFieldValue('videoURL', data.file)
                                            setFieldValue('videoFile', data)
                                            setAppLoading(false);
                                            SuccessToaster('Video Uploaded Successfully.')
                                        },
                                        onError: (error) => {
                                            ErrorToaster(error)
                                            setAppLoading(false);
                                        }
                                    });
                                }
                            })
                        }
                    })
                };
                const onClearData = () => {
                    setFromData(facebookCarouselFileInitialValues)
                    setInitialModalData(facebookCarouselFileInitialValues)
                    setIsShowCardModal(false)
                    resetForm()
                    setActiveCarouselCardId(null)
                }

                const onSubmitData = async (values) => {
                    createCarouselAd({ ...values, fileType: cardModalType })
                    onClearData()
                }
                return (
                    <Form autoComplete="off">
                        <Modal isOpen={isShowCardModal} size="xl" onClose={onClearData}>
                            <ModalOverlay />
                            <ModalContent>
                                <ModalHeader>Add {cardModalType} card</ModalHeader>
                                <ModalBody>
                                    {cardModalType === 'image' &&
                                        <InstagramImageFileUpload
                                            setIsShowCardModal={setIsShowCardModal}
                                            onDrop={onDrop}
                                            touched={touched}
                                            errors={errors}
                                            setFromData={setFromData}
                                            setFieldValue={setFieldValue}
                                            values={values}
                                            formData={formData}
                                        />
                                    }
                                </ModalBody>
                                <ModalFooter marginTop="5" justifyContent="flex-end">
                                    <Button
                                        disabled={!values.imageURL}
                                        type="submit"
                                        onClick={() => onSubmitData(values)}
                                    >{activeCarouselCardId !== null ? 'Update' : 'Add'}
                                    </Button>
                                    <Button marginLeft="20px" onClick={onClearData}>Close</Button>
                                </ModalFooter>
                            </ModalContent>
                        </Modal>
                    </Form>
                );
            }}
        </Formik>
    );
}


export default WithAppContext(Main)
