import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    formDetails: {},
    mediaDetails: [],
    handlePreviewData: null,
};

export const dv360AudioSlice = createSlice({
    name: "dv360Audio",
    initialState,
    reducers: {
        settingDV360AudioFormDetail: (state, action) => {
            state.formDetails = action.payload
        },
        settingDV360AudioFormMedia: (state, action) => {
            state.mediaDetails = action.payload
        },
        settingDV360AudioPreviewData: (state, action) => {
            state.handlePreviewData = action.payload
        },
        deleteAllDV360AudioField: (state, action) => {
            state.formDetails = {};
            state.mediaDetails = [];
            state.handlePreviewData = null
        }
    },
});

export const { settingDV360AudioFormDetail, settingDV360AudioFormMedia, settingDV360AudioPreviewData, deleteAllDV360AudioField} = dv360AudioSlice.actions;

// The reducer
export const dv360AudioReducer = dv360AudioSlice.reducer;
