export const detailInitialValue = (details) => ({
    name: details.name,
    lastName: details.lastName,
    email: details.email,
    company: details.company,
});

export const passwordInitialValue = {
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
};
