import {Box, Heading, Image, SimpleGrid} from "@chakra-ui/react";
import {TEXT_COLOR} from "../../../../../../layout/constant/MenuList";
import React, {useEffect, useState} from "react";
import {selectSocialMedia, selectMediaType, defaultMediaTypeNull} from "../../../../../../store/adUploads";
import {useDispatch, useSelector} from "react-redux";

const type = [
    {
        name: "Static Image",
        image: "image.svg",
        type: "FACEBOOK_IMAGE"
    },
    {
        name: "Video",
        image: "video.svg",
        type: "FACEBOOK_VIDEO"
    },
    {
        name: "Carousel",
        image: "carousel.svg",
        type: "FACEBOOK_CAROUSEL"
    }
];

function FacebookType({activeStep, steps, prevStep, nextStep, label}) {

    const { mediaType } = useSelector((state) => state.adUpload);
    const [selectedTab, setSelectTab] = useState(mediaType);


    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(defaultMediaTypeNull())
    },[])

    const activeTab = {
        border: "2px solid #3B82F6",
        boxSizing: "border-box"
    }

    function handleClick(index, type) {
        handleSelect(index, type)
        nextStep()
    }

    function handleSelect(index, type) {
        setSelectTab(type);
        dispatch(selectMediaType(type))
    }

    return (
        <Box w='100%' p={4}>
            <Box display="flex" mb={6} justifyContent="space-between" alignItems="center">
                <Heading fontSize="xl" color={"#757998"}>
                    What type of ad is this?
                </Heading>
                <Heading fontSize="md" color="#A7A9BD">
                    4 steps to complete
                </Heading>
            </Box>

            <SimpleGrid columns={[1, 1, 1, 2]} spacing='20px'>
                {
                    type.map((item, index) => {
                        const {type, image, name} = item;
                        return (
                            <Box
                                 cursor={"pointer"}
                                 border={"2px solid rgba(167, 169, 189, 0.2)"}
                                 borderRadius={"10px"}
                                 height='80px'
                                 display={"flex"}
                                 p={4}
                                 key={item.name}
                                 style={selectedTab === type ? activeTab : null}
                                 onClick={() => handleClick(index, type)}
                            >
                                    <Box display={"flex"} alignItems={"center"} gap={"10px"} color={"#757998"}>
                                        <Image
                                            src={`${process.env.PUBLIC_URL}/images/${image}`}
                                            height="40px"
                                        /> {name}</Box>
                            </Box>
                        )
                    })
                }
            </SimpleGrid>
        </Box>
    )
}

export default FacebookType