import {
  Box,
  Button,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Radio,
  Text,
} from "@chakra-ui/react";
import InputBox from "../../../../components/InputBox";
import MultiSelectInputBox from "../../../../components/MultiSelectInputBox";
import { CampaignBudgetOptions } from "../../constant/SelectValues";
import { CloseIcon } from "@chakra-ui/icons";
import "../../style/CampaignDetails.css";
import { RadioGroupControl } from "formik-chakra-ui";
import React, { useState } from "react";
import { useEffect } from "react";
import { TEXT_COLOR } from "../../../../layout/constant/MenuList";
import { Flex } from "@chakra-ui/layout";
import _ from "lodash";

const CampaignBudgetStep = ({
  values,
  setFieldValue,
  errors,
  activeStep,
  steps,
  prevStep,
  nextStep,
}) => {
  const [budgets, setBudgets] = useState([]);
  const [total, setTotal] = useState(0);
  const [campaignValue, setCampaignValue] = useState([]);

  useEffect(() => {
    let total = 0;
    if (values.detail.campaignBudget.googleSearchAds) {
      total += parseInt(values.detail.campaignBudget.googleSearchAds) || 0;
    }
    if (values.detail.campaignBudget.bingSearchAds) {
      total += parseInt(values.detail.campaignBudget.bingSearchAds) || 0;
    }
    if (values.detail.campaignBudget.fbAds) {
      total += parseInt(values.detail.campaignBudget.fbAds) || 0;
    }
    if (values.detail.campaignBudget.instaAds) {
      total += parseInt(values.detail.campaignBudget.instaAds) || 0;
    }
    if (values.detail.campaignBudget.displayAds) {
      total += parseInt(values.detail.campaignBudget.displayAds) || 0;
    }
    if (values.detail.campaignBudget.twitterAds) {
      total += parseInt(values.detail.campaignBudget.twitterAds) || 0;
    }
    if (values.detail.campaignBudget.nativeAds) {
      total += parseInt(values.detail.campaignBudget.nativeAds) || 0;
    }
    if (values.detail.campaignBudget.spotifyAudioAds) {
      total += parseInt(values.detail.campaignBudget.spotifyAudioAds) || 0;
    }
    if (values.detail.campaignBudget.pinterestAds) {
      total += parseInt(values.detail.campaignBudget.pinterestAds) || 0;
    }
    if (values.detail.campaignBudget.quoraAds) {
      total += parseInt(values.detail.campaignBudget.quoraAds) || 0;
    }
    if (values.detail.campaignBudget.displayVideo) {
      total += parseInt(values.detail.campaignBudget.displayVideo) || 0;
    }
    if (values.detail.campaignBudget.youtubeVideo) {
      setTotal(
        total + parseInt(values.detail.campaignBudget.youtubeVideo) || 0
      );
    }
    if (values.detail.campaignBudget.fbVideo) {
      total += parseInt(values.detail.campaignBudget.fbVideo) || 0;
    }
    if (values.detail.campaignBudget.instaVideo) {
      total += parseInt(values.detail.campaignBudget.instaVideo) || 0;
    }
    if (values.detail.campaignBudget.overTheTop) {
      total += parseInt(values.detail.campaignBudget.overTheTop) || 0;
    }
    if (values.detail.campaignBudget.retargetting) {
      if (values.detail.campaignBudget.retargetting.googleSearchRt) {
        total +=
          parseInt(values.detail.campaignBudget.retargetting.googleSearchRt) ||
          0;
      }
      if (values.detail.campaignBudget.retargetting.bingSearchRt) {
        total +=
          parseInt(values.detail.campaignBudget.retargetting.bingSearchRt) || 0;
      }
      if (values.detail.campaignBudget.retargetting.fbRt) {
        total += parseInt(values.detail.campaignBudget.retargetting.fbRt) || 0;
      }
      if (values.detail.campaignBudget.retargetting.instaRt) {
        total +=
          parseInt(values.detail.campaignBudget.retargetting.instaRt) || 0;
      }
      if (values.detail.campaignBudget.retargetting.displayRt) {
        total +=
          parseInt(values.detail.campaignBudget.retargetting.displayRt) || 0;
      }
      if (values.detail.campaignBudget.retargetting.linkedinRt) {
        total +=
          parseInt(values.detail.campaignBudget.retargetting.linkedinRt) || 0;
      }
      if (values.detail.campaignBudget.retargetting.nativeRt) {
        total +=
          parseInt(values.detail.campaignBudget.retargetting.nativeRt) || 0;
      }
      if (values.detail.campaignBudget.retargetting.pinterestRt) {
        total +=
          parseInt(values.detail.campaignBudget.retargetting.pinterestRt) || 0;
      }
      if (values.detail.campaignBudget.retargetting.videoRt) {
        total +=
          parseInt(values.detail.campaignBudget.retargetting.videoRt) || 0;
      }
    }
    if (values.detail.campaignBudget.other) {
      if (values.detail.campaignBudget.other.hotelAds) {
        total += parseInt(values.detail.campaignBudget.other.hotelAds) || 0;
      }
      if (values.detail.campaignBudget.other.travelAds) {
        total += parseInt(values.detail.campaignBudget.other.travelAds) || 0;
      }
      if (values.detail.campaignBudget.other.shoppingAds) {
        total += parseInt(values.detail.campaignBudget.other.shoppingAds) || 0;
      }
      if (values.detail.campaignBudget.other.fbEvents) {
        total += parseInt(values.detail.campaignBudget.other.fbEvents) || 0;
      }
      if (values.detail.campaignBudget.other.fbLookalike) {
        total += parseInt(values.detail.campaignBudget.other.fbLookalike) || 0;
      }
      if (values.detail.campaignBudget.other.instaLookalike) {
        total +=
          parseInt(values.detail.campaignBudget.other.instaLookalike) || 0;
      }
      if (values.detail.campaignBudget.other.displayList) {
        total += parseInt(values.detail.campaignBudget.other.displayList) || 0;
      }
      if (values.detail.campaignBudget.other.fbList) {
        total += parseInt(values.detail.campaignBudget.other.fbList) || 0;
      }
      if (values.detail.campaignBudget.other.instaList) {
        total += parseInt(values.detail.campaignBudget.other.instaList) || 0;
      }
      if (values.detail.campaignBudget.other.weatherAds) {
        total += parseInt(values.detail.campaignBudget.other.weatherAds) || 0;
      }
      if (values.detail.campaignBudget.other.linkedinInmail) {
        total +=
          parseInt(values.detail.campaignBudget.other.linkedinInmail) || 0;
      }
      if (values.detail.campaignBudget.other.linkedinSponsored) {
        total +=
          parseInt(values.detail.campaignBudget.other.linkedinSponsored) || 0;
      }
      if (values.detail.campaignBudget.other.fbLead) {
        total += parseInt(values.detail.campaignBudget.other.fbLead) || 0;
      }
      if (values.detail.campaignBudget.other.instaLead) {
        total += parseInt(values.detail.campaignBudget.other.instaLead) || 0;
      }
      if (values.detail.campaignBudget.other.instantExperience) {
        total +=
          parseInt(values.detail.campaignBudget.other.instantExperience) || 0;
      }
    }

    setTotal(total);
  }, [values.detail.campaignBudget]);

  useEffect(() => {
    const datas = CampaignBudgetOptions.map((el) => {
      let d = null;
      Object.entries(values.detail.campaignBudget).map((campaignBudget) => {
        if (
          el.name === `detail.campaignBudget.${campaignBudget[0]}` &&
          campaignBudget[1] !== ""
        ) {
          d = el !== null && el;
        }
      });
      Object.entries(values.detail.campaignBudget.other).map(
        (campaignBudget) => {
          if (
            el.name === `detail.campaignBudget.other.${campaignBudget[0]}` &&
            campaignBudget[1] !== ""
          ) {
            d = el !== null && el;
          }
        }
      );
      Object.entries(values.detail.campaignBudget.retargetting).map(
        (campaignBudget) => {
          if (
            el.name ===
              `detail.campaignBudget.retargetting.${campaignBudget[0]}` &&
            campaignBudget[1] !== ""
          ) {
            d = el !== null && el;
          }
        }
      );
      return d;
    });
    setCampaignValue(_.without(datas, undefined, null));
  }, []);

  const finalBudget = values?.detail?.campaignBudget?.finalBudget;
  const sum = parseInt(total) + parseInt(finalBudget);
  const finalSum = isNaN(sum) ? "N/A" : sum.toLocaleString();

  return (
    <Box p={4}>
      <Box mb={6} display="flex" justifyContent="space-between">
        <Heading fontSize="xl" color={"#757998"}>
          Campaign Budget
        </Heading>
        <Heading fontSize="md" color="#A7A9BD">
          6 steps to complete
        </Heading>
      </Box>
      <Grid
        className="flex-for-mobile"
        templateColumns="repeat(2, 1fr)"
        gap={"8px"}
      >
        <GridItem colSpan={2}>
          <Grid
            className="flex-for-mobile"
            templateColumns="repeat(3, 1fr)"
            gap={4}
          >
            <GridItem colSpan={1}>
              <MultiSelectInputBox
                label="Campaign Budget"
                name="Campaign Budget"
                options={CampaignBudgetOptions}
                value={campaignValue}
                placeholder={`Select...`}
                onChange={(e) => {
                  setBudgets(e);
                  setCampaignValue(e);
                }}
              />
            </GridItem>
            <GridItem colSpan={2}>
              <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                {campaignValue.map((el, index) => (
                  <GridItem key={index} colSpan={1}>
                    <Box className="budget-item">
                      <Box className="heading">
                        <Text fontSize="sm">{el.label}</Text>
                        <CloseIcon
                          onClick={() => {
                            setBudgets(
                              budgets.filter((e) => e.label != el.label)
                            );
                            setCampaignValue(
                              campaignValue.filter((e) => e.label != el.label)
                            );
                            setFieldValue(el.name, "");
                          }}
                          cursor="pointer"
                          w={3}
                          h={3}
                        />
                      </Box>
                      <Box className="input-box">
                        <InputBox name={el.name} />
                      </Box>
                    </Box>
                  </GridItem>
                ))}
              </Grid>
            </GridItem>
          </Grid>
        </GridItem>
        <GridItem>
          <RadioGroupControl
            label="Monthly"
            labelProps={{
              fontSize: "sm",
              color: "gray",
            }}
            name="detail.campaignBudget.isMonthly"
          >
            <Radio colorScheme="green" value="Yes">
              Yes
            </Radio>
            <Radio colorScheme="green" value="No">
              No
            </Radio>
          </RadioGroupControl>
        </GridItem>
        <GridItem>
          <Box>
            <FormLabel>Total</FormLabel>
            <Button className="total-budget">
              $ {total.toLocaleString()}
              {values.detail?.campaignBudget?.isMonthly === "Yes" && " / month"}
            </Button>
          </Box>
        </GridItem>
        <GridItem colSpan={2}>
          <InputBox name="detail.campaignBudget.custom" placeholder="Custom" />
        </GridItem>
        <GridItem colSpan={2}>
          <InputBox
            name="detail.campaignBudget.budgetAddOn"
            placeholder="Budget Add On"
          />
        </GridItem>
        <GridItem>
          <Flex gap={5}>
            <InputBox
              name="detail.campaignBudget.finalBudget"
              placeholder="Final Budget"
            />
            <Button mt={2} className="total-budget">
              {" "}
              $ {finalSum}
              {values.detail?.campaignBudget?.isMonthly === "Yes" && " / month"}
            </Button>
          </Flex>
        </GridItem>
      </Grid>
      <Flex width="100%" justify="flex-end" py={10}>
        <Button
          isDisabled={activeStep === 0}
          mr={4}
          onClick={prevStep}
          colorScheme="blue"
          variant="outline"
          borderRadius={0}
          px={10}
        >
          Back
        </Button>

        <Button
          mr={4}
          onClick={nextStep}
          colorScheme="green"
          variant="solid"
          borderRadius={0}
          px={10}
        >
          Next
        </Button>
      </Flex>
    </Box>
  );
};

export default CampaignBudgetStep;
