import React from "react";
import { useDropzone } from 'react-dropzone';
import { CloseIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import {
    Button,
    Flex, Grid, Heading, Image, Input, Progress, Spacer, Text, Tooltip, Box
} from "@chakra-ui/react";
import { TEXT_COLOR } from "../../../../../../layout/constant/MenuList";
import FileUploadIcon from "../../../../../../assets/images/file-upload-icon.png";

export function UploadAudioFile({ files, onDelete, onDrop, errors, touched }) {
    const acceptAudioFiles = {
        "image/ogg": [".ogg"],
        "image/mp3": [".mp3"],
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: acceptAudioFiles,
        maxSize: 1024 * 1024 * 1024,
        disabled: files.length !== 0 && true,
    });

    return (
        <>
            <Grid border='2px' borderColor={(errors.audioFiles && touched.audioFiles) ? 'red.500' : 'gray.200'}
                padding="20px" borderStyle="dashed">
                <div {...getRootProps()}>
                    <Input {...getInputProps()} />
                    <div className="file-upload-content">
                        <Image
                            boxSize="100px"
                            objectFit="cover"
                            src={FileUploadIcon}
                            alt="upload-icon"
                        />
                        <Heading fontSize="xl" display="flex" alignItems="center" mb={1} color={TEXT_COLOR}>
                            Drag and drop audio files
                            <Tooltip label={
                                <Box>
                                    <Text color="#000"><b>Audio file type:</b> ogg or mp3</Text>
                                    <Text color="#000"><b>Duration:</b> 15 or 30 seconds</Text>
                                    <Text color="#000"><b>Audio maximum file size:</b> 1 GB</Text>
                                </Box>} closeDelay={500} bg='#e2e8f0'>
                                <InfoOutlineIcon marginLeft="2" height="15px" width="15px" marginTop="2px" />
                            </Tooltip>
                        </Heading>
                        <Heading fontSize="sm" mb={1} color={TEXT_COLOR}>
                            or
                        </Heading>
                        <Button size="small" onClick={() => {
                        }} disabled={files.length !== 0 && true}>Browse</Button>
                    </div>
                </div>
            </Grid>
            {(errors.audioFiles && touched.audioFiles) &&
                <Text color="red.500" marginTop="1">
                    Audio File is required
                </Text>
            }
            {files.map((fileWrapper, index) => {
                let videoURL = fileWrapper?.imageUrl ? `${process.env.REACT_APP_API_URL}/uploads${fileWrapper?.imageUrl}` : null;
                if (!videoURL) {
                    videoURL = URL.createObjectURL(fileWrapper.file)
                }
                return (
                    <Grid className="file-box" key={index}>
                        <Flex marginTop="5" marginBottom="5">
                            <audio
                                src={videoURL}
                                controls
                            />
                            <Spacer />
                            <Grid>
                                <CloseIcon
                                    cursor="pointer"
                                    className="close-icon"
                                    size="small"
                                    onClick={() => {
                                        if (fileWrapper.file) {
                                            onDelete(fileWrapper.file)
                                        } else {
                                            onDelete(fileWrapper)
                                        }
                                    }
                                    }
                                />
                            </Grid>
                        </Flex>
                        <Progress
                            className="loading"
                            value={100}
                        />
                    </Grid>
                );
            })}
        </>
    );
}
