import {CloseIcon, InfoOutlineIcon} from "@chakra-ui/icons";
import {
    Box, Button,
    Flex, Grid, Heading, Image, Input, Progress, Text, Tooltip, useToast
} from "@chakra-ui/react";
import calculateAspectRatios from 'calculate-aspect-ratio';
import React, {useCallback, useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import ReactPlayer from 'react-player'
import FileUploadIcon from "../../../../../../../assets/images/file-upload-icon.png";
import {TEXT_COLOR} from "../../../../../../../layout/constant/MenuList";

function PinterestVideoFile({values, setFieldValue, errors, touched, setErrors, playingDetails, setPlayingDetails,}) {
    const [fileURL, setFileURL] = useState('')
    const toast = useToast()


    useEffect(() => {
        if (values.fileURL) {
            let videoURL = values?.fileURL[0]?.imageUrl ? `${process.env.REACT_APP_API_URL}/uploads${values.fileURL[0]?.imageUrl}` : null;
            if (!videoURL) {
                videoURL = URL.createObjectURL(values.fileURL[0])
            } else {
                setErrors({})
            }
            setFileURL(videoURL)
        }
    }, [values])

    const onDrop = useCallback(async (accFiles, rejFiles) => {
        accFiles.map((file) => {
            var fileType = file.type.split("/").shift();
            if (fileType === "video") {
                const objectURL = URL.createObjectURL(file);
                const mySound = new Audio([objectURL]);

                var video = document.createElement('video');
                video.preload = 'metadata';
                video.onloadedmetadata = function () {
                    window.URL.revokeObjectURL(video.src);
                    return video
                }
                video.src = URL.createObjectURL(file);

                if (file) {
                    mySound.addEventListener("canplaythrough", () => {
                        const size = 1024 * 1024 * 1024
                        const ratio = calculateAspectRatios(video.videoHeight, video.videoWidth);
                        const fileName = file.name.substring(0, file.name.lastIndexOf('.')) || file.name;
                        if ((ratio === '16:9' || ratio === '4:3' || ratio === '9:16' || ratio === '3:4') && file.size <= size && fileName.length < 50 && video.duration >= 5 && video.duration <= 900) {
                            setFieldValue('fileURL', [file])
                        } else {
                            toast({
                                status: 'error',
                                variant: 'top-accent',
                                position: 'top-right',
                                description: 'Please upload valid video.'
                            })
                        }
                    });
                }
            }
        });
    }, []);

    const acceptVideoFiles = {
        "video/avi": [".avi"],
        "video/mov": [".mov"],
        "video/mp4": [".mp4"],
        "video/m4v": [".m4v"],
        "video/mpeg": [".mpeg"],
        "video/mpg": [".mpg"],
        "video/ogv": [".ogv"],
        "video/webm": [".webm"],
        "video/wmv": [".wmv"],
    };

    const {getRootProps, getInputProps} = useDropzone({
        onDrop,
        accept: acceptVideoFiles,
        maxSize: 1024 * 1024 * 1024,
        disabled: values.fileURL && true,
    });

    return (
        <>
            <Grid border='2px'
                  borderColor={(errors.fileURL) ? 'red.500' : 'gray.200'}
                  padding="20px" borderStyle="dashed">
                <Grid>
                    <div {...getRootProps()}>
                        <Input {...getInputProps()} />

                        <div className="file-upload-content">
                            <Image
                                boxSize="100px"
                                objectFit="cover"
                                src={FileUploadIcon}
                                alt="upload-icon"
                            />
                            <Heading
                                fontSize="xl"
                                mb={1}
                                color={TEXT_COLOR}
                            >
                                Drag and drop video files
                                <Tooltip label={
                                    <Box>
                                        <Text color="#000"><b>File type:-</b> .mp4, .mov or .m4v.</Text>
                                        <Text color="#000"><b>Encoding:-</b> H.264 or H.265.</Text>
                                        <Text color="#000"><b>Max file size:-</b> Up to 2GB.</Text>
                                        <Text color="#000"><b>Video length:-</b> Minimum 4 seconds, maximum 15 minutes.</Text>
                                        <Text color="#000"><b>Aspect ratio:-</b> Shorter than 1:2 (width:height), taller
                                            than 1.91:1. We recommend making your videos square (1:1) or vertical (2:3
                                            or 9:16).</Text>
                                    </Box>} closeDelay={500} bg='#e2e8f0'>
                                    <InfoOutlineIcon marginLeft="2" height="15px" width="15px" marginBottom="3px"/>
                                </Tooltip>
                            </Heading>
                            <Heading
                                fontSize="sm"
                                mb={1}
                                color={TEXT_COLOR}
                            >
                                or
                            </Heading>
                            <Button size="small" disabled={values.fileURL && true}>Browse</Button>
                        </div>
                    </div>
                </Grid>
            </Grid>
            {(errors.fileURL) &&
                <Text color="red.500" marginTop="1">
                    Video File is required
                </Text>
            }
            {values.fileURL &&
                < Grid className="file-box">
                    <Flex marginTop="5" marginBottom="5" justifyContent='center'>
                        <Box maxHeight='300px'>
                            <ReactPlayer
                                className='react-player'
                                url={fileURL}
                                width="300px"
                                height="170px"
                                controls
                                playing={playingDetails}
                                onPlay={() => {
                                    setPlayingDetails(true)
                                }}
                            />
                        </Box>
                        <Grid>
                            <CloseIcon
                                marginLeft="-19px"
                                zIndex="999"
                                marginTop="4px"
                                padding="2px"
                                bg="rgba(255, 255, 255, 0.45)"
                                cursor="pointer"
                                className="close-icon"
                                size="small"
                                onClick={() => setFieldValue('fileURL', '')}
                            />
                        </Grid>
                    </Flex>
                    <Progress
                        className="loading"
                        value={100}
                    />
                </Grid>
            }

        </>
    );
}

export default PinterestVideoFile;
