import {CloseIcon, DownloadIcon, InfoOutlineIcon} from "@chakra-ui/icons";
import {
    Box, Button,
    Flex, Grid, Heading, Image, Input, Progress, Spacer, Text, Tooltip, useToast
} from "@chakra-ui/react";
import FileSaver from 'file-saver';
import React, {useCallback, useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import DefaultImageIcon from "../../../../../../../assets/images/default-image-icon.png";
import FileUploadIcon from "../../../../../../../assets/images/upload.svg";
import WithAppContext from "../../../../../../../helpers/appContext";
import {useUploadImage} from "../../../../../../../hooks/campaign-briefs/useUploadImage";
import {GREY_TEXT_COLOR, TEXT_COLOR, BLUE_BUTTON_COLOR} from "../../../../../../../layout/constant/MenuList";
import {PINTEREST_IMAGE_SIZE} from "../../../../../../../constant";
import JPGIcon from "../../../../../../../assets/images/jpg.svg";
import PNGIcon from "../../../../../../../assets/images/png.svg";

const PinterestCarouselAdFileUpload = ({errors, touched, values, setFieldValue, context}) => {
    const toast = useToast()

    const onDrop = (accFiles) => {
        accFiles.map((file) => {
            setFieldValue('imageURL', [...values.imageURL, file]);
        });
    }

    const acceptImageFiles = {
        "image/png": [".png"],
        "image/jpeg": [".jpg"],
    };

    const {getRootProps, getInputProps, fileRejections} = useDropzone({
        onDrop,
        accept: acceptImageFiles,
        maxSize: PINTEREST_IMAGE_SIZE, // 10MB
        disabled: values.imageURL.length >= 12, // 12 max count
    });

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    const handleRemoveFile = (idx) => {
        const arr = [...values.imageURL];
        const newArr = arr.filter((el, index) => index !== idx);
        setFieldValue('imageURL', newArr);
    }

    // dropZone Errors
    useEffect(() => {
        fileRejections && fileRejections.map(({errors}) => {
            errors.map((err) => {
                toast({
                    status: 'error',
                    variant: 'top-accent',
                    position: 'top-right',
                    description: err.code + ',' + err.message || 'Please upload valid image.'
                })
            })
        })
    }, [fileRejections])


    return (
        <>
            <Box className="upload-main"  >
                <Grid padding="20px" paddingY={"40px"} border={"2px dashed"}
                      borderColor={(errors.imageURL && touched.imageURL) ? 'red.500' : 'gray.200'}>
                    <div {...getRootProps()}>
                        <Input {...getInputProps()} />

                        <div className="file-upload-content">
                            <Image
                                boxSize="100px"
                                objectFit="cover"
                                src={FileUploadIcon}
                                alt="upload-icon"
                            />
                            <Heading
                                fontSize="xl"
                                display="flex"
                                alignItems="center"
                                color={GREY_TEXT_COLOR}
                                my={4}
                            >
                                Drag Image Files to Upload
                                <Tooltip label={
                                    <Box>
                                        <Text color="#000"><b>Image file type:</b> jpg, png and jpeg</Text>
                                        <Text color="#000"><b>Image maximum file size:</b> 10 MB</Text>
                                        <Text color="#000"><b>Image maximum files:</b> 12</Text>
                                    </Box>} closeDelay={500} bg='#e2e8f0'>
                                    <InfoOutlineIcon marginLeft="2" height="15px" width="15px" marginTop="2px"/>
                                </Tooltip>
                            </Heading>
                            <Text
                                fontSize="sm"
                                mb={4}
                                color={TEXT_COLOR}
                            >
                                - OR -
                            </Text>
                            <Button bgColor={BLUE_BUTTON_COLOR} color="white" size="small" px={10} py={2} borderRadius={100}>Browse Files</Button>
                        </div>
                    </div>
                    {(errors.imageURL && touched.imageURL) &&
                    <Text color="red.500" marginTop="2" textAlign="center">
                        {errors.imageURL || 'please valid file'}
                    </Text>
                    }
                </Grid>

                <div className="image-listing">
                    {
                        values.imageURL.map((file, index) => {
                            let imgURL = '';
                            let fileName = '';
                            if (file && file.fieldname) {
                                imgURL = `${process.env.REACT_APP_API_URL}/uploads/${file.filename}`;
                                fileName = file.originalname;
                            } else {
                                imgURL = URL.createObjectURL(file)
                                fileName = file.name
                            }

                            return (
                                <Box display={"flex"} alignItems={"center"} gap={"16px"} className="file-box" key={index}>
                                    {
                                        file.type === "image/jpeg" && <Image
                                            boxSize="50px"
                                            objectFit="cover"
                                            src={JPGIcon}
                                            alt="Dan Abramov"
                                        />
                                    }
                                    {
                                        file.type === "image/png" && <Image
                                            boxSize="50px"
                                            objectFit="cover"
                                            src={PNGIcon}
                                            alt="Dan Abramov"
                                        />
                                    }
                                    {
                                        file?.mimetype === "image/jpeg" && <Image
                                            boxSize="50px"
                                            objectFit="cover"
                                            src={JPGIcon}
                                            alt="Dan Abramov"
                                        />

                                    }
                                    {
                                        file?.mimetype === "image/png" && <Image
                                            boxSize="50px"
                                            objectFit="cover"
                                            src={PNGIcon}
                                            alt="Dan Abramov"
                                        />

                                    }
                                    <Box flexGrow={1}>
                                        <Flex marginTop="5"  marginBottom="5">
                                            <Grid marginLeft="2">
                                                <Grid alignItems='center' display="flex">
                                                    <Text
                                                        fontSize="lg"
                                                        className="file-name"
                                                    >
                                                        {fileName}
                                                    </Text>
                                                </Grid>
                                                {file &&
                                                <Text fontSize="sm">
                                                    {formatBytes(file.size)}
                                                </Text>
                                                }
                                            </Grid>
                                            <Spacer/>
                                            <Grid>
                                                <Grid justifyContent="center">
                                                    <CloseIcon
                                                        cursor="pointer"
                                                        className="close-icon"
                                                        size="small"
                                                        onClick={() => handleRemoveFile(index)}
                                                    />
                                                </Grid>
                                                <Grid>
                                                    <DownloadIcon
                                                        onClick={() => FileSaver.saveAs(imgURL, fileName)}
                                                        cursor="pointer"
                                                        className="close-icon"
                                                        size="small"
                                                        w={6}
                                                        h={6}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Flex>
                                        <Box marginTop={"-20px"}>
                                            <Text textAlign={"right"} display={"block"} color={"green"}>100% Completed</Text>
                                            <Progress
                                                className="loading"
                                                value={100}
                                                colorScheme='green'
                                                size='sm'
                                            />
                                        </Box>

                                    </Box>

                                </Box>
                            );
                        })
                    }
                </div>
            </Box>

        </>
    );
}

export default WithAppContext(PinterestCarouselAdFileUpload)
