import React, {useReducer, useContext, createContext, useEffect} from "react"
import {googleApiData} from "../Data"
import reducer from "./reducer";
import {
    EMPTY_DATA,
    EMPTY_EXTENSION_DATA,
    EMPTY_SITELINK_DATA,
    SELECTED_DATA,
    SET_EXTENSION_DATA, SET_INITIAL_EXTENSION, SET_INITIAL_MASTERDATA, SET_INITIAL_SITELINK,
    SET_SITELINK_DATA
} from "./type";
import axios from "../../../../../../helpers/axios";

const AppContext = createContext();

const initialState = {
    data: googleApiData,
    selectedData: {},
    siteLinks: [],
    extension: {
        call: [],
        location: [],
        callout: []
    },
    headline: [],
    description: [],
}

export const WrapContext = ({children}) => {

    const [state, dispatch] = useReducer(reducer, initialState)

    const setInitialMasterData = (data) => {
        dispatch({type: SET_INITIAL_MASTERDATA, payload: data})
    }

    const setInitialExtensionData = (data) => {
        dispatch({type: SET_INITIAL_EXTENSION, payload: data})
    }

    const setInitialSiteLinkData = (data) => {
        dispatch({type: SET_INITIAL_SITELINK, payload: data})
    }

    const getMasterApiData = (clientId, id, googleId) => {
        // axios.get(`/client/88236d5f-b351-47dd-812b-9668871e563b/campaign-brief/${id}/ad-upload/${googleId}`).then(r => console.log('r', r))
    }

    const selectedDataFunction = (id) => {
        dispatch({type: SELECTED_DATA, payload: id})
    }

    const setSiteLink = (data) => {
        dispatch({type: SET_SITELINK_DATA, payload: data.siteLinks})
    }

    const setExtension = (data) => {
        dispatch({type: SET_EXTENSION_DATA, payload: data})
    }

    const emptyTheField = () => {
        dispatch({type: EMPTY_DATA})
    }

    const emptySiteLinkData = () => {
        dispatch({type: EMPTY_SITELINK_DATA})
    }

    const emptyExtensionData = () => {
        dispatch({type: EMPTY_EXTENSION_DATA})
    }


    return (
        <AppContext.Provider value={{
            ...state,
            setInitialSiteLinkData,
            selectedDataFunction,
            setSiteLink,
            setExtension,
            emptyTheField,
            emptySiteLinkData,
            emptyExtensionData,
            getMasterApiData,
            setInitialMasterData,
            setInitialExtensionData
        }}>
            {children}
        </AppContext.Provider>
    )
}

export const useGlobalContext = () => {
    return useContext(AppContext)
}