import {useQuery, useMutation} from 'react-query'
import axios from '../../helpers/axios'

export const useGetClientDetails = (id) => {
    return useQuery(['client', id], async () => {
        return axios
            .get(`/client/${id}`, {
                withCredentials: false,
            })
            .then((res) => {
                if (res.status === 200) {
                    return res.data.data.client
                }
            })
            .catch((err) => {
                throw err.response.data.message
            })
    })
}

export const removeUserInClient = (clientId, userId) => {
    return axios
        .delete(`/client/${clientId}/${userId}`, {
            withCredentials: false,
        })
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => {
            throw err.response.data.message
        })
}

export const useGetClientDetailsOnClick = () => {
    return useMutation((values) =>
        axios({
            method: 'GET',
            url: `/client/${values.id}`,
            withCredentials: false,
        })
            .then((res) => {
                if (res.status === 200) {
                    return res.data.data
                }
            })
            .catch((err) => {
                return err.response
            })
    )
}
