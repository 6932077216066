import DV360ImageAdPreview from "./DV360/DV360Image/DV360ImageAdPreview";
import {useSelector} from "react-redux";
import React from "react";
import DV360YoutubePreview from "./DV360/DV360YoutubeVideo/DV360YoutubePreview";
import PinterestImageAdPreview from "./Pintrest/PinterestImage/PinterestImageAdPreview";
import DV360VideoAdDetails from "./DV360/DV360Video/DV360VideoAdDetails";
import DV360VideoAdPreview from "./DV360/DV360Video/DV360VideoAdPreview";
import PinterestVideoAdPreview from "./Pintrest/PinterestVideo/PinterestVideoAdPreview";
import TiktokVideoAdPreview from "./Tiktok/TiktokVideo/TiktokVideoAdPreview";
import DV360AudioPreview from "./DV360/DV360Audio/DV360AudioPreview";
import PinterestCarouselAdPreview from "./Pintrest/PinterestCarousel/PinterestCarouselAdPreview";
import FacebookImageAdPreview from "./Facebook/FacebookImage/FacebookImageAdPreview";
import InstagramImageAdPreview from "./Instagram/InstagramImage/InstagramImageAdPreview";
import InstagramVideoAdPreview from "./Instagram/InstagramVideo/InstagramVideoAdPreview";
import FacebookVideoAdPreview from "./Facebook/FacebookVideo/FacebookVideoAdPreview";
import FacebookCarouselAdPreview from "./Facebook/FacebookCarousel/FacebookCarouselAdPreview";
import InstagramCarouselAdPreview from "./Instagram/InstagramCarousel/InstagramCarouselAdPreview";
import GoogleAdsTableData from "./Google/GoogleCsv/GoogleAdsTableData";
import {WrapContext} from "./Google/GoogleCsv/store/WrapContext";

function AdPreview({SetTabIndex, activeStep, steps, prevStep, nextStep, label}) {

    const {mediaType, editMediaType} = useSelector((state) => state.adUpload)

    if(mediaType === "dv360" || editMediaType === "DV360") {
        return (
            <DV360ImageAdPreview SetTabIndex={SetTabIndex} activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }

    if(mediaType === "PINTEREST_IMAGE" || editMediaType === "PinterestImage") {
        return (
            <PinterestImageAdPreview SetTabIndex={SetTabIndex} activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "PINTEREST_VIDEO" || editMediaType === "PinterestVideo") {
        return (
            <PinterestVideoAdPreview SetTabIndex={SetTabIndex} activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "PINTEREST_CAROUSEL" || editMediaType === "PinterestCarousel") {
        return (
            <PinterestCarouselAdPreview SetTabIndex={SetTabIndex} activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "TIKTOK_VIDEO" || editMediaType === "TikTokVideo") {
        return (
            <TiktokVideoAdPreview SetTabIndex={SetTabIndex} activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "INSTAGRAM_VIDEO" || editMediaType === "InstagramVideo") {
        return (
            <InstagramVideoAdPreview SetTabIndex={SetTabIndex} activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "INSTAGRAM_IMAGE" || editMediaType === "InstagramImage") {
        return (
            <InstagramImageAdPreview SetTabIndex={SetTabIndex} activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "INSTAGRAM_CAROUSEL" || editMediaType === "InstagramCarousel") {
        return (
            <InstagramCarouselAdPreview SetTabIndex={SetTabIndex} activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "FACEBOOK_VIDEO" || editMediaType === "FBVideo") {
        return (
            <FacebookVideoAdPreview SetTabIndex={SetTabIndex} activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "FACEBOOK_IMAGE" || editMediaType === "FACEBOOK") {
        return (
            <FacebookImageAdPreview SetTabIndex={SetTabIndex} activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "FACEBOOK_CAROUSEL" || editMediaType === "FBCarousel") {
        return (
            <FacebookCarouselAdPreview SetTabIndex={SetTabIndex} activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }

    if(editMediaType === "DV360YoutubeVideo" || mediaType === "DV360_YOUTUBE_VIDEO") {
        return <DV360YoutubePreview SetTabIndex={SetTabIndex} activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
    }

    if(editMediaType === "DV360Video" || mediaType === "DV360_VIDEO") {
        return <DV360VideoAdPreview SetTabIndex={SetTabIndex} activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label}  />
    }

    if(editMediaType === "DV360Audio" || mediaType === "DV360_AUDIO") {
        return <DV360AudioPreview SetTabIndex={SetTabIndex} activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label}  />
    }

    if(editMediaType === "Google" || mediaType === "display ads") {
        return <WrapContext><GoogleAdsTableData SetTabIndex={SetTabIndex} activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} /></WrapContext>
    }

}

export default AdPreview