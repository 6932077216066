import {Box, HStack, useToast, VStack, Button, Stack, Image, Heading, Text} from "@chakra-ui/react";
import React, {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {deleteAllFacebookImageField} from "./Facebook/FacebookImage/store";
import {deleteAllFacebookCarouselField} from "./Facebook/FacebookCarousel/store"
import {deleteAllFacebookVideoField} from "./Facebook/FacebookVideo/store"
import {deleteAllDV360AudioField} from "./DV360/DV360Audio/store";
import {deleteTheField} from "../../../../../store/adUploads/index"
import {deleteAllDV360VideoField} from "./DV360/DV360Video/store";
import {deleteAlLDV360YoutubeVideoField} from "./DV360/DV360YoutubeVideo/store";
import {deleteAllInstagramCarouselFields} from "./Instagram/InstagramCarousel/store";
import {deleteAllInstagramImageFields} from "./Instagram/InstagramImage/store";
import {deleteAllInstagramVideoFields} from "./Instagram/InstagramVideo/store";
import {deleteAllPinterestCarouselFields} from "./Pintrest/PinterestCarousel/store";
import {deleteAllPinterestVideoFields} from "./Pintrest/PinterestVideo/store";
import {deleteAllPinterestImageFields} from "./Pintrest/PinterestImage/store";
import {deleteAllTiktokVideoFields} from "./Tiktok/TiktokVideo/store/index"

function AdApproveSuccessUI({adStatus}) {


    const navigate = useNavigate()

    const {id} = useParams()

    const dispatch = useDispatch()

    useEffect(() => {
        setTimeout(() => {
            navigate(`/campaign-briefs/${id}`, {
                state: {isShowUpload: true},
            });

            // Deleting the DV360 Redux State
            dispatch(deleteAllDV360AudioField());
            dispatch(deleteTheField())
            dispatch(deleteAllDV360VideoField())
            dispatch(deleteAlLDV360YoutubeVideoField())

            // Deleting the Facebook Redux State
            dispatch(deleteAllFacebookImageField());
            dispatch(deleteAllFacebookCarouselField());
            dispatch(deleteAllFacebookVideoField());

            // Deleting the Instagram Redux State
            dispatch(deleteAllInstagramCarouselFields());
            dispatch(deleteAllInstagramImageFields());
            dispatch(deleteAllInstagramVideoFields());

            // Deleting the Pintrest Redux State
            dispatch(deleteAllPinterestCarouselFields());
            dispatch(deleteAllPinterestImageFields());
            dispatch(deleteAllPinterestVideoFields());

            // Deleting the Tiktok Redux State
            dispatch(deleteAllTiktokVideoFields());
        } , 2000)
    }, [])

    return (
        <Box p={10} border={"20px solid rgba(239, 246, 255, 0.5)"} mb={10} py={20} display="flex" flexDirection={"column"} gap={4} justifyContent={"center"} alignItems={"center"}>
            <Box display="flex" flexDirection={"column"} alignItems={"center"} gap={"4"}>
                {/*  When Adstatus is rejected or approved showiing the Image */}
                {
                    adStatus === "Rejected" &&  <Image
                        src={`${process.env.PUBLIC_URL}/images/Cancel.svg`}
                        height="80px"
                    />
                }

                {
                    adStatus === "Approved" && <Image
                        src={`${process.env.PUBLIC_URL}/images/greenSuccess.png`}
                        height="80px"
                    />
                }

                {/*  When Adstatus is rejected or approved showiing the Text */}


                {
                    adStatus === "Rejected" && <Heading size='lg' color="red.500">Rejected</Heading>
                }

                {
                    adStatus === "Approved" && <Heading size='lg' color="green.500">Success!</Heading>
                }

                <Text w={"50%"} textAlign={"center"}>Your ads have been submitted. Your account manager will be in contact with you soon. </Text>
            </Box>
        </Box>
    )
}

export default AdApproveSuccessUI