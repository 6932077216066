import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const baseStyle = defineStyle({
  fontSize: "sm",
  textColor: "gray.400",
  bg: "red",
  variant: "outline",
  resize: "none",
  rows: 3,
  _placeholder: {
    color: "brand.text",
  },
});

export const Textarea = defineStyleConfig({
  baseStyle,
});
