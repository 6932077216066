import {
  Avatar,
  AvatarBadge,
  Box,
  Flex,
  HStack,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import {
  FiChevronDown,
  FiLogOut,
  FiMenu,
  FiUser,
  FiBell,
  FiUsers,
} from "react-icons/fi";
import { FaBell } from "react-icons/fa";
import { useRecoilValue } from "recoil";
import { useLocation, useNavigate } from "react-router-dom";
import { profile } from "../../atoms/authAtom";
import { useLogout } from "../../hooks/useLogout";
import Notification from "./Notification";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const MobileNavbar = ({ onOpen, borderColor, backgroundColor, ...rest }) => {
  const profileState = useRecoilValue(profile);
  const navigate = useNavigate();
  const { mutate, isLoading } = useLogout();
  const clientId = useSelector((state) => state.client?.clientId);
  const [clientName, setClientName] = useState("");
  const location = useLocation();

  useEffect(() => {}, [location]);

  useEffect(() => {
    if (clientId) {
      const clientData =
        profileState?.access_info &&
        profileState?.access_info?.clients?.find((el) => el?.id === clientId);
      if (clientData) {
        setClientName(clientData?.name);
      }
    }
  }, [clientId]);

  return (
    <>
      <Flex
        ml={{ base: 0, md: location === "/reports" ? 20 : 60 }}
        px={{ base: 4, md: 4 }}
        height={location.pathname.includes("/campaign-briefs/") ? "0" : "20"}
        alignItems="center"
        bg={backgroundColor}
        borderBottomWidth="1px"
        borderBottomColor={
          location.pathname.includes("/campaign-briefs/")
            ? "transparent"
            : borderColor
        }
        justifyContent={{
          base: "space-between",
          md: location === "/reports" ? "space-between" : "flex-end",
        }}
        {...rest}
      >
        <HStack spacing={4} color={"#757998"}>
          <IconButton
            display={{ base: "flex", md: "none" }}
            onClick={onOpen}
            variant="ghost"
            aria-label="open menu"
            icon={<FiMenu />}
          />

          <Text
            display={{
              base: "flex",
              md: location === "/reports" ? "" : "none",
            }}
            fontSize="large"
            fontWeight="semibold"
          >
            <Text
              fontSize="large"
              fontWeight="semibold"
              display="flex"
              flexDirection={"column"}
              alignItems={"center"}
              marginBottom={"10px"}
            >
              <Image
                src={`${process.env.PUBLIC_URL}/images/Kedet Logo.png`}
                width={"120px"}
              />
              <Text
                style={{
                  fontSize: "10px",
                  marginTop: "-14px",
                }}
                color="brand.primary"
              >
                Powered by War Room.
              </Text>
            </Text>
          </Text>
        </HStack>

        {!location.pathname.includes("/campaign-briefs/") && (
          <HStack spacing={2} color={"#757998"}>
            {/* notification */}
            <Flex alignItems={"center"}>
              <Menu isLazy={true}>
                <MenuButton
                  py={2}
                  transition="all 0.3s"
                  _focus={{ boxShadow: "none" }}
                >
                  <IconButton
                    variant="ghost"
                    aria-label="Notifications"
                    fontSize={"24px"}
                    icon={<FaBell />}
                  />
                </MenuButton>
                <MenuList
                  bg={backgroundColor}
                  boxShadow={"6px 6px 12px rgba(167, 169, 189, 0.2)"}
                  borderColor={borderColor}
                >
                  <Notification />
                </MenuList>
              </Menu>
            </Flex>

            {/* <ToggleThemeMode /> */}
            <Flex alignItems={"center"}>
              <Menu>
                <MenuButton
                  py={2}
                  transition="all 0.3s"
                  _focus={{ boxShadow: "none" }}
                >
                  <HStack spacing={3}>
                    <Avatar
                      src={
                        profileState.other_info?.profile_pic_url &&
                        `${process.env.REACT_APP_API_URL}/uploads/${profileState.other_info?.profile_pic_url}`
                      }
                      name={profileState.name}
                    >
                      <AvatarBadge boxSize="1em" bg="green.500" />
                    </Avatar>
                    <VStack
                      display={{ base: "none", md: "flex" }}
                      alignItems="flex-start"
                      spacing="1px"
                    >
                      <Text fontWeight="semibold" fontSize="sm">
                        {profileState.name} {profileState.lastName}
                      </Text>
                      <Text fontSize="xs" color="gray">
                        {clientName}
                      </Text>
                    </VStack>
                    <Box display={{ base: "none", md: "flex" }}>
                      <FiChevronDown />
                    </Box>
                  </HStack>
                </MenuButton>
                <MenuList bg={backgroundColor} borderColor={borderColor}>
                  <MenuItem
                    icon={<FiUser />}
                    onClick={() => {
                      navigate("/settings");
                    }}
                    className={"profile-tab"}
                  >
                    Profile
                  </MenuItem>
                  <MenuItem
                    icon={<FiUsers />}
                    onClick={() => {
                      navigate("/company-profile");
                    }}
                    className={"profile-tab"}
                  >
                    Company Profile
                  </MenuItem>
                  <MenuItem
                    closeOnSelect={false}
                    color="red.600"
                    _hover={{ color: "red.500" }}
                    onClick={mutate}
                    disabled={isLoading}
                    icon={isLoading ? <Spinner size="sm" /> : <FiLogOut />}
                    className={"profile-tab"}
                  >
                    Sign out
                  </MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          </HStack>
        )}
      </Flex>
    </>
  );
};

export default MobileNavbar;
