import {useSelector} from "react-redux";
import DV360ImageAdMedia from "./DV360/DV360Image/DV360ImageAdMedia";
import DV360AudioAdMedia from "./DV360/DV360Audio/DV360AudioAdMedia";
import React from "react";
import DV360YoutubeVideoAdMedia from "./DV360/DV360YoutubeVideo/DV360YoutubeVideoAdMedia";
import PinterestImageAdMedia from "./Pintrest/PinterestImage/PinterestImageAdMedia";
import DV360VideoAdMedia from "./DV360/DV360Video/DV360VideoAdMedia";
import PinterestVideoAdDetails from "./Pintrest/PinterestVideo/PinterestVideoAdDetails";
import PinterestVideoAdMedia from "./Pintrest/PinterestVideo/PinterestVideoAdMedia";
import TiktokVideoAdMedia from "./Tiktok/TiktokVideo/TiktokVideoAdMedia";
import PinterestCarouselAdMedia from "./Pintrest/PinterestCarousel/PinterestCarouselAdMedia";
import InstagramImageAdMedia from "./Instagram/InstagramImage/InstagramImageAdMedia";
import FacebookImageAdMedia from "./Facebook/FacebookImage/FacebookImageAdMedia";
import InstagramVideoAdMedia from "./Instagram/InstagramVideo/InstagramVideoAdMedia";
import FacebookVideoAdMedia from "./Facebook/FacebookVideo/FacebookVideoAdMedia";
import FacebookCarouselAdMedia from "./Facebook/FacebookCarousel/FacebookCarouselAdMedia";
import InstagramCarouselAdMedia from "./Instagram/InstagramCarousel/InstagramCarouselAdMedia";

function AdMediaForm({activeStep, steps, prevStep, nextStep, label}) {

    const {selectedSocialMedia, mediaType, editMediaType} = useSelector((state) => state.adUpload);


    if(mediaType === "dv360" || editMediaType === "DV360") {
        return (
            <DV360ImageAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "PINTEREST_IMAGE" || editMediaType === "PinterestImage") {
        return (
            <PinterestImageAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "PINTEREST_CAROUSEL" || editMediaType === "PinterestCarousel") {
        return (
            <PinterestCarouselAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "PINTEREST_VIDEO" || editMediaType === "PinterestVideo") {
        return (
            <PinterestVideoAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "TIKTOK_VIDEO" || editMediaType === "TikTokVideo") {
        return (
            <TiktokVideoAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "INSTAGRAM_VIDEO" || editMediaType === "InstagramVideo") {
        return (
            <InstagramVideoAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "INSTAGRAM_IMAGE" || editMediaType === "InstagramImage") {
        return (
            <InstagramImageAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "INSTAGRAM_CAROUSEL" || editMediaType === "InstagramCarousel") {
        return (
            <InstagramCarouselAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "FACEBOOK_IMAGE" || editMediaType === "FACEBOOK") {
        return (
            <FacebookImageAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "FACEBOOK_CAROUSEL" || editMediaType === "FBCarousel") {
        return (
            <FacebookCarouselAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "FACEBOOK_VIDEO" || editMediaType === "FBVideo") {
        return (
            <FacebookVideoAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }

    // skipping for now
    if(mediaType === "DV360_AUDIO") {
        return <DV360AudioAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
    }

    if(editMediaType === "DV360YoutubeVideo" || mediaType === "DV360_YOUTUBE_VIDEO") {
        return <DV360YoutubeVideoAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label}/>
    }

    if(editMediaType === "DV360Video" || mediaType === "DV360_VIDEO") {
        return <DV360VideoAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
    }

    if(editMediaType === "DV360Audio" || mediaType === "DV360_AUDIO") {
        return <DV360AudioAdMedia  activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
    }

}

export default AdMediaForm