import {
  Box,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  SimpleGrid,
  Button,
  Text,
  FormControl,
} from "@chakra-ui/react";
// import DatePicker from "react-datepicker";
import InputBox from "../../../../components/InputBox";
import { Checkbox } from "@chakra-ui/react";
import "../../style/CampaignDetails.css";
import { TbEqual } from "react-icons/tb";
import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { TEXT_COLOR } from "../../../../layout/constant/MenuList";
import FormStepButton from "./FormStepButton";
import { Flex } from "@chakra-ui/layout";
import TooltipComponent from "../AdUpload/StepByStepAdUploadComponent/TooltipComponent";
// import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";
import { InputControl } from "formik-chakra-ui";

const CampaignDetailsStep = ({
  setCampaignName,
  setFieldValue,
  values,
  errors,
  activeStep,
  steps,
  prevStep,
  nextStep,
}) => {
  const [isError, setIsError] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    if (errors?.detail?.campaignBasic?.campaignName?.length > 0) {
      setIsError(true);
    } else if (errors?.detail?.campaignBasic?.duration?.length > 0) {
      setIsError(true);
    } else if (errors?.detail?.campaignBasic?.endDate?.length > 0) {
      setIsError(true);
    } else if (errors?.detail?.campaignBasic?.startDate?.length > 0) {
      setIsError(true);
    } else if (errors?.detail?.campaignBasic?.websiteUrl?.length > 0) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [
    errors?.detail?.campaignBasic?.campaignName,
    errors?.detail?.campaignBasic?.duration,
    errors?.detail?.campaignBasic?.endDate,
    errors?.detail?.campaignBasic?.startDate,
    errors?.detail?.campaignBasic?.websiteUrl,
  ]);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const [duration, setDuration] = useState(0);
  useEffect(() => {
    if (
      values?.detail?.campaignBasic?.startDate &&
      values?.detail?.campaignBasic?.endDate
    ) {
      const startDate = moment(values.detail.campaignBasic.startDate);
      const endDate = moment(values.detail.campaignBasic.endDate);
      const diff = endDate.diff(startDate, "days");
      setDuration(diff + 1);
      setFieldValue("detail.campaignBasic.duration", duration);
    }
  }, [
    values.detail.campaignBasic.startDate,
    values.detail.campaignBasic.endDate,
  ]);

  const startDate = useRef();

  return (
    <Box p={4}>
      <Box mb={6} display="flex" justifyContent="space-between">
        <Heading fontSize="xl" color={"#757998"}>
          Campaign Details
        </Heading>
        <Heading fontSize="md" color="#A7A9BD">
          7 steps to complete
        </Heading>
      </Box>
      <SimpleGrid
        className="flex-for-mobile"
        columns={{ base: 1, md: 2 }}
        gap={"8px"}
      >
        <GridItem colSpan={2} marginBottom={"3"}>
          <div className="input-box" style={{ padding: "4px 0 6px" }}>
            <InputBox
              name="detail.campaignBasic.campaignName"
              placeholder="Campaign Name*"
              onChange={(e) => {
                setFieldValue(
                  "detail.campaignBasic.campaignName",
                  e.target.value
                );
                setFieldValue("name", e.target.value);
                setCampaignName(e.target.value);
              }}
            />
            <TooltipComponent
              text={
                "The ad name helps you distinguish between ads and won’t appear in the final ad. Pick something memorable but relevant to your ad."
              }
            />
          </div>
        </GridItem>
      </SimpleGrid>
      <Box
        display={"flex"}
        marginBottom={3}
        alignItems={"flex-end"}
        gap={"8px"}
      >
        <GridItem>
          <InputBox
            name="detail.campaignBasic.startDate"
            label="Start Date*"
            type="date"
            width={"300px"}
            placeholder={"Please select a date"}
            onChange={(e) => {
              setFieldValue("detail.campaignBasic.startDate", e.target.value);
              setFieldValue("startDate", e.target.value);
            }}
          />
        </GridItem>
        <GridItem paddingBottom={"10px"}>To</GridItem>
        <GridItem>
          {/*<DatePicker*/}
          {/*    className={"chakra-input css-lqm227"}*/}
          {/*    dateFormat="yyyy-MM-dd"*/}
          {/*    name="detail.campaignBasic.endDate"*/}
          {/*    placeholderText={'End Date*'}*/}
          {/*    onChange={(e) => {*/}
          {/*        let value = moment(e).format();*/}
          {/*        console.log("value", value);*/}
          {/*        setFieldValue('detail.campaignBasic.endDate', value);*/}
          {/*        setFieldValue('endDate', value)*/}
          {/*    }}*/}
          {/*    selected={values.endDate}*/}
          {/*/>*/}
          <InputBox
            name="detail.campaignBasic.endDate"
            label="End Date*"
            type="date"
            width={"300px"}
            onChange={(e) => {
              setFieldValue("detail.campaignBasic.endDate", e.target.value);
              setFieldValue("endDate", e.target.value);
            }}
          />
        </GridItem>
        <GridItem
          paddingBottom={"10px"}
          display="flex"
          className={"equal-none"}
          justifyContent="end"
          alignItems="center"
        >
          <TbEqual />
        </GridItem>
        <GridItem>
          <Text as="span" color={"#3B82F6"}>
            {" "}
            {duration}days{" "}
          </Text>
          <InputBox
            display={"none"}
            name="detail.campaignBasic.duration"
            placeholder="Duration (Days)"
            type="number"
            value={duration}
            style={{ border: "none", outline: "none" }}
            disabled={"off"}
          />
        </GridItem>
      </Box>
      <SimpleGrid
        className="flex-for-mobile"
        columns={{ base: 1, md: 2 }}
        gap={"8px"}
      >
        <GridItem colSpan={2}>
          <InputBox
            name="detail.campaignBasic.websiteUrl"
            placeholder="Website URL*"
            type="url"
          />
        </GridItem>
        <GridItem colSpan={2}>
          <Box>
            <Box display={"flex"}>
              <FormLabel display={"none"}>
                Link to Landing Page Document
              </FormLabel>
              <Checkbox display={"none"} colorScheme="yellow"></Checkbox>
            </Box>
            <InputBox
              name="detail.campaignBasic.landingPageDocLink"
              type="url"
              placeholder=" Link to Landing Page Document"
            />
          </Box>
        </GridItem>
        <GridItem colSpan={2}>
          <Box>
            <Box display={"flex"}>
              <FormLabel display={"none"}>Mobile Landing Page URL</FormLabel>
              <Checkbox display={"none"} colorScheme="yellow"></Checkbox>
            </Box>
            <InputBox
              name="detail.campaignBasic.landingPageMobileLink"
              type="url"
              placeholder={"Mobile Landing Page URL"}
            />
          </Box>
        </GridItem>
        <GridItem colSpan={2}>
          <Box>
            <Box display={"flex"}>
              <FormLabel display={"none"}>Exit/Thank You Page URL</FormLabel>
              <Checkbox display={"none"} colorScheme="yellow"></Checkbox>
            </Box>
            <InputBox
              name="detail.campaignBasic.thankYouLink"
              type="url"
              placeholder={"Exit/Thank You Page URL"}
            />
          </Box>
        </GridItem>
        {/*<GridItem colSpan={2}>*/}
        {/*    <InputBox*/}
        {/*        name="detail.campaignBasic.notes"*/}
        {/*        type="url"*/}
        {/*        placeholder={"Notes"}*/}
        {/*    />*/}
        {/*</GridItem>*/}
      </SimpleGrid>
      <Flex width="100%" justify="flex-end" py={10}>
        <Button
          mr={4}
          onClick={prevStep}
          colorScheme="blue"
          variant="outline"
          borderRadius={0}
          px={10}
        >
          Back
        </Button>

        <Button
          mr={4}
          isDisabled={isError}
          onClick={nextStep}
          colorScheme="green"
          variant="solid"
          borderRadius={0}
          px={10}
        >
          Next
        </Button>
      </Flex>
      {/*<FormStepButton errors={errors} activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} />*/}
    </Box>
  );
};

export default CampaignDetailsStep;
