import {
    useToast,
    FormControl,
    FormLabel,
    Textarea,
    Button,
    css,
    Box,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import instance from "../../../helpers/axios";
import { useGetClientUsers } from "../../../hooks/campaign-briefs/useGetClientUsers";
import { useGetComments } from "../../../hooks/campaign-briefs/useGetComments";
import validationSchema from "../../../validations/CampaignBrief/Comments"
import { commentInitialValue } from "../constant/InitialValues";

const ReadMore = ({ children }) => {
    const text = children;
    let newText = text.trim().split(" ");
    let firstText = newText.shift()
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    if(text.length < 150) {
        return (
            <p className="text">
                <span style={{ color: "#3B82F6" }}>{firstText} </span>{newText.join(" ")}
            </p>
        )
    }

    return (
        <p className="text">
            {isReadMore ? (
                <>
                <span style={{ color: "#3B82F6"}}>{firstText}</span> <span>{newText.join(" ").slice(0, 150)}</span>
                </>
                ) : (
                <span className="text">
                    <span style={{ color: "#3B82F6" }}>{firstText} </span>{newText.join(" ")}
                </span>
            )}
            <span onClick={toggleReadMore} className="read-or-hide">
                {isReadMore ? <span style={{ cursor: "pointer",textDecoration: "underline"}}> read more...</span> : <span style={{ cursor: "pointer", textDecoration: "underline"}}> show less</span>}
            </span>
        </p>
    );
};


export const NewComment = ({ clientId, campaignId }) => {
    const toast = useToast();

    const [clientUsers, setClientUsers] = useState([]);

    const { data } = useGetClientUsers(clientId);
    const { mutate, data: comments } = useGetComments();

    useEffect(() => {
        if (clientId && campaignId) {
            mutate({
                clientId: clientId,
                campaignId: campaignId,
            });
        }
    }, [clientId, campaignId]);

    useEffect(() => {
        const ids = data?.users?.length ? data?.users.map((el) => el.id) : [];
        setClientUsers(ids);
    }, [data]);

    const mainBoxStyle = {
        border: "1.5px solid rgba(167, 169, 189, 0.2)",
        boxShadow: "0px 4px 6px rgba(167, 169, 189, 0.2)",
        padding: "20px"
    }

    return (
        <>
            <Box style={mainBoxStyle} fontFamily="Poppins">
            <Formik
                enableReinitialize
                initialValues={commentInitialValue}
                validationSchema={validationSchema}
                onSubmit={async (values, actions) => {
                    await instance({
                        method: "POST",
                        url: `/client/${clientId}/campaign-brief/${campaignId}/comment`,
                        withCredentials: false,
                        data: {
                            content: values.comment,
                            users: clientUsers,
                        },
                    })
                        .then((res) => {
                            if (res.status === 200) {
                                toast({
                                    isClosable: true,
                                    status: "success",
                                    variant: "top-accent",
                                    position: "top-right",
                                    title: "Success",
                                    description: res.data.message,
                                });
                                values.comment = "";
                                mutate({
                                    clientId: clientId,
                                    campaignId: campaignId,
                                });
                            }
                        })
                        .catch((error) => {
                            toast({
                                isClosable: true,
                                status: "error",
                                variant: "top-accent",
                                position: "top-right",
                                description: error.response.data.message,
                            });
                        });
                }}
            >
                {({ dirty, isValid, isSubmitting, values, handleChange }) => (
                    <Form autoComplete="off">
                        <FormControl display="flex" gap="10px" alignItems="center" marginBottom="10px">
                            <FormLabel
                                htmlFor="comment"
                                css={css({
                                    fontWeight: "600",
                                    fontSize: "18px",
                                    lineHeight: "15px",
                                    marginTop: "20px",
                                    display: "none"
                                })}
                            >
                                Discussion
                            </FormLabel>
                            <Box style={{ minWidth: "25px", minHeight: "25px", background: "#3B82F6", borderRadius: "50%" }}>
                            </Box>
                            <Textarea
                                id="comment"
                                rows={1}
                                resize={"none"}
                                name="comment"
                                placeholder="Write a comment..."
                                value={values.comment}
                                onChange={handleChange}
                                css={css({
                                    borderRadius: "100px",
                                    border: "2px solid #757998",
                                    fontWeight: "600",
                                    fontSize: "14px",
                                    color: "#757998",
                                })}
                            />
                        </FormControl>
                        <Button
                            size="sm"
                            type="submit"
                            disabled={!(dirty && isValid) || isSubmitting}
                        >
                            Submit
                        </Button>
                    </Form>
                )}
            </Formik>
                <Box
                    style={{
                        marginTop: 10,
                        overflowY: "auto",
                        maxHeight: "calc(100vh - 420px)",
                    }}
                >
                    {comments?.comments
                        ?.sort((a, b) => {
                            if (a.created_at < b.created_at) return 1;
                            if (a.created_at > b.created_at) return -1;
                            return 0;
                        })
                        ?.map((el, index) => (
                            <Box
                                key={index}
                                borderRadius={10}
                                w="100%"
                                my={6}
                                display="flex" gap="10px" alignItems="flex-start"
                            >
                                <Box style={{ minWidth: "25px", minHeight: "25px", background: "#3B82F6", borderRadius: "50%" }}>
                                </Box>
                                <Box flexGrow={1}>
                                    <Box display="flex" mb={2} justifyContent="space-between">
                                        <Box
                                            color="gray.500"
                                            fontWeight="semibold"
                                            textTransform="capitalize"
                                            fontSize="12px"
                                        >
                                            {el?.user?.name}
                                        </Box>

                                        <Box fontSize="12px" color="gray.500">
                                            <Box>
                                                {
                                                    moment(el?.created_at).fromNow()
                                                }
                                                {/*{moment(el?.created_at).format(*/}
                                                {/*    "MMMM DD YYYY"*/}
                                                {/*)}*/}
                                                {/*&nbsp; {moment(el?.created_at).format(*/}
                                                {/*    "h:mm a"*/}
                                                {/*)}*/}
                                            </Box>
                                            <Box>

                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box fontSize="12px"
                                    >
                                        <ReadMore>
                                            {el?.content}
                                        </ReadMore>
                                    </Box>
                                </Box>
                            </Box>
                        ))}
                </Box>
            </Box>


        </>
    );
};
