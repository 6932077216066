import { createSlice } from "@reduxjs/toolkit";

const initialState = { loading: false };

export const loadingSlice = createSlice({
    name: "loading",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
    },
});
export const {
    setLoading,
} = loadingSlice.actions;
// The reducer
export default loadingSlice.reducer;
