export const googleApiData = {
        sitelinkdata : [
            {
                id     : 1,
                ad_upload_id    : "dsnjkadasjk",
                sitelink_text : "Things To Do",
                sitelink_description_1    : " Discover a Lifetime's Worth of",
                sitelink_description_2     : "Activities in Banff & Lake Louise.",
                siltelink_final_url   : "https://www.banfflakelouise.com/things-to-do"
            },
            {
                id     : 2,
                ad_upload_id    : "dsnjkadasjk",
                sitelink_text : "Things To Do",
                sitelink_description_1    : " Discover a Lifetime's Worth of",
                sitelink_description_2     : "Activities in Banff & Lake Louise.",
                siltelink_final_url   : "https://www.banfflakelouise.com/things-to-do"
            },
        ],
        extensiondata:[
            {
                location: ["Bantt", "Bantt 2", "Paris", "Londan"],
                callout: ["Bantt Resturant", "Coffee"],
                call : [9377024392]
            }
        ],
        masterdata : [
                {
                    id : 1,
                    ad_upload_id : "sakddasdasd",
                    ad_group : "Vacation Packages 1",
                    headline_1 : "What 100% Placement",
                    headline_2 : "Explore Banff",
                    headline_3 : "Explore Banff",
                    headline_4 : "Explore Banff",
                    headline_5 : "Explore Banff",
                    headline_6 : "Explore Banff",
                    headline_7 : "Explore Banff",
                    headline_8 : "Explore Banff",
                    headline_9 : "Explore Banff",
                    headline_9_char_count : "Explore Banff",
                    headline_10 : "Explore Banff",
                    headline_10_char_count : "Explore Banff",
                    headline_11 : "Explore Banff",
                    headline_11_char_count : "Explore Banff",
                    headline_12 : "Explore Banff",
                    headline_12_char_count : "Explore Banff",
                    headline_13 : "Explore Banff",
                    headline_13_char_count : "Explore Banff",
                    headline_14 : "Explore Banff",
                    headline_14_char_count : "Explore Banff",
                    description_1 : "Explore Vacation Packages and Be Inspired by the Beauty of This Breathtaking Destination.",
                    description_2 : "Explore Vacation Packages and Be Inspired by the Beauty of This Breathtaking Destination.",
                    description_3 : "Explore Vacation Packages and Be Inspired by the Beauty of This Breathtaking Destination.",
                    description_4 : "Explore Vacation Packages and Be Inspired by the Beauty of This Breathtaking Destination.",
                    path_1: "travel",
                    final_url : "https://www.banfflakelouise.com/"
                },
                {
                    id : 2,
                    ad_upload_id : "sakddasdasd",
                    ad_group : "Vacation Packages 2",
                    headline_1 : "Explore Banff",
                    headline_2 : "Explore Banff",
                    headline_3 : "Explore Banff",
                    headline_4 : "Explore Banff",
                    headline_5 : "Explore Banff",
                    headline_6 : "Explore Banff",
                    headline_7 : "Explore Banff",
                    headline_8 : "Explore Banff",
                    headline_9 : "Explore Banff",
                    headline_9_char_count : "Explore Banff",
                    headline_10 : "Explore Banff",
                    headline_10_char_count : "Explore Banff",
                    headline_11 : "Explore Banff",
                    headline_11_char_count : "Explore Banff",
                    headline_12 : "Explore Banff",
                    headline_12_char_count : "Explore Banff",
                    headline_13 : "Explore Banff",
                    headline_13_char_count : "Explore Banff",
                    headline_14 : "Explore Banff",
                    headline_14_char_count : "Explore Banff",
                    description_1 : "Explore Vacation Packages and Be Inspired by the Beauty of This Breathtaking Destination.",
                    description_2 : "Explore Vacation Packages and Be Inspired by the Beauty of This Breathtaking Destination.",
                    description_3 : "Explore Vacation Packages and Be Inspired by the Beauty of This Breathtaking Destination.",
                    description_4 : "Explore Vacation Packages and Be Inspired by the Beauty of This Breathtaking Destination.",
                    path_1: "travel",
                    final_url : "https://www.banfflakelouise.com/"
                },
            ]
}