import {
  Box,
  CloseButton,
  Flex,
  Text,
  useColorModeValue,
  VStack,
  Image,
  Select,
} from "@chakra-ui/react";
import {
  MenuList,
  UserMenuList,
  DeveloperMenuList,
  ClientMenuList,
} from "../constant/MenuList";
import NavItem from "./NavItem";
import { For } from "react-haiku";
import { profile } from "../../atoms/authAtom";
import { useGetClientDetailsOnClick } from "../../hooks/clients/useGetClientDetails";
import { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { useDispatch, useSelector } from "react-redux";
import {
  getClients,
  updateCurrentClient,
} from "../../store/client/clientThunk";
import { useLocation } from "react-router-dom";

function getLocalStorageClient() {
  const clients = localStorage.getItem("clients");
  if (clients) {
    const clientData = JSON.parse(localStorage.getItem("clients"));
    return {
      id: clientData.id,
      name: clientData.name,
      state: clientData.state,
    };
  }
  return null;
}

const Sidebar = ({ onClose, ...rest }) => {
  const [getCurrentClient, setGetCurrentClient] = useState(
    getLocalStorageClient()
  );

  var BORDER_COLOR = useColorModeValue("gray.100", "gray.800");
  var BG_COLOR = useColorModeValue("gray.100", "gray.900");

  const [isAdmin, setIsAdmin] = useState(false);
  const [isDeveloper, setIsDeveloper] = useState(false);
  const [isClient, setIsClient] = useState(false);
  const [selectedClient, setSelectedClient] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (getCurrentClient) {
      setGetCurrentClient(getCurrentClient?.id);
    }
  }, [getCurrentClient]);

  const { access_info } = useRecoilValue(profile);

  const userClients =
    access_info?.clients?.length !== 0 ? access_info?.clients : [];
  const roles = access_info?.roles?.length !== 0 ? access_info?.roles : [];

  useEffect(() => {
    if (userClients && userClients?.length !== 0) {
      dispatch(getClients(userClients));
      setSelectedClient(getCurrentClient?.id || userClients[0].id);
      dispatch(updateCurrentClient(getCurrentClient?.id || userClients[0].id));
    }
  }, [userClients]);

  const clients = useSelector((state) => state.client.clients);

  useEffect(() => {
    if (roles && roles.length !== 0) {
      setIsAdmin(roles.includes("Admin"));
      setIsDeveloper(roles.includes("Developer"));
      setIsClient(roles.includes("Client"));
    }
  }, [roles]);

  const { mutate } = useGetClientDetailsOnClick();

  function switchClient(e) {
    if (e.target.value.length) {
      dispatch(updateCurrentClient(e.target.value));
      setSelectedClient(e.target.value);
      mutate(
        {
          id: e.target.value,
        },
        {
          onSuccess: (data, variables, context) => {
            localStorage.setItem("clients", JSON.stringify(data.client));
          },
        }
      );
    } else {
      localStorage.removeItem("clientId");
      localStorage.removeItem("clients");
    }
  }

  const location = useLocation();

  return (
    <>
      {location.pathname === "/reports" ? (
        <Box
          h="full"
          as="aside"
          pos="fixed"
          borderRight="1px"
          paddingTop={6}
          transition="0.6s ease"
          width={{ base: "full", md: 12 }}
          bg={BG_COLOR}
          borderColor={BORDER_COLOR}
          {...rest}
        >
          <Flex
            mt={"1.5"}
            direction="column"
            overflow="hidden"
            height="auto"
            overflowY="auto"
          >
            <VStack align="stretch" fontFamily={"poppins"}>
              {isDeveloper
                ? DeveloperMenuList.map((link) => (
                    <NavItem
                      key={link.id}
                      path={link.path}
                      icon={link.icon}
                      onClick={onClose}
                    />
                  ))
                : isAdmin
                ? MenuList.map((link) => (
                    <NavItem
                      key={link.id}
                      path={link.path}
                      icon={link.icon}
                      onClick={onClose}
                    />
                  ))
                : isClient
                ? ClientMenuList.map((link) => (
                    <NavItem
                      key={link.id}
                      path={link.path}
                      icon={link.icon}
                      onClick={onClose}
                    />
                  ))
                : UserMenuList.map((link) => (
                    <NavItem
                      key={link.id}
                      path={link.path}
                      icon={link.icon}
                      onClick={onClose}
                    />
                  ))}
            </VStack>
          </Flex>
        </Box>
      ) : (
        <Box
          h="full"
          as="aside"
          pos="fixed"
          borderRight="1px"
          transition="0.6s ease"
          width={{ base: "full", md: 60 }}
          bg={BG_COLOR}
          borderColor={BORDER_COLOR}
          {...rest}
        >
          <Flex
            alignItems="center"
            px="6"
            pt={4}
            mb="5"
            justifyContent={{ base: "space-between", md: "center" }}
          >
            <Text
              fontSize="large"
              fontWeight="semibold"
              display="flex"
              flexDirection={"column"}
              alignItems={"center"}
              marginBottom={"10px"}
            >
              <Image
                src={`${process.env.PUBLIC_URL}/images/Kedet Logo.png`}
                width={"120px"}
              />
              <Text
                style={{
                  fontSize: "10px",

                  marginTop: "-14px",
                }}
                color="brand.primary"
              >
                Powered by War Room.
              </Text>
            </Text>
            <CloseButton
              display={{ base: "flex", md: "none" }}
              onClick={onClose}
            />
          </Flex>
          <Flex
            mt={"1.5"}
            direction="column"
            overflow="hidden"
            height="auto"
            overflowY="auto"
          >
            <VStack align="stretch" fontFamily={"poppins"}>
              <Box px={4} mb={6}>
                {clients.length === 1 ? (
                  <Text
                    textAlign="center"
                    py={2}
                    borderRadius={8}
                    fontSize={15}
                    borderColor="brand.primary"
                    color="brand.primary"
                    borderWidth={2}
                    fontWeight={600}
                  >
                    {clients[0].name}
                  </Text>
                ) : (
                  <Select
                    fontWeight={600}
                    fontSize={15}
                    borderColor="brand.primary"
                    color="brand.primary"
                    borderWidth={2}
                    value={selectedClient}
                    onChange={(e) => switchClient(e)}
                  >
                    <For
                      each={clients}
                      render={(client) => (
                        <option value={client.id}>{client.name}</option>
                      )}
                    />
                  </Select>
                )}
              </Box>

              {isDeveloper
                ? DeveloperMenuList.map((link) => (
                    <NavItem
                      key={link.id}
                      path={link.path}
                      icon={link.icon}
                      title={link.name}
                      onClick={onClose}
                    />
                  ))
                : isAdmin
                ? MenuList.map((link) => (
                    <NavItem
                      key={link.id}
                      path={link.path}
                      icon={link.icon}
                      title={link.name}
                      onClick={onClose}
                    />
                  ))
                : isClient
                ? ClientMenuList.map((link) => (
                    <NavItem
                      key={link.id}
                      path={link.path}
                      icon={link.icon}
                      title={link.name}
                      onClick={onClose}
                    />
                  ))
                : UserMenuList.map((link) => (
                    <NavItem
                      key={link.id}
                      path={link.path}
                      icon={link.icon}
                      title={link.name}
                      onClick={onClose}
                    />
                  ))}
            </VStack>
          </Flex>
        </Box>
      )}
    </>
  );
};

export default Sidebar;
