import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  css,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
} from "@chakra-ui/react";
import { Form, FormikProvider, useFormik } from "formik";
import { InputControl, SelectControl } from "formik-chakra-ui";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import ErrorModal from "../../../../../../components/PopupModal/ErrorModal";
import SuccessModal from "../../../../../../components/PopupModal/SuccessModal";
import {
  AD_UPLOAD_STATUS_COLOR,
  AD_UPLOAD_TYPE,
} from "../../../../../../constant";
import WithAppContext from "../../../../../../helpers/appContext";
import instance from "../../../../../../helpers/axios";
import { useUpdateAdUploadStatus } from "../../../../../../hooks/campaign-briefs/useUpdateAdUploadStatus";
import { useUploadImage } from "../../../../../../hooks/campaign-briefs/useUploadImage";
import {
  tiktokAdUploadValueLengths,
  dv360AdVideoInitialValue,
  tiktokVideoInitialValue,
} from "../../../../constant/InitialValues";
import { facebookAccountIds } from "../../../../constant/SelectValues";
import { UploadDisplayVideoFile } from "./UploadFiles/UploadDisplayVideoFile";

const TikTokAdDisplayVideoDetails = ({
  data,
  setTabIndex,
  tabIndex,
  handlePreviewData,
  url,
  method,
  campaignId,
  playingDetails,
  setPlayingDetails,
  context,
}) => {
  const { setAppLoading } = context;
  const { id } = useParams();
  const clientId = useSelector((state) => state.client.clientId);
  const navigate = useNavigate();
  const [isSuccessModalOpen, setSuccessModal] = useState(false);
  const [isErrorModalOpen, setErrorModal] = useState(false);
  const [landingPageURL, setLandingPageURL] = useState("");
  const { mutateAsync } = useUploadImage();
  const { mutate } = useUpdateAdUploadStatus();

  const schema = yup.object().shape({
    adName: yup
      .string()
      .trim()
      .min(4)
      .max(40)
      .required()
      .matches(
        /^[!@$%^&#()-=+{};:,<.>0-9a-zA-Z\s]+$/i,
        "Emojis are not allowed as ad Name"
      )
      .label("Ad name"),
    displayName: yup
      .string()
      .trim()
      .min(2)
      .max(20)
      .required()
      .matches(
        /^[!@$%^&#()-=+{};:,<.>0-9a-zA-Z\s]+$/i,
        "Emojis are not allowed as display name"
      )
      .label("Display name"),
    description: yup
      .string()
      .trim()
      .min(1)
      .max(100)
      .required()
      .matches(
        /^[!@$%^&()-=+;:,<.>0-9a-zA-Z\s]+$/i,
        "Emojis and Special characters consists {} and # is not allowed in the description"
      )
      .label("Description"),
    fileURL: yup.array().required().label("Video"),
  });

  const onHandleSubmit = async (values) => {
    let videoData;
    let mediaInfoList = [];
    let mediaIds = [];
    let mediaDimensions = [];
    if (_.isEqual(data?.detail?.audio?.fileInfoList, values.fileURL)) {
      const { detail } = data;
      videoData = { audio: detail.audio };
    } else {
      const audioFile = await mutateAsync({
        clientId: clientId,
        campaignBriefId: id,
        adFile: values.fileURL,
        type: "tiktok",
      });

      mediaInfoList =
        audioFile &&
        audioFile.fileInfoList.map((el) => {
          return {
            ...el,
            metadata: [{ height: "", width: "" }],
          };
        });
      mediaIds = audioFile && audioFile.fileInfoList.map((el) => el.imageHash);
      mediaDimensions =
        audioFile &&
        audioFile.fileInfoList.map(() => {
          return {
            width: "",
            height: "",
          };
        });

      videoData = {
        audio: {
          mediaIds: mediaIds,
          fileInfoList: mediaInfoList,
          dimensions: mediaDimensions,
        },
      };
    }
    return {
      name: values.adName,
      displayName: values.displayName,
      description: values.description,
      detail: {
        name: values.adName,
        displayName: values.displayName,
        description: values.description,
        ...videoData,
      },
    };
  };

  const formik = useFormik({
    initialValues: tiktokVideoInitialValue,
    validationSchema: schema,
    onSubmit: async (values) => {
      try {
        setAppLoading(true);
        let payload = await onHandleSubmit(values);
        if (!data?.id) {
          payload = {
            ...payload,
            ad_upload_type: AD_UPLOAD_TYPE.TIKTOK_VIDEO,
          };
        }
        await instance({
          method: method,
          url: url,
          withCredentials: false,
          data: payload,
        })
          .then((res) => {
            if (res.status === 200) {
              setSuccessModal(true);
            }
            setAppLoading(false);
          })
          .catch((error) => {
            setLandingPageURL(error.response.data.message);
            setErrorModal(true);
            setAppLoading(false);
          });
      } catch (error) {
        setAppLoading(false);
      }
    },
  });

  const {
    errors,
    values,
    isValid,
    touched,
    handleSubmit,
    handleBlur,
    setFieldValue,
    setErrors,
  } = formik;

  useEffect(() => {
    if (data && data.id) {
      const { name, description, detail } = data;
      setFieldValue("adName", name);
      setFieldValue("displayName", detail.displayName);
      setFieldValue("fileURL", detail.audio.fileInfoList);
      setFieldValue("description", description);
    }
  }, [data]);

  // show preview

  useEffect(() => {
    if (tabIndex === 1) {
      if (values.fileURL) {
        let videoURL = values?.fileURL[0]?.imageUrl
          ? `${process.env.REACT_APP_API_URL}/uploads${values.fileURL[0]?.imageUrl}`
          : null;
        if (!videoURL) {
          videoURL = URL.createObjectURL(values.fileURL[0]);
        } else {
          setErrors({});
        }
        handlePreviewData({
          ...values,
          url: videoURL,
        });
      }
    }
  }, [tabIndex]);

  const adUploadStatusColor = (item) => {
    return item
      ? AD_UPLOAD_STATUS_COLOR[_.toUpper(item)]
      : AD_UPLOAD_STATUS_COLOR["OTHER"];
  };

  const handleStatus = (status) => {
    setAppLoading(true);
    mutate(
      {
        clientId,
        campaignId,
        adUploadId: data?.id,
        status,
      },
      {
        onSuccess: () => {
          navigate(`/campaign-briefs/${campaignId}`, {
            state: { isShowUpload: true },
          });
        },
      }
    );

    setAppLoading(false);
  };

  const onCloseSuccessModal = () => {
    setSuccessModal(false);
    navigate(`/campaign-briefs/${campaignId}`, {
      state: { isShowUpload: true },
    });
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        my={4}
        mb={10}
      >
        <Heading color={"gray"} fontSize="xl">
          Current status:
          <span style={{ marginLeft: "10px" }}>
            <Icon
              viewBox="0 0 200 200"
              mr={2}
              color={adUploadStatusColor(data && data.status)}
            >
              <path
                fill="currentColor"
                d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
              />
            </Icon>
            {(data && data.status) || "Draft"}
          </span>
        </Heading>
        {data && _.toUpper(data.status) === "CREATED" && (
          <Box display="flex">
            <Button
              size="sm"
              mr={2}
              colorScheme="green"
              backgroundColor="green.400"
              borderRadius={4}
              disabled={!clientId || !campaignId || !data?.id}
              onClick={() => handleStatus("Approved")}
            >
              Approve
            </Button>
            <Button
              size="sm"
              colorScheme="red"
              backgroundColor="red.400"
              borderRadius={4}
              disabled={!clientId || !campaignId || !data?.id}
              onClick={() => handleStatus("Rejected")}
            >
              Reject
            </Button>
          </Box>
        )}
      </Box>
      <Grid className="fb-upload-detail-form">
        <FormikProvider value={formik}>
          <Form autoComplete="off" onSubmit={handleSubmit}>
            <Grid
              templateColumns="repeat(6, 1fr)"
              gap={4}
              className="fb-upload"
            >
              <GridItem w="full" colSpan={{ base: 6, lg: 4 }}>
                <GridItem>
                  <FormControl>
                    <FormLabel htmlFor="adName">
                      Ad Name - max 512 characters
                    </FormLabel>
                    <div className="input-box">
                      <InputControl
                        id="adName"
                        name="adName"
                        placeholder=""
                        inputprops={{
                          variant: "outline",
                          type: "text",
                        }}
                        onBlur={handleBlur}
                      />
                      <CircularProgress
                        max={tiktokAdUploadValueLengths.adName}
                        value={values?.adName?.length}
                        color={
                          values?.adName?.length >
                          tiktokAdUploadValueLengths?.adName
                            ? "red.400"
                            : "green.400"
                        }
                      >
                        <CircularProgressLabel>
                          {values?.adName?.length >
                          tiktokAdUploadValueLengths?.adName
                            ? tiktokAdUploadValueLengths?.adName -
                              values?.adName?.length
                            : values?.adName?.length}
                        </CircularProgressLabel>
                      </CircularProgress>
                    </div>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel htmlFor="displayName">
                      Display Name - max 512 characters
                    </FormLabel>
                    <div className="input-box">
                      <InputControl
                        id="displayName"
                        name="displayName"
                        placeholder=""
                        inputprops={{
                          variant: "outline",
                          type: "text",
                        }}
                        onBlur={handleBlur}
                      />
                      <CircularProgress
                        max={tiktokAdUploadValueLengths.displayName}
                        value={values?.displayName?.length}
                        color={
                          values?.displayName?.length >
                          tiktokAdUploadValueLengths?.displayName
                            ? "red.400"
                            : "green.400"
                        }
                      >
                        <CircularProgressLabel>
                          {values?.displayName?.length >
                          tiktokAdUploadValueLengths?.displayName
                            ? tiktokAdUploadValueLengths?.displayName -
                              values?.displayName?.length
                            : values?.displayName?.length}
                        </CircularProgressLabel>
                      </CircularProgress>
                    </div>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel htmlFor="description">Description</FormLabel>
                    <div className="input-box">
                      <InputControl
                        id="description"
                        name="description"
                        placeholder=""
                        inputprops={{
                          variant: "outline",
                          type: "text",
                        }}
                      />
                      <CircularProgress
                        max={tiktokAdUploadValueLengths?.description}
                        value={values?.description?.length}
                        color={
                          values?.description?.length >
                          tiktokAdUploadValueLengths?.description
                            ? "red.400"
                            : "green.400"
                        }
                      >
                        <CircularProgressLabel>
                          {values?.description?.length >
                          tiktokAdUploadValueLengths?.description
                            ? tiktokAdUploadValueLengths?.description -
                              values?.description?.length
                            : values?.description?.length}
                        </CircularProgressLabel>
                      </CircularProgress>
                    </div>
                  </FormControl>
                </GridItem>
                <GridItem
                  css={css({
                    float: "right",
                    marginRight: "68px",
                  })}
                >
                  <Button
                    size="small"
                    css={css({
                      background: "#24a0ed !important",
                      borderRadius: "32px",
                      width: "134px",
                      height: "33px",
                      marginRight: "10px",
                    })}
                    disabled={!isValid || !values.adName}
                    onClick={() => {
                      setPlayingDetails(false);
                      setTabIndex(1);
                    }}
                  >
                    Preview
                  </Button>
                  <Button
                    size="small"
                    css={css({
                      background: "#4CAF50 !important",
                      borderRadius: "32px",
                      width: "134px",
                      height: "33px",
                    })}
                    type="submit"
                    // disabled={!isValid}
                  >
                    {data?.id ? "Update Ad" : "Submit Ad"}
                  </Button>
                </GridItem>
              </GridItem>
              <GridItem w="full" colSpan={{ base: 6, lg: 2 }}>
                <Box className="file-upload-box">
                  <UploadDisplayVideoFile
                    type="dv360-video"
                    values={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    setErrors={setErrors}
                    playingDetails={playingDetails}
                    setPlayingDetails={setPlayingDetails}
                  />
                </Box>
              </GridItem>
            </Grid>
          </Form>
        </FormikProvider>
      </Grid>

      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={() => onCloseSuccessModal()}
      />
      <ErrorModal
        isOpen={isErrorModalOpen}
        onClose={() => setErrorModal(false)}
        description={landingPageURL}
      />
    </>
  );
};

export default WithAppContext(TikTokAdDisplayVideoDetails);
