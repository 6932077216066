import {
    Box,
    Container, Flex, Grid, GridItem, Heading, Link
} from "@chakra-ui/react";
import React from "react";
import ReactPlayer from 'react-player'
import {TEXT_COLOR} from "../../../../../../layout/constant/MenuList";
import {BsThreeDots} from "react-icons/bs";
import {RiUpload2Fill} from "react-icons/ri";
import {GrLink} from "react-icons/gr";
import {FaAngleDown} from "react-icons/fa";
import "../style/pinterest.css"

const PinterestVideoAdPreview = ({url, vidWidth, playingPreview, setPlayingPreview,}) => {
    return (
        <Grid templateColumns="repeat(6, 1fr)" gap={4} className="fb-upload">
            <GridItem w="full" colSpan={{base: 6, lg: 6}}>
                <Box
                    borderWidth="2px"
                    borderColor="gray"
                    p={10}
                    background="#FFF8F4"
                >
                    <Container maxWidth="container.lg" px={8}>
                        <Flex
                            flexDirection="row"
                            justify="stretch"
                            minHeight="md"
                        >
                            <Box minHeight="md" flex={1} borderLeftRadius={20} overflow="hidden">
                                <ReactPlayer
                                    className='react-player pinterest-video'
                                    url={url.url}
                                    controls
                                    playing={true}
                                    onPlay={() => {
                                        setPlayingPreview(true)
                                    }}
                                />
                            </Box>
                            <Box flex={1} borderRightRadius={20} overflow="hidden" p={8} background="white">
                                <div className="pinterest-header">
                                    <div className="header-left-icon">
                                        <BsThreeDots/>
                                        <RiUpload2Fill/>
                                        <GrLink/>
                                    </div>
                                    <div className="header-right">
                                        <div className="option">
                                            <span>Seo</span> <FaAngleDown/>
                                        </div>
                                        <div className="save">
                                            <span>Save</span>
                                        </div>
                                    </div>
                                </div>
                                <Flex flexDirection="column" marginBottom={10} className="main">
                                    {url?.link &&
                                        <Link mb={2} className="url" textDecoration="underline" color="#111"
                                              href={url.link} isExternal>
                                            {url.link}
                                        </Link>}
                                    {url?.title && <Heading fontSize="4xl" className="heading" color="#111">
                                        {url.title}
                                    </Heading>}
                                    {url?.description &&
                                        <Heading fontSize="md" className="description" color="#111" fontWeight="normal">
                                            {url.description}
                                        </Heading>}
                                </Flex>
                                <div className="account">
                                    <div className="account-profile">
                                        <img
                                            src="https://i.pinimg.com/75x75_RS/4a/df/5b/4adf5bd3dbd13f55a65d5d220791c97d.jpg"/>
                                        <span>User Name</span>
                                    </div>
                                    <button className="follow-button">Follow</button>
                                </div>
                                <div className="comment">
                                    <span>Comment</span> <FaAngleDown/>
                                </div>
                                <div className="comment-box">
                                    <div className="user-name">
                                        <span>P</span>
                                    </div>
                                    <div className="comment-input-box">
                                        <input type="text" placeholder="Add a comment"/>
                                    </div>
                                </div>
                            </Box>
                        </Flex>
                    </Container>
                </Box>
            </GridItem>
        </Grid>
    );
};

export default PinterestVideoAdPreview;
