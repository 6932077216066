import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetAdUpload } from "../../../../../../../hooks/campaign-briefs/useGetAdUpload";
import { Form, FormikProvider, useFormik } from "formik";
import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  css,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
} from "@chakra-ui/react";
import { InputControl, SelectControl, TextareaControl } from "formik-chakra-ui";
import {
  dv360AdUploadalueLengths,
  dv360AdUploadInitialValues,
  facebookVideoAdValueLengths,
  fbCarouselAdUploadValueLengths,
  pinterestAdUploadvalueLengths,
} from "../../../../../constant/InitialValues";
import {
  CreativeType,
  facebookAccountIds,
  hostingSource,
} from "../../../../../constant/SelectValues";
import * as yup from "yup";
import { Flex } from "@chakra-ui/layout";
import {
  settingInstagramCarouselForm,
  settingInstagramCarouselMedia,
} from "./store";
import { TEXT_COLOR } from "../../../../../../../layout/constant/MenuList";
import {
  settingFacebookCarouselForm,
  settingFacebookCarouselMedia,
} from "../../Facebook/FacebookCarousel/store";
import TooltipComponent from "../../TooltipComponent";

const initialValue = {
  adName: "",
  type: "",
  link: "",
};

function InstagramCarouselAdDetails({
  activeStep,
  steps,
  prevStep,
  nextStep,
  label,
}) {
  const { id, adUploadId } = useParams();

  const clientId = useSelector((state) => state.client.clientId);
  const { formDetails } = useSelector((state) => state.instagramCarouselAd);

  const schema = yup.object().shape({
    adName: yup.string().trim().min(3).max(512).required().label("Ad name"),
    type: yup.string().required().label("Type"),
    link: yup
      .string()
      .trim()
      .required("Landing page url is required")
      .label("Landing page url")
      .matches(
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
        "Landing page url is not valid!"
      ),
  });

  const { data, refetch } = useGetAdUpload(clientId, id, adUploadId);

  useEffect(() => {
    if (adUploadId) {
      refetch();
    }
  }, []);

  useEffect(() => {
    if (adUploadId) {
      dispatch(
        settingInstagramCarouselForm({
          ...data?.adUpload?.detail,
          adName: data?.adUpload?.name,
          // primaryText: data?.adUpload?.detail?.description,
          landingPageURL: data?.adUpload?.detail?.link,
          type: data?.adUpload?.detail?.child_attachments[0]?.call_to_action
            ?.type,
        })
      );
      dispatch(
        settingInstagramCarouselMedia({
          carouselCards: data?.adUpload?.detail?.child_attachments,
        })
      );
    }
  }, [data]);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: schema,
    onSubmit: (values) => {
      dispatch(settingInstagramCarouselForm(values));
      nextStep();
    },
  });

  const { errors, values, touched, handleSubmit, isValid, setFieldValue } =
    formik;

  useEffect(() => {
    setFieldValue("adName", formDetails?.adName || "");
    setFieldValue("type", formDetails?.type || "");
    setFieldValue("link", formDetails?.link || "");
  }, [formDetails]);

  return (
    <>
      {adUploadId && (
        <Box
          display="flex"
          mb={6}
          justifyContent="space-between"
          alignItems="center"
        >
          <Heading fontSize="xl" color={TEXT_COLOR}>
            Please review the ad details carefully.
          </Heading>
          <Heading fontSize="md" color="#757998">
            3 step to complete
          </Heading>
        </Box>
      )}

      {!adUploadId && (
        <Box
          display="flex"
          mb={6}
          justifyContent="space-between"
          alignItems="center"
        >
          <Heading fontSize="xl" color={"#757998"}>
            Please fill in your ad details.
          </Heading>
          <Heading fontSize="md" color="gray.400">
            3 steps to complete
          </Heading>
        </Box>
      )}

      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <Grid gap={4} className="fb-upload">
            <GridItem w="full" colSpan={{ base: 6, lg: 4 }}>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="adName" display={"none"}>
                    Ad Name - max 512 characters
                  </FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="adName"
                      name="adName"
                      value={values.adName}
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "Ad Name - max 512 characters",
                      }}
                      onChange={(e) => setFieldValue("adName", e.target.value)}
                    />
                    <TooltipComponent
                      text={
                        "The ad name helps you distinguish between ads and won’t appear in the final ad. Pick something memorable but relevant to your ad."
                      }
                    />

                    <CircularProgress
                      max={fbCarouselAdUploadValueLengths?.adName}
                      value={values?.adName?.length}
                      color={
                        values?.adName?.length >
                        fbCarouselAdUploadValueLengths?.adName
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values?.adName?.length >
                        fbCarouselAdUploadValueLengths?.adName
                          ? fbCarouselAdUploadValueLengths?.adName -
                            values?.adName?.length
                          : values?.adName?.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="link" display={"none"}>
                    Landing page URL
                  </FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="link"
                      name="link"
                      value={values.link}
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "Landing page URL",
                      }}
                      onChange={(e) => setFieldValue("link", e.target.value)}
                    />
                    <Box h="48px" w="48px" />
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="type" display={"none"}>
                    Call to Action
                  </FormLabel>
                  <div className="input-box">
                    <SelectControl
                      id="type"
                      name="type"
                      selectProps={{
                        placeholder: "-- Select One --",
                        variant: "outline",
                        border: "2px",
                        borderRadius: 0,
                        borderColor: "gray",
                        fontWeight: "600",
                        fontSize: "14px",
                        lineHeight: "16px",
                        color: "#757998",
                        marginRight: "100px",
                      }}
                      onChange={(e) => setFieldValue("type", e.target.value)}
                    >
                      {facebookAccountIds.map((el) => (
                        <option value={el.key} key={el.key}>
                          {" "}
                          {el.name}{" "}
                        </option>
                      ))}
                    </SelectControl>
                    <CircularProgress opacity={0}>
                      <CircularProgressLabel>0</CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
            </GridItem>
          </Grid>
          <Flex width="100%" justify="flex-end" py={10}>
            {!adUploadId && (
              <Button
                mr={4}
                onClick={prevStep}
                colorScheme="blue"
                variant="outline"
                borderRadius={0}
                px={10}
              >
                Back
              </Button>
            )}
            {label === "Ad Details" && !(activeStep === steps.length - 1) && (
              <Button
                mr={4}
                colorScheme="green"
                type="submit"
                variant="solid"
                borderRadius={0}
                px={10}
              >
                Next
              </Button>
            )}
          </Flex>
        </Form>
      </FormikProvider>
    </>
  );
}

export default InstagramCarouselAdDetails;
