import React from "react";
import {Text} from "@chakra-ui/react";

function TooltipComponent({text}) {
    return (
        <div className="custom-tooltip">
            <span className="top"></span>
            <h3><i><b>Tool tip</b></i></h3>
            <Text marginTop={3}>{text}</Text>
        </div>
    )
}

export default TooltipComponent