import React, {useEffect, useMemo, useState} from "react";
import {
    Box,
    Button,
    Heading,
    HStack,
    Icon,
    Input,
    InputGroup,
    InputLeftElement,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Stack, Tag, TagLeftIcon,
    Text,
} from "@chakra-ui/react";
import {BiPlusCircle} from "react-icons/bi";
import {useNavigate, Link} from "react-router-dom";
import {CheckCircleIcon, SearchIcon} from "@chakra-ui/icons";
import {FiEye} from "react-icons/fi";
import Datatable from "../../../../components/Datatable";
import {useAdUploadList} from "../../../../hooks/campaign-briefs/useAdUploadList";
import {useParams} from "react-router-dom";
import "../../style/AdUploadList.css";
import {useSelector} from "react-redux";
import moment from "moment";
import GoogleIcon from "../../../../assets/images/google.png"
import PinterestIcon from "../../../../assets/images/pinterest.png"
import InstagramIcon from "../../../../assets/images/instagram.png"
import FacebookIcon from "../../../../assets/images/facebook.png"
import TikTokIcon from "../../../../assets/images/tiktok.png"
import warningSign from "../../../../assets/images/exclamation_mark.png"
import approveSign from "../../../../assets/images/check_mark.png"
import cancelSign from "../../../../assets/images/letterx_87521.png"
import {WarningIcon, CheckIcon, SmallCloseIcon, SmallAddIcon} from '@chakra-ui/icons'
import {Image} from '@chakra-ui/react'
import {
    AD_UPLOAD_STATUS,
    AD_UPLOAD_STATUS_COLOR,
    AD_UPLOAD_TYPE,
    AD_UPLOAD_TYPE_COLOR,
} from "../../../../constant";
import _ from "lodash";
import FileUploadIcon from "../../../../assets/images/file-upload-icon.png";
import {TagLabel} from "@chakra-ui/tag";

const AdUploadList = () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const clientId = useSelector((state) => state.client.clientId);

    const {data, refetch} = useAdUploadList(clientId, id);

    const [search, setSearch] = useState();
    const [adUploadList, setAdUploadList] = useState();

    useEffect(() => {
        refetch();
    }, [clientId]);

    useEffect(() => {
        setAdUploadList(data?.adUploads);
    }, [data]);

    useEffect(() => {
        if (search?.trim()) {
            const searchedAdUploads = adUploadList.filter((el) => {
                if (el?.ad_upload_type?.toLowerCase().includes(search.trim())) {
                    return true;
                } else if (el?.description?.toLowerCase().includes(search.trim())) {
                    return true;
                } else if (el?.message?.toLowerCase().includes(search.trim())) {
                    return true;
                } else if (el?.name?.toLowerCase().includes(search.trim())) {
                    return true;
                } else if (el?.status?.toLowerCase().includes(search.trim())) {
                    return true;
                } else if (el?.created_at?.toLowerCase().includes(search.trim())) {
                    return true;
                }
            });
            setAdUploadList(searchedAdUploads);
        } else {
            setAdUploadList(data?.adUploads);
        }
    }, [search]);

    // const adUploadTypeColor = (item) => {
    //   return item
    //     ? AD_UPLOAD_TYPE_COLOR[_.toUpper(item)]
    //     : AD_UPLOAD_TYPE_COLOR["OTHER"];
    // };
    //

    const checkStatusByTag = (value) => {
        return AD_UPLOAD_STATUS[value.toUpperCase()];
    };

    // const adUploadStatusColor = (item) => {
    //     return item
    //         ? AD_UPLOAD_STATUS_COLOR[_.toUpper(item)]
    //         : AD_UPLOAD_STATUS_COLOR["OTHER"];
    // };

    const handleRedirectUrl = (data) => {
        const adUploadType = data?.row.original?.ad_upload_type;
        const url = `/campaign-brief/${id}/ad-upload/`;
        if (AD_UPLOAD_TYPE.DV360 === adUploadType) {
            return `${url}dv360/${data.row.original.id}`;
        } else if (AD_UPLOAD_TYPE.DV360AUDIO === adUploadType) {
            return `${url}dv360-audio/${data.row.original.id}`;
        } else if (AD_UPLOAD_TYPE.DV360YOUTUBEVIDEO === adUploadType) {
            return `${url}dv360-youtube-video/${data.row.original.id}`;
        } else if (AD_UPLOAD_TYPE.DV360VIDEO === adUploadType) {
            return `${url}dv360-video/${data.row.original.id}`;
        } else if (AD_UPLOAD_TYPE.FBCAROUSEL === adUploadType) {
            return `${url}fb/carousel/${data.row.original.id}`;
        } else if (AD_UPLOAD_TYPE.FBVIDEO === adUploadType) {
            return `${url}fb/video/${data.row.original.id}`;
        } else if (AD_UPLOAD_TYPE.FACEBOOK === adUploadType) {
            return `${url}fb/${data.row.original.id}`;
        } else if (AD_UPLOAD_TYPE.INSTAGRAM_IMAGE === adUploadType) {
            return `${url}instagram/image/${data.row.original.id}`;
        } else if (AD_UPLOAD_TYPE.INSTAGRAM_VIDEO === adUploadType) {
            return `${url}instagram/video/${data.row.original.id}`;
        } else if (AD_UPLOAD_TYPE.INSTAGRAM_CAROUSEL === adUploadType) {
            return `${url}instagram/carousel/${data.row.original.id}`;
        } else if (AD_UPLOAD_TYPE.PINTEREST_IMAGE === adUploadType) {
            return `${url}pinterest/${data.row.original.id}`;
        } else if (AD_UPLOAD_TYPE.PINTEREST_VIDEO === adUploadType) {
            return `${url}pinterest-video/${data.row.original.id}`;
        } else if (AD_UPLOAD_TYPE.PINTEREST_CAROUSEL === adUploadType) {
            return `${url}pinterest-carousel/${data.row.original.id}`;
        } else if (AD_UPLOAD_TYPE.TIKTOK_VIDEO === adUploadType) {
            return `${url}tiktok-video/${data.row.original.id}`;
        } else if (AD_UPLOAD_TYPE.GOOGLE_CSV === adUploadType) {
            return `${url}google/${data.row.original.id}`;
        } else {
            return '/NotFound';
        }
    };


    const columns = useMemo(
        () => [
            {
                Header: "Ad set name",
                accessor: "name",
            },
            {
                Header: "Channel",
                accessor: "ad_upload_type",
                Cell: (data) => {
                    if (data.value === AD_UPLOAD_TYPE.GOOGLE_CSV) {
                        return (
                            <div className="channel-type">
                                <Image src={GoogleIcon}
                                       boxSize="22px"
                                       objectFit="cover"
                                       alt="upload-icon"/>
                                <span>{data.row.original.ad_upload_type}</span>
                            </div>
                        );
                    }
                    if (data.value === AD_UPLOAD_TYPE.PINTEREST_IMAGE
                        || data.value === AD_UPLOAD_TYPE.PINTEREST_VIDEO
                        || data.value === AD_UPLOAD_TYPE.PINTEREST_CAROUSEL) {
                        return (
                            <div className="channel-type">
                                <Image src={PinterestIcon}
                                       boxSize="22px"
                                       objectFit="cover"
                                       alt="upload-icon"/>
                                <span>{'Pinterest'}</span>
                            </div>
                        );
                    }
                    if (data.value === AD_UPLOAD_TYPE.INSTAGRAM_CAROUSEL || data.value === AD_UPLOAD_TYPE.INSTAGRAM_VIDEO || data.value === AD_UPLOAD_TYPE.INSTAGRAM_CAROUSEL) {
                        return (
                            <div className="channel-type">
                                <Image src={InstagramIcon}
                                       boxSize="22px"
                                       objectFit="cover"
                                       alt="upload-icon"/>
                                <span>{'Instagram'}</span>
                            </div>
                        );
                    }
                    if (data.value === AD_UPLOAD_TYPE.FBVIDEO || data.value === AD_UPLOAD_TYPE.FBCAROUSEL || data.value === AD_UPLOAD_TYPE.FACEBOOK) {
                        return (
                            <div className="channel-type">
                                <Image src={FacebookIcon}
                                       boxSize="22px"
                                       objectFit="cover"
                                       alt="upload-icon"/>
                                <span>{'Facebook'}</span>
                            </div>
                        );
                    }
                    if (data.value === AD_UPLOAD_TYPE.TIKTOK_VIDEO) {
                        return (
                            <div className="channel-type">
                                <Image src={TikTokIcon}
                                       boxSize="22px"
                                       objectFit="cover"
                                       alt="upload-icon"/>
                                <span>{'TikTok'}</span>
                            </div>
                        );
                    }
                },
            },
            {
                Header: "Ad Type",
                accessor: "type",
                Cell: (data) => {
                    return (
                        <>
                            {data.row.original.ad_upload_type}
                        </>
                    );
                },
            },
            {
                Header: "Status",
                accessor: "status",
                Cell: (data) => {
                    return (
                        <>
                            {AD_UPLOAD_STATUS.CREATED === data.value.toUpperCase() &&
                            <Tag size="lg" variant='subtle' color={"white"} backgroundColor={"#FFC108"} w={"80%"}
                                 borderRadius={100}>
                                <TagLeftIcon boxSize='16px' as={WarningIcon}/>
                                <TagLabel>Action Needed</TagLabel>
                            </Tag>
                            }
                            {AD_UPLOAD_STATUS.APPROVED === data.value.toUpperCase() &&
                            <Tag size="lg" variant='subtle' color="white" backgroundColor={"#63C68B"} w={"80%"}
                                 borderRadius={100}>
                                <TagLeftIcon boxSize='16px' as={CheckCircleIcon}/>
                                <TagLabel>Approved</TagLabel>
                            </Tag>
                            }
                            {AD_UPLOAD_STATUS.REJECTED === data.value.toUpperCase() &&
                            <Tag size="lg" variant='subtle' backgroundColor="#A7A9BD" color="white" w={"80%"}
                                 borderRadius={100}>
                                <TagLeftIcon boxSize='16px' as={SmallCloseIcon} backgroundColor={"white"}
                                             color={"#A7A9BD"} borderRadius={100}/>
                                <TagLabel>Closed</TagLabel>
                            </Tag>
                            }
                        </>
                    );
                },
            },
            {
                Header: "Added on",
                accessor: "created_at",
                Cell: (data) => {
                    return (
                        <>
                            {` ${moment(data.row.original.created_at).format(
                                "YYYY-MM-DD"
                            )}`}
                        </>
                    );
                },
            },
            {
                Header: () => <Text>Actions</Text>,
                accessor: "actions",
                Cell: (data) => {
                    const url = handleRedirectUrl(data);
                    const status = data.row.values.status;
                    return (
                        <HStack>
                            <Button
                                as={Link}
                                size="sm"
                                variant="outline"
                                aria-label="View details"
                                icon={<FiEye/>}
                                to={url}
                                color="#3B82F6"
                                py={5}
                                w={100}

                            >
                                {AD_UPLOAD_STATUS.CREATED === status.toUpperCase() ? 'View/Edit' : 'View'}
                            </Button>
                            <Button
                                size="sm"
                                variant="outline"
                                aria-label="View details"
                                icon={<FiEye/>}
                                color="#FF8189"
                                py={5}
                                w={100}

                            >
                                Delete
                            </Button>
                        </HStack>
                    )
                },
            },
        ],
        []
    );

    return (
        <div className="ad-upload-list">
            <div className="search scrollable">
                <Stack direction="row" justifyContent="space-between" spacing={4}>
                    <InputGroup width={660}>
                        <InputLeftElement
                            pointerEvents="none"
                            children={<SearchIcon color="gray.300" ml={8}/>}
                        />
                        <Input
                            pl={12}
                            borderRadius={100}
                            name="search"
                            type="tel"
                            placeholder="Search"
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </InputGroup>
                    <Menu>
                        <MenuButton
                            style={{ minWidth: "inherit" }}
                            as={Button}
                            colorScheme="blue"
                            backgroundColor="blue.400"
                            borderRadius={4}
                            px="5"
                            marginTop={5}
                            rightIcon={<BiPlusCircle />}
                        >
                            Facebook
                        </MenuButton>
                        <MenuList>
                            <MenuItem
                                onClick={() => navigate(`/campaign-brief/${id}/ad-upload/new/fb`)}
                            >
                                Image
                            </MenuItem>
                            <MenuItem
                                onClick={() =>
                                    navigate(`/campaign-brief/${id}/ad-upload/new/fb/video`)
                                }
                            >
                                Video
                            </MenuItem>
                            <MenuItem
                                onClick={() =>
                                    navigate(`/campaign-brief/${id}/ad-upload/new/fb/carousel`)
                                }
                            >
                                Carousel
                            </MenuItem>
                        </MenuList>
                    </Menu>
                    <Menu>
                        <MenuButton
                            style={{ minWidth: "inherit" }}
                            as={Button}
                            colorScheme="blue"
                            backgroundColor="blue.400"
                            borderRadius={4}
                            px="5"
                            marginTop={5}
                            rightIcon={<BiPlusCircle />}
                        >
                            DV360
                        </MenuButton>
                        <MenuList>
                            <MenuItem
                                onClick={() =>
                                    navigate(`/campaign-brief/${id}/ad-upload/new/dv360`)
                                }
                            >
                                Image
                            </MenuItem>
                            <MenuItem
                                onClick={() =>
                                    navigate(`/campaign-brief/${id}/ad-upload/new/dv360-audio`)
                                }
                            >
                                Audio
                            </MenuItem>
                            <MenuItem
                                onClick={() =>
                                    navigate(
                                        `/campaign-brief/${id}/ad-upload/new/dv360-youtube-video`
                                    )
                                }
                            >
                                Youtube Video
                            </MenuItem>
                            <MenuItem
                                onClick={() =>
                                    navigate(`/campaign-brief/${id}/ad-upload/new/dv360-video`)
                                }
                            >
                                Video
                            </MenuItem>
                        </MenuList>
                    </Menu>
                    {/* INSTAGRAM */}
                    <Menu>
                        <MenuButton
                            style={{ minWidth: "inherit" }}
                            as={Button}
                            colorScheme="blue"
                            backgroundColor="blue.400"
                            borderRadius={4}
                            px="5"
                            marginTop={5}
                            rightIcon={<BiPlusCircle />}
                        >
                            Instagram
                        </MenuButton>
                        <MenuList>
                            <MenuItem
                                onClick={() =>
                                    navigate(`/campaign-brief/${id}/ad-upload/instagram/image`)
                                }
                            >
                                Image
                            </MenuItem>
                            <MenuItem
                                onClick={() =>
                                    navigate(`/campaign-brief/${id}/ad-upload/instagram/video`)
                                }
                            >
                                Video
                            </MenuItem>
                            <MenuItem
                                onClick={() =>
                                    navigate(
                                        `/campaign-brief/${id}/ad-upload/instagram/carousel`
                                    )
                                }
                            >
                                Carousel
                            </MenuItem>
                        </MenuList>
                    </Menu>
                    {/* PINTEREST */}
                    <Menu>
                        <MenuButton
                            style={{ minWidth: "inherit" }}
                            as={Button}
                            colorScheme="blue"
                            backgroundColor="blue.400"
                            borderRadius={4}
                            px="5"
                            marginTop={5}
                            rightIcon={<BiPlusCircle />}
                        >
                            Pinterest
                        </MenuButton>
                        <MenuList>
                            <MenuItem
                                onClick={() =>
                                    navigate(`/campaign-brief/${id}/ad-upload/new/pinterest`)
                                }
                            >
                                Image
                            </MenuItem>
                            <MenuItem
                                onClick={() =>
                                    navigate(`/campaign-brief/${id}/ad-upload/new/pinterest-video`)
                                }
                            >
                                Video
                            </MenuItem>
                            <MenuItem
                                onClick={() =>
                                    navigate(`/campaign-brief/${id}/ad-upload/new/pinterest-carousel`)
                                }
                            >
                                Carousel
                            </MenuItem>
                        </MenuList>
                    </Menu>
                    {/** TIKTOK */}
                    <Menu>
                        <MenuButton
                            style={{ minWidth: "inherit" }}
                            as={Button}
                            colorScheme="blue"
                            backgroundColor="blue.400"
                            borderRadius={4}
                            px="5"
                            marginTop={5}
                            rightIcon={<BiPlusCircle />}
                        >
                            TikTok
                        </MenuButton>
                        <MenuList>
                            <MenuItem
                                onClick={() =>
                                    navigate(`/campaign-brief/${id}/ad-upload/new/tiktok-video`)
                                }
                            >
                                Video
                            </MenuItem>
                        </MenuList>
                    </Menu>
                    {/*  google */}
                    <Menu>
                        <MenuButton
                            style={{minWidth: "inherit"}}
                            as={Button}
                            colorScheme="blue"
                            backgroundColor="blue.400"
                            borderRadius={4}
                            px="5"
                            marginTop={5}
                            rightIcon={<BiPlusCircle />}
                            onClick={() => navigate(`/campaign-brief/${id}/ad-upload/google`)}
                        >
                            Google
                        </MenuButton>
                    </Menu>
                    <Menu>
                        <MenuButton style={{minWidth: "inherit"}}
                                    as={Button}
                                    backgroundColor={"#3B82F6"}
                                    borderRadius={0}
                                    leftIcon={<SmallAddIcon boxSize={8}/>}
                                    onClick={() => navigate('/campaign-briefs/:id/ad-upload/new')}>
                            Create new ad set
                        </MenuButton>
                    </Menu>
                </Stack>
            </div>
            <Datatable data={adUploadList || []} columns={columns} headerColor="#E598C2"/>
        </div>

    );
};

export default AdUploadList;
