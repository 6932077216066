export const userModelInitialValues = {
  name: "",
  email: "",
  password: "",
  roles: [],
  clients: [],
};

export const passwordInitialValues = {
  newPassword: "",
  confirmPassword: "",
};

export const userDetailInitialValues = (data) => ({
  firstName: data?.name.split(" ")[0] || "",
  lastName: data?.name.replace(`${data?.name.split(" ")[0]} `, "") || "",
  email: data?.email || "",
  password: data?.password || "",
  roles: data?.access_info.roles || "",
  state: data?.state || "",
  clients: data?.access_info.clients.map((c) => c.id) || "",
});
