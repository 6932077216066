import {
    Button,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Table, Tbody,
    Th,
    Thead, Tr
} from "@chakra-ui/react";
import {CheckboxContainer, CheckboxControl} from "formik-chakra-ui";
import {Form, FormikProvider, useFormik} from "formik";
import * as yup from "yup";
import {useGlobalContext} from "../store/WrapContext";
import {useEffect, useState} from "react";
import SitelinkEditForm from "./SitelinkEditForm";
import {useGetExtensionData, useGetSiteLink} from "../../../../../../hooks/campaign-briefs/useTableData";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import instance from "../../../../../../helpers/axios";
import FullPageLoader from "../../../../../../components/FullPageLoader";
import Loading from "../../../../../../components/Loading";

function SitelinkModelContent({whichModel, onClose}) {

    const {setSiteLink, initialSiteLink, siteLinks, setInitialSiteLinkData} = useGlobalContext()
    const [isLoading, setIsLoading] = useState(true)
    const {id, googleId} = useParams();
    const clientId = useSelector((state) => state.client.clientId);
    const {data, refetch} = useGetSiteLink(clientId, id, googleId, setInitialSiteLinkData, setIsLoading)
    const [isEditFormOpen, setIsEditFormOpen] = useState(false)
    const [formValue, setFormValue] = useState({})

    const initialValues = {
        siteLinks: siteLinks.length > 0 ? siteLinks.map((item) => item.id) : [],
    };

    const validationSchema = yup.object({
        siteLinks: yup.array().min(1).max(4),
    });

    useEffect(() => {
        refetch()
        setInitialSiteLinkData(data)
    }, [isEditFormOpen])

    async function onSubmit(values) {
        // console.log("Values", values.siteLinks)
        // await instance({
        //     method: "POST",
        //     url: `/client/${clientId}/campaign-brief/${id}/ad-upload/${googleId}/preview/`,
        //     withCredentials: false,
        //     data: values,
        // }).then((res) => {
        //     if (res.status === 200) {
        //         console.log('res', res)
        //     }
        // })
        //     .catch((error) => {
        //         console.log(error)
        //     });
        setSiteLink(values)
        onClose()
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit
    })

    const {
        errors,
        values,
        isValid,
        touched,
        handleSubmit,
        handleBlur,
        setFieldValue,
        setErrors,
    } = formik;


    function openSiteLinkForm(value) {
        setFormValue(value)
        setIsEditFormOpen(true)
    }

    if (isLoading) {
        return (
            <ModalContent maxW="1200px">
                <Loading/>
            </ModalContent>
        )
    }

    return (
        <ModalContent maxW="1200px">
            <FormikProvider value={formik}>
                <Form>
                    <ModalHeader> {whichModel}</ModalHeader>
                    <ModalCloseButton/>
                    {
                        !isEditFormOpen && <ModalBody>
                            <CheckboxContainer name="siteLinks">
                                <Table>
                                    <Thead>
                                        <Tr textAlign='left'>
                                            <th>Sr No</th>
                                            <th> Text</th>
                                            <th> Description 1</th>
                                            <th> Description 2</th>
                                            <th> Url</th>
                                            <th>Action</th>
                                        </Tr>
                                    </Thead>

                                    <Tbody>
                                        {
                                            data && data.length > 0 && data.map((item, index) => {
                                                const {
                                                    id,
                                                    ad_upload_id,
                                                    sitelink_text,
                                                    sitelink_description_1,
                                                    sitelink_description_2,
                                                    siltelink_final_url
                                                } = item

                                                return (
                                                    <Tr key={index}>
                                                        <td>
                                                            {index + 1}
                                                        </td>
                                                        <td>
                                                            <CheckboxControl name="siteLinks" value={String(id)}>
                                                                {sitelink_text}
                                                            </CheckboxControl>
                                                        </td>
                                                        <td>
                                                            {sitelink_description_1}
                                                        </td>
                                                        <td>
                                                            {sitelink_description_2}
                                                        </td>
                                                        <td>
                                                            {siltelink_final_url}
                                                        </td>
                                                        <td>
                                                            <Button onClick={() => openSiteLinkForm(item)}>Edit</Button>
                                                        </td>
                                                    </Tr>
                                                )
                                            })
                                        }
                                    </Tbody>
                                </Table>
                            </CheckboxContainer>
                        </ModalBody>
                    }
                    {
                        !isEditFormOpen && <ModalFooter>
                            <Button colorScheme='blue' mr={3} onClick={onClose}>
                                Close
                            </Button>
                            <Button variant='ghost' type="submit" disabled={!isValid}>Add</Button>
                        </ModalFooter>
                    }
                </Form>
            </FormikProvider>
            <ModalBody marginBottom={10}>
                {
                    isEditFormOpen &&
                    <SitelinkEditForm formValue={formValue} setIsEditFormOpen={setIsEditFormOpen}
                                      isEditFormOpen={isEditFormOpen} onClose={onClose}/>
                }
            </ModalBody>
        </ModalContent>
    )
}

export default SitelinkModelContent