import {
  Box,
  Container, Flex, Grid, GridItem
} from "@chakra-ui/react";
import React from "react";
import ReactPlayer from 'react-player'

const DisplayVideoAdPreview = ({ url, vidWidth, playingPreview, setPlayingPreview, }) => {
  return (
    <Grid templateColumns="repeat(6, 1fr)" gap={4} className="fb-upload">
      <GridItem w="full" colSpan={{ base: 6, lg: 6 }}>
        <Box
          borderWidth="2px"
          borderColor="gray"
          p={10}
          background="client/src/pages/CampaignBriefs/components/AdUpload/DV360Audio/AudioAdPreview#FFF8F4"
        >
          <Container height="100%">
            <Flex alignItems="center" flexDirection="row" justify="center">
              <ReactPlayer
                className='react-player'
                url={url}
                width={vidWidth}
                controls
                playing={playingPreview}
                onPlay={() => { setPlayingPreview(true) }}
              />
            </Flex>
          </Container>
        </Box>
      </GridItem>
    </Grid>
  );
};

export default DisplayVideoAdPreview;
