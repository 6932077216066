import {useQuery} from "react-query";
import axios from "../../helpers/axios";

export const useGetSingleMasterData = (clientId, campaignId, adId, masterId, setFormValue) => {
    return useQuery(["campaign", clientId, campaignId, adId, masterId], async () => {
        return axios
            .get(`/client/${clientId}/campaign-brief/${campaignId}/ad-upload/${adId}/master/${masterId}`, {
                withCredentials: false,
            })
            .then((res) => {
                if (res.status === 200) {
                    setFormValue(res?.data?.data?.masterField)
                    return res.data.data;
                }
            })
            .catch((err) => {
                return err.response;
            });
    });
};
