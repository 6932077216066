import React, { useEffect, useState } from "react";
import { Step, Steps, useSteps } from "chakra-ui-steps";
import CampaignDetails from "./CampaignDetails";
import CampaignObjective from "./CampaignObjective";
import CampaignBudget from "./CampaignBudget";
import Demographics from "./Demographics";
import MediaStrategies from "./MediaStrategies";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useGetSingleCampaign } from "../../hooks/campaign-briefs/useGetSingleCampaign";
import {
  deleteAllAdCampaignFields,
  settingBusinessDetails,
  settingCampaignBudget,
  settingCampaignDetails,
  settingCampaignObjective,
  settingDemographics,
  settingDemographyDetails,
  settingMediaStrategies,
  settingMediaStrategyDetails,
  settingNotes,
} from "../../store/adCampaign";
import {
  Button,
  Center,
  css,
  Image,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { Box, Flex } from "@chakra-ui/layout";
import _ from "lodash";
import { BiArrowBack } from "react-icons/bi";
import AddNotesModal from "../../components/AdCampaign/AddNotesModal";
import axios from "../../helpers/axios";

function CreateAdCampaign() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const clientId = useSelector((state) => state.client.clientId);
  const { data, refetch } = useGetSingleCampaign(clientId, id);
  const [open, setOpen] = useState(false);
  const [isMobile] = useMediaQuery("(min-width: 768px)");

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (id && data) {
      setAllData();
      getSingleBusiness(data.campaign.business_id);
    } else {
      dispatch(deleteAllAdCampaignFields());
    }
  }, [data, id]);

  function handleClick() {
    dispatch(deleteAllAdCampaignFields());
    navigate("/campaign-briefs");
  }

  function getSingleBusiness(id) {
    axios
      .get(`/client/business/${id}`, {
        withCredentials: false,
      })
      .then((res) => {
        if (res.status === 200 && !_.isEmpty(res.data.data.business)) {
          dispatch(settingBusinessDetails(res.data.data.business));
        }
      })
      .catch((err) => {
        return err.response;
      });
  }

  function handleOpenNotes() {
    setOpen(true);
  }

  function handleCloseNotes() {
    setOpen(false);
    setNote("");
  }

  function setAllData() {
    const { detail } = data.campaign;
    dispatch(settingCampaignDetails(detail.campaignDetails));
    dispatch(settingCampaignObjective(detail.objective));
    dispatch(settingCampaignBudget(detail.campaignBudget));
    dispatch(settingDemographics(detail.demographics));
    dispatch(settingMediaStrategies(detail.mediaStrategies));
    dispatch(settingDemographyDetails(detail.demographicDetails));
    dispatch(settingMediaStrategyDetails(detail.mediaStrategyDetails));
    dispatch(settingNotes(detail.notes));
  }

  const { nextStep, prevStep, reset, activeStep, setStep } = useSteps({
    initialStep: 0,
  });
  const totalStep = [1, 2, 3, 4, 5];

  const steps = [
    {
      label: "Campaign Details",
      content: (
        <CampaignDetails
          activeStep={activeStep}
          steps={totalStep}
          prevStep={prevStep}
          nextStep={nextStep}
          label="Campaign Details"
          handleNotes={handleOpenNotes}
          getSingleBusiness={getSingleBusiness}
        />
      ),
    },
    {
      label: "Campaign Objective",
      content: (
        <CampaignObjective
          activeStep={activeStep}
          steps={totalStep}
          prevStep={prevStep}
          nextStep={nextStep}
          label="Campaign Objective"
          handleNotes={handleOpenNotes}
        />
      ),
    },
    {
      label: "Campaign Budget",
      content: (
        <CampaignBudget
          activeStep={activeStep}
          steps={totalStep}
          prevStep={prevStep}
          nextStep={nextStep}
          label="Campaign Budget"
          handleNotes={handleOpenNotes}
        />
      ),
    },
    {
      label: "Demographics",
      content: (
        <Demographics
          activeStep={activeStep}
          steps={totalStep}
          prevStep={prevStep}
          nextStep={nextStep}
          label="Demographics"
          handleNotes={handleOpenNotes}
        />
      ),
    },
    {
      label: "Media Strategies",
      content: (
        <MediaStrategies
          activeStep={activeStep}
          steps={totalStep}
          prevStep={prevStep}
          nextStep={nextStep}
          label="Media Strategies"
          handleNotes={handleOpenNotes}
        />
      ),
    },
  ];

  const [initialStep, setInitialStep] = useState(steps);
  const [note, setNote] = useState("");

  return (
    <>
      <Flex
        mt={{ base: "8", md: "2" }}
        style={{ transform: "translateX(-1.5rem)" }}
        onClick={handleClick}
        cursor={"pointer"}
        px="4"
      >
        <Center marginRight={2}>
          <BiArrowBack size={16} />
        </Center>
        <Center>
          <Text
            css={css({
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "14px",
            })}
          >
            Back to Campaigns
          </Text>
        </Center>
      </Flex>
      <Box maxWidth={{ base: "full", md: "90%" }} mx="auto">
        <Steps
          activeStep={activeStep}
          mt={{ base: "8", md: "20" }}
          orientation={isMobile ? "horizontal" : "vertical"}
        >
          {initialStep.map(({ label, content }) => {
            return (
              <Step key={label} label={label} pb={5}>
                {content}
              </Step>
            );
          })}
        </Steps>
        <AddNotesModal
          isOpen={open}
          note={note}
          setNote={setNote}
          onClose={handleCloseNotes}
        />
      </Box>
    </>
  );
}

export default CreateAdCampaign;
