import {Button, HStack, useToast} from "@chakra-ui/react";
import {FiEye} from "react-icons/fi";
import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import CampaignModal from "../../../layout/components/CampaignModal";
import instance from "../../../helpers/axios";
import axios from "../../../helpers/axios";
import {useGetClientBusiness} from "../../../hooks/clients/useGetClientBusiness";

const BusinessAction = ({to, row}) => {

    const {id} = useParams()
    const [open, setOpen] = useState(false)
    const [business, setBusiness] = useState([])
    const toast = useToast()
    const [businessData, setBusinessData] = useState({
        name: "",
        phone: "",
        email: "",
        contactName: "",
        industry: "",
        address: ""
    })

    const {data, refetch} = useGetClientBusiness(id);

    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        setBusiness(data?.businesses);
    }, [data]);


    function handleOpenBusinessModal() {
        setOpen(true)
        getSingleBusiness()
    }
    function handleCloseBusinessModal() {
        setOpen(false)
    }

    function getSingleBusiness() {
        axios
            .get(`/client/business/${row.id}`, {
                withCredentials: false,
            })
            .then((res) => {
                if (res.status === 200) {
                    setBusinessData(res.data.data.business)
                }
            })
            .catch((err) => {
                return err.response
            })
    }

    const onSubmit = async (values) => {
        await instance({
            method: "PUT",
            url: `client/business/${row.id}`,
            data: {
                name: values.name,
                contactName: values.contactName,
                phone: values.phone,
                email: values.email,
                industry: values.industry,
                address : values.address
            },
        })
            .then((response) => {
                handleCloseBusinessModal()
                toast({
                    isClosable: true,
                    status: "success",
                    variant: "top-accent",
                    position: "top-right",
                    title: "Success",
                    description: response.data?.data?.message,
                });
                refetch()
            })
            .catch((error) => {
                toast({
                    isClosable: true,
                    status: "error",
                    variant: "top-accent",
                    position: "top-right",
                    description: error.response.data.message,
                });
            });
    };

    const handleDelete = async (values) => {
        await instance({
            method: "DELETE",
            url: `client/business/${row.id}`
        })
            .then((response) => {
                toast({
                    isClosable: true,
                    status: "success",
                    variant: "top-accent",
                    position: "top-right",
                    title: "Success",
                    description: response.data?.data?.message,
                });
                refetch()
            })
            .catch((error) => {
                toast({
                    isClosable: true,
                    status: "error",
                    variant: "top-accent",
                    position: "top-right",
                    description: error.response.data.message,
                });
            });
    };

    return (
        <>
            <HStack>
                <Button
                    size="sm"
                    variant="outline"
                    aria-label="View details"
                    icon={<FiEye/>}
                    py={5}
                    onClick={handleOpenBusinessModal}
                >
                    View/Edit
                </Button>
                <Button py={5} size="sm" variant="outline" colorScheme="red" onClick={handleDelete}>
                    Delete
                </Button>
            </HStack>
            <CampaignModal
                isOpen={open}
                onClose={handleCloseBusinessModal}
                businessData={businessData}
                dialogTitle={"Edit Business"}
                onSubmit={onSubmit}
                buttonType={"Save"} reportData={businessData}/>
        </>
    );
};

export default BusinessAction;
