export const campaignInitialValue = (data, campaignDetails) => ({
    name: campaignDetails?.name || "",
    startDate: campaignDetails?.start_date || "",
    endDate: campaignDetails?.end_date || "",
    detail: {
        industryBasic: {
            companyName: data?.detail?.companyName || "",
            phone: data?.detail?.phone || "",
            contactName: data?.detail?.contactName || "",
            email: data?.detail?.email || "",
            address: data?.detail?.address || "",
            productAndServices: data?.detail?.productAndServices || "",
            industry: data?.detail?.industry || "",
            industryType: data?.detail?.industryType || "",
        },
        campaignBasic: {
            campaignName: campaignDetails?.name || "",
            startDate: campaignDetails?.start_date || "",
            endDate: campaignDetails?.end_date || "",
            duration: campaignDetails?.detail?.campaignBasic?.duration || "",
            websiteUrl: campaignDetails?.detail?.campaignBasic?.websiteUrl || "",
            landingPageDocLink:
                campaignDetails?.detail?.campaignBasic?.landingPageDocLink || "",
            landingPageMobileLink:
                campaignDetails?.detail?.campaignBasic?.landingPageMobileLink || "",
            thankYouLink: campaignDetails?.detail?.campaignBasic?.thankYouLink || "",
            notes: campaignDetails?.detail?.campaignBasic?.notes || "",
        },
        campaignBudget: {
            googleSearchAds:
                campaignDetails?.detail?.campaignBudget?.googleSearchAds || "",
            bingSearchAds:
                campaignDetails?.detail?.campaignBudget?.bingSearchAds || "",
            fbAds: campaignDetails?.detail?.campaignBudget?.fbAds || "",
            instaAds: campaignDetails?.detail?.campaignBudget?.instaAds || "",
            displayAds: campaignDetails?.detail?.campaignBudget?.displayAds || "",
            twitterAds: campaignDetails?.detail?.campaignBudget?.twitterAds || "",
            nativeAds: campaignDetails?.detail?.campaignBudget?.nativeAds || "",
            spotifyAudioAds:
                campaignDetails?.detail?.campaignBudget?.spotifyAudioAds || "",
            pinterestAds: campaignDetails?.detail?.campaignBudget?.pinterestAds || "",
            quoraAds: campaignDetails?.detail?.campaignBudget?.quoraAds || "",
            displayVideo: campaignDetails?.detail?.campaignBudget?.displayVideo || "",
            youtubeVideo: campaignDetails?.detail?.campaignBudget?.youtubeVideo || "",
            fbVideo: campaignDetails?.detail?.campaignBudget?.fbVideo || "",
            instaVideo: campaignDetails?.detail?.campaignBudget?.instaVideo || "",
            overTheTop: campaignDetails?.detail?.campaignBudget?.overTheTop || "",
            retargetting: {
                googleSearchRt:
                    campaignDetails?.detail?.campaignBudget?.retargetting
                        ?.googleSearchRt || "",
                bingSearchRt:
                    campaignDetails?.detail?.campaignBudget?.retargetting?.bingSearchRt ||
                    "",
                fbRt: campaignDetails?.detail?.campaignBudget?.retargetting?.fbRt || "",
                instaRt:
                    campaignDetails?.detail?.campaignBudget?.retargetting?.instaRt || "",
                displayRt:
                    campaignDetails?.detail?.campaignBudget?.retargetting?.displayRt ||
                    "",
                linkedinRt:
                    campaignDetails?.detail?.campaignBudget?.retargetting?.linkedinRt ||
                    "",
                nativeRt:
                    campaignDetails?.detail?.campaignBudget?.retargetting?.nativeRt || "",
                pinterestRt:
                    campaignDetails?.detail?.campaignBudget?.retargetting?.pinterestRt ||
                    "",
                videoRt:
                    campaignDetails?.detail?.campaignBudget?.retargetting?.videoRt || "",
            },
            other: {
                hotelAds:
                    campaignDetails?.detail?.campaignBudget?.other?.hotelAds || "",
                travelAds:
                    campaignDetails?.detail?.campaignBudget?.other?.travelAds || "",
                shoppingAds:
                    campaignDetails?.detail?.campaignBudget?.other?.shoppingAds || "",
                fbEvents:
                    campaignDetails?.detail?.campaignBudget?.other?.fbEvents || "",
                fbLookalike:
                    campaignDetails?.detail?.campaignBudget?.other?.fbLookalike || "",
                instaLookalike:
                    campaignDetails?.detail?.campaignBudget?.other?.instaLookalike || "",
                displayList:
                    campaignDetails?.detail?.campaignBudget?.other?.displayList || "",
                fbList: campaignDetails?.detail?.campaignBudget?.other?.fbList || "",
                instaList:
                    campaignDetails?.detail?.campaignBudget?.other?.instaList || "",
                weatherAds:
                    campaignDetails?.detail?.campaignBudget?.other?.weatherAds || "",
                linkedinInmail:
                    campaignDetails?.detail?.campaignBudget?.other?.linkedinInmail || "",
                linkedinSponsored:
                    campaignDetails?.detail?.campaignBudget?.other?.linkedinSponsored ||
                    "",
                fbLead: campaignDetails?.detail?.campaignBudget?.other?.fbLead || "",
                instaLead:
                    campaignDetails?.detail?.campaignBudget?.other?.instaLead || "",
                instantExperience:
                    campaignDetails?.detail?.campaignBudget?.other?.instantExperience ||
                    "",
            },
            total: campaignDetails?.detail?.campaignBudget?.total || "",
            finalBudget: campaignDetails?.detail?.campaignBudget?.finalBudget || "",
            budgetAddOn: campaignDetails?.detail?.campaignBudget?.budgetAddOn || "",
            isMonthly: campaignDetails?.detail?.campaignBudget?.isMonthly || "Yes",
            notes: campaignDetails?.detail?.campaignBudget?.notes || "",
            budgetAddOnArr: campaignDetails?.detail?.campaignBudget?.budgetAddOnArr || [],
        },
        priceModel: campaignDetails?.detail?.priceModel || [],
        Kpis: campaignDetails?.detail?.Kpis || [],
        customMetricsAndGoals: campaignDetails?.detail?.customMetricsAndGoals || "",
        campaignConverters: campaignDetails?.detail?.campaignConverters || [],
        campaignConverterNotes:
            campaignDetails?.detail?.campaignConverterNotes || "",
        objective: campaignDetails?.detail?.objective || "",
        conversionMeasurement: campaignDetails?.detail?.conversionMeasurement || "",
        demographyDetails: {
            gender: campaignDetails?.detail?.demographyDetails?.gender || [],
            ageGroup: campaignDetails?.detail?.demographyDetails?.ageGroup || [],
            other: campaignDetails?.detail?.demographyDetails?.other || "",
            isRequired:
                campaignDetails?.detail?.demographyDetails?.isRequired || "Yes",
        },
        families: campaignDetails?.detail?.families || [],
        familiesCustom:
            campaignDetails?.detail?.demographyDetails?.familiesCustom || "",
        householdIncomeDetailsfb:
            campaignDetails?.detail?.householdIncomeDetailsfb || [],
        householdIncomeDetailsSearchDisplay:
            campaignDetails?.detail?.householdIncomeDetailsSearchDisplay || [],
        audienceTargetting: {
            behavior: {
                affinity:
                    campaignDetails?.detail?.audienceTargetting?.behavior?.affinity || [],
                automotive:
                    campaignDetails?.detail?.audienceTargetting?.behavior?.automotive ||
                    [],
                businessToBusiness:
                    campaignDetails?.detail?.audienceTargetting?.behavior
                        ?.businessToBusiness || [],
                charitableDonations:
                    campaignDetails?.detail?.audienceTargetting?.behavior
                        ?.charitableDonations || [],
                digitalActivities:
                    campaignDetails?.detail?.audienceTargetting?.behavior
                        ?.digitalActivities || [],
                expats:
                    campaignDetails?.detail?.audienceTargetting?.behavior?.expats || [],
                financial:
                    campaignDetails?.detail?.audienceTargetting?.behavior?.financial ||
                    [],
                inMarket:
                    campaignDetails?.detail?.audienceTargetting?.behavior?.inMarket || [],
                jobRole:
                    campaignDetails?.detail?.audienceTargetting?.behavior?.jobRole || [],
                media:
                    campaignDetails?.detail?.audienceTargetting?.behavior?.media || [],
                mobileDeviceUser:
                    campaignDetails?.detail?.audienceTargetting?.behavior
                        ?.mobileDeviceUser || [],
                purchaseBehavior:
                    campaignDetails?.detail?.audienceTargetting?.behavior
                        ?.purchaseBehavior || [],
                residentialProfiles:
                    campaignDetails?.detail?.audienceTargetting?.behavior
                        ?.residentialProfiles || [],
                seasonalAndEvents:
                    campaignDetails?.detail?.audienceTargetting?.behavior
                        ?.seasonalAndEvents || [],
                travel:
                    campaignDetails?.detail?.audienceTargetting?.behavior?.travel || [],
            },
            interest: {
                businessAndIndustry:
                    campaignDetails?.detail?.audienceTargetting?.interest
                        ?.businessAndIndustry || [],
                education:
                    campaignDetails?.detail?.audienceTargetting?.interest?.education ||
                    [],
                entertainment:
                    campaignDetails?.detail?.audienceTargetting?.interest
                        ?.entertainment || [],
                familyAndRelationship:
                    campaignDetails?.detail?.audienceTargetting?.interest
                        ?.familyAndRelationship || [],
                fieldOfStudy:
                    campaignDetails?.detail?.audienceTargetting?.interest?.fieldOfStudy ||
                    [],
                fitnessAndWellness:
                    campaignDetails?.detail?.audienceTargetting?.interest
                        ?.fitnessAndWellness || [],
                foodAndDrink:
                    campaignDetails?.detail?.audienceTargetting?.interest?.foodAndDrink ||
                    [],
                hobbiesAndActivities:
                    campaignDetails?.detail?.audienceTargetting?.interest
                        ?.hobbiesAndActivities || [],
                relationshipGender:
                    campaignDetails?.detail?.audienceTargetting?.interest
                        ?.relationshipGender || [],
                relationshipType:
                    campaignDetails?.detail?.audienceTargetting?.interest
                        ?.relationshipType || [],
                school:
                    campaignDetails?.detail?.audienceTargetting?.interest?.school || [],
                shoppingAndFashion:
                    campaignDetails?.detail?.audienceTargetting?.interest
                        ?.shoppingAndFashion || [],
                sportsAndOutdoors:
                    campaignDetails?.detail?.audienceTargetting?.interest
                        ?.sportsAndOutdoors || [],
                technology:
                    campaignDetails?.detail?.audienceTargetting?.interest?.technology ||
                    [],
                undergradYears:
                    campaignDetails?.detail?.audienceTargetting?.interest
                        ?.undergradYears || [],
                work: campaignDetails?.detail?.audienceTargetting?.interest?.work || [],
                workEmployer:
                    campaignDetails?.detail?.audienceTargetting?.interest?.workEmployer ||
                    "",
                workJobTitle:
                    campaignDetails?.detail?.audienceTargetting?.interest?.workJobTitle ||
                    [],
            },
            contextual: campaignDetails?.detail?.audienceTargetting?.contextual || [],
        },
        competitorLinks: campaignDetails?.detail?.competitorLinks || "",
        targettingType: campaignDetails?.detail?.targettingType || [],
        audienceNames: campaignDetails?.detail?.audienceNames || "",
        keywordListLink: campaignDetails?.detail?.keywordListLink || "",
        geography: campaignDetails?.detail?.geography || "",
        location: campaignDetails?.detail?.location || [],
        radius: campaignDetails?.detail?.radius || [],
        devices: campaignDetails?.detail?.devices || [],
        language: campaignDetails?.detail?.language || "",
        tactics: campaignDetails?.detail?.tactics || "",
        dayPartingRequirements:
            campaignDetails?.detail?.dayPartingRequirements || "",
        frequencyCapping: campaignDetails?.detail?.frequencyCapping || "",
        optimizationExclusions:
            campaignDetails?.detail?.optimizationExclusions || "",
        // Remaining fields
        inventoryRestrictions: campaignDetails?.detail?.inventoryRestrictions || [],
        whitelist: campaignDetails?.detail?.whitelist || "",
        blacklist: campaignDetails?.detail?.blacklist || "",
        creativeUnit: campaignDetails?.detail?.creativeUnit || [],
        linkToAdServing: campaignDetails?.detail?.linkToAdServing || "",
        creativeSize: campaignDetails?.detail?.creativeSize || [],
        numberOfCreativeExecutions:
            campaignDetails?.detail?.numberOfCreativeExecutions || "",
        canPixelLandingPage: campaignDetails?.detail?.canPixelLandingPage || "",
        googleTagManager: campaignDetails?.detail?.googleTagManager || "",
        numUniquePageViews: campaignDetails?.detail?.numUniquePageViews || "",
        socialPage: campaignDetails?.detail?.socialPage || "",
        briefDueDate: campaignDetails?.detail?.briefDueDate || "",
        specialRequirements: campaignDetails?.detail?.specialRequirements || "",
        numberOfReports: campaignDetails?.detail?.numberOfReports || "",
        bookingEngineName: campaignDetails?.detail?.bookingEngineName || "",
        analytics: campaignDetails?.detail?.analytics || "",
        partnerWithCompeting: campaignDetails?.detail?.partnerWithCompeting || "",
        specialTargetRequest: campaignDetails?.detail?.specialTargetRequest || "",
        extraNotes: campaignDetails?.detail?.extraNotes || "",
        addedOn: campaignDetails?.detail?.addedOn || "",
        updatedOn: campaignDetails?.detail?.updatedOn || "",
        status: campaignDetails?.detail?.status || "",
    },
});

export const commentInitialValue = {comment: ""};

export const fileUploadInitialValue = {files: []};

export const dv360AdUploadInitialValues = {
    adName: "",
    description: "",
    displayName: "",
    creativeType: "",
    hostingSource: "",
    imageURL: [],
};

export const pinterestAdUploadInitialValues = {
  adName: "",
  title: "",
  description: "",
  link: "",
  displayName: "",
  creativeType: "",
  hostingSource: "",
  imageURL: [],
};

export const dv360AudioAdUploadInitialValues = {
    adName: "",
    landingPageURL: "",
    audioFiles: [],
    audioThumbnail: [],
};

// dv360 video initial value
export const dv360AdVideoInitialValue = {
    adName: "",
    youtubeURL: "",
    landingPageURL: "",
}

export const pinterestVideoInitialValue = {
  adName: "",
  title: "",
  description: "",
  link: "",
  landingPageURL: "",
};

// dv360 display video initial value
export const dv360AdDisplayVideoInitialValue = {
    adName: "",
    fileURL: "",
    landingPageURL: "",
}

export const dv360ModelInitialValues = (data) => ({
    campaignName: data?.name || "",
    month: "",
    isLead: false,
    adCategory: "",
    targetingMethod: "",
    creativeType: "",
    location: "",
    audience: "",
    campaignGoalType: "",
    startDate: data?.start_date || "",
    endDate: data?.end_date || "",
    performanceGoalType: "",
    performanceGoalAmountMicros: "",
    performanceGoalPercentageMicros: "",
    performanceGoalString: "",
});

export const facebookAdUploadInitialValues = {
    adName: "",
    primaryText: "",
    description: "",
    link: "",
    type: "",
    thumbnailURL: null,
    thumbnailFile: null,
};

export const facebookVideoAdInitialValue = {
    adName: "",
    primaryText: "",
    headline: "",
    description: "",
    videoURL: null,
    videoFile: null,
    thumbnailURL: null,
    thumbnailFile: null,
    landingPageURL: "",
    facebookAccountId: "",
}

export const instagramImageAdInitialValue = {
    adName: "",
    primaryText: "",
    link: "",
    type: "",
    thumbnailURL: null,
    thumbnailFile: null,
}


export const googleCSVInitialValue = {
    adName: "",
    siteLink: "",
    extension: "",
    master: ""
}

export const instagramVideoAdInitialValue = {
    adName: "",
    primaryText: "",
    link: "",
    type: "",
    thumbnailURL: null,
    thumbnailFile: null,
    videoURL: null,
    videoFile: null,
}

export const tiktokVideoInitialValue = {
  adName: "",
  displayName: "",
  description: "",
};

export const instagramCarouselInitialValues = {
    adName: "",
    link: "",
    type: "",
    carouselCards: []
};

export const pinterestCarouselInitialValues = {
    adName: "",
    description: "",
    link: "",
    carouselCards: []
};

export const instagramCarouselFileInitialValues = {
    name: "",
    description: "",
    link: "",
    fileType: "",
    imageURL: null,
    videoURL: null,
    imageFile: null,
    videoFile: null,
};

export const pinterestCarouselFileInitialValues = {
    fileType: "",
    imageURL: null,
    imageFile: null,
};

export const facebookVideoAdValueLengths = {
    adName: 512,
    primaryText: 125,
    headline: 32,
    description: 125,
    landingPageURL: 255,
};

export const facebookCarouselAdUploadInitialValues = {
    adName: "",
    primaryText: "",
    headline: "",
    description: "",
    landingPageURL: "",
    facebookAccountId: "",
    carouselCards: []
};

export const facebookCarouselFileInitialValues = {
    fileType: "",
    imageURL: null,
    thumbnailURL: null,
    videoURL: null,
    headline: "",
    description: "",
    landingPageURL: "",
    imageFile: null,
    videoFile: null,
    thumbnailFile: null,
};

export const facebookModelInitialValues = (data) => ({
    campaignName: data?.name || "",
    month: "",
    isLead: false,
    category: "",
    targetingMethod: "",
    creativeType: "",
    location: "",
    segment: "",
});

export const fbAdSetInitialValue = (data) => ({
    campaignName: data?.detail?.campaignName || "",
    isLead: data?.detail?.lead.trim() ? true : false,
    adCategory: data?.detail?.adCategory || "",
    targetingMethod: data?.detail?.targetingMethod || "",
    location: data?.detail?.location || "",
    audience: data?.detail?.audience || "",
    promo: "",
    device: "",
    creativeType: data?.detail?.creativeType || "",
    adName: "",
    bidAmount: "",
    lifetimeBudget: "",
    startTime: "",
    endTime: "",
    optimizationGoal: "",
    pixelId: "",
    eventType: "",
});

export const dv360AdUploadalueLengths = {
    adName: 512,
    mainAsset: 128,
    landingPage: 1024,
};

export const pinterestAdUploadvalueLengths = {
  adName: 100,
  description: 500,
  link: 255,
  mainAsset: 128,
  landingPage: 1024,
};

export const tiktokAdUploadValueLengths = {
    adName: 100,
    displayName: 100,
    description: 500,
    link: 255,
    mainAsset: 128,
    landingPage: 1024,
};

export const googleCsvLengths = {
    adName: 512
}

export const googleFormLenght = {
    heading: 30,
    description: 90
}

export const fbAdUploadValueLengths = {
    adName: -1,
    primaryText: 125,
    headline: 27,
    description: 125,
    url: 255,
};

export const fbCarouselAdUploadValueLengths = {
    adName: 512,
    primaryText: 125,
    headline: 32,
    description: 18,
    url: 255,
};

export const facebookAdSets = (fbAd) => ({
    adSetId: fbAd?.id || "",
    imageHash: "",
    requireTracking: "",
    adCategory: fbAd?.detail?.adCategory || "",
    adName: fbAd?.detail?.adName || "",
    adSize: "",
    audience: fbAd?.detail?.audience || "",
    campaignName: fbAd?.detail?.campaignName || "",
    creativeType: fbAd?.detail?.creativeType || "",
    device: fbAd?.detail?.device || "",
    isLead: fbAd?.detail?.lead?.trim() ? true : false,
    location: fbAd?.detail?.location || "",
    promo: fbAd?.detail?.promo || "",
    targetingMethod: fbAd?.detail?.targetingMethod || "",
});

