import { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Image,
  Stack,
  Text,
  useToast,
  VStack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Checkbox,
  Button,
  Icon,
} from "@chakra-ui/react";
import axios from "axios";
import { Form, Formik, Field } from "formik";
import { SubmitButton } from "formik-chakra-ui";
import validationSchema from "../../validations/Login/Login";
import { useRecoilState } from "recoil";
import { profile } from "../../atoms/authAtom";
import Loading from "../../components/Loading";
import "./floatingStyle.css";
import { loginInitialValue } from "./constant/InitialValues";
import { Link } from "react-router-dom";
import { FaGoogle, FaMicrosoft } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { loginRequest } from "../../authConfig";
import { useMsal } from "@azure/msal-react";
import AuthLayout from "../../layout/AuthLayout";

const FloatingLogin = () => {
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line
  const [profileData, setProfileData] = useRecoilState(profile);
  const { instance } = useMsal();

  const handleLogin = () => {
    instance
      .loginPopup(loginRequest)
      .then((res) => {
        if (res.account.username) {
          axios
            .post(`${process.env.REACT_APP_API_URL}/microsoft-login`, {
              email: res.account.username,
            })
            .then((res) => {
              toast({
                isClosable: true,
                status: "success",
                variant: "top-accent",
                position: "top-right",
                title: "Success",
                description: "You have been logged in",
              });
              const data = res.data.data.tokens;
              localStorage.setItem("jwt", data.jwt);
              localStorage.setItem("jwtRefresh", data.jwtRefresh);
              window.location = "/";
            })
            .catch((error) => {
              const errorMessage =
                error.response?.data?.data?.message || "Something went wrong!";
              toast({
                isClosable: true,
                status: "error",
                variant: "top-accent",
                position: "top-right",
                description: errorMessage,
              });
            });
        }
      })
      .catch((e) => console.log(e));
  };

  let toast = useToast();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    if (code) {
      handleGoogleLogin(code).then(() => setIsLoading(true));
    } else {
      loginUser();
    }
  }, [setProfileData]);

  function loginUser() {
    axios({
      method: "GET",
      baseURL: `${process.env.REACT_APP_API_URL}`,
      url: "/user/me",
      withCredentials: false,
    })
      .then((response) => {
        if (response.status === 200) {
          setProfileData(response.data.data.user);
          window.location = "/";
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
    return () => {
      setIsLoading(true);
    };
  }

  const handleGoogleLogin = async (code) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/token?code=${code}`
      );
      const data = response.data.data.tokens;
      localStorage.setItem("jwt", data.jwt);
      localStorage.setItem("jwtRefresh", data.jwtRefresh);
      toast({
        isClosable: true,
        status: "success",
        variant: "top-accent",
        position: "top-right",
        title: "Success",
        description: "You have been logged in",
      });
      window.location = "/";
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Something went wrong!";
      toast({
        isClosable: true,
        status: "error",
        variant: "top-accent",
        position: "top-right",
        description: errorMessage,
      });
      window.location = "/";
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    return await axios({
      method: "POST",
      baseURL: `${process.env.REACT_APP_API_URL}`,
      url: "/login",
      data: {
        email: values.email,
        password: values.password,
        remember_me: values.remember_me,
      },
      withCredentials: false,
    })
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem("jwt", response.data.data.tokens.jwt);
          localStorage.setItem(
            "jwtRefresh",
            response.data.data.tokens.jwtRefresh
          );
          toast({
            isClosable: true,
            status: "success",
            variant: "top-accent",
            position: "top-right",
            title: "Success",
            description: "You have been logged in",
          });
          window.location = "/";
        }
      })
      .catch((error) => {
        toast({
          isClosable: true,
          status: "error",
          variant: "top-accent",
          position: "top-right",
          description: error.response?.data?.message || "Something goes wrong!",
        });
      });
  };

  function oauthSignIn() {
    const oauth2Endpoint = "https://accounts.google.com/o/oauth2/v2/auth";

    const form = document.createElement("form");
    form.setAttribute("method", "GET");
    form.setAttribute("action", oauth2Endpoint);
    const params = {
      client_id: process.env.REACT_APP_CLIENT_ID,
      redirect_uri: `${process.env.REACT_APP_AUTH_REDIRECT_URL}`,
      response_type: "code",
      scope:
        "email profile https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/drive.metadata.readonly https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/datastudio openid",
      include_granted_scopes: "true",
      state: "pass-through value",
      access_type: "offline",
    };
    for (let p in params) {
      const input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", p);
      input.setAttribute("value", params[p]);
      form.appendChild(input);
    }

    document.body.appendChild(form);
    form.submit();
  }

  return isLoading ? (
    <Loading />
  ) : (
    <AuthLayout>
      <VStack alignItems="start">
        <Image
          src={`${process.env.PUBLIC_URL}/images/Kedet Logo.png`}
          height="80px"
        />
        <Text
          color="brand.primary"
          style={{ marginTop: "-16px" }}
          fontWeight="600"
        >
          Powered by War Room.
        </Text>
        <Stack spacing={4} py={12} w={"full"} maxW={"md"}>
          <Heading color={"#757998"} fontWeight="700" fontSize={"35px"}>
            Welcome Back.
          </Heading>
          <Text
            color="#A7A9BD"
            mt={0}
            mb={10}
            style={{ marginTop: "-1px" }}
            fontWeight="600"
          >
            Please sign in to your Kedet account to continue.
          </Text>

          <div style={{ marginTop: "36px" }}>
            <Formik
              initialValues={loginInitialValue}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                handleSubmit,
                dirty,
                errors,
                touched,
                isValid,
                isSubmitting,
                setFieldValue,
              }) => {
                return (
                  <VStack as={Form} w="full" alignItems="start">
                    <Stack w="full" spacing={3} autoComplete="off">
                      <FormControl variant="floating" isRequired isInvalid>
                        <Box className="login-controller">
                          <Field
                            as={Input}
                            name="email"
                            placeholder=" "
                            className="login-input"
                          />
                          <FormLabel htmlFor="email" className="login-label">
                            Email Address
                          </FormLabel>
                        </Box>
                        {touched.email && (
                          <FormErrorMessage>{errors.email}</FormErrorMessage>
                        )}
                      </FormControl>
                      <FormControl variant="floating" isRequired isInvalid>
                        <Box className="login-controller">
                          <Field
                            as={Input}
                            type="password"
                            name="password"
                            placeholder=" "
                            className="login-input"
                          />
                          <FormLabel htmlFor="password" className="login-label">
                            Password
                          </FormLabel>
                        </Box>
                        {touched.password && (
                          <FormErrorMessage>{errors.password}</FormErrorMessage>
                        )}
                      </FormControl>
                    </Stack>
                    <Box
                      width={"100%"}
                      display={"flex"}
                      alignItems="center"
                      justifyContent={"space-between"}
                      spacing={3}
                    >
                      <Box display={"flex"} alignItems="center" gap="2">
                        <Checkbox
                          name="remember_me"
                          id="remember_me"
                          onChange={(e) =>
                            setFieldValue("remember_me", e.target.checked)
                          }
                        />
                        <FormLabel
                          htmlFor="remember_me"
                          margin="0"
                          cursor="pointer"
                          className="login-label"
                        >
                          Remember Me
                        </FormLabel>
                      </Box>
                      <Box display={"flex"} alignItems="center" gap="2">
                        <FormLabel margin="0">
                          <Link to="/forgot-password">
                            Forgot your password?
                          </Link>
                        </FormLabel>
                      </Box>
                    </Box>
                    <SubmitButton
                      disabled={!(dirty && isValid) || isSubmitting}
                      variant="solid"
                      colorScheme="blue"
                      backgroundColor={"brand.primary"}
                      borderRadius={0}
                      style={{ marginTop: "36px", width: "100%" }}
                      px="14"
                    >
                      Log In
                    </SubmitButton>
                    <Button
                      onClick={() => oauthSignIn()}
                      mt={2}
                      // style={{ width: "100%" }}
                      // colorScheme={"blue"}
                      // backgroundColor={"brand.primary"}
                      // borderRadius={0}
                      leftIcon={
                        <Icon as={FcGoogle} boxSize="6" color="#357EC7" />
                      }
                      w="full"
                      variant="outline"
                      color="black"
                      bg="white"
                    >
                      Continue with Google
                    </Button>
                    <Button
                      onClick={() => handleLogin()}
                      mt={2}
                      // style={{ width: "100%" }}
                      // colorScheme={"blue"}
                      // backgroundColor={"brand.primary"}
                      w="full"
                      variant="outline"
                      color="black"
                      bg="white"
                      leftIcon={
                        <Icon as={FaMicrosoft} boxSize="6" color="#357EC7" />
                      }
                    >
                      Continue with Microsoft Account
                    </Button>
                  </VStack>
                );
              }}
            </Formik>
          </div>
        </Stack>
      </VStack>
    </AuthLayout>
  );
};

export default FloatingLogin;
