import { useQuery } from "react-query";
import axios from "./../helpers/axios";

export const useNotification = (clientId, setIsLoading) => {
    return useQuery(["notification", clientId], async () => {
        return axios
            .get(`/client/${clientId}/notification`, {
                withCredentials: false,
            })
            .then((res) => {
                if (res.status === 200) {
                    setIsLoading(false)
                    return res.data.data;
                }
            })
            .catch((err) => {
                setIsLoading(false)
                return err.response;
            });
    });
};
