import "../css/desktopView.css"
import {useGlobalContext} from "../store/WrapContext";
import _ from "lodash";
import {useEffect, useState} from "react";

function DesktopPreview() {
    const {selectedData, siteLinks, extension, headline, description} = useGlobalContext()
    const {call, location, callout} = extension

    let randomHeadline = _.sampleSize(headline, 3)
    let randomDescription = _.sampleSize(description, 2)

    const [randomHeadlineState, setRandormHeadlineState] = useState(randomHeadline)
    const [randomDescriptionState, setRandomDescriptionState] = useState(randomDescription)

    useEffect(() => {
        setInterval(() => {
            setRandormHeadlineState(_.sampleSize(headline, 3))
            setRandomDescriptionState(_.sampleSize(description, 2))
        }, 5000)
    }, [])

    return (
        <div style={{display: "flex", justifyContent: "center", marginTop: "20px"}}>
            <div className="desktop">
                <div className="desktop-top">
                    <div className="desktop-top-header">
                        <b>Ad</b>
                        {
                            extension?.call.length > 0 && <span> Call 9377024392 </span>
                        }
                    </div>
                    <div className="header">
                        {
                            randomHeadline && randomHeadline.length > 0 && randomHeadlineState.map((item, index) => {
                                return <span className='fade' key={index}>{`${item} ${index > 1 ? "" : "|"} `}</span>
                            })
                        }
                    </div>
                    <div className="description">
                        {
                            randomDescriptionState && randomDescriptionState.length > 0 && randomDescriptionState.map((item, index) => {
                                    return (
                                        <span className='fade' key={index}>{item} </span>
                                    )
                                }
                            )
                        }
                        {
                            callout && callout.length > 0 && callout.map((item, index, el) => {
                                return <span key={index}>{item}{!(el.length === (index + 1)) ? "," : null}</span>
                            })
                        }
                        {
                            location && location.length > 0 && location.map((item, index, el) => {
                                return <span key={index}> {item}{!(el.length === (index + 1)) ? "," : null}</span>

                            })
                        }
                    </div>
                </div>
                {
                    siteLinks.length > 0 && (
                        <div className="sitelink">
                            <div className="sitelink-box">
                                {
                                    siteLinks.map((item) => {
                                        const {sitelink_text, sitelink_description_1, sitelink_description_2} = item
                                        return (
                                            <div className="box">
                                                <h2> {sitelink_text} </h2>
                                                <p> {sitelink_description_1} {sitelink_description_2} </p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>

                    )
                }
            </div>
        </div>
    )
}

export default DesktopPreview