import React, {useEffect, useMemo, useState} from "react";
import {
    Avatar,
    Button,
    Heading, HStack,
    Input,
    InputGroup,
    InputLeftElement,
    Stack, Text,
} from "@chakra-ui/react";
import {MdFileDownload} from "react-icons/md";
import {SearchIcon} from "@chakra-ui/icons";
import Datatable from "../../../components/Datatable/Datatable";
import {useGetAuditLogs} from "../../../hooks/campaign-briefs/useGetAuditLogs";
import {useParams} from "react-router-dom";
import "../style/AdUploadList.css";
import {useSelector} from "react-redux";
import moment from "moment";

const AuditLogsList = () => {
        const {id} = useParams();
        const clientId = useSelector((state) => state.client.clientId);
        const [search, setSearch] = useState();
        const [auditLogList, setAuditLogList] = useState();
        const {data, mutate} = useGetAuditLogs();

        useEffect(() => {
            mutate({
                clientId,
                campaignId: id,
            });
        }, []);

        useEffect(() => {
            setAuditLogList(data?.auditLogs);
        }, [data]);

        useEffect(() => {
            if (search?.trim()) {
                const searchedAuditLog = auditLogList.filter((el) => {
                    if (el?.user.name?.toLowerCase().includes(search.trim())) {
                        return true;
                    } else if (
                        el?.created_at?.toLowerCase().includes(search.trim())
                    ) {
                        return true;
                    } else if (el?.state?.toLowerCase().includes(search.trim())) {
                        return true;
                    }
                });
                setAuditLogList(searchedAuditLog);
            } else {
                setAuditLogList(data?.auditLogs);
            }
        }, [search]);

        const columns = useMemo(
            () => [
                {
                    Header: "User",
                    accessor: "user.name",
                    Cell: (data) => (
                        <HStack align="center" gap={2}>
                            {data?.row?.original?.user?.other_info
                                ?.profile_pic_url ? (
                                <Avatar
                                    w={12}
                                    h={12}
                                    size="sm"
                                    src={`${process.env.REACT_APP_API_URL}/uploads/${data.row.original.user.other_info.profile_pic_url}`}
                                    name={data.row.original.user.name}
                                />
                            ) : (
                                <Avatar
                                    w={12}
                                    h={12}
                                    size="sm"
                                    name={data.row.original.user.name}
                                />
                            )}
                            <Text fontSize={16} fontWeight={600} color="#757998">{data.row.original.user.name}</Text>
                        </HStack>
                    ),
                },
                {
                    Header: "Timestamp",
                    accessor: "created_at",
                    Cell: (data) => {
                        const createdAt = data.row.original.created_at;
                        var a = moment(createdAt, "GMT");
                        return (
                            <>
                                <div style={{fontWeight: "500", color: "#757998"}}>
                                    {moment(createdAt).format('h:mm:ss A MMMM DD YYYY')}
                                    <br/>
                                    <p style={{marginTop: "5px"}}>
                                        GMT-{a.format("hh:mm:ss")}
                                    </p>
                                </div>
                            </>
                        );
                    },
                },
                {
                    Header: "Details",
                    accessor: "ad-upload-type",
                    Cell: (data) => {
                        const name = data?.row?.original?.content?.name
                        const status = data?.row?.original?.content?.status

                        return (
                            <>
                                {status === 'created' &&
                                <p style={{fontWeight:600,fontFamily:"poppins",color:"#757998"}}> Created <span style={{color:'#3B82F6'}}> campaign - Campaign Name {name}</span></p>
                                }
                                {status === 'updated' &&
                                <p style={{fontWeight:600,fontFamily:"poppins",color:"#757998"}}> Updated <span style={{color:'#3B82F6'}}> campaign - Campaign Name {name} </span> detail</p>
                                }
                            </>
                        )
                    }
                }
            ], []);

        return (
            <>
                <div className="ad-upload-list">
                    <Datatable data={auditLogList || []} columns={columns} headerColor={"#3B82F6"} auditTrue={true}/>
                </div>
            </>
        );
    }
;

export default AuditLogsList;
