import {useMutation, useQuery} from "react-query";
import axios from "../../helpers/axios";

export const useGetSheetData = () => {
    return useQuery(['sheets'], async () => {
        return axios
            .get('/spreadsheet-config', {
                withCredentials: false,
            })
            .then((res) => {
                if (res.status === 200) {
                    return res.data
                }
            })
            .catch((err) => {
                return err.response
            })
    })
}