import { createSlice } from "@reduxjs/toolkit";
import {AD_UPLOAD_TYPE} from "../../../../../../../../constant";

const initialState = {
    formDetails: {},
    mediaDetails: [],
    handlePreviewData: null,
};

export const facebookImageSlice = createSlice({
    name: AD_UPLOAD_TYPE.FACEBOOK,
    initialState,
    reducers: {
        settingFacebookImageFormDetail: (state, action) => {
            state.formDetails = action.payload
        },
        settingFacebookImageFormMedia: (state, action) => {
            state.mediaDetails = action.payload
        },
        settingFacebookImagePreviewData: (state, action) => {
            state.handlePreviewData = action.payload
        },
        deleteAllFacebookImageField: (state, action) => {
            state.formDetails = {};
            state.mediaDetails = [];
            state.handlePreviewData = null
        }
    },
});

export const { settingFacebookImageFormDetail, settingFacebookImageFormMedia, settingFacebookImagePreviewData, deleteAllFacebookImageField} = facebookImageSlice.actions;

// The reducer
export const facebookImageReducer = facebookImageSlice.reducer;
