import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetAdUpload } from "../../../../../../../hooks/campaign-briefs/useGetAdUpload";
import { Form, FormikProvider, useFormik } from "formik";
import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  css,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
} from "@chakra-ui/react";
import { InputControl, SelectControl, TextareaControl } from "formik-chakra-ui";
import {
  dv360AdUploadalueLengths,
  dv360AdUploadInitialValues,
  facebookVideoAdValueLengths,
} from "../../../../../constant/InitialValues";
import {
  CreativeType,
  facebookAccountIds,
  hostingSource,
} from "../../../../../constant/SelectValues";
import * as yup from "yup";
import { Flex } from "@chakra-ui/layout";
import {
  settingEditMediaType,
  settingFormDetails,
  settingFormMedia,
} from "../../../../../../../store/adUploads";
import { TEXT_COLOR } from "../../../../../../../layout/constant/MenuList";
import {
  settingFacebookImageFormDetail,
  settingFacebookImageFormMedia,
} from "../FacebookImage/store";
import {
  settingFacebookVideoFormDetail,
  settingFacebookVideoFormMedia,
} from "./store";
import { UploadVideoPreview } from "../../../Facebook/video/UploadFiles/UploadVideoPreview";
import { UploadThumbnailPreview } from "../../../Facebook/video/UploadFiles/UploadThumbnailPreview";
import TooltipComponent from "../../TooltipComponent";

function FacebookVideoAdDetails({
  activeStep,
  steps,
  prevStep,
  nextStep,
  label,
}) {
  const { id, adUploadId } = useParams();

  const clientId = useSelector((state) => state.client.clientId);
  const { formDetails } = useSelector((state) => state.facebookVideoAd);

  const { data, refetch } = useGetAdUpload(clientId, id, adUploadId);

  const initialValue = {
    adName: "",
    primaryText: "",
    description: "",
    headline: "",
    landingPageURL: "",
    facebookAccountId: "",
  };

  useEffect(() => {
    if (adUploadId) {
      refetch();
    }
  }, [adUploadId]);

  const schema = yup.object().shape({
    adName: yup.string().trim().min(3).max(512).required().label("Ad name"),
    primaryText: yup
      .string()
      .trim()
      .min(3)
      .max(125)
      .required()
      .label("Primary text"),
    headline: yup.string().trim().min(3).max(32).required().label("Headline"),
    description: yup
      .string()
      .trim()
      .min(3)
      .max(125)
      .required()
      .label("Description"),
    facebookAccountId: yup.string().required().label("Type"),
    landingPageURL: yup
      .string()
      .trim()
      .required("Landing page url is required")
      .max(255)
      .matches(
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
        "Landing page url is not valid!"
      )
      .label("Landing page url"),
  });

  useEffect(() => {
    if (adUploadId) {
      dispatch(
        settingFacebookVideoFormDetail({
          ...data?.adUpload?.detail,
          adName: data?.adUpload?.name,
          description: data?.adUpload?.detail?.link_description,
          primaryText: data?.adUpload?.description,
          headline: data?.adUpload?.detail?.headline,
          landingPageURL: data?.adUpload?.detail?.call_to_action?.value?.link,
          facebookAccountId: data?.adUpload?.detail?.call_to_action?.type,
        })
      );
      dispatch(
        settingFacebookVideoFormMedia({
          videoURL: data?.adUpload?.detail?.videoFile,
          thumbnailURL: data?.adUpload?.detail?.thumbnailFile,
          thumbnailFile: data?.adUpload?.detail?.image_url,
          videoFile: data?.adUpload?.detail?.video_id,
        })
      );
    }
  }, [data]);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: schema,
    onSubmit: (values) => {
      dispatch(
        settingFacebookVideoFormDetail({ ...values, ...data?.adUpload?.detail })
      );
      nextStep();
    },
  });

  const {
    errors,
    values,
    touched,
    handleSubmit,
    isValid,
    handleBlur,
    setFieldValue,
  } = formik;

  useEffect(() => {
    setFieldValue("adName", formDetails.adName || "");
    setFieldValue("primaryText", formDetails.primaryText || "");
    setFieldValue("headline", formDetails.headline || "");
    setFieldValue("description", formDetails.description || "");
    setFieldValue("landingPageURL", formDetails.landingPageURL || "");
    setFieldValue("type", formDetails.type || "");
    setFieldValue("facebookAccountId", formDetails.facebookAccountId || "");
  }, [formDetails]);

  return (
    <>
      {adUploadId && (
        <Box
          display="flex"
          mb={6}
          justifyContent="space-between"
          alignItems="center"
        >
          <Heading fontSize="xl" color={"#757998"}>
            Please review the ad details carefully.
          </Heading>
          <Heading fontSize="md" color="gray.400">
            3 steps to complete
          </Heading>
        </Box>
      )}

      {!adUploadId && (
        <Box
          display="flex"
          mb={6}
          justifyContent="space-between"
          alignItems="center"
        >
          <Heading fontSize="xl" color={"#757998"}>
            Please fill in your ad details.
          </Heading>
          <Heading fontSize="md" color="gray.400">
            3 steps to complete
          </Heading>
        </Box>
      )}

      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <Grid gap={4} className="fb-upload">
            <GridItem w="full" colSpan={{ base: 6, lg: 4 }}>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel
                    display={"none"}
                    htmlFor="adName"
                    color="gray"
                    fontSize="sm"
                  >
                    Ad Name - max 512 characters
                  </FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="adName"
                      name="adName"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "Ad Name - max 512 characters",
                      }}
                    />
                    <TooltipComponent
                      text={
                        "The ad name helps you distinguish between ads and won’t appear in the final ad. Pick something memorable but relevant to your ad."
                      }
                    />

                    <CircularProgress
                      max={facebookVideoAdValueLengths?.adName}
                      value={values?.adName?.length}
                      color={
                        values?.adName?.length >
                        facebookVideoAdValueLengths?.adName
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values?.adName?.length >
                        facebookVideoAdValueLengths?.adName
                          ? facebookVideoAdValueLengths?.adName -
                            values?.adName?.length
                          : values?.adName?.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>

              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="primaryText" display={"none"}>
                    Primary Text - max 125 characters
                  </FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="primaryText"
                      name="primaryText"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "Primary Text - max 125 characters",
                      }}
                    />
                    <CircularProgress
                      max={facebookVideoAdValueLengths?.primaryText}
                      value={values?.primaryText?.length}
                      color={
                        values?.primaryText?.length >
                        facebookVideoAdValueLengths?.primaryText
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values?.primaryText?.length >
                        facebookVideoAdValueLengths?.primaryText
                          ? facebookVideoAdValueLengths?.primaryText -
                            values?.primaryText?.length
                          : values?.primaryText?.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="headline" display={"none"}>
                    Headline - max 32 characters
                  </FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="headline"
                      name="headline"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: " Headline - max 32 characters",
                      }}
                    />
                    <CircularProgress
                      max={facebookVideoAdValueLengths?.headline}
                      value={values?.headline?.length}
                      color={
                        values?.headline?.length >
                        facebookVideoAdValueLengths?.headline
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values?.headline?.length >
                        facebookVideoAdValueLengths?.headline
                          ? facebookVideoAdValueLengths?.headline -
                            values?.headline?.length
                          : values?.headline?.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="description" display={"none"}>
                    Description - max 18 characters
                  </FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="description"
                      name="description"
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "Description - max 18 characters",
                      }}
                    />
                    <CircularProgress
                      max={facebookVideoAdValueLengths?.description}
                      value={values?.description?.length}
                      color={
                        values?.description?.length >
                        facebookVideoAdValueLengths?.description
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values?.description?.length >
                        facebookVideoAdValueLengths?.description
                          ? facebookVideoAdValueLengths?.description -
                            values?.description?.length
                          : values?.description?.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="landingPageURL" display={"none"}>
                    Landing Page URL - max 255 characters
                  </FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="landingPageURL"
                      name="landingPageURL"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: " Landing Page URL - max 255 characters",
                      }}
                    />
                    <CircularProgress
                      max={facebookVideoAdValueLengths?.landingPageURL}
                      value={values?.landingPageURL?.length}
                      color={
                        values?.landingPageURL?.length >
                        facebookVideoAdValueLengths?.landingPageURL
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values?.landingPageURL?.length >
                        facebookVideoAdValueLengths?.landingPageURL
                          ? facebookVideoAdValueLengths?.landingPageURL -
                            values?.landingPageURL?.length
                          : values?.landingPageURL?.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="facebookAccountId">
                    Call to Action
                  </FormLabel>
                  <div className="input-box">
                    <SelectControl
                      id="facebookAccountId"
                      name="facebookAccountId"
                      selectProps={{
                        placeholder: "-- Select One --",
                        variant: "outline",
                        border: "2px",
                        borderRadius: 0,
                        borderColor: "gray",
                        fontWeight: "600",
                        fontSize: "14px",
                        lineHeight: "16px",
                        color: "#757998",
                        marginRight: "100px",
                      }}
                      onChange={(e) => {
                        setFieldValue("facebookAccountId", e.target.value);
                      }}
                    >
                      {facebookAccountIds.map((el) => (
                        <option value={el.key} key={el.key}>
                          {el.name}
                        </option>
                      ))}
                    </SelectControl>
                    <CircularProgress opacity={0}>
                      <CircularProgressLabel>0</CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
            </GridItem>
          </Grid>
          <Flex width="100%" justify="flex-end" py={10}>
            {!adUploadId && (
              <Button
                mr={4}
                onClick={prevStep}
                colorScheme="blue"
                variant="outline"
                borderRadius={0}
                px={10}
              >
                Back
              </Button>
            )}

            {label === "Ad Details" && !(activeStep === steps.length - 1) && (
              <Button
                mr={4}
                colorScheme="green"
                type="submit"
                variant="solid"
                borderRadius={0}
                px={10}
              >
                Next
              </Button>
            )}
          </Flex>
        </Form>
      </FormikProvider>
    </>
  );
}

export default FacebookVideoAdDetails;
