import React, { useState, useEffect } from "react";
import {
  Center,
  css,
  Flex,
  Grid,
  GridItem,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  Box,
  Spinner,
  Button,
} from "@chakra-ui/react";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import { TEXT_COLOR } from "../../../../../layout/constant/MenuList";
import "../../../style/FacebookAdUpload.css";
import DV360AdDetails from "./DV360AdDetails";
import DV360AdPreview from "./DV360AdPreview";
import { useGetAdUpload } from "../../../../../hooks/campaign-briefs/useGetAdUpload";
import { Comments } from "./Comments";
import { useSelector } from "react-redux";
import AuditLogsList from "../AuditLogsList";
import FullPageLoader from "../../../../../components/FullPageLoader";
import WithAppContext from "../../../../../helpers/appContext";
import { ArrowBackIcon } from "@chakra-ui/icons";

const DV360AdUpload = ({ context }) => {
  const [images, setImages] = useState();
  const [method, setMethod] = useState();
  const [url, setUrl] = useState();
  const [previewData, setPreviewData] = useState();
  const [tabIndex, setTabIndex] = useState(0);

  const navigate = useNavigate();
  const { id, dv360Id } = useParams();
  const clientId = useSelector((state) => state.client.clientId);

  const { data, refetch } = useGetAdUpload(clientId, id, dv360Id);

  const { appLoading } = context;
  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (dv360Id) {
      setMethod("PUT");
      setUrl(`/client/${clientId}/campaign-brief/${id}/ad-upload/${dv360Id}`);
    } else {
      setMethod("POST");
      setUrl(`/client/${clientId}/campaign-brief/${id}/ad-upload`);
    }
  }, [dv360Id, clientId]);

  return (
    <>
      {appLoading && <FullPageLoader />}

      <Grid templateColumns="repeat(6, 1fr)" gap={4} className="fb-upload">
        <GridItem w="full" colSpan={{ base: 6, lg: 6 }} mb={{ base: 3, lg: 0 }}>
          <Heading fontSize="sm" mb={7} color={TEXT_COLOR}>
            <Flex
              onClick={() => navigate(`/campaign-briefs/${id}`)}
              cursor={"pointer"}
            >
              <Button
                color="brand.primary"
                variant="outline"
                fontFamily={"poppins"}
                fontSize={14}
                lineHeight={21}
                leftIcon={<ArrowBackIcon />}
              >
                Back
              </Button>
            </Flex>
          </Heading>
          <Heading fontSize="4xl" mb={4} color={TEXT_COLOR}>
            DV360 Ad Upload
          </Heading>
          <Tabs
            index={tabIndex}
            size="sm"
            w="full"
            colorScheme="black"
            overflow="hidden"
            onChange={(index) => setTabIndex(index)}
          >
            <TabList
              overflowX="auto"
              css={css({
                scrollbarWidth: "none",
                "::-webkit-scrollbar": { display: "none" },
                WebkitOverflowScrolling: "touch",
                boxShadow: "inset 0 -2px 0 rgba(0, 0, 0, 0.1)",
                border: "0 none",
              })}
            >
              <Tab whiteSpace="nowrap">Details</Tab>
              <Tab whiteSpace="nowrap" isDisabled={images?.images?.length < 1}>
                Preview
              </Tab>
              <Tab whiteSpace="nowrap" isDisabled={!dv360Id}>
                Comments
              </Tab>
              <Tab whiteSpace="nowrap">Audit Logs</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <DV360AdDetails
                  handlePreviewData={setPreviewData}
                  data={data?.adUpload}
                  url={url}
                  method={method}
                  clientId={clientId}
                  campaignId={id}
                  adUploadId={dv360Id}
                  tabIndex={tabIndex}
                  setTabIndex={setTabIndex}
                />
              </TabPanel>
              <TabPanel>
                <DV360AdPreview data={previewData} />
              </TabPanel>
              <TabPanel>
                <Comments
                  clientId={clientId}
                  campaignId={id}
                  dv360AdId={dv360Id}
                />
              </TabPanel>
              <TabPanel>
                <AuditLogsList />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </GridItem>
      </Grid>
    </>
  );
};

export default WithAppContext(DV360AdUpload);
