import { SearchIcon } from "@chakra-ui/icons";
import { Box, HStack } from "@chakra-ui/layout";
import { Button, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import React from "react";
import { IoMdAddCircle } from "react-icons/io";

const SearchAndButton = ({ buttonText, btnClick, setSearch }) => {
  return (
    <HStack
      mb="4"
      flexDir={{
        base: "column-reverse",
        md: "row",
      }}
      align={{
        base: "end",
        md: "center",
      }}
      spacing={4}
    >
      <InputGroup>
        <InputLeftElement
          pointerEvents="none"
          children={<SearchIcon color="gray.300" />}
        />
        <Input
          name="search"
          type="tel"
          placeholder="Search"
          onChange={setSearch}
        />
      </InputGroup>

      <Box>
        <Button
          size={{
            base: "sm",
            md: "md",
          }}
          rightIcon={<IoMdAddCircle />}
          onClick={btnClick}
        >
          {buttonText}
        </Button>
      </Box>
    </HStack>
  );
};

export default SearchAndButton;
