import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,
  GridItem,
  FormControl,
  FormLabel,
  Select,
  Grid,
  Button,
} from "@chakra-ui/react";
import { Input } from "@chakra-ui/input";
import React, { useState } from "react";
import { CampaignInventoryRestriction } from "../../constant";
import { useDispatch, useSelector } from "react-redux";
import { settingMediaStrategyDetails } from "../../store/adCampaign";

function MediaStrategyDialog({ onClose, isOpen }) {
  const { mediaStrategyDetails } = useSelector((state) => state.adCampaign);
  const [mediaSettings, setMediaSettings] = useState({
    tactics: mediaStrategyDetails?.tactics || "",
    // restrictions: mediaStrategyDetails?.restrictions || '',
    placement: mediaStrategyDetails?.placement || "",
    dayPartingRequirements: mediaStrategyDetails?.dayPartingRequirements || "",
  });

  const dispatch = useDispatch();

  function handleMediaSettings() {
    dispatch(settingMediaStrategyDetails(mediaSettings));
    onClose();
  }

  return (
    <>
      <Modal onClose={onClose} isOpen={isOpen} isCentered size="5xl">
        <ModalOverlay />
        <ModalContent mx={{ base: "4", md: "0" }}>
          <ModalHeader>Advanced Settings</ModalHeader>
          <ModalCloseButton />
          <ModalBody fontFamily={"poppins"}>
            <Grid
              templateColumns={{
                base: "repeat(1, 1fr)",
                lg: "repeat(2, 1fr)",
              }}
              gap={6}
            >
              <GridItem>
                <FormControl>
                  <FormLabel>Tactics</FormLabel>
                  <Input
                    placeholder="Tactics"
                    value={mediaSettings.tactics}
                    onChange={(e) =>
                      setMediaSettings({
                        ...mediaSettings,
                        tactics: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </GridItem>
              {/*<GridItem>*/}
              {/*    <FormControl isRequired>*/}
              {/*        <FormLabel>Restrictions</FormLabel>*/}
              {/*        <Select*/}
              {/*            id="detail.objective"*/}
              {/*            name="detail.objective"*/}
              {/*            placeholder="Select"*/}
              {/*            variant="outline"*/}
              {/*            border="2px"*/}
              {/*            borderRadius={0}*/}
              {/*            borderColor="gray"*/}
              {/*            value={mediaSettings.restrictions}*/}
              {/*            onChange={(e) => setMediaSettings({*/}
              {/*                ...mediaSettings,*/}
              {/*                restrictions: e.target.value*/}
              {/*            })}*/}
              {/*        >*/}
              {/*            {CampaignInventoryRestriction.map((objective) => (*/}
              {/*                <option key={objective.label} value={objective.value}>*/}
              {/*                    {objective.label}*/}
              {/*                </option>*/}
              {/*            ))}*/}
              {/*        </Select>*/}
              {/*    </FormControl>*/}
              {/*</GridItem>*/}
              <GridItem>
                <FormControl>
                  <FormLabel>Placement</FormLabel>
                  <Input
                    placeholder="Placement"
                    value={mediaSettings.placement}
                    onChange={(e) =>
                      setMediaSettings({
                        ...mediaSettings,
                        placement: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>Day Parting Requirements</FormLabel>
                  <Input
                    placeholder="Day Parting Requirements"
                    value={mediaSettings.dayPartingRequirements}
                    onChange={(e) =>
                      setMediaSettings({
                        ...mediaSettings,
                        dayPartingRequirements: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </GridItem>
            </Grid>
          </ModalBody>
          <ModalFooter>
            <Button
              _hover={{ bg: "" }}
              color={"#FFFFFF"}
              bgColor={"#1F95FF"}
              border={"2px"}
              fontFamily={"poppins"}
              borderColor="#1F95FF"
              px={8}
              py={5}
              onClick={handleMediaSettings}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default MediaStrategyDialog;
