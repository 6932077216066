import { Step, Steps, useSteps } from 'chakra-ui-steps';
import React, {useEffect, useState} from "react";
import SocialPlatform from "./StepByStepAdUploadComponent/SocialPlatform";
import AdUploadFormStepButton from "./StepByStepAdUploadComponent/AdUploadFormStepButton";
import SocialMediaPlatformType from "./StepByStepAdUploadComponent/SocialMediaPlatformType";
import AdDetailsForm from "./StepByStepAdUploadComponent/AdDetailsForm";
import AdMediaForm from "./StepByStepAdUploadComponent/AdMediaForm";
import AdPreview from "./StepByStepAdUploadComponent/AdPreview";
import {useSelector} from "react-redux";

function SelectAdType({SetTabIndex}) {

    const { nextStep, prevStep, reset, activeStep, setStep } = useSteps({
        initialStep: 0,
    });

    const totalStep = [1,2,3,4,5];
    const totalStepForDV360YoutubeStep = [1,2,3,4];

    const {mediaType} =  useSelector((state) => state.adUpload)


    const steps = [
        { label: "Social Platform", content: <SocialPlatform activeStep={activeStep} steps={totalStep} prevStep={prevStep} nextStep={nextStep} label="Social Platform" /> },
        { label: "Ad Type", content: <SocialMediaPlatformType activeStep={activeStep} steps={totalStep} prevStep={prevStep} nextStep={nextStep} label="Ad Type"/> },
        { label: "Ad Details", content: <AdDetailsForm activeStep={activeStep} steps={totalStep} prevStep={prevStep} nextStep={nextStep} label="Ad Details"/> },
        { label: "Ad Media", content: <AdMediaForm activeStep={activeStep} steps={totalStep} prevStep={prevStep} nextStep={nextStep} label="Ad Media" /> },
        { label: "Client Detail 5", content: <AdPreview SetTabIndex={SetTabIndex}  activeStep={activeStep} steps={totalStep} prevStep={prevStep} nextStep={nextStep} label="Ad Media" />},
        { label: "Empty", content: <h1>Completed</h1>},

    ];

    const DV360YoutubeStep = [
        { label: "Social Platform", content: <SocialPlatform activeStep={activeStep} steps={totalStepForDV360YoutubeStep} prevStep={prevStep} nextStep={nextStep} label="Social Platform" /> },
        { label: "Ad Type", content: <SocialMediaPlatformType activeStep={activeStep} steps={totalStepForDV360YoutubeStep} prevStep={prevStep} nextStep={nextStep} label="Ad Type"/> },
        { label: "Ad Details", content: <AdDetailsForm activeStep={activeStep} steps={totalStepForDV360YoutubeStep} prevStep={prevStep} nextStep={nextStep} label="Ad Details"/> },
        { label: "Client Detail 5", content: <AdPreview SetTabIndex={SetTabIndex}  activeStep={activeStep} steps={totalStepForDV360YoutubeStep} prevStep={prevStep} nextStep={nextStep} label="Ad Media" />},
        { label: "Empty", content: <h1>Completed</h1>},
    ]

    const Google = [
        { label: "Social Platform", content: <SocialPlatform activeStep={activeStep} steps={totalStepForDV360YoutubeStep} prevStep={prevStep} nextStep={nextStep} label="Social Platform" /> },
        { label: "Ad Type", content: <SocialMediaPlatformType activeStep={activeStep} steps={totalStepForDV360YoutubeStep} prevStep={prevStep} nextStep={nextStep} label="Ad Type"/> },
        { label: "Ad Details", content: <AdDetailsForm SetTabIndex={SetTabIndex} activeStep={activeStep} steps={totalStepForDV360YoutubeStep} prevStep={prevStep} nextStep={nextStep} label="Ad Details"/> },
        { label: "Empty", content: <h1>Completed</h1>},

        // { label: "Client Detail 5", content: <AdPreview SetTabIndex={SetTabIndex}  activeStep={activeStep} steps={totalStepForDV360YoutubeStep} prevStep={prevStep} nextStep={nextStep} label="Ad Media" />},
    ]

    const [initialStep, setInitialStep] = useState(steps);


    useEffect(() => {

        if(mediaType === "DV360_YOUTUBE_VIDEO") {
            setInitialStep(DV360YoutubeStep)
        } else if(mediaType === "display ads") {
            setInitialStep(Google)
        } else {
            setInitialStep(steps)
        }

    }, [mediaType])

    return (
        <>
            <div className="form-step-main">
                <Steps activeStep={activeStep} mt="8">
                    {initialStep.map(({ label, content }) => {
                            return (
                                <Step key={label} pb={5}>
                                    {content}
                                    <AdUploadFormStepButton activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
                                </Step>
                            )
                        }
                    )}
                </Steps>
            </div>
        </>
    )
}

export default SelectAdType