import { Text } from "@chakra-ui/react";
import React from "react";

const RolesView = ({ roles }) => {
  return roles.map((role, index) => {
    return (
      <Text fontSize="xs" key={index}>
        {role}
      </Text>
    );
  });
};

export default RolesView;
