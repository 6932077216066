import { Box, Flex, HStack, Stack } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/react";
import React from "react";

const AuthLayout = ({ children }) => {
  return (
    <Flex
      align={"center"}
      bg={{
        base: "white",
        md: "gray.50",
      }}
      justify={"center"}
      minH={"100vh"}
      padding={{
        base: 0,
        md: 5,
      }}
    >
      <Stack
        spacing={4}
        w={"full"}
        overflow="hidden"
        maxW="container.lg"
        bg="white"
        rounded={{
          base: "none",
          md: "xl",
        }}
        boxShadow={{
          base: "none",
          md: "lg",
        }}
      >
        <HStack justifyContent="space-between" alignItems="strech">
          <Flex
            p={{
              base: 4,
              md: 6,
              lg: 12,
            }}
            flex={1}
            align={"center"}
            justify={"center"}
          >
            {children}
          </Flex>
          <Flex flex={1} display={{ base: "none", md: "flex" }}>
            <Box overflow="hidden">
              <Image
                alt={"Login Image"}
                objectFit="cover"
                height="100%"
                src={`${process.env.PUBLIC_URL}/images/login-bg.webp`}
              />
            </Box>
          </Flex>
        </HStack>
      </Stack>
    </Flex>
  );
};

export default AuthLayout;
