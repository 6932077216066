import { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Heading,
  HStack,
  Image,
  Stack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import axios from "axios";
import { Form, Formik } from "formik";
import { InputControl, SubmitButton } from "formik-chakra-ui";
import validationSchema from "../../validations/Login/Login";
import { useRecoilState } from "recoil";
import { profile } from "../../atoms/authAtom";
import Loading from "../../components/Loading";
import { loginInitialValue } from "./constant/InitialValues";
import AuthLayout from "../../layout/AuthLayout";

const Login = () => {
  const [isLoading, setIsLoading] = useState(true);
  // eslint-disable-next-line
  const [profileData, setProfileData] = useRecoilState(profile);

  useEffect(() => {
    axios({
      method: "GET",
      baseURL: `${process.env.REACT_APP_API_URL}`,
      url: "/user/me",
      withCredentials: false,
    })
      .then((response) => {
        if (response.status === 200) {
          setProfileData(response.data.data.user);
          window.location = "/";
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });

    return () => {
      setIsLoading(true);
    };
  }, [setProfileData]);

  let toast = useToast();

  const handleSubmit = async (values, { setSubmitting }) => {
    return await axios({
      method: "POST",
      baseURL: `${process.env.REACT_APP_API_URL}`,
      url: "/login",
      data: {
        email: values.email,
        password: values.password,
      },
      withCredentials: false,
    })
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem("jwt", response.data.data.tokens.jwt);
          localStorage.setItem(
            "jwtRefresh",
            response.data.data.tokens.jwtRefresh
          );
          toast({
            isClosable: true,
            status: "success",
            variant: "top-accent",
            position: "top-right",
            title: "Success",
            description: "You have been logged in",
          });
          window.location = "/";
        }
      })
      .catch((error) => {
        toast({
          isClosable: true,
          status: "error",
          variant: "top-accent",
          position: "top-right",
          description: error.response?.data?.message || "Something goes wrong!",
        });
      });
  };

  return isLoading ? (
    <Loading />
  ) : (
    <AuthLayout>
      <VStack alignItems="start">
        <Image
          src={`${process.env.PUBLIC_URL}/images/Logo.png`}
          height="33px"
          mb="45px"
        />
        <Stack spacing={4} w={"full"} maxW={"md"}>
          <Heading fontWeight="700" fontFamily="serif">
            Welcome to Kedet.
          </Heading>
          <Text color="gray.500" fontWeight="500">
            Please sign in to your Kedet account to continue.
          </Text>
          <Formik
            initialValues={loginInitialValue}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ dirty, isValid, isSubmitting }) => (
              <VStack as={Form} w="full" alignItems="start">
                <Stack w="full" spacing={3} autoComplete="off">
                  <InputControl
                    name="email"
                    label="Email address"
                    inputProps={{
                      type: "email",
                      placeholder: "Enter your email",
                    }}
                  />
                  <InputControl
                    name="password"
                    label="Password"
                    colorScheme="pink"
                    inputProps={{
                      type: "password",
                      placeholder: "Enter your password",
                    }}
                  />
                </Stack>
                <SubmitButton
                  disabled={!(dirty && isValid) || isSubmitting}
                  variant="solid"
                  colorScheme="blue"
                  borderRadius={0}
                  px="14"
                >
                  Log In
                </SubmitButton>
              </VStack>
            )}
          </Formik>
        </Stack>
      </VStack>
    </AuthLayout>
  );
};

export default Login;
