import {Box, Button, Grid, GridItem, Heading, Radio, SimpleGrid} from "@chakra-ui/react";
import { RadioGroupControl } from "formik-chakra-ui";
import InputBox from "../../../../components/InputBox";
import TextAreaBox from "../../../../components/TextAreaBox";
import {TEXT_COLOR} from "../../../../layout/constant/MenuList";
import FormStepButton from "./FormStepButton";
import React from "react";
import {Flex} from "@chakra-ui/layout";

const ClientDetailsStep = ({errors, activeStep, steps, prevStep, nextStep}) => {
    return (
        <Box p={4}>
            <Box display="flex" mb={6} justifyContent="space-between" alignItems="center">
                <Heading fontSize="xl" color={"#757998"}>
                    Client Details
                </Heading>
                <Heading fontSize="md" color="#A7A9BD">
                    8 steps to complete
                </Heading>
            </Box>
            <SimpleGrid columns={{ base: 1, md: 2 }} gap={4}>
                <GridItem>
                    <InputBox
                        name="detail.industryBasic.companyName"
                        placeholder="Company name"
                        autoFocus="true"
                    />
                </GridItem>
                <GridItem>
                    <InputBox
                        name="detail.industryBasic.phone"
                        placeholder="Phone number"
                    />
                </GridItem>
                <GridItem>
                    <InputBox
                        name="detail.industryBasic.contactName"
                        placeholder="Contact name"
                    />
                </GridItem>
                <GridItem>
                    <InputBox
                        name="detail.industryBasic.email"
                        placeholder="Email address"
                    />
                </GridItem>
                <GridItem rowSpan={2}>
                    <TextAreaBox
                        name="detail.industryBasic.address"
                        placeholder="Address"
                    />
                </GridItem>
                <GridItem>
                    <InputBox
                        name="detail.industryBasic.productAndServices"
                        placeholder="Industry | Product & Services "
                    />
                </GridItem>
                <GridItem>
                    <RadioGroupControl name="detail.industryBasic.industryType">
                        <Radio value="B2B">B2B</Radio>
                        <Radio value="B2C">B2C</Radio>
                    </RadioGroupControl>
                </GridItem>
            </SimpleGrid>

            <FormStepButton errors={errors} activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} />

        </Box>
    );
};

export default ClientDetailsStep;
