import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    formDetails: {},
    mediaDetails: null,
    handlePreviewData: null,
};

export const dv360VideoSlice = createSlice({
    name: "dv360Video",
    initialState,
    reducers: {
        settingDV360VideoFormDetail: (state, action) => {
            state.formDetails = action.payload
        },
        settingDV360VideoFormMedia: (state, action) => {
            state.mediaDetails = action.payload
        },
        settingDV360VideoHandlePreview: (state, action) => {
            state.handlePreviewData = action.payload
        },
        deleteAllDV360VideoField: (state, action) => {
            state.formDetails = {};
            state.mediaDetails = null;
            state.handlePreviewData = null;
        }
    },
});

export const { settingDV360VideoFormDetail, settingDV360VideoFormMedia, settingDV360VideoHandlePreview, deleteAllDV360VideoField } = dv360VideoSlice.actions;

// The reducer
export const dv360VideoReducer = dv360VideoSlice.reducer;
