import { createSlice } from "@reduxjs/toolkit";
import {adUploadSlice} from "../../../../../../../../store/adUploads";
import {AD_UPLOAD_TYPE} from "../../../../../../../../constant";

const initialState = {
    formDetails: {},
    mediaDetails: [],
    handlePreviewData: null,
};

export const instagramVideoSlice = createSlice({
    name: AD_UPLOAD_TYPE.INSTAGRAM_VIDEO,
    initialState,
    reducers: {
        settingInstagramVideoForm: (state, action) => {
            state.formDetails = action.payload
        },
        settingInstagramVideoMedia: (state, action) => {
            state.mediaDetails = action.payload
        },
        settingInstagramVideoPreview: (state, action) => {
            state.handlePreviewData = action.payload
        },
        deleteAllInstagramVideoFields: (state, action) => {
            state.formDetails = {};
            state.mediaDetails = null;
            state.handlePreviewData = null;
        }
    },
});

export const { settingInstagramVideoForm,settingInstagramVideoMedia,settingInstagramVideoPreview,deleteAllInstagramVideoFields} = instagramVideoSlice.actions;

// The reducer
export const instagramVideoReducer = instagramVideoSlice.reducer;
