import React, {useEffect, useMemo, useState} from "react";
import {
    Avatar,
    Button,
    Heading,
    HStack,
    Input,
    InputGroup,
    InputLeftElement,
    Stack,
    Text,
} from "@chakra-ui/react";
import {MdFileDownload} from "react-icons/md";
import {SearchIcon} from "@chakra-ui/icons";
import Datatable from "../../../../components/Datatable/Datatable";
import {useGetAdUploadAuditLogs} from "../../../../hooks/campaign-briefs/useGetAdUploadAuditLogs";
import {useParams} from "react-router-dom";
import "../../style/AdUploadList.css";
import {useSelector} from "react-redux";
import moment from "moment";

const AuditLogsList = () => {
    const {id, adUploadId} = useParams();
    const clientId = useSelector((state) => state.client.clientId);

    const [search, setSearch] = useState();
    const [adLogs, setAdLogs] = useState();

    const {data, mutate} = useGetAdUploadAuditLogs();

    useEffect(() => {
        mutate({
            clientId,
            campaignId: id,
            adUploadId: adUploadId,
        });
    }, []);

    useEffect(() => {
        setAdLogs(data?.auditLogs);
    }, [data]);
    useEffect(() => {
        if (search?.trim()) {
            const searchedAdLogs = adLogs.filter((el) => {
                if (el?.user?.name?.toLowerCase().includes(search.trim())) {
                    return true;
                } else if (
                    el?.created_at?.toLowerCase().includes(search.trim())
                ) {
                    return true;
                } else if (el?.state?.toLowerCase().includes(search.trim())) {
                    return true;
                }
            });
            setAdLogs(searchedAdLogs);
        } else {
            setAdLogs(data?.adLogs);
        }
    }, [search]);

    const columns = useMemo(
        () => [
            {
                Header: "User",
                accessor: "user.name",
                Cell: (data) => (
                    <HStack align="center" gap={2}>
                        {data?.row?.original?.user?.other_info
                            ?.profile_pic_url ? (
                            <Avatar
                                w={12}
                                h={12}
                                size="sm"
                                src={`${process.env.REACT_APP_API_URL}/uploads/${data.row.original.user.other_info.profile_pic_url}`}
                                name={data.row.original.user.name}
                            />
                        ) : (
                            <Avatar
                                w={12}
                                h={12}
                                size="sm"
                                name={data.row.original.user.name}
                            />
                        )}
                        <Text fontSize={16} fontWeight={600} color="#757998" fontFamily={"poppins"}>{data.row.original.user.name}</Text>
                    </HStack>
                ),
            },
            {
                Header: "Timestamp",
                accessor: "created_at",
                Cell: (data) => {
                    return (
                        <>
                            <div style={{fontWeight:"500",color:"#757998",fontFamily:"poppins"}}>
                                {`${moment(data.row.original.created_at).format(
                                    "h:mm:ss A" 
                                )}, ${moment(data.row.original.created_at).format(
                                    "MMMM DD YYYY"
                                )}`}
                                <br/>
                                <p style={{marginTop: "5px"}}>GMT-07:00</p>
                            </div>
                        </>
                    );
                },
            },
            // {
            //     Header: "Action",
            //     accessor: "state",
            // },
            {
                Header: "Details",
                accessor: "ad_upload_type",
                Cell:(data)=>{
                    const ad_upload_type = data?.row?.original?.content?.ad_type
                    const status = data.row.original.content.status
                    const name = data.row.original.content.name
                    return (
                        <>
                            {status === 'created' &&
                            <p style={{fontWeight:600,fontFamily:"poppins",color:"#757998"}}> Created <span style={{color:'#3B82F6'}}>{ad_upload_type} ad - Ad Name {name}</span></p>
                            }
                            {status === 'updated' &&
                            <p style={{fontWeight:600,fontFamily:"poppins",color:"#757998"}}> Updated <span style={{color:'#3B82F6'}}>{ad_upload_type} ad - Ad Name {name}</span> detail</p>
                            }
                        </>
                    )

                }
            },
        ],
        []
    );

    return (
        <>
        <div>
            <div className="ad-upload-list">
                <Datatable data={adLogs || []} columns={columns} headerColor={"#3B82F6"} auditTrue={true}/>
            </div>
        </div>
        </>
    );
};

export default AuditLogsList;
