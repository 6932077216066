import DV360AdFileUpload from "../../../DV360/DV360AdFileUpload";
import {Box, Button, Grid, GridItem, Heading} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {Form, FormikProvider, useFormik} from "formik";
import * as yup from "yup";
import {Flex} from "@chakra-ui/layout";
import {useDispatch, useSelector} from "react-redux";
import {TEXT_COLOR} from "../../../../../../../layout/constant/MenuList";
import {useParams} from "react-router-dom";
import {DV360AudioFileUpload} from "./DV360AudioFileUpload";
import {settingDV360AudioFormMedia, settingDV360AudioPreviewData} from "./store";


function DV360AudioAdMedia({activeStep, steps, prevStep, nextStep, label}) {

    const { adUploadId } = useParams()

    const { mediaDetails } = useSelector((state) => state.dv360Audio)
    const [hashArray, setHashArray] = useState([]);


    const schema = yup.object().shape({
        audioFiles: yup.array().min(1).required("Ad name is required."),
        audioThumbnail: yup.array().min(1).required("Ad name is required."),
    });

    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            audioFiles: [],
            audioThumbnail: []
        },
        validationSchema: schema,
        onSubmit: (values => {
            dispatch(settingDV360AudioFormMedia(values))
            dispatch(settingDV360AudioPreviewData(values));
            nextStep()
        })
    });


    const {
        errors,
        values,
        touched,
        handleSubmit,
        isValid,
        setFieldValue,
    } = formik;

    useEffect(() => {
        setFieldValue("audioFiles", mediaDetails?.audioFiles ? mediaDetails?.audioFiles : [])
        setFieldValue("audioThumbnail", mediaDetails?.audioThumbnail ? mediaDetails?.audioThumbnail : []);
    }, [mediaDetails])


    return (
        <Box className="file-upload-box">
            {
                adUploadId && (
                    <Box display="flex" mb={6} justifyContent="space-between" alignItems="center">
                        <Heading fontSize="xl" color={"#757998"}>
                            Please review the ad details carefully.
                        </Heading>
                        <Heading fontSize="md" color="gray.400">
                            2 steps to complete
                        </Heading>
                    </Box>
                )
            }

            {
                !adUploadId && (
                    <Box display="flex" mb={6} justifyContent="space-between" alignItems="center">
                        <Heading fontSize="xl" color={"#757998"}>
                            Please upload your ad creative.
                        </Heading>
                        <Heading fontSize="md" color="gray.400">
                            2 steps to complete
                        </Heading>
                    </Box>
                )
            }

            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit}>
                    <DV360AudioFileUpload
                        valuesData={values}
                        getHashArray={(value) => setHashArray(value)}
                        type="dv360audio"
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                    />

                    <Flex width="100%" justify="flex-end" py={10}>
                        <Button
                            mr={4}
                            onClick={prevStep}
                            colorScheme='blue' variant='outline' borderRadius={0} px={10}

                        >
                            Back
                        </Button>
                        <Button mr={4} colorScheme='green' type="submit" variant='solid' borderRadius={0} px={10}>
                            Next
                        </Button>
                    </Flex>
                </Form>

            </FormikProvider>

        </Box>
    )
}

export default DV360AudioAdMedia