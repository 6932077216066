import React  from "react";
import {
  Grid,
} from "@chakra-ui/react";
import ReactPlayer from 'react-player'

const VideoAdPreview = ({ url, vidWidth }) => {
  return (
    <Grid className="fb-upload">
      <ReactPlayer
          className='react-player'
          url={url}
          width={vidWidth}
      />
    </Grid>
  );
};

export default VideoAdPreview;
