import React from 'react';
import axios from "../../helpers/axios";
import {useQuery} from "react-query";

export const useGetLookerStudioReports = (clientId) => {
    return useQuery(['report',clientId], async () => {
        if(clientId){
            return axios
                .get(`/client/${clientId}/looker-studio/reports`, {
                    withCredentials: false,
                })
                .then((res) => {
                    if (res.status === 200) {
                        return res.data.data?.reports
                    }
                })
                .catch((err) => {
                    return err.response
                })
        }
    })
}

