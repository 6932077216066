import {deleteAllDV360AudioField} from "./StepByStepAdUploadComponent/DV360/DV360Audio/store";
import {deleteTheField} from "../../../../store/adUploads";
import {deleteAllDV360VideoField} from "./StepByStepAdUploadComponent/DV360/DV360Video/store";
import {deleteAlLDV360YoutubeVideoField} from "./StepByStepAdUploadComponent/DV360/DV360YoutubeVideo/store";
import {deleteAllFacebookImageField} from "./StepByStepAdUploadComponent/Facebook/FacebookImage/store";
import {deleteAllFacebookCarouselField} from "./StepByStepAdUploadComponent/Facebook/FacebookCarousel/store";
import {deleteAllFacebookVideoField} from "./StepByStepAdUploadComponent/Facebook/FacebookVideo/store";
import {deleteAllInstagramCarouselFields} from "./StepByStepAdUploadComponent/Instagram/InstagramCarousel/store";
import {deleteAllInstagramImageFields} from "./StepByStepAdUploadComponent/Instagram/InstagramImage/store";
import {deleteAllInstagramVideoFields} from "./StepByStepAdUploadComponent/Instagram/InstagramVideo/store";
import {deleteAllPinterestCarouselFields} from "./StepByStepAdUploadComponent/Pintrest/PinterestCarousel/store";
import {deleteAllPinterestImageFields} from "./StepByStepAdUploadComponent/Pintrest/PinterestImage/store";
import {deleteAllPinterestVideoFields} from "./StepByStepAdUploadComponent/Pintrest/PinterestVideo/store";
import {deleteAllTiktokVideoFields} from "./StepByStepAdUploadComponent/Tiktok/TiktokVideo/store";
import {useDispatch} from "react-redux";

export function deleteAllStateFromRedux(dispatch) {


    // Deleting the DV360 Redux State
    dispatch(deleteAllDV360AudioField());
    dispatch(deleteTheField())
    dispatch(deleteAllDV360VideoField())
    dispatch(deleteAlLDV360YoutubeVideoField())

    // Deleting the Facebook Redux State
    dispatch(deleteAllFacebookImageField());
    dispatch(deleteAllFacebookCarouselField());
    dispatch(deleteAllFacebookVideoField());

    // Deleting the Instagram Redux State
    dispatch(deleteAllInstagramCarouselFields());
    dispatch(deleteAllInstagramImageFields());
    dispatch(deleteAllInstagramVideoFields());

    // Deleting the Pintrest Redux State
    dispatch(deleteAllPinterestCarouselFields());
    dispatch(deleteAllPinterestImageFields());
    dispatch(deleteAllPinterestVideoFields());

    // Deleting the Tiktok Redux State
    dispatch(deleteAllTiktokVideoFields());
}