import React from 'react';
import {Box, Flex, Spinner, Text, VStack} from "@chakra-ui/react";

const FullPageLoader = () => {
        return (
            <Box
                position="absolute"
                bg="rgba(0, 0, 0, 0.12)"
                top="0"
                bottom="0"
                left="0"
                right="0"
                zIndex="999999999"
                width="100%"
                height="100%"
            >
                <Flex h="full" align="center" justify="center">
                    <VStack spacing={4}>
                        <Spinner color="blue.500" size="lg" />
                        <Text>Loading...</Text>
                    </VStack>
                </Flex>
            </Box>
        );
}

export default FullPageLoader;
