import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Flex,
  Text,
  Tooltip,
  IconButton,
  Button,
  HStack,
} from "@chakra-ui/react";
import { GrLinkPrevious, GrLinkNext } from "react-icons/gr";
import { FaSort } from "react-icons/fa";
import { useTable, usePagination, useSortBy } from "react-table";
import { MdFileDownload } from "react-icons/md";

const Datatable = ({ data, columns, auditTrue, paginationDataCount }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,

    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: paginationDataCount || 5 },
      autoResetHiddenColumns: false,
      autoResetSortBy: false,
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <TableContainer>
        <Table {...getTableProps()} variant="simple" size="sm">
          <Thead backgroundColor="brand.primary" height="10" borderRadius="xl">
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                {headerGroup.headers.map((column) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    onClick={() => column.toggleSortBy(!column.isSortedDesc)}
                  >
                    <HStack color="white">
                      <Text fontFamily="body">
                        {column.render("Header") === "Action"
                          ? ""
                          : column.render("Header")}
                      </Text>
                      {column.render("Header") !== "Action" ? <FaSort /> : ""}
                    </HStack>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody height="70px" {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, index) => {
                    return (
                      <Tooltip
                        label={cell.value}
                        hasArrow
                        arrowSize={15}
                        placement="top-start"
                        margin={-4}
                        color="gray.800"
                        bg="gray.100"
                      >
                        <Td fontSize="xs" {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </Td>
                      </Tooltip>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
      {/* Pagination */}
      <Flex justifyContent="space-between">
        <Flex justifyContent="start" m={4} alignItems="center">
          <Flex>
            <Tooltip label="Previous Page">
              <IconButton
                size="sm"
                variant="ghost"
                color="white !important"
                onClick={previousPage}
                isDisabled={!canPreviousPage}
                background={
                  !canPreviousPage ? "#F8F9FA !important" : "#3B82F6 !important"
                }
                icon={
                  <GrLinkPrevious
                    color={!canPreviousPage ? "#A7A9BD" : "#FFFFFF"}
                    h={6}
                    w={6}
                  />
                }
              />
            </Tooltip>
          </Flex>
          <Flex alignItems="center" mx={3}>
            <Text flexShrink="0">
              {/* Page{" "} */}
              <Text
                borderColor="brand.primary"
                borderRadius={5}
                color="brand.primary"
                px="18px"
                py="3px"
                borderWidth={2}
                fontWeight="bold"
                as="span"
              >
                {pageIndex + 1}
              </Text>{" "}
              <span style={{ margin: "auto 8px" }}>/</span>
              <Text p={1} color="brand.primary" fontWeight="bold" as="span">
                {pageOptions.length}
              </Text>
            </Text>
          </Flex>
          <Flex>
            <Tooltip label="Next Page">
              <IconButton
                size="sm"
                variant="ghost"
                onClick={nextPage}
                isDisabled={!canNextPage}
                background={
                  !canNextPage
                    ? "#F8F9FA !important"
                    : "brand.primary !important"
                }
                icon={
                  <GrLinkNext
                    color={!canNextPage ? "#FFFFFF" : "#FFFFFF"}
                    h={6}
                    w={6}
                  />
                }
              />
            </Tooltip>
          </Flex>
        </Flex>
        {auditTrue ? (
          <Button
            backgroundColor="brand.primary"
            rightIcon={<MdFileDownload />}
          >
            Download Data
          </Button>
        ) : (
          ""
        )}
      </Flex>
    </>
  );
};

export default Datatable;
