import { createSlice } from "@reduxjs/toolkit";
import {adUploadSlice} from "../../../../../../../../store/adUploads";
import {AD_UPLOAD_TYPE} from "../../../../../../../../constant";

const initialState = {
    formDetails: {},
    mediaDetails: [],
    handlePreviewData: null,
};

export const instagramCarouselSlice = createSlice({
    name: AD_UPLOAD_TYPE.INSTAGRAM_CAROUSEL,
    initialState,
    reducers: {
        settingInstagramCarouselForm: (state, action) => {
            state.formDetails = action.payload
        },
        settingInstagramCarouselMedia: (state, action) => {
            state.mediaDetails = action.payload
        },
        settingInstagramCarouselPreview: (state, action) => {
            state.handlePreviewData = action.payload
        },
        deleteAllInstagramCarouselFields: (state, action) => {
            state.formDetails = {};
            state.mediaDetails = null;
            state.handlePreviewData = null;
        }
    },
});

export const { settingInstagramCarouselForm,settingInstagramCarouselMedia,settingInstagramCarouselPreview,deleteAllInstagramCarouselFields} = instagramCarouselSlice.actions;

// The reducer
export const instagramCarouselReducer = instagramCarouselSlice.reducer;
