import { CloseIcon, InfoOutlineIcon, DownloadIcon } from "@chakra-ui/icons";
import {
    Box,
    Button, CircularProgress,
    CircularProgressLabel, Flex, FormControl,
    FormLabel, Grid, GridItem, Heading, Image, Input, Progress, Spacer, Text, Tooltip
} from "@chakra-ui/react";
import { InputControl } from "formik-chakra-ui";
import React, { useEffect, useState } from "react";
import { useDropzone } from 'react-dropzone';
import FileUploadIcon from "../../../../../../../assets/images/upload.svg";
import { fbCarouselAdUploadValueLengths } from "../../../../../constant/InitialValues";
import DefaultVideoIcon from "../../../../../../../assets/images/upload.svg";
import { UploadVideoThumbnail } from './UploadVideoThumbnail'
import FileSaver from 'file-saver';
import {BLUE_BUTTON_COLOR, GREY_TEXT_COLOR, TEXT_COLOR} from "../../../../../../../layout/constant/MenuList";



export function UploadVideoFile({ onDrop, errors, touched, values, setFieldValue }) {
    const [fileURL, setFileURL] = useState('')
    const [fileName, setFileName] = useState('')

    const acceptVideoFiles = {
        "video/mp4": [".mp4"],
        "video/mov": [".mov"],
        "image/gif": [".gif"],
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: acceptVideoFiles,
        maxSize: 4096 * 1024 * 1024,
        disabled: values.fileURL && true,
    });

    useEffect(() => {
        if (values.videoURL && values.videoURL) {
            const file = values.videoURL;
            const videoURL = file && `${process.env.REACT_APP_API_URL}/uploads/${file?.filename}`;
            const getFileName = file?.originalname
            setFileURL(videoURL)
            setFileName(getFileName)
        }
    }, [values])


    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    return (
        <>
            <Flex justifyContent='space-between'>
                <Grid width='48%' height='min-content'>
                    <Grid border='2px'
                        borderColor={(errors.videoURL && touched.videoURL) ? 'red.500' : 'gray.200'}
                        padding="20px" borderStyle="dashed">
                        <div {...getRootProps()}>
                            <Input {...getInputProps()} />
                            <Box className="file-upload-content" justifyContent="center" display="flex" flexDirection="column" alignItems="center" textAlign="center" >
                                <Image
                                    boxSize="100px"
                                    objectFit="cover"
                                    src={FileUploadIcon}
                                    alt="upload-icon"
                                />
                                <Heading fontSize="xl" my={4} color={GREY_TEXT_COLOR}>
                                    Drag video files to upload
                                    <Tooltip label={
                                        <Box>
                                            <Text color="#000"><b>Video file type:</b> MP4, MOV or GIF</Text>
                                            <Text color="#000"><b>Ratio:</b> 1:1</Text>
                                            <Text color="#000"><b>Video maximum file size:</b> 4 GB</Text>
                                            <Text color="#000"><b>Video duration:</b> 1 second to 240 minutes</Text>
                                        </Box>} closeDelay={500} bg='#e2e8f0'>
                                        <InfoOutlineIcon marginLeft="2" height="15px" width="15px" marginBottom="3px" />
                                    </Tooltip>
                                </Heading>
                                <Text fontSize="sm" mb={1} color={GREY_TEXT_COLOR}>
                                    - OR -
                                </Text>
                                <Button bgColor={BLUE_BUTTON_COLOR} color="white" size="small" mt={4} px={10} py={2} disabled={values.videoFile && true} borderRadius={100}>Browse Files</Button>
                            </Box>
                        </div>
                    </Grid>
                    {(errors.videoURL && touched.videoURL) &&
                        <Text color="red.500" marginTop="2" textAlign={"center"}>
                            Card video is required
                        </Text>
                    }
                    {values.videoURL &&
                        <Grid className="file-box">
                            <Flex marginTop="5" marginBottom="5">
                                <Image
                                    boxSize="20px"
                                    objectFit="cover"
                                    src={DefaultVideoIcon}
                                    alt="Dan Abramov"
                                />
                                <Grid marginLeft="2">
                                    <Grid width='100%' wordBreak="break-word">
                                        <Text
                                            fontSize="15px"
                                            className="file-name"
                                            textOverflow="ellipsis"
                                            overflow='hidden'
                                            whiteSpace="nowrap"
                                        >
                                            {values.videoURL.originalname}
                                        </Text>
                                    </Grid>
                                    <Text fontSize="sm">
                                        {formatBytes(values.videoURL.size)}
                                    </Text>
                                </Grid>
                                <Spacer />
                                <Grid>
                                    <Grid justifyContent="center">
                                        <CloseIcon
                                            cursor="pointer"
                                            className="close-icon"
                                            size="small"
                                            onClick={() => {
                                                setFieldValue('videoURL', '')
                                            }}
                                        />
                                    </Grid>
                                    <Grid>
                                        <DownloadIcon
                                            onClick={() => FileSaver.saveAs(fileURL, fileName)}
                                            cursor="pointer"
                                            className="close-icon"
                                            size="small"
                                            w={6}
                                            h={6}
                                        />
                                    </Grid>
                                </Grid>
                            </Flex>
                            <Box marginTop={"-20px"}>
                                <Text textAlign={"right"} display={"block"} color={"green"}>100% Completed</Text>
                                <Progress
                                    className="loading"
                                    value={100}
                                    colorScheme='green'
                                    size='sm'
                                />
                            </Box>
                        </Grid >
                    }
                </Grid>
                <Grid width='48%' height='min-content'>
                    <UploadVideoThumbnail
                        onDrop={onDrop}
                        touched={touched}
                        errors={errors}
                        values={values}
                        setFieldValue={setFieldValue}
                    />
                </Grid>
            </Flex>

            <Grid
                gap={4}
                className="fb-upload"
                marginTop="20px"
            >
                <GridItem w='100%'>
                    <FormControl>
                        <FormLabel
                            htmlFor="landingPageURL"
                            color="gray"
                            fontSize="sm"
                            display={"none"}
                        >
                            Landing page URL
                        </FormLabel>
                        <Flex className="input-box" marginRight="15px">
                            <InputControl
                                id="landingPageURL"
                                name="landingPageURL"
                                value={values.landingPageURL}
                                placeholder=""
                                inputProps={{
                                    variant: "outline",
                                    type: "text",
                                    placeholder: "Landing page URL"
                                }}
                                onChange={(e) => setFieldValue('landingPageURL', e.target.value)}
                            />
                            <Box h="48px" w="48px" />
                        </Flex>
                    </FormControl>
                </GridItem>
                <GridItem w='100%'>
                    <FormControl>
                        <FormLabel
                            htmlFor="headline"
                            color="gray"
                            fontSize="sm"
                            display={"none"}
                        >
                            Headline - max 32 characters
                        </FormLabel>
                        <Flex className="input-box">
                            <InputControl
                                id="headline"
                                name="headline"
                                value={values.headline}
                                inputProps={{
                                    variant: "outline",
                                    type: "text",
                                    placeholder: "Headline - max 32 characters"
                                }}
                                onChange={(e) => setFieldValue('headline', e.target.value)}
                            />
                            <CircularProgress
                                marginLeft="15px"
                                max={fbCarouselAdUploadValueLengths?.headline}
                                value={values?.headline?.length}
                                color={values?.headline?.length > fbCarouselAdUploadValueLengths?.headline ? "red.400" : "green.400"}
                            >
                                <CircularProgressLabel>
                                    {values?.headline?.length > fbCarouselAdUploadValueLengths?.headline ? fbCarouselAdUploadValueLengths?.headline - values?.headline?.length : values?.headline?.length}
                                </CircularProgressLabel>
                            </CircularProgress>
                        </Flex>
                    </FormControl>
                </GridItem>
                <GridItem w='100%'>
                    <FormControl>
                        <FormLabel
                            htmlFor="description"
                            color="gray"
                            fontSize="sm"
                            display={"none"}
                        >
                            Description - max 18 characters
                        </FormLabel>
                        <Flex className="input-box" justifyContent='center' alignItems="center">
                            <InputControl
                                id="description"
                                name="description"
                                value={values.description}
                                placeholder=""
                                inputProps={{
                                    variant: "outline",
                                    type: "text",
                                    placeholder: "Description - max 18 characters"
                                }}
                                onChange={(e) => setFieldValue('description', e.target.value)}
                            />
                            <CircularProgress
                                marginLeft="15px"
                                max={fbCarouselAdUploadValueLengths?.description}
                                value={values?.description?.length}
                                color={values?.description?.length > fbCarouselAdUploadValueLengths?.description ? "red.400" : "green.400"}
                            >
                                <CircularProgressLabel>
                                    {values?.description?.length > fbCarouselAdUploadValueLengths?.description ? fbCarouselAdUploadValueLengths?.description - values?.description?.length : values?.description?.length}
                                </CircularProgressLabel>
                            </CircularProgress>
                        </Flex>
                    </FormControl>
                </GridItem>
            </Grid>
        </>
    );
}
