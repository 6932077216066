import {combineReducers, configureStore } from "@reduxjs/toolkit";
import {clientReducer} from "./client";
import {campaignReducer} from "./campaign";
import { adUploadReducer } from "./adUploads";
import fullScreenLoader from "./Loading/index";
import {dv360YoutubeVideoReducer} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/DV360/DV360YoutubeVideo/store";
import {dv360VideoReducer} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/DV360/DV360Video/store";
import {pinterestImageReducer} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/Pintrest/PinterestImage/store";
import {pinterestVideoReducer} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/Pintrest/PinterestVideo/store";
import {tiktokVideoReducer} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/Tiktok/TiktokVideo/store";
import {dv360AudioReducer} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/DV360/DV360Audio/store";
import {pinterestCarouselReducer} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/Pintrest/PinterestCarousel/store";
import {facebookImageReducer} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/Facebook/FacebookImage/store";
import {instagramCarouselReducer} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/Instagram/InstagramCarousel/store";
import {instagramImageReducer} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/Instagram/InstagramImage/store";
import {facebookCarouselReducer} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/Facebook/FacebookCarousel/store";
import {facebookVideoReducer} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/Facebook/FacebookVideo/store";
import {instagramVideoReducer} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/Instagram/InstagramVideo/store";
import {adCampaignReducer} from "./adCampaign";


const combinedReducer = combineReducers({
    client: clientReducer,
    campaign: campaignReducer,
    fullScreenLoader,
    adUpload: adUploadReducer,
    dv360Video: dv360VideoReducer,
    dv360YoutubeVideo: dv360YoutubeVideoReducer,
    pinterestImageAd : pinterestImageReducer,
    pinterestVideoAd : pinterestVideoReducer,
    tiktokVideoAd : tiktokVideoReducer,
    dv360Audio: dv360AudioReducer,
    pinterestCarouselAd : pinterestCarouselReducer,
    instagramCarouselAd : instagramCarouselReducer,
    instagramImageAd : instagramImageReducer,
    instagramVideoAd : instagramVideoReducer,
    facebookImageAd : facebookImageReducer,
    facebookCarouselAd: facebookCarouselReducer,
    facebookVideoAd : facebookVideoReducer,
    adCampaign: adCampaignReducer
});

const rootReducer = (state, action) => {
    return combinedReducer(state, action);
};

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});
