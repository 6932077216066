import React, { useState, useEffect } from "react";
import {
  Flex,
  Grid,
  GridItem,
  Heading,
  Text,
  Box,
  Container,
  Button,
  Spacer,
  Image,
  Tooltip,
  Link,
} from "@chakra-ui/react";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { TEXT_COLOR } from "../../../../../layout/constant/MenuList";

const AudioAdPreview = ({ data }) => {
  const [index, setIndex] = useState(0);
  const [thumbnailURL, setThumbnailURL] = useState("");
  const [audioURL, setAudioURL] = useState("");
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    if (data) {
      setPreview(data);
      const videoURL =
        data && data.audioFiles[0]?.imageUrl
          ? `${process.env.REACT_APP_API_URL}/uploads${
              data && data.audioFiles[0]?.imageUrl
            }`
          : URL.createObjectURL(data.audioFiles[0].file);
      const audioThumbnail =
        data && data.audioThumbnail[0]?.imageUrl
          ? `${process.env.REACT_APP_API_URL}/uploads${
              data && data.audioThumbnail[0]?.imageUrl
            }`
          : URL.createObjectURL(data.audioThumbnail[0].file);
      setThumbnailURL(audioThumbnail);
      setAudioURL(videoURL);
    }
  }, [data]);

  const getSlider = () => {
    if (preview?.length) {
      const slides = preview.map((el, ind) => (
        <Text
          height={1}
          borderRadius={5}
          px={4}
          mx={1}
          mt={8}
          key={ind}
          background={ind === index ? "grey" : "silver"}
          onClick={() => setIndex(ind)}
          cursor="pointer"
        />
      ));
      return slides;
    }
  };

  return (
    <Grid templateColumns="repeat(6, 1fr)" gap={4} className="fb-upload">
      <GridItem w="full" colSpan={{ base: 6, lg: 6 }}>
        <Heading fontSize="lg" my={4} color={TEXT_COLOR}>
          {/*{preview?.[index]?.filename}*/}
        </Heading>
        <Box
          borderWidth="2px"
          borderColor="gray"
          p={10}
          background="client/src/pages/CampaignBriefs/components/AdUpload/DV360Audio/AudioAdPreview#FFF8F4"
        >
          <Container height="100%">
            <Flex alignItems="center" flexDirection="row" justify="center">
              {preview && (
                <Box boxShadow="base" p="6" rounded="md" bg="white">
                  {/* {data?.images ? (
                                    <iframe
                                        src={`${process.env.REACT_APP_API_URL}/uploads/${ads?.[index]?.imageUrl}`}
                                        title={ads?.[index]?.filename}
                                        width="400px"
                                        height={600}
                                        scrolling="no"
                                    />
                                ) : (
                                    <iframe
                                        src={ads?.[index]?.src}
                                        title={ads?.[index]?.filename}
                                        width="400px"
                                        height={600}
                                        scrolling="no"
                                    />
                                )} */}

                  <Link href={preview.landingPageURL} target="_blank">
                    <Image
                      src={thumbnailURL}
                      cursor="pointer"
                      marginBottom="20px"
                    />
                  </Link>
                  <audio style={{width: '100%'}} src={audioURL} controls />
                </Box>
              )}
            </Flex>
          </Container>
        </Box>
        <Flex templateColumns="repeat(12, 1fr)" justifyContent="space-between">
          <Button
            leftIcon={<ArrowBackIcon color="gray" w={10} h={10} />}
            py={8}
            variant="ghost"
            onClick={() => setIndex(index > 1 ? index - 1 : 0)}
          />
          <Spacer />
          {getSlider()}
          <Spacer />
          <Button
            leftIcon={<ArrowForwardIcon color="gray" w={10} h={10} />}
            py={8}
            variant="ghost"
            onClick={() =>
              setIndex(
                preview && Object.keys(preview)?.length > index + 1
                  ? index + 1
                  : index
              )
            }
          />
        </Flex>
      </GridItem>
    </Grid>
  );
};

export default AudioAdPreview;
