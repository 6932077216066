import React from "react";
import {
  Avatar,
  Box,
  HStack,
  IconButton,
  Text,
  VStack,
  Heading,
  Radio,
} from "@chakra-ui/react";
import Upload from "rc-upload/es";
import { Form, Formik, FieldArray } from "formik";
import { detailInitialValue } from "../Settings/constant/InititalValues";
import validationSchema from "../../validations/Setting/Details";
import InputBox from "../../components/InputBox";
import MultiSelectInputBox from "../../components/MultiSelectInputBox";
import { Roles } from "../../constant";
import { RadioGroupControl, SubmitButton } from "formik-chakra-ui";
import { HiCamera } from "react-icons/hi";
import { useRecoilState } from "recoil";
import { profile } from "../../atoms/authAtom";
import TextAreaBox from "../../components/TextAreaBox";
import { AiFillPlusCircle } from "react-icons/ai";
import { BsThreeDots } from "react-icons/bs";
import { CampaignBudgetOptions } from "../CampaignBriefs/constant/SelectValues";

function Profile() {
  const [details, setDetails] = useRecoilState(profile);

  const initialValue = {
    company_name: "Hello Company",
    facebook: [
      {
        text: "Facebook 1",
      },
      {
        text: "Facebook 2",
      },
    ],
  };

  const onSubmit = async (values, actions) => {
    console.log("Values", values);
  };

  return (
    <>
      <Heading as="h3" size="xl">
        Client Details
      </Heading>
      <HStack mt={6} spacing={10} align={"start"}>
        <Box pos="relative">
          <Avatar size="2xl" name={"User Name"} />
          <Upload>
            <IconButton
              pos="absolute"
              right={0}
              bottom={0}
              variant="solid"
              rounded="full"
              colorScheme="blackAlpha"
              aria-label="Search database"
              icon={<HiCamera />}
            />
          </Upload>
        </Box>
        <Formik
          initialValues={initialValue}
          // initialValues={detailInitialValue(details)}
          // validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, errors }) => {
            return (
              <VStack as={Form} w="full" align={"start"} spacing={4}>
                <InputBox
                  name="company_name"
                  label="Company Name"
                  type="text"
                />
                <HStack spacing={4} w="full">
                  <InputBox name="name" label="Contact Full Name" />
                  <InputBox name="lastName" label="Contact Last Name" />
                </HStack>
                <InputBox name="email" label="Email address" type="email" />
                <HStack spacing={4} w="full">
                  <InputBox name="phone" label="Phone" />
                  <InputBox
                    name="industry"
                    label="Industry | Product & Service"
                  />
                </HStack>
                <HStack spacing={4} w="full">
                  <TextAreaBox name="address" label="Address" />
                  <RadioGroupControl
                    label="Monthly"
                    labelProps={{
                      fontSize: "sm",
                      color: "gray",
                    }}
                    name="Monthly"
                  >
                    <Radio colorScheme="blue" value="Yes">
                      B2B
                    </Radio>
                    <Radio colorScheme="blue" value="No">
                      B2C
                    </Radio>
                  </RadioGroupControl>
                </HStack>
                <RadioGroupControl
                  label="Status"
                  labelProps={{
                    fontSize: "sm",
                    color: "gray",
                  }}
                  name="status"
                >
                  <Radio colorScheme="blue" value="active">
                    ACTIVE
                  </Radio>
                  <Radio colorScheme="blue" value="inactive">
                    INACTIVE
                  </Radio>
                  <Radio colorScheme="blue" value="onhold">
                    ON HOLD
                  </Radio>
                </RadioGroupControl>
                <hr style={{ width: "100%" }} />
                <HStack
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <label>Facebook</label>
                  <FieldArray
                    name="facebook"
                    render={(arrayHelpers) => {
                      return (
                        <div style={{ width: "100%" }}>
                          {values.facebook && values.facebook.length > 0 ? (
                            values.facebook.map((friend, index) => (
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  gap: "10px",
                                  alignItems: "center",
                                }}
                              >
                                <InputBox name={`facebook.${index}.text`} />
                                <BsThreeDots />
                              </div>
                            ))
                          ) : (
                            <button
                              type="button"
                              onClick={() => arrayHelpers.push({ text: "" })}
                            >
                              {/* show this when user has removed all friends from the list */}
                              Add a friend
                            </button>
                          )}
                          <button
                            type="button"
                            style={{
                              display: "flex",
                              gap: "6px",
                              alignItems: "center",
                              margin: "20px 0",
                            }}
                            onClick={() => arrayHelpers.push({ text: "" })} // insert an empty string at a position
                          >
                            <AiFillPlusCircle
                              style={{ color: "#3182ce", fontSize: "32px" }}
                            />{" "}
                            New Line
                          </button>
                        </div>
                      );
                    }}
                  ></FieldArray>
                </HStack>
                <hr style={{ width: "100%" }} />
                <HStack
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <MultiSelectInputBox
                    name="Campaign Budget"
                    label={"Facebook Account ID"}
                    options={CampaignBudgetOptions}
                    placeholder={`Select...`}
                  />
                </HStack>
                <HStack
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <InputBox
                    name="facebook_page_id"
                    label="Facebook Page ID"
                    type="text"
                  />
                </HStack>
                <div style={{ width: "100%" }}>
                  <label>Associated Users</label>
                  <Box
                    py={"10"}
                    display={"grid"}
                    gridTemplateColumns={"repeat(4, 1fr)"}
                    gap={"10px"}
                  >
                    {[1, 2, 3, 4, 5, 6].map((item, index) => {
                      return (
                        <Box
                          key={index}
                          display={"flex"}
                          flexDirection={"column"}
                          alignItems={"center"}
                          gap={2}
                          marginBottom={6}
                        >
                          <Avatar
                            width={"100px"}
                            height={"100px"}
                            name={"Some Random User"}
                          ></Avatar>
                          <Text>Random User</Text>
                        </Box>
                      );
                    })}
                  </Box>
                </div>
                <InputBox name="notes" label="Notes" type="text" />
                <SubmitButton
                  size="sm"
                  colorScheme="blue"
                  px="14"
                  rounded="full"
                >
                  Update
                </SubmitButton>
              </VStack>
            );
          }}
        </Formik>
      </HStack>
    </>
  );
}

export default Profile;
