import {
    Box, Button,
    Container, Flex,
    Grid,
    GridItem,
    Heading,
    Image, Link, Spacer, Text
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {PREVIEW_BG_COLOR, TEXT_COLOR, INDICATOR_BG_COLOR} from "../../../../../../../layout/constant/MenuList";
import {ArrowBackIcon, ArrowForwardIcon} from "@chakra-ui/icons";
import {useDispatch, useSelector} from "react-redux";
import {AD_UPLOAD_TYPE} from "../../../../../../../constant";
import {useUploadImage} from "../../../../../../../hooks/campaign-briefs/useUploadImage";
import instance from "../../../../../../../helpers/axios";
import {useNavigate, useParams} from "react-router-dom";
import WithAppContext from "../../../../../../../helpers/appContext";
import SuccessModal from "../../../../../../../components/PopupModal/SuccessModal";
import ErrorModal from "../../../../../../../components/PopupModal/ErrorModal";
import FullPageLoader from "../../../../../../../components/FullPageLoader";
import {deleteTheField} from "../../../../../../../store/adUploads/index"
import {useGetAdUpload} from "../../../../../../../hooks/campaign-briefs/useGetAdUpload";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import {FaAngleDown} from "react-icons/fa";
import {BsThreeDots} from "react-icons/bs";
import {RiUpload2Fill} from "react-icons/ri";
import {GrLink} from "react-icons/gr"
import {settingPinterestCarouselFormDetails,settingPinterestCarouselMedia,settingPinterestCarouselPreview,deleteAllPinterestCarouselFields} from "./store";
import {useRecoilValue} from "recoil";
import {profile} from "../../../../../../../atoms/authAtom";


const PinterestCarouselAdPreview = ({SetTabIndex, activeStep, steps, prevStep, nextStep, label, context}) => {

    const [index, setIndex] = useState(0);
    const [ads, setAds] = useState([]);
    const [method, setMethod] = useState();
    const [url, setUrl] = useState();
    const { id: campaignId, adUploadId } = useParams();
    const clientId = useSelector((state) => state.client.clientId);
    const {setAppLoading, appLoading} = context;
    const [isSuccessModalOpen, setSuccessModal] = useState(false);
    const [isErrorModalOpen, setErrorModal] = useState(false);
    const [description, setDescription] = useState("");
    const {
        access_info: { roles, clients: userClients },
    } = useRecoilValue(profile);
    const [isClient, setIsClient] = useState(false)

    useEffect(() => {
        setIsClient(roles.includes("Client"));
    }, [roles]);

    const { data, refetch } = useGetAdUpload(clientId, campaignId, adUploadId);

    useEffect(() => {
        refetch()
    }, [])

    useEffect(() => {
        if (adUploadId) {
            setMethod("PUT");
            setUrl(
                `/client/${clientId}/campaign-brief/${campaignId}/ad-upload/${adUploadId}`
            );
        } else {
            setMethod("POST");
            setUrl(`/client/${clientId}/campaign-brief/${campaignId}/ad-upload`);
        }
    }, [adUploadId, clientId]);

    const {handlePreviewData, formDetails, mediaDetails} = useSelector((state) => state.pinterestCarouselAd)
    const { fileInfoList} = formDetails

    useEffect(() => {
        setAds(handlePreviewData)
    }, [handlePreviewData]);

    const getSlider = () => {
        if (ads?.length) {
            const slides = ads.map((el, ind) => (
                <Text
                    height={1}
                    borderRadius={5}
                    px={4}
                    mx={1}
                    mt={8}
                    key={ind}
                    background={ind === index ? INDICATOR_BG_COLOR : "silver"}
                    onClick={() => setIndex(ind)}
                    cursor="pointer"
                />
            ));
            return slides;
        }
    };

    const {mutateAsync} = useUploadImage();

    const onSubmitImages = async (multiFiles) => {
        let payload = [];
        let otherFile = [];
        let files = [];

        multiFiles.forEach((file) => {
            if (file?.fieldname) {
                otherFile = [...fileInfoList]
            } else {
                payload.push(file);
            }
        })


        if(payload && payload.length){
            await mutateAsync(
                {
                    clientId: clientId,
                    campaignBriefId: campaignId,
                    adFile: payload,
                    type: "pinterest"
                },
                {
                    onSuccess: (data, variables, context) => {
                        payload = data.fileInfoList;
                        setAppLoading(false)
                    },
                    onError: (error) => {
                        setAppLoading(false)
                        setDescription(error?.response?.data?.message);
                    }
                }
            );
            files = [...otherFile, ...payload]
        }else{
            files = [...otherFile]
        }
        return files;
    }

    const navigate = useNavigate();
    const dispatch = useDispatch()


    const onCloseSuccessModal = () => {
        setSuccessModal(false)

        if(adUploadId) {
            navigate(`/campaign-briefs/${campaignId}`, {
                state: {isShowUpload: true}
            })
            dispatch(deleteAllPinterestCarouselFields())
            dispatch(deleteTheField())
        }
        else {
            SetTabIndex(isClient? 0 : 3);
            // navigate(`/campaign-briefs/${campaignId}`, {
            //     state: {isShowUpload: true}
            // })
            navigate(`/campaign-briefs/${campaignId}`)
            dispatch(deleteAllPinterestCarouselFields())
            dispatch(deleteTheField())
        }
    }

    async function handleSubmit() {
        setAppLoading(true);
        const files = await onSubmitImages(mediaDetails.imageURL);
        const mediaIds = files.map((el) => el.imageHash);

        const dimensions = files.map(() => {
            return {
                width: "",
                height: "",
            };
        });

        const fileInfoList = files.map((el) => {
            return {
                ...el,
                metadata: [{height: "", width: ""}],
            };
        });

        let payload = {
            name: formDetails.adName,
            description: formDetails.description,
            title: formDetails.title,
            link: formDetails.link,
            detail: {
                name: formDetails.adName,
                description: formDetails.description,
                title: formDetails.title,
                link: formDetails.link,
                mediaIds: mediaIds,
                fileInfoList
            },
        };

        if (!adUploadId) {
            payload = {
                ...payload,
                ad_upload_type: AD_UPLOAD_TYPE.PINTEREST_CAROUSEL,
            };
        }


        await instance({
            method: method,
            url: url,
            withCredentials: false,
            data: payload,
        })
            .then((res) => {
                if (res.status === 200) {
                    setSuccessModal(true);
                    setAppLoading(false)
                    dispatch(settingPinterestCarouselFormDetails({}))
                    dispatch(settingPinterestCarouselMedia([]))
                    dispatch(settingPinterestCarouselPreview(null))
                }
            })
            .catch((error) => {
                setDescription(error.response.data.message);
                setErrorModal(true);
                setAppLoading(false)
            });
    }

    return (
        <>
            {
                adUploadId && (
                    <Box display="flex" mb={6} justifyContent="space-between" alignItems="center">
                        <Heading fontSize="xl" color={"#757998"}>
                            Please review the ad details carefully
                        </Heading>
                        <Heading fontSize="md" color="gray.400">
                            1 steps to complete
                        </Heading>
                    </Box>
                )
            }

            {
                !adUploadId && (
                    <Box display="flex" mb={6} justifyContent="space-between" alignItems="center">
                        <Heading fontSize="xl" color={"#757998"}>
                            Please review your ad.
                        </Heading>
                        <Heading fontSize="md" color="gray.400">
                            1 steps to complete
                        </Heading>
                    </Box>
                )
            }

            <Grid templateColumns="repeat(6, 1fr)" gap={4} className="fb-upload">
                {appLoading && <FullPageLoader />}
                <GridItem w="full" colSpan={{base: 6, lg: 6}}>
                    <Heading fontSize="lg" my={4} color={"#757998"}>
                        {ads?.[index]?.filename}
                    </Heading>
                    <Box
                        borderWidth="2px"
                        borderColor="gray"
                        p={10}
                        background={PREVIEW_BG_COLOR}
                        position={"relative"}
                    >
                        {/*<Image*/}
                        {/*    minHeight="md"*/}
                        {/*    src={mediaDetails?.imageURL[0]?.path}*/}
                        {/*    className="previewImage"*/}
                        {/*/>*/}
                        <Container maxWidth="container.lg" px={8}>
                            <Flex
                                flexDirection="row"
                                justify="stretch"
                                minHeight="md"
                            >
                                {/*swiper-button-prev*/}
                                {(ads && ads.length !== 0) ?
                                    <Box minHeight="md" flex={1} borderLeftRadius={20} overflow="hidden">
                                        <Swiper navigation={true} modules={[Navigation]} style={{ height: "100%" }}>
                                            {
                                                ads.map((item) => {
                                                    const {imageUrl} = item;
                                                    return (
                                                        <SwiperSlide>
                                                            <Image
                                                                minHeight="md"
                                                                src={item?.imageUrl}
                                                                className="previewImage"
                                                            />
                                                        </SwiperSlide>
                                                    )
                                                })
                                            }
                                        </Swiper>
                                        {/*<Image*/}
                                        {/*    minHeight="md"*/}
                                        {/*    src={ads[index]?.imageUrl}*/}
                                        {/*    alt={ads[index]?.filename}*/}
                                        {/*    className="previewImage"*/}
                                        {/*/>*/}
                                    </Box> : <Box minHeight="md" flex={1} borderLeftRadius={20} overflow="hidden"></Box>
                                }

                                <Box flex={1} borderRightRadius={20} overflow="hidden" background="white" p={8}>
                                    <div className="pinterest-header">
                                        <div className="header-left-icon">
                                            <BsThreeDots/>
                                            <RiUpload2Fill/>
                                            <GrLink/>
                                        </div>
                                        <div className="header-right">
                                            {/*<div className="option">*/}
                                            {/*    <span>Seo</span> <FaAngleDown/>*/}
                                            {/*</div>*/}
                                            <div className="save">
                                                <span>Save</span>
                                            </div>
                                        </div>
                                    </div>
                                    <Flex flexDirection="column" marginBottom={10} className="main">
                                        {formDetails?.link && <Link mb={2} className="url" textDecoration="underline" color="#111"
                                                             href={formDetails.link} isExternal>
                                            {formDetails.link}
                                        </Link>}
                                        {/*{*/}
                                        {/*    details?.adUpload?.detail?.link &&*/}
                                        {/*    <Link mb={2} className="url" textDecoration="underline" color="#111"*/}
                                        {/*          href={details?.adUpload?.detail?.link} isExternal>*/}
                                        {/*        {details?.adUpload?.detail?.link}*/}
                                        {/*    </Link>*/}
                                        {/*}*/}
                                        {formDetails?.title && <Heading fontSize="4xl" className="heading" color="#111">
                                            {formDetails.title}
                                        </Heading>}

                                        {formDetails?.name && <Heading fontSize="4xl" className="heading" color="#111">
                                            {formDetails.name}</Heading>}
                                        {formDetails?.description &&
                                        <Heading fontSize="md" color="#111" className="description" fontWeight="normal">
                                            {formDetails.description}
                                        </Heading>}
                                    </Flex>
                                    <div className="account">
                                        <div className="account-profile">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/images/Kedet Logo.png`} className="user_img"/>
                                            <span>User Name</span>
                                        </div>
                                        <button className="follow-button">Follow</button>
                                    </div>
                                    <div className="comment">
                                        <span>Comment</span> <FaAngleDown/>
                                    </div>
                                    <div className="comment-box">
                                        <div className="user-name">
                                            <span>P</span>
                                        </div>
                                        <div className="comment-input-box">
                                            <input type="text" placeholder="Add a comment"/>
                                        </div>
                                    </div>
                                </Box>
                            </Flex>
                        </Container>
                    </Box>
                    <Flex width="100%" justify="flex-end" py={10}>
                        <Button
                            mr={4}
                            onClick={prevStep}
                            colorScheme='blue' variant='outline' borderRadius={0} px={10}
                        >
                            Back
                        </Button>
                        <Button mr={4} colorScheme='blue' variant='solid' borderRadius={0} px={10}>
                            Save Draft
                        </Button>
                        <Button mr={4} onClick={handleSubmit} colorScheme='green' variant='solid' borderRadius={0} px={10}>
                            { adUploadId ? 'Update' : 'Submit'}
                        </Button>
                    </Flex>

                </GridItem>
                <SuccessModal
                    isOpen={isSuccessModalOpen}
                    onClose={() => onCloseSuccessModal()}
                />
                <ErrorModal
                    isOpen={isErrorModalOpen}
                    onClose={() => setErrorModal(false)}
                    description={description}
                />
            </Grid>
        </>
    );
};

export default WithAppContext(PinterestCarouselAdPreview);
