import {
    Box, Button,
    Container, Flex,
    Grid,
    GridItem,
    Heading,
    Image, Link, Spacer, Text
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {TEXT_COLOR} from "../../../../../../layout/constant/MenuList";
import {ArrowBackIcon, ArrowForwardIcon} from "@chakra-ui/icons";
import "../style/pinterest.css"
import {FaAngleDown} from "react-icons/fa";
import {BsThreeDots} from "react-icons/bs";
import {RiUpload2Fill} from "react-icons/ri";
import {GrLink} from "react-icons/gr"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper";

const PinterestAdPreview = ({data, details}) => {
    const [index, setIndex] = useState(0);
    const [ads, setAds] = useState([]);


    useEffect(() => {
        if (data?.images) {
            setAds(data?.images);
        } else {
            setAds(data);
        }
    }, [data]);

    const getSlider = () => {
        if (ads?.length) {
            const slides = ads.map((el, ind) => (
                <Text
                    height={1}
                    borderRadius={5}
                    px={4}
                    mx={1}
                    mt={8}
                    key={ind}
                    background={ind === index ? "grey" : "silver"}
                    onClick={() => setIndex(ind)}
                    cursor="pointer"
                />
            ));
            return slides;
        }
    };

    return (
        <Grid templateColumns="repeat(6, 1fr)" gap={4} className="fb-upload">
            <GridItem w="full" colSpan={{base: 6, lg: 6}}>
                <Heading fontSize="lg" my={4} color={TEXT_COLOR}>
                    {ads?.[index]?.filename}
                </Heading>
                <Box
                    borderWidth="2px"
                    borderColor="gray"
                    p={10}
                    background="#FFF8F4"
                >
                    <Container maxWidth="container.lg" px={8}>
                        <Flex
                            flexDirection="row"
                            justify="stretch"
                            minHeight="md"
                        >
                            {/*swiper-button-prev*/}
                            {(ads && ads.length !== 0) ?
                                <Box minHeight="md" flex={1} borderLeftRadius={20} overflow="hidden">
                                    <Swiper navigation={true} modules={[Navigation]} style={{ height: "100%" }}>
                                        {
                                            ads.map((item) => {
                                                const {imageUrl} = item;
                                                return (
                                                    <SwiperSlide>
                                                        <Image
                                                            minHeight="md"
                                                            src={imageUrl}
                                                            className="previewImage"
                                                        />
                                                    </SwiperSlide>
                                                )
                                            })
                                        }
                                    </Swiper>
                                    {/*<Image*/}
                                    {/*    minHeight="md"*/}
                                    {/*    src={ads[index]?.imageUrl}*/}
                                    {/*    alt={ads[index]?.filename}*/}
                                    {/*    className="previewImage"*/}
                                    {/*/>*/}
                                </Box> : <Box minHeight="md" flex={1} borderLeftRadius={20} overflow="hidden"></Box>
                            }

                            <Box flex={1} borderRightRadius={20} overflow="hidden" background="white" p={8}>
                                <div className="pinterest-header">
                                    <div className="header-left-icon">
                                        <BsThreeDots/>
                                        <RiUpload2Fill/>
                                        <GrLink/>
                                    </div>
                                    <div className="header-right">
                                        {/*<div className="option">*/}
                                        {/*    <span>Seo</span> <FaAngleDown/>*/}
                                        {/*</div>*/}
                                        <div className="save">
                                            <span>Save</span>
                                        </div>
                                    </div>
                                </div>
                                <Flex flexDirection="column" marginBottom={10} className="main">
                                    {data?.link && <Link mb={2} className="url" textDecoration="underline" color="#111"
                                                         href={data.link} isExternal>
                                        {data.link}
                                    </Link>}
                                    {
                                        details?.adUpload?.detail?.link &&
                                        <Link mb={2} className="url" textDecoration="underline" color="#111"
                                              href={details?.adUpload?.detail?.link} isExternal>
                                            {details?.adUpload?.detail?.link}
                                        </Link>
                                    }
                                    {data?.title && <Heading fontSize="4xl" className="heading" color="#111">
                                        {data.title}
                                    </Heading>}

                                    {data?.name && <Heading fontSize="4xl" className="heading" color="#111">
                                        {data.name}</Heading>}
                                    {data?.description &&
                                        <Heading fontSize="md" color="#111" className="description" fontWeight="normal">
                                            {data.description}
                                        </Heading>}
                                </Flex>
                                <div className="account">
                                    <div className="account-profile">
                                        <img
                                            src={`${process.env.PUBLIC_URL}/images/Kedet Logo.png`} className="user_img"/>
                                        <span>User Name</span>
                                    </div>
                                    <button className="follow-button">Follow</button>
                                </div>
                                <div className="comment">
                                    <span>Comment</span> <FaAngleDown/>
                                </div>
                                <div className="comment-box">
                                    <div className="user-name">
                                        <span>P</span>
                                    </div>
                                    <div className="comment-input-box">
                                        <input type="text" placeholder="Add a comment"/>
                                    </div>
                                </div>
                            </Box>
                        </Flex>
                    </Container>
                </Box>
                {/*<Flex*/}
                {/*    templateColumns="repeat(12, 1fr)"*/}
                {/*    justifyContent="space-between"*/}
                {/*>*/}
                {/*    <Button*/}
                {/*        leftIcon={<ArrowBackIcon color="gray" w={10} h={10}/>}*/}
                {/*        py={8}*/}
                {/*        variant="ghost"*/}
                {/*        onClick={() => setIndex(index > 1 ? index - 1 : 0)}*/}
                {/*    />*/}
                {/*    <Spacer/>*/}
                {/*    {getSlider()}*/}
                {/*    <Spacer/>*/}
                {/*    <Button*/}
                {/*        leftIcon={*/}
                {/*            <ArrowForwardIcon color="gray" w={10} h={10}/>*/}
                {/*        }*/}
                {/*        py={8}*/}
                {/*        variant="ghost"*/}
                {/*        onClick={() =>*/}
                {/*            setIndex(*/}
                {/*                ads && Object.keys(ads)?.length > index + 1*/}
                {/*                    ? index + 1*/}
                {/*                    : index*/}
                {/*            )*/}
                {/*        }*/}
                {/*    />*/}
                {/*</Flex>*/}
            </GridItem>
        </Grid>
    );
};

export default PinterestAdPreview;
