import {Box, Button, Grid, GridItem, Heading, useToast} from "@chakra-ui/react";
import React, {useCallback, useEffect, useState} from "react";
import {Form, FormikProvider, useFormik} from "formik";
import * as yup from "yup";
import {Flex} from "@chakra-ui/layout";
import {useDispatch, useSelector} from "react-redux";
import {TEXT_COLOR} from "../../../../../../../layout/constant/MenuList";
import {useNavigate, useParams} from "react-router-dom";
import {UploadVideoPreview} from "../../../Facebook/video/UploadFiles/UploadVideoPreview";
import {UploadThumbnailPreview} from "../../../Facebook/video/UploadFiles/UploadThumbnailPreview";
import calculateAspectRatios from "calculate-aspect-ratio";
import {
    FB_IMAGE_SIZE,
    FB_VIDEO_DURATION,
    FB_VIDEO_SIZE,
    INSTAGRAM_VIDEO_WIDTH_MIN
} from "../../../../../../../constant";
import WithAppContext from "../../../../../../../helpers/appContext";
import {useUploadImage} from "../../../../../../../hooks/campaign-briefs/useUploadImage";
import {useUpdateAdUploadStatus} from "../../../../../../../hooks/campaign-briefs/useUpdateAdUploadStatus";
import {useCreateAdPreview} from "../../../../../../../hooks/campaign-briefs/useCreateAdPreview";
import {settingFacebookVideoFormMedia} from "./store";
import {FacebookVideoAdVideoUpload} from "./FacebookVideoAdVideoUpload"
import {FacebookVideoAdThumbnailUpload} from "./FacebookVideoAdThumbnailUpload";
import FullPageLoader from "../../../../../../../components/FullPageLoader";


function FacebookVideoAdMedia({activeStep, steps, prevStep, nextStep, label, context}) {

    const {adUploadId} = useParams()
    const toast = useToast()

    const {id} = useParams();
    const clientId = useSelector((state) => state.client.clientId);
    const navigate = useNavigate();
    const [isSuccessModalOpen, setSuccessModal] = useState(false);
    const [isErrorModalOpen, setErrorModal] = useState(false);
    const [landingPageURL, setLandingPageURL] = useState("");
    const {mutateAsync} = useUploadImage();
    const {mutate} = useUpdateAdUploadStatus();
    const {mutateAsync: mutateAsyncPreview} = useCreateAdPreview();

    const {mediaDetails} = useSelector((state) => state.facebookVideoAd)
    const [hashArray, setHashArray] = useState([]);
    const {setAppLoading, SuccessToaster, ErrorToaster, appLoading} = context;

    const schema = yup.object().shape({
        videoURL: yup.object().required().nullable(),
        thumbnailURL: yup.object().required().nullable(),
    });

    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            videoURL: null,
            videoFile: null,
            thumbnailURL: null,
            thumbnailFile: null,
        },
        validationSchema: schema,
        onSubmit: (values => {
            dispatch(settingFacebookVideoFormMedia(values))
            nextStep()
        })
    });


    const {
        errors,
        values,
        touched,
        handleSubmit,
        isValid,
        setFieldValue,
        setErrors,
    } = formik;

    const onDrop = useCallback(async (accFiles) => {
        accFiles.map((file) => {
            let fileType = file.type.split("/").shift();
            if (fileType === "video") {
                const objectURL = URL.createObjectURL(file);
                const mySound = new Audio([objectURL]);

                let video = document.createElement('video');
                video.preload = 'metadata';
                video.onloadedmetadata = function () {
                    window.URL.revokeObjectURL(video.src);
                    return video
                }
                video.src = URL.createObjectURL(file);

                if (file) {
                    console.log(file.size <= FB_VIDEO_SIZE, file.size, FB_VIDEO_SIZE)
                    mySound.addEventListener("canplaythrough", async () => {
                        const ratio = calculateAspectRatios(video.videoHeight, video.videoWidth);
                        if (!ratio >= parseInt('1:1') && !ratio <= parseInt('5:4')) {
                            ErrorToaster('Please valid aspect ratio.')
                        } else if (mySound.duration > FB_VIDEO_DURATION) {
                            ErrorToaster('Please valid Video Duration: 1 second to 60 minutes.')
                        } else if (file.size > FB_VIDEO_SIZE) {
                            ErrorToaster('Please valid video file size')
                        } else {
                            setAppLoading(true);
                            await mutateAsync(
                                {
                                    clientId: clientId,
                                    campaignBriefId: id,
                                    adFile: accFiles,
                                    type: 'fbAdVideos',
                                }, {
                                    onSuccess: (data, variables, context) => {
                                        setFieldValue('videoURL', data.file)
                                        setFieldValue('videoFile', data)
                                        setAppLoading(false);
                                        SuccessToaster('Video Uploaded Successfully.')
                                    },
                                    onError: (err) => {
                                        setAppLoading(false);
                                    }
                                });
                        }
                    });
                }
            } else {
                const img = new Image()
                img.onload = async () => {
                    const {naturalWidth: width, naturalHeight: height} = img
                    if (width < 1048 && height < 1048) {
                        ErrorToaster('Please valid height and width.')
                    } else if (file.size > FB_IMAGE_SIZE) {
                        ErrorToaster('Please valid file size')
                    } else {
                        setAppLoading(true);
                        await mutateAsync(
                            {
                                clientId: clientId,
                                campaignBriefId: id,
                                adFile: accFiles,
                                type: "fbAdImages",
                            }, {
                                onSuccess: (data, variables, context) => {
                                    setFieldValue('thumbnailURL', data.file)
                                    setFieldValue('thumbnailFile', data)
                                    setAppLoading(false);
                                    SuccessToaster('Image Upload Successfully.')
                                },
                                onError: () => {
                                    setAppLoading(false);
                                }
                            });
                    }
                }
                img.src = URL.createObjectURL(file)
            }
        });
    }, []);

    useEffect(() => {
        // setFieldValue("videoURL", mediaDetails?.videoFile ? mediaDetails.videoFile : null);
        setFieldValue("videoURL", mediaDetails?.videoURL ? mediaDetails.videoURL : null);
        setFieldValue("thumbnailURL", mediaDetails?.thumbnailURL ? mediaDetails.thumbnailURL : null);
        setFieldValue("thumbnailFile", mediaDetails?.thumbnailFile ? mediaDetails.thumbnailFile : null);
        setFieldValue("videoFile", mediaDetails?.videoFile ? mediaDetails.video_id || mediaDetails.videoFile : null);
    }, [mediaDetails])


    return (
        <>
            {
                appLoading && <FullPageLoader/>
            }
            <Box className="file-upload-box">

                {
                    adUploadId && (
                        <Box display="flex" mb={6} justifyContent="space-between" alignItems="center">
                            <Heading fontSize="xl" color={"#757998"}>

                                Please review the ad details carefully.
                            </Heading>
                            <Heading fontSize="md" color="gray.400">
                                2 steps to complete
                            </Heading>
                        </Box>
                    )
                }

                {
                    !adUploadId && (
                        <Box display="flex" mb={6} justifyContent="space-between" alignItems="center">
                            <Heading fontSize="xl" color={"#757998"}>
                                Please upload your ad creative.
                            </Heading>
                            <Heading fontSize="md" color="gray.400">
                                2 steps to complete
                            </Heading>
                        </Box>
                    )
                }

                <FormikProvider value={formik}>
                    <Form autoComplete="off" onSubmit={handleSubmit}>
                        <FacebookVideoAdVideoUpload
                            type="facebook-video"
                            values={values}
                            setFieldValue={setFieldValue}
                            errors={errors}
                            touched={touched}
                            setErrors={setErrors}
                            onDrop={onDrop}
                        />
                        <FacebookVideoAdThumbnailUpload
                            type="facebook-video-thumbnail"
                            values={values}
                            setFieldValue={setFieldValue}
                            errors={errors}
                            touched={touched}
                            setErrors={setErrors}
                            onDrop={onDrop}
                        />
                        <Flex width="100%" justify="flex-end" py={10}>
                            <Button
                                mr={4}
                                onClick={prevStep}
                                colorScheme='blue' variant='outline' borderRadius={0} px={10}

                            >
                                Back
                            </Button>
                            <Button mr={4} colorScheme='green' type="submit" variant='solid' borderRadius={0} px={10}>
                                Next
                            </Button>
                        </Flex>
                    </Form>

                </FormikProvider>

            </Box>
        </>
    )
}

export default WithAppContext(FacebookVideoAdMedia)