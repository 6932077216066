import {Step, Steps, useSteps} from "chakra-ui-steps";
import AdUploadFormStepButton from "./StepByStepAdUploadComponent/AdUploadFormStepButton";
import React, {useEffect, useState} from "react";
import SocialPlatform from "./StepByStepAdUploadComponent/SocialPlatform";
import SocialMediaPlatformType from "./StepByStepAdUploadComponent/SocialMediaPlatformType";
import AdDetailsForm from "./StepByStepAdUploadComponent/AdDetailsForm";
import AdMediaForm from "./StepByStepAdUploadComponent/AdMediaForm";
import AdPreview from "./StepByStepAdUploadComponent/AdPreview";
import EditAdDetailsForm from "./StepByStepAdUploadComponent/EditAdDetailForm";
import {useNavigate, useParams} from "react-router-dom";
import {useGetAdUpload} from "../../../../hooks/campaign-briefs/useGetAdUpload";
import {useDispatch, useSelector} from "react-redux";
import {settingFormDetails, settingFormMedia, settingEditMediaType} from "../../../../store/adUploads";
import _ from "lodash";
import {Box, Button, Heading, Icon} from "@chakra-ui/react";
import {useUpdateAdUploadStatus} from "../../../../hooks/campaign-briefs/useUpdateAdUploadStatus";
import AdApproveSuccessUI from "./StepByStepAdUploadComponent/AdApproveSuccessUI";
import {deleteAllFacebookImageField} from "./StepByStepAdUploadComponent/Facebook/FacebookImage/store";

function EditAd() {

    const clientId = useSelector((state) => state.client.clientId);
    const {id, adUploadId} = useParams();
    const { data, refetch } = useGetAdUpload(clientId, id, adUploadId);
    const  { editMediaType } = useSelector((state) => state.adUpload);
    const [ adStatus , setAdStatus ] = useState(null)

    const dispatch = useDispatch()
    useEffect(() => {
        refetch()
    }, [])

    useEffect(() => {
        dispatch(settingEditMediaType(data?.adUpload?.ad_upload_type));
    }, [data])


    const { nextStep, prevStep, reset, activeStep, setStep } = useSteps({
        initialStep: 0,
    });

    const totalStep = [1,2,3];
    const totalStepForDV360YoutubeStep = [1,2,3];
    const {mutate} = useUpdateAdUploadStatus();
    const navigate = useNavigate();



    const steps = [
        { label: "Ad Details", content: <AdDetailsForm activeStep={activeStep} steps={totalStep} prevStep={prevStep} nextStep={nextStep} label="Ad Details"/> },
        { label: "Ad Media", content: <AdMediaForm activeStep={activeStep} steps={totalStep} prevStep={prevStep} nextStep={nextStep} label="Ad Media" /> },
        { label: "Ad Preview", content: <AdPreview  activeStep={activeStep} steps={totalStep} prevStep={prevStep} nextStep={nextStep} label="Ad Preview" />},
        { label: "Empty", content: <AdApproveSuccessUI  adStatus={adStatus} />},
    ];

    const DV360YoutubeStep = [
        { label: "Ad Details", content: <AdDetailsForm activeStep={activeStep} steps={totalStepForDV360YoutubeStep} prevStep={prevStep} nextStep={nextStep} label="Ad Details"/> },
        { label: "Ad Preview", content: <AdPreview  activeStep={activeStep} steps={totalStepForDV360YoutubeStep} prevStep={prevStep} nextStep={nextStep} label="Ad Preview" />},
        { label: "Empty", content: <AdApproveSuccessUI adStatus={adStatus} />},
    ]

    const Google = [
        { label: "Ad Details", content: <AdDetailsForm activeStep={activeStep} steps={totalStepForDV360YoutubeStep} prevStep={prevStep} nextStep={nextStep} label="Ad Details"/> },
        { label: "Ad Preview", content: <AdPreview  activeStep={activeStep} steps={totalStepForDV360YoutubeStep} prevStep={prevStep} nextStep={nextStep} label="Ad Preview" />},
        { label: "Empty", content: <AdApproveSuccessUI adStatus={adStatus} />},
    ]

    const [ initialStep, setInitialStep ] = useState(steps);

    useEffect(() => {
        if(editMediaType === "DV360YoutubeVideo") {
            setInitialStep(DV360YoutubeStep)
        } else if(editMediaType === "Google") {
            setInitialStep(Google)
        } else {
            setInitialStep(steps)
        }
    }, [editMediaType, adStatus])

    const handleStatus = (status) => {
        setAdStatus(status)

        mutate(
            {
                clientId,
                campaignId: id,
                adUploadId,
                status,
            },
            {
                onSuccess: () => {

                    if(editMediaType === "DV360YoutubeVideo") {
                        setStep(totalStep.length - 1)
                    } else if(editMediaType === "Google") {
                        setStep(totalStep.length - 1)
                    }else {
                        setStep(totalStep.length)
                    }

                    // if(status === "Approved") {
                    //     if(editMediaType === "DV360YoutubeVideo") {
                    //         setStep(totalStep.length - 1)
                    //     } else {
                    //         setStep(totalStep.length)
                    //     }
                    // } else if(status === "Rejected") {
                    //     navigate(`/campaign-briefs/${id}`, {
                    //         state: {isShowUpload: true},
                    //     });
                    //     dispatch(deleteAllFacebookImageField())
                    // }
                }
            }
        )
    }

    return (
        <>
            <Box width={"100%"} display="flex" alignItems={"center"} justifyContent={"space-between"} >
            {/*<Heading color={"gray"} fontSize="xl">*/}
            {/*    Current status:*/}
            {/*    <span style={{marginLeft: "10px"}}>*/}
            {/*            <Icon*/}
            {/*                viewBox="0 0 200 200"*/}
            {/*                mr={2}*/}
            {/*                color={data?.adUpload?.status === "Created" ? "#59AB9E" : data?.adUpload?.status === "Approved" ? "#3F7EE6" : data?.adUpload?.status === "Rejected" ? "#FFA383" : "#FFA383"}*/}
            {/*            >*/}
            {/*                <path*/}
            {/*                    fill="currentColor"*/}
            {/*                    d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"*/}
            {/*                />*/}
            {/*            </Icon>*/}
            {/*        {data?.adUpload?.status || "Draft"}*/}
            {/*        </span>*/}
            {/*</Heading>*/}
            <Heading></Heading>
            {data && _.toUpper(data?.adUpload?.status) === "CREATED" &&
            <Box display="flex"  justifyContent={"flex-end"}>
                <Button
                    size="sm"
                    mr={2}
                    colorScheme="green"
                    backgroundColor="green.400"
                    borderRadius={4}
                    onClick={() => handleStatus('Approved')}
                >
                    Approve
                </Button>
                <Button
                    size="sm"
                    colorScheme="red"
                    backgroundColor="red.400"
                    borderRadius={4}
                    onClick={() => handleStatus('Rejected')}
                >
                    Reject
                </Button>
            </Box>
            }
            </Box>

            <div className="form-step-main">

                <Steps activeStep={activeStep} mt="8">
                    {initialStep.map(({ label, content }) => {
                            return (
                                <Step key={label} pb={5}>
                                    {content}
                                    <AdUploadFormStepButton color={"#757998"} activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
                                </Step>
                            )
                        }
                    )}
                </Steps>
            </div>
        </>
    )
}

export default EditAd