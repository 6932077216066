import {
    Box, Button,
    Container, Flex,
    Grid,
    GridItem,
    Heading,
    Image, Spacer, Text
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {PREVIEW_BG_COLOR, TEXT_COLOR, INDICATOR_BG_COLOR} from "../../../../../../../layout/constant/MenuList";
import {useDispatch, useSelector} from "react-redux";
import instance from "../../../../../../../helpers/axios";
import {useNavigate, useParams} from "react-router-dom";
import WithAppContext from "../../../../../../../helpers/appContext";
import SuccessModal from "../../../../../../../components/PopupModal/SuccessModal";
import ErrorModal from "../../../../../../../components/PopupModal/ErrorModal";
import FullPageLoader from "../../../../../../../components/FullPageLoader";
import {deleteTheField} from "../../../../../../../store/adUploads/index"
import {useGetAdUpload} from "../../../../../../../hooks/campaign-briefs/useGetAdUpload";
import ReactPlayer from "react-player";
import {deleteAlLDV360YoutubeVideoField} from "./store";
import {AD_UPLOAD_TYPE} from "../../../../../../../constant";
import {useRecoilValue} from "recoil";
import {profile} from "../../../../../../../atoms/authAtom";

const DV360YoutubePreview = ({SetTabIndex, activeStep, steps, prevStep, nextStep, label, context}) => {

    const [index, setIndex] = useState(0);
    const [ads, setAds] = useState([]);
    const [method, setMethod] = useState();
    const [url, setUrl] = useState();
    const { id: campaignId, adUploadId } = useParams();
    const clientId = useSelector((state) => state.client.clientId);
    const {setAppLoading, appLoading} = context;
    const [isSuccessModalOpen, setSuccessModal] = useState(false);
    const [isErrorModalOpen, setErrorModal] = useState(false);
    const [description, setDescription] = useState("");
    const navigate = useNavigate();


    const { data, refetch } = useGetAdUpload(clientId, campaignId, adUploadId);
    const {
        access_info: { roles, clients: userClients },
    } = useRecoilValue(profile);
    const [isClient, setIsClient] = useState(false)

    useEffect(() => {
        setIsClient(roles.includes("Client"));
    }, [roles]);

    useEffect(() => {
        refetch()
    }, [])

    useEffect(() => {
        if (adUploadId) {
            setMethod("PUT");
            setUrl(
                `/client/${clientId}/campaign-brief/${campaignId}/ad-upload/${adUploadId}`
            );
        } else {
            setMethod("POST");
            setUrl(`/client/${clientId}/campaign-brief/${campaignId}/ad-upload`);
        }
    }, [adUploadId, clientId]);

    const {handlePreviewData, formDetails, mediaDetails} = useSelector((state) => state.adUpload)
    const { formDetails: youtubeVideoDetails } = useSelector((state) => state.dv360YoutubeVideo)
    const { fileInfoList} = formDetails

    useEffect(() => {
        setAds(handlePreviewData)
    }, [handlePreviewData]);


    const dispatch = useDispatch()


    const onCloseSuccessModal = () => {
        setSuccessModal(false)

        if(adUploadId) {
            navigate(`/campaign-briefs/${campaignId}`, {
                state: {isShowUpload: true}
            })
            dispatch(deleteAlLDV360YoutubeVideoField())
            dispatch(deleteTheField())

        }
        else {
            SetTabIndex(isClient ? 0 : 3);
            navigate(`/campaign-briefs/${campaignId}`)
            dispatch(deleteAlLDV360YoutubeVideoField())
            dispatch(deleteTheField())

        }
    }

    async function handleSubmit() {
        try {
            setAppLoading(true);
            let payload = {
                name: youtubeVideoDetails.adName,
                detail: {
                    name: youtubeVideoDetails.adName,
                    landingPageURL: youtubeVideoDetails.landingPageURL,
                    youtubeURL: youtubeVideoDetails.youtubeURL,
                },
            }
            if (!adUploadId) {
                payload = {
                    ...payload,
                    ad_upload_type: AD_UPLOAD_TYPE.DV360YOUTUBEVIDEO
                }
            };
            await instance({
                method: method,
                url: url,
                withCredentials: false,
                data: payload,
            })
                .then((res) => {
                    if (res.status === 200) {
                        setSuccessModal(true);
                    }
                    setAppLoading(false);
                })
                .catch((error) => {
                    // setLandingPageURL(error.response.data.message);
                    setErrorModal(true);
                    setAppLoading(false);
                    setDescription(error?.response?.data?.message);

                });
        } catch (error) {
            setAppLoading(false);
        }
    }


    return (
        <>
            {
                adUploadId && (
                    <Box display="flex" mb={6} justifyContent="space-between" alignItems="center">
                        <Heading fontSize="xl" color={"#757998"}>
                            Please review the ad details carefully.
                        </Heading>
                        <Heading fontSize="md" color="gray.400">
                            1 steps to complete
                        </Heading>
                    </Box>
                )
            }

            {
                !adUploadId && (
                    <Box display="flex" mb={6} justifyContent="space-between" alignItems="center">
                        <Heading fontSize="xl" color={"#757998"}>
                            Please review your ad.
                        </Heading>
                        <Heading fontSize="md" color="gray.400">
                            1 steps to complete
                        </Heading>
                    </Box>
                )
            }

            <Grid templateColumns="repeat(6, 1fr)" gap={4} className="fb-upload">
                {appLoading && <FullPageLoader />}
                <GridItem w="full" colSpan={{base: 6, lg: 6}}>

                    <Box width={"100%"} display={"flex"} justifyContent={"center"}>
                        <ReactPlayer
                            className='react-player'
                            controls
                            url={youtubeVideoDetails?.youtubeURL}
                        />
                    </Box>

                    <Flex width="100%" justify="flex-end" py={10}>
                        <Button
                            mr={4}
                            onClick={prevStep}
                            colorScheme='blue' variant='outline' borderRadius={0} px={10}
                        >
                            Back
                        </Button>
                        <Button mr={4} colorScheme='blue' variant='solid' borderRadius={0} px={10}>
                            Save Draft
                        </Button>
                        <Button mr={4} onClick={handleSubmit} colorScheme='green' variant='solid' borderRadius={0} px={10}>
                            { adUploadId ? 'Update' : 'Submit'}
                        </Button>
                    </Flex>

                </GridItem>
                <SuccessModal
                    isOpen={isSuccessModalOpen}
                    onClose={() => onCloseSuccessModal()}
                />
                <ErrorModal
                    isOpen={isErrorModalOpen}
                    onClose={() => setErrorModal(false)}
                    description={description}
                />
            </Grid>
        </>
    );
};

export default WithAppContext(DV360YoutubePreview);
