import { createSlice } from "@reduxjs/toolkit";
import {AD_UPLOAD_TYPE} from "../../../../../../../../constant";

const initialState = {
    formDetails: {},
    mediaDetails: null,
    handlePreviewData: null,
};

export const tiktokVideoSlice = createSlice({
    name: AD_UPLOAD_TYPE.TIKTOK_VIDEO,
    initialState,
    reducers: {
        settingTiktokVideoFormDetail: (state, action) => {
            state.formDetails = action.payload

        },
        settingTiktokVideoFormMedia: (state, action) => {
            state.mediaDetails = action.payload
        },
        settingTiktokVideoHandlePreview: (state, action) => {
            state.handlePreviewData = action.payload
        },
        deleteAllTiktokVideoFields: (state, action) => {
            state.formDetails = {};
            state.mediaDetails = null;
            state.handlePreviewData = null;
        }
    },
});

export const { deleteAllTiktokVideoFields,settingTiktokVideoFormDetail, settingTiktokVideoFormMedia, settingTiktokVideoHandlePreview } = tiktokVideoSlice.actions;

// The reducer
export const tiktokVideoReducer = tiktokVideoSlice.reducer;
