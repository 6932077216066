import {
  Divider,
  Grid,
  GridItem,
  Text,
  VStack,
  Box,
  useToast,
  Icon,
  Button,
  FormLabel,
} from "@chakra-ui/react";
import { Form, Formik, useFormik } from "formik";
import { InputControl, SubmitButton } from "formik-chakra-ui";
import { useChangePassword } from "../../hooks/users/useChangePassword";
import { Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import * as Yup from "yup";

const schema = Yup.object().shape({
  currentPassword: Yup.string().required().label("Current Password"),
  newPassword: Yup.string().required("Please provide a valid password"),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .min(5)
    .max(20)
    .required()
    .label("New Password"),
});

const PasswordUpdate = () => {
  const toast = useToast();
  var { mutateAsync } = useChangePassword();
  const [visiblePassword, setVisiblePassword] = useState("password");
  const [lowerValidated, setLowerValidated] = useState(false);
  const [upperValidated, setUpperValidated] = useState(false);
  const [numberValidated, setNumberValidated] = useState(false);
  const [specialValidated, setSpecialValidated] = useState(false);
  const [lengthValidated, setLengthValidated] = useState(false);

  const initialValues = {
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };

  const handleSubmit = async (values, actions) => {
    await mutateAsync(
      {
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
        confirmPassword: values.confirmNewPassword,
      },
      {
        onSuccess: (data, variables, context) => {
          toast({
            isClosable: true,
            status: "success",
            variant: "top-accent",
            position: "top-right",
            title: "Success",
            description: data.message,
          });
          actions.resetForm();
          setTimeout(() => (window.location = "/login"), 800);
        },
      }
    );
  };

  const IconComponent = (props) => {
    return (
      <Icon
        boxSize={3}
        viewBox="0 0 200 200"
        mt={2}
        color={props.value ? "#3B82F6" : "#C4C4C4"}
      >
        <path
          fill="currentColor"
          d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
        />
      </Icon>
    );
  };

  const handleChange = (value) => {
    const lower = new RegExp("(?=.*[a-z])");
    const upper = new RegExp("(?=.*[A-Z])");
    const number = new RegExp("(?=.*[0-9])");
    const special = new RegExp("(?=.*[!@#$%^&*])");
    const length = new RegExp("(?=.{8,})");

    if (lower.test(value)) {
      setLowerValidated(true);
    } else {
      setLowerValidated(false);
    }
    if (upper.test(value)) {
      setUpperValidated(true);
    } else {
      setUpperValidated(false);
    }
    if (number.test(value)) {
      setNumberValidated(true);
    } else {
      setNumberValidated(false);
    }
    if (special.test(value)) {
      setSpecialValidated(true);
    } else {
      setSpecialValidated(false);
    }
    if (length.test(value)) {
      setLengthValidated(true);
    } else {
      setLengthValidated(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
      width={"77.5%"}
    >
      <VStack autoComplete="off" as={Form} align="stretch">
        <VStack align="stretch" spacing={0}>
          <Text
            color="brand.heading"
            fontSize={{
              base: "lg",
              md: "xl",
            }}
            fontWeight="semibold"
          >
            Password
          </Text>
          <Text
            color="brand.text"
            fontSize={{
              base: "xs",
              md: "sm",
            }}
          >
            Please enter your current password to change your password.
          </Text>
        </VStack>
        <VStack
          align="stretch"
          spacing={{
            base: 0,
            md: 6,
          }}
          gap={{
            base: 6,
            md: 4,
          }}
          mt="8"
        >
          <Grid
            templateColumns="repeat(4, 1fr)"
            alignItems="center"
            columns={2}
          >
            <GridItem colSpan={{ base: 4, lg: 1 }}>
              <FormLabel>Current password</FormLabel>
            </GridItem>
            <GridItem colSpan={{ base: 4, lg: 3 }}>
              <InputControl name="currentPassword" />
            </GridItem>
          </Grid>
          <Grid
            mt={{
              md: "4",
            }}
            templateColumns="repeat(4, 1fr)"
            columns={2}
          >
            <GridItem colSpan={{ base: 4, lg: 1 }}>
              <FormLabel>New password</FormLabel>
            </GridItem>
            <GridItem colSpan={{ base: 4, lg: 3 }}>
              <Flex alignItems="center" position={"relative"}>
                <InputControl
                  name="newPassword"
                  onChange={(e) => handleChange(e.target.value)}
                  inputProps={{
                    type: visiblePassword,
                  }}
                />
                {visiblePassword === "password" && (
                  <ViewIcon
                    onClick={() => setVisiblePassword("text")}
                    zIndex="999"
                    cursor="pointer"
                    position={"absolute"}
                    top={"10px"}
                    right={"20px"}
                    boxSize={5}
                  />
                )}
                {visiblePassword === "text" && (
                  <ViewOffIcon
                    onClick={() => setVisiblePassword("password")}
                    zIndex="999"
                    cursor="pointer"
                    position={"absolute"}
                    top={"10px"}
                    right={"20px"}
                    boxSize={5}
                  />
                )}
              </Flex>
              <Grid
                templateColumns={{
                  base: "repeat(1, max-content)",
                  lg: "repeat(2, max-content)",
                }}
                gridColumnGap={8}
                mt={2}
              >
                <GridItem>
                  <div>
                    <Flex alignItems="center">
                      <IconComponent value={lowerValidated} />
                      <Text mt={2} ml={2} color={"brand.text"} fontSize="xs">
                        Contains a lowercase character
                      </Text>
                    </Flex>
                  </div>
                </GridItem>
                <GridItem>
                  <div>
                    <Flex alignItems="center">
                      <IconComponent value={lengthValidated} />
                      <Text mt={2} ml={2} color={"brand.text"} fontSize="xs">
                        8 Characters minimum
                      </Text>
                    </Flex>
                  </div>
                </GridItem>
                <GridItem>
                  <div>
                    <Flex alignItems="center">
                      <IconComponent value={upperValidated} />
                      <Text mt={2} ml={2} color={"brand.text"} fontSize="xs">
                        Contains an uppercase character
                      </Text>
                    </Flex>
                  </div>
                </GridItem>
                <GridItem>
                  <div>
                    <Flex alignItems="center">
                      <IconComponent value={numberValidated} />
                      <Text mt={2} ml={2} color={"brand.text"} fontSize="xs">
                        Contains a digit
                      </Text>
                    </Flex>
                  </div>
                </GridItem>
                <GridItem>
                  <div>
                    <Flex alignItems="center">
                      <IconComponent value={specialValidated} />
                      <Text mt={2} ml={2} color={"brand.text"} fontSize="xs">
                        Contains a Special Character
                      </Text>
                    </Flex>
                  </div>
                </GridItem>
              </Grid>
            </GridItem>
          </Grid>

          <Grid
            templateColumns="repeat(4, 1fr)"
            alignItems="center"
            columns={2}
          >
            <GridItem colSpan={{ base: 4, lg: 1 }}>
              <FormLabel
                fontSize={{
                  base: "xs",
                  md: "sm",
                }}
              >
                Confirm new password
              </FormLabel>
            </GridItem>
            <GridItem colSpan={{ base: 4, lg: 3 }}>
              <InputControl
                name="confirmNewPassword"
                borderColor="brand.text"
                inputProps={{
                  type: "password",
                }}
              />
            </GridItem>
          </Grid>
          <Grid
            templateColumns="repeat(4, 1fr)"
            alignItems="center"
            columns={2}
            pt={{
              base: "4",
              md: "0",
            }}
          ></Grid>
          <Flex justify="end">
            <SubmitButton>Update</SubmitButton>
          </Flex>
        </VStack>
      </VStack>
    </Formik>
  );
};

export default PasswordUpdate;
