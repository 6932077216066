import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Spacer,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  PREVIEW_BG_COLOR,
  TEXT_COLOR,
  INDICATOR_BG_COLOR,
} from "../../../../../../../layout/constant/MenuList";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { AD_UPLOAD_TYPE } from "../../../../../../../constant";
import { useUploadImage } from "../../../../../../../hooks/campaign-briefs/useUploadImage";
import instance from "../../../../../../../helpers/axios";
import { useNavigate, useParams } from "react-router-dom";
import WithAppContext from "../../../../../../../helpers/appContext";
import SuccessModal from "../../../../../../../components/PopupModal/SuccessModal";
import ErrorModal from "../../../../../../../components/PopupModal/ErrorModal";
import FullPageLoader from "../../../../../../../components/FullPageLoader";
import { deleteTheField } from "../../../../../../../store/adUploads/index";
import { useGetAdUpload } from "../../../../../../../hooks/campaign-briefs/useGetAdUpload";
import { useRecoilValue } from "recoil";
import { profile } from "../../../../../../../atoms/authAtom";

const DV360ImageAdPreview = ({
  SetTabIndex,
  activeStep,
  steps,
  prevStep,
  nextStep,
  label,
  context,
}) => {
  const [index, setIndex] = useState(0);
  const [ads, setAds] = useState([]);
  const [method, setMethod] = useState();
  const [url, setUrl] = useState();
  const { id: campaignId, adUploadId } = useParams();
  const clientId = useSelector((state) => state.client.clientId);
  const { setAppLoading, appLoading } = context;
  const [isSuccessModalOpen, setSuccessModal] = useState(false);
  const [isErrorModalOpen, setErrorModal] = useState(false);
  const [description, setDescription] = useState("");
  const {
    access_info: { roles, clients: userClients },
  } = useRecoilValue(profile);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(roles.includes("Client"));
  }, [roles]);

  const { data, refetch } = useGetAdUpload(clientId, campaignId, adUploadId);

  useEffect(() => {
    if (adUploadId) {
      refetch();
    }
  }, [adUploadId]);

  useEffect(() => {
    if (adUploadId) {
      setMethod("PUT");
      setUrl(
        `/client/${clientId}/campaign-brief/${campaignId}/ad-upload/${adUploadId}`
      );
    } else {
      setMethod("POST");
      setUrl(`/client/${clientId}/campaign-brief/${campaignId}/ad-upload`);
    }
  }, [adUploadId, clientId]);

  const { handlePreviewData, formDetails, mediaDetails } = useSelector(
    (state) => state.adUpload
  );
  const { fileInfoList } = formDetails;

  useEffect(() => {
    setAds(handlePreviewData);
  }, [handlePreviewData]);

  const getSlider = () => {
    if (ads?.length) {
      const slides = ads.map((el, ind) => (
        <Text
          height={1}
          borderRadius={5}
          px={4}
          mx={1}
          mt={8}
          key={ind}
          background={ind === index ? INDICATOR_BG_COLOR : "silver"}
          onClick={() => setIndex(ind)}
          cursor="pointer"
        />
      ));
      return slides;
    }
  };

  const { mutateAsync } = useUploadImage();

  const onSubmitImages = async (multiFiles) => {
    let payload = [];
    let otherFile = [];
    let files = [];

    multiFiles.forEach((file) => {
      if (file?.fieldname) {
        otherFile = [...fileInfoList];
      } else {
        payload.push(file);
      }
    });

    if (payload && payload.length) {
      await mutateAsync(
        {
          clientId: clientId,
          campaignBriefId: campaignId,
          adFile: payload,
          type: AD_UPLOAD_TYPE.DV360,
        },
        {
          onSuccess: (data, variables, context) => {
            payload = data.fileInfoList;
            setAppLoading(false);
          },
          onError: () => {
            setAppLoading(false);
          },
        }
      );
      files = [...otherFile, ...payload];
    } else {
      files = [...otherFile];
    }
    return files;
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onCloseSuccessModal = () => {
    setSuccessModal(false);

    if (adUploadId) {
      navigate(`/campaign-briefs/${campaignId}`, {
        state: { isShowUpload: true },
      });
      dispatch(deleteTheField());
    } else {
      SetTabIndex(isClient ? 0 : 3);
      // navigate(`/campaign-briefs/${campaignId}`, {
      //     state: {isShowUpload: true}
      // })
      navigate(`/campaign-briefs/${campaignId}`);
      dispatch(deleteTheField());
    }
  };

  async function handleSubmit() {
    setAppLoading(true);
    const files = await onSubmitImages(mediaDetails.imageURL);
    const mediaIds = files.map((el) => el.imageHash);

    const dimensions = files.map(() => {
      return {
        width: "",
        height: "",
      };
    });

    const fileInfoList = files.map((el) => {
      return {
        ...el,
        metadata: [{ height: "", width: "" }],
      };
    });

    let payload = {
      name: formDetails.adName,
      description: formDetails.description,
      detail: {
        name: formDetails.adName,
        creativeType: formDetails.creativeType,
        description: formDetails.description,
        displayName: formDetails.displayName,
        hostingSource: formDetails.hostingSource,
        mediaIds: mediaIds,
        dimensions: dimensions,
        fileInfoList,
      },
    };

    if (!adUploadId) {
      payload = {
        ...payload,
        ad_upload_type: AD_UPLOAD_TYPE.DV360,
      };
    }

    await instance({
      method: method,
      url: url,
      withCredentials: false,
      data: payload,
    })
      .then((res) => {
        if (res.status === 200) {
          setSuccessModal(true);
          setAppLoading(false);
        }
      })
      .catch((error) => {
        setDescription(error?.response?.data?.message);
        setErrorModal(true);
        setAppLoading(false);
      });
  }

  return (
    <>
      {adUploadId && (
        <Box
          display="flex"
          mb={6}
          justifyContent="space-between"
          alignItems="center"
        >
          <Heading fontSize="xl" color={"#757998"}>
            Please review the ad details carefully.
          </Heading>
          <Heading fontSize="md" color="gray.400">
            1 steps to complete
          </Heading>
        </Box>
      )}

      {!adUploadId && (
        <Box
          display="flex"
          mb={6}
          justifyContent="space-between"
          alignItems="center"
        >
          <Heading fontSize="xl" color={"#757998"}>
            Please review your ad.
          </Heading>
          <Heading fontSize="md" color="gray.400">
            1 steps to complete
          </Heading>
        </Box>
      )}

      <Grid templateColumns="repeat(6, 1fr)" gap={4} className="fb-upload">
        {appLoading && <FullPageLoader />}
        <GridItem w="full" colSpan={{ base: 6, lg: 6 }}>
          <Heading fontSize="lg" my={4} color={TEXT_COLOR}>
            {ads?.[index]?.filename}
          </Heading>
          <Box
            borderWidth="2px"
            borderColor="gray"
            p={10}
            background={PREVIEW_BG_COLOR}
            position={"relative"}
          >
            <Container height="100%">
              <Flex alignItems="center" flexDirection="row" justify="center">
                {ads && ads.length !== 0 && (
                  <Box border="2px" borderColor="#757998">
                    <Image
                      src={ads[index]?.imageUrl}
                      alt={ads[index]?.filename}
                    />
                  </Box>
                )}
              </Flex>
            </Container>
            <Flex
              templateColumns="repeat(12, 1fr)"
              justifyContent="space-between"
            >
              <Button
                leftIcon={<ArrowBackIcon color="brand.primary" w={10} h={10} />}
                py={8}
                variant="ghost"
                position={"absolute"}
                top={"50%"}
                style={{ transform: "translateY(-50%)" }}
                onClick={() => setIndex(index > 1 ? index - 1 : 0)}
              />
              <Spacer />
              <Box
                display={"flex"}
                position={"absolute"}
                bottom={"-14px"}
                left={"50%"}
                style={{ transform: "translateX(-50%)" }}
              >
                {getSlider()}
              </Box>

              <Spacer />
              <Button
                leftIcon={
                  <ArrowForwardIcon color="brand.primary" w={10} h={10} />
                }
                py={8}
                variant="ghost"
                position={"absolute"}
                top={"50%"}
                right={"0%"}
                style={{ transform: "translateY(-50%)" }}
                onClick={() =>
                  setIndex(
                    ads && Object.keys(ads)?.length > index + 1
                      ? index + 1
                      : index
                  )
                }
              />
            </Flex>
          </Box>

          <Flex width="100%" justify="flex-end" py={10}>
            <Button
              mr={4}
              onClick={prevStep}
              colorScheme="blue"
              variant="outline"
              borderRadius={0}
              px={10}
            >
              Back
            </Button>
            <Button
              mr={4}
              colorScheme="blue"
              variant="solid"
              borderRadius={0}
              px={10}
            >
              Save Draft
            </Button>
            <Button
              mr={4}
              onClick={handleSubmit}
              colorScheme="green"
              variant="solid"
              borderRadius={0}
              px={10}
            >
              {adUploadId ? "Update" : "Submit"}
            </Button>
          </Flex>
        </GridItem>
        <SuccessModal
          isOpen={isSuccessModalOpen}
          onClose={() => onCloseSuccessModal()}
        />
        <ErrorModal
          isOpen={isErrorModalOpen}
          onClose={() => setErrorModal(false)}
          description={description}
        />
      </Grid>
    </>
  );
};

export default WithAppContext(DV360ImageAdPreview);
