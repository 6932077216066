import React, { useEffect, useState } from "react";
import { Flex, Grid, HStack, Heading } from "@chakra-ui/layout";
import {
  Box,
  Text,
  Image,
  SimpleGrid,
  Button,
  Divider,
} from "@chakra-ui/react";
import { settingCampaignObjective } from "../../store/adCampaign";
import { useDispatch, useSelector } from "react-redux";
import {
  awarenessType,
  considerationType,
  conversionType,
} from "../../constant";
import { CheckIcon } from "@chakra-ui/icons";

function CampaignObjective({ activeStep, nextStep, prevStep, handleNotes }) {
  const { objective } = useSelector((state) => state.adCampaign);

  const dispatch = useDispatch();

  const activeTab = {
    border: "2px solid bRAND.PRIMARY",
    boxSizing: "border-box",
  };

  function handleClick(value) {
    dispatch(settingCampaignObjective(value));
  }

  return (
    <div style={{ fontFamily: "poppins" }}>
      <Heading mt={5} fontSize="2xl">
        Campaign Objective
      </Heading>
      <Box minH={460} color={"brand.label"}>
        <Text fontSize="lg" fontWeight={500} mt={10} mb={2}>
          Awareness
        </Text>
        <SimpleGrid columns={[1, 1, 1, 2, 2, 3, 4]} spacing="20px">
          {awarenessType.map((item, index) => {
            const { image, title, desc, value } = item;
            return (
              <Box
                cursor={"pointer"}
                border={"1px solid"}
                borderColor="gray.300"
                borderRadius={"10px"}
                minH="95px"
                display={"flex"}
                p={4}
                key={title}
                style={objective === value ? activeTab : null}
                onClick={() => handleClick(value)}
              >
                <HStack justify="space-between" w="full">
                  <HStack gap="3" color={"brand.text"}>
                    <Grid>
                      <Box
                        backgroundColor={"gray.100"}
                        padding={{
                          base: 2.5,
                          md: 3,
                        }}
                        borderRadius="full"
                        boxSize={{
                          base: "10",
                          md: "12",
                        }}
                      >
                        <Image
                          src={`${process.env.PUBLIC_URL}/images/${image}`}
                          height="full"
                          width="full"
                        />
                      </Box>
                    </Grid>
                    <Flex justify="start" direction="column" gap={1}>
                      <Heading
                        fontSize={{
                          base: "sm",
                          md: "md",
                        }}
                        fontFamily={"poppins"}
                        fontWeight={600}
                        color={"brand.label"}
                        textAlign="start"
                      >
                        {title}
                      </Heading>
                      <Text
                        lineHeight="1.1rem"
                        fontSize={{
                          base: "xs",
                          md: "sm",
                        }}
                        color={"brand.heading"}
                        noOfLines={2}
                        textAlign="start"
                      >
                        {desc}
                      </Text>
                    </Flex>
                  </HStack>
                  <Box>
                    {objective === value && (
                      <CheckIcon
                        marginRight={2}
                        fontSize={"lg"}
                        color={"brand.primary"}
                      />
                    )}
                  </Box>
                </HStack>
              </Box>
            );
          })}
        </SimpleGrid>

        <Text fontSize="lg" fontWeight={500} color="brand.label" mt={8} mb={2}>
          Consideration
        </Text>
        <SimpleGrid columns={[1, 1, 1, 2, 2, 3, 4]} spacing="20px">
          {considerationType.map((item, index) => {
            const { image, title, desc, value } = item;
            return (
              <Box
                cursor={"pointer"}
                border={"1px solid"}
                borderRadius="lg"
                borderColor="gray.300"
                minH="95px"
                display={"flex"}
                p={4}
                key={title}
                style={objective === value ? activeTab : null}
                onClick={() => handleClick(value)}
              >
                <HStack justify="space-between" w="full">
                  <HStack gap="3" color={"brand.heading"}>
                    <Grid>
                      <Box
                        backgroundColor={"gray.100"}
                        padding={{
                          base: 2.5,
                          md: 3,
                        }}
                        borderRadius="full"
                        boxSize={{
                          base: "10",
                          md: "12",
                        }}
                      >
                        <Image
                          src={`${process.env.PUBLIC_URL}/images/${image}`}
                          height="full"
                          width="full"
                        />
                      </Box>
                    </Grid>
                    <Flex justify="start" direction="column" gap={1}>
                      <Heading
                        fontSize={{
                          base: "sm",
                          md: "md",
                        }}
                        fontFamily={"poppins"}
                        fontWeight={600}
                        color={"brand.label"}
                        textAlign="start"
                      >
                        {title}
                      </Heading>
                      <Text
                        lineHeight="1.1rem"
                        fontSize={{
                          base: "xs",
                          md: "sm",
                        }}
                        color={"brand.heading"}
                        noOfLines={2}
                        textAlign="start"
                      >
                        {desc}
                      </Text>
                    </Flex>
                  </HStack>
                  <Box>
                    {objective === value && (
                      <CheckIcon
                        marginRight={2}
                        fontSize={"lg"}
                        color={"brand.primary"}
                      />
                    )}
                  </Box>
                </HStack>
              </Box>
            );
          })}
        </SimpleGrid>

        <Text fontSize="lg" fontWeight={500} color="brand.label" mt={8} mb={2}>
          Conversion
        </Text>
        <SimpleGrid columns={[1, 1, 1, 2, 2, 3, 4]} spacing="20px">
          {conversionType.map((item, index) => {
            const { image, title, desc, value } = item;
            return (
              <Box
                cursor={"pointer"}
                borderRadius="lg"
                border={"1px solid"}
                borderColor="gray.300"
                minH="95px"
                display={"flex"}
                p={4}
                key={title}
                style={objective === value ? activeTab : null}
                onClick={() => handleClick(value)}
              >
                <HStack justify="space-between" w="full">
                  <HStack gap="3" color={"brand.heading"}>
                    <Grid>
                      <Box
                        backgroundColor={"gray.100"}
                        padding={{
                          base: 2.5,
                          md: 3,
                        }}
                        borderRadius="full"
                        boxSize={{
                          base: "10",
                          md: "12",
                        }}
                      >
                        <Image
                          src={`${process.env.PUBLIC_URL}/images/${image}`}
                          height="full"
                          width="full"
                        />
                      </Box>
                    </Grid>
                    <Flex justify="start" direction="column" gap={1}>
                      <Heading
                        fontSize={{
                          base: "sm",
                          md: "md",
                        }}
                        fontFamily={"poppins"}
                        fontWeight={600}
                        color={"brand.label"}
                        textAlign="start"
                      >
                        {title}
                      </Heading>
                      <Text
                        lineHeight="1.1rem"
                        fontSize={{
                          base: "xs",
                          md: "sm",
                        }}
                        color={"brand.heading"}
                        noOfLines={2}
                        textAlign="start"
                      >
                        {desc}
                      </Text>
                    </Flex>
                  </HStack>
                  <Box>
                    {objective === value && (
                      <CheckIcon
                        marginRight={2}
                        fontSize={"lg"}
                        color={"brand.primary"}
                      />
                    )}
                  </Box>
                </HStack>
              </Box>
            );
          })}
        </SimpleGrid>
      </Box>
      <Divider my={8} />
      <Box
        style={{ display: "flex", justifyContent: "space-between" }}
        flexDirection={{ base: "column", md: "row" }}
      >
        <Button
          fontWeight={400}
          fontFamily={"poppins"}
          leftIcon={
            <Image
              src={`${process.env.PUBLIC_URL}/images/notes.svg`}
              height="18px"
            />
          }
          onClick={handleNotes}
        >
          Add Notes
        </Button>
        <HStack>
          <Button variant="outline" onClick={() => prevStep()}>
            Back
          </Button>
          <Button
            type="submit"
            isDisabled={!objective}
            onClick={() => nextStep()}
          >
            Next
          </Button>
        </HStack>
      </Box>
    </div>
  );
}

export default CampaignObjective;
