import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Flex,
    Text,
    Tooltip,
    IconButton,
    Box,
    HStack,
    Button,
    useDisclosure,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalCloseButton, Modal, Badge, Link, InputGroup, InputLeftElement, Input, Stack, GridItem, Grid,
} from "@chakra-ui/react";
import React, {useEffect, useMemo, useState} from "react";
import {usePagination, useSortBy, useTable} from "react-table";
import {FiEye} from "react-icons/fi";
import SitelinkModelContent from "./Components/SitelinkModelContent";
import ExtensionModelContent from "./Components/ExtensionModelContent";
import PreviewModelContext from "./Components/PreviewModelContext";
import {useGlobalContext} from "./store/WrapContext";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {GrLinkNext, GrLinkPrevious} from "react-icons/gr";
import "./css/desktopView.css"
// import {useGetAdUpload} from "../../../../../../hooks/campaign-briefs/useGetAdUpload";
import {useGetMasterData} from "../../../../../../../hooks/campaign-briefs/useTableData";
import {SearchIcon} from "@chakra-ui/icons";
import Loading from "../../../../../../../components/Loading";
import ErrorModal from "../../../../../../../components/PopupModal/ErrorModal";
import DownloadErrorModel from "../../../../../../../components/PopupModal/DownloadErrorModel";
import instance from "../../../../../../../helpers/axios";
import {deleteAllTiktokVideoFields} from "../../Tiktok/TiktokVideo/store";
import {deleteTheField} from "../../../../../../../store/adUploads";
import SuccessModal from "../../../../../../../components/PopupModal/SuccessModal";

function MyModal({isOpen, onClose, whichModel}) {

    const {emptyTheField} = useGlobalContext()

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay/>
            {whichModel === 'Sitelink' && <SitelinkModelContent whichModel={whichModel} onClose={onClose}/>}
            {whichModel === 'Extension' && <ExtensionModelContent whichModel={whichModel} onClose={onClose}/>}
            {whichModel === 'Preview' && <PreviewModelContext whichModel={whichModel} onClose={onClose}/>}
        </Modal>
    )
}

function handlePreview(data, onOpen, selectedDataFunction) {
    selectedDataFunction(data)
    onOpen()
}

function handleSiteLinkModel(onOpen) {
    onOpen()
}

function handleExtensionModel(onOpen) {
    onOpen()
}

const RowData = (data) => {
    const {
        id,
        onOpen,
        setWhichModel,
        selectedDataFunction,
        emptySiteLinkData,
        emptyExtensionData,
        apUploadId,
    } = data
    const {id: campaignId, adUploadId: googleId} = useParams();
    const navigate = useNavigate()

    function handleNavigate(id, selectedDataFunction) {
        navigate(`/campaign-brief/${campaignId}/ad-upload/google/${googleId}/editMaster`, {state: id})
    }

    return (
        <>
            <HStack>
                <Button
                    size="sm"
                    variant="outline"
                    aria-label="View details"
                    icon={<FiEye/>}
                    colorScheme="blue"
                    onClick={() => handleNavigate(id, selectedDataFunction)}
                    py={5}
                >
                    Edit
                </Button>
                <Button
                    size="sm"
                    variant="outline"
                    aria-label="View details"
                    icon={<FiEye/>}
                    colorScheme="red"
                    py={5}
                    onClick={() => {
                        handlePreview(id, onOpen, selectedDataFunction)
                        setWhichModel('Preview')
                    }}
                >
                    Preview
                </Button>
            </HStack>
        </>

    )
}

function GoogleAdsTableData({SetTabIndex, activeStep, steps, prevStep, nextStep, label ,context}) {

    const {id, adUploadId: googleId} = useParams();
    const clientId = useSelector((state) => state.client.clientId);
    const contextData = useGlobalContext()
    const {
        emptyExtensionData,
        emptySiteLinkData,
        selectedDataFunction,
        siteLinks,
        extension,
        setInitialMasterData
    } = contextData
    const {data: masterTableData, refetch} = useGetMasterData(clientId, id, googleId)
    const [tableData, setTableData] = useState([])
    const [refactorMasterData, setRefactorMasterData] = useState([])
    const [search, setSearch] = useState("");
    const [isSuccessModalOpen, setSuccessModal] = useState(false);

    const client = JSON.parse(localStorage.getItem("client"));
    const checkRole = client.access_info.roles.some((role) => role === 'Client');
    function getLocalStoragePageIndexData() {
        let localPageIndexNumber = localStorage.getItem("pageIndex")
        if (localPageIndexNumber) {
            return Number(localPageIndexNumber)
        }
        return 0
    }

    const [tablePageIndex, setTablePageIndex] = useState(getLocalStoragePageIndexData())

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onCloseSuccessModal = () => {
        setSuccessModal(false)
        navigate(`/campaign-briefs/${campaignId}`, {
                state: {isShowUpload: true}
            })
        dispatch(deleteTheField())
    }

    // console.log('table page index', tablePageIndex)

    const columns = useMemo(
        () => [
            {
                Header: 'Campaign',
                accessor: 'campaign', // accessor is the "key" in the data
            },
            {
                Header: 'Ad Group',
                accessor: 'ad_group', // accessor is the "key" in the data
            },
            {
                Header: 'Heading 1',
                accessor: 'headline_1',
            },
            {
                Header: 'Heading 2',
                accessor: 'headline_2',
            },
            {
                Header: 'Heading 3',
                accessor: 'headline_3',
            },
            {
                Header: 'Heading 4',
                accessor: 'headline_4',
            },
            {
                Header: 'Heading 5',
                accessor: 'headline_5',
            },
            {
                Header: 'Heading 6',
                accessor: 'headline_6',
            },
            {
                Header: 'Heading 7',
                accessor: 'headline_7',
            },
            {
                Header: 'Heading 8',
                accessor: 'headline_8',
            },
            {
                Header: 'Heading 9',
                accessor: 'headline_9',
            },
            {
                Header: 'headline_9_character_count',
                accessor: 'headline_9_character_count'
            },
            {
                Header: 'Heading 10',
                accessor: 'headline_10',
            },
            {
                Header: 'headline_10_character_count',
                accessor: 'headline_10_character_count'
            },
            {
                Header: 'Heading 11',
                accessor: 'headline_11',
            },
            {
                Header: 'headline_11_char_count',
                accessor: 'headline_11_char_count'
            },
            {
                Header: 'Heading 12',
                accessor: 'headline_12',
            },
            {
                Header: 'headline_12_char_count',
                accessor: 'headline_12_char_count'
            },
            {
                Header: 'Heading 13',
                accessor: 'headline_13',
            },
            {
                Header: 'headline_13_char_count',
                accessor: 'headline_13_char_count'
            },
            {
                Header: 'Heading 14',
                accessor: 'headline_14',
            },
            {
                Header: 'headline_14_char_count',
                accessor: 'headline_14_char_count'
            },
            {
                Header: 'Description 1',
                accessor: 'description_1',
            },
            {
                Header: 'Description 2',
                accessor: 'description_2',
            },
            {
                Header: 'Description 3',
                accessor: 'description_3',
            },
            {
                Header: 'Description 4',
                accessor: 'description_4',
            },
            {
                Header: () => <Text>Actions</Text>,
                accessor: "actions",
            }
        ],
        []
    )


    useEffect(() => {
        refetch();
    }, []);


    useEffect(() => {
        if (Array.isArray(masterTableData)) {
            setTableData(masterTableData)
            setInitialMasterData(masterTableData)
        }
    }, [masterTableData])


    const [whichModel, setWhichModel] = useState("")

    const {isOpen, onOpen, onClose} = useDisclosure()

    const data = refactorMasterData

    const {id: campaignId} = useParams();


    const [isSiteLinkDownloadLoading, setIsSiteLinkDownloadLoading] = useState(false)

    async function downloadSiteLink() {
        setIsSiteLinkDownloadLoading(true)
        await instance({
            method: 'post',
            url: `client/${clientId}/campaign-brief/${campaignId}/ad-upload/${googleId}/download/siteLink`,
            withCredentials: false,
            responseType: 'blob'
        }).then((resp) => {
            const url = window.URL.createObjectURL(new Blob([resp.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Sitelink_${Date.now()}.xlsx`);
            document.body.appendChild(link);
            link.click();
            setIsSiteLinkDownloadLoading(false)

        }).catch((err) => {
            setErrorModal(true);
            setIsSiteLinkDownloadLoading(false)
        })
    }

    const [isExtensionDownloadLoading, setIsExtensionDownloadLoading] = useState(false)

    async function downloadExtension() {
        setIsExtensionDownloadLoading(true)
        await instance({
            method: 'post',
            url: `client/${clientId}/campaign-brief/${campaignId}/ad-upload/${googleId}/download/extension`,
            withCredentials: false,
            responseType: 'blob'
        }).then((resp) => {
            const url = window.URL.createObjectURL(new Blob([resp.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Extension_${Date.now()}.xlsx`);
            document.body.appendChild(link);
            link.click();
            setIsExtensionDownloadLoading(false)
        }).catch((err) => {
            setErrorModal(true);
            setIsExtensionDownloadLoading(false)
        })
    }

    const [isMasterDownloadLoading, setIsMasterDownloadLoading] = useState(false)

    async function downloadMaster() {
        setIsMasterDownloadLoading(true)
        await instance({
            method: 'post',
            url: `client/${clientId}/campaign-brief/${campaignId}/ad-upload/${googleId}/download/master`,
            withCredentials: false,
            responseType: 'blob'
        }).then((resp) => {
            const url = window.URL.createObjectURL(new Blob([resp.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Master_${Date.now()}.xlsx`);
            document.body.appendChild(link);
            link.click();
            setIsMasterDownloadLoading(false)
        }).catch((err) => {
            setErrorModal(true);
            setIsMasterDownloadLoading(false)
        })
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: {pageIndex},
        rows
    } = useTable(
        {
            columns,
            data,
            initialState: {pageIndex: tablePageIndex, pageSize: 20},
            autoResetHiddenColumns: false,
            autoResetSortBy: false,
        },
        // useSortBy,
        usePagination
    );

    useEffect(() => {
        localStorage.setItem("pageIndex", pageIndex)
    }, [pageIndex]);


    useEffect(() => {
        const newData = tableData.map((item) => {
            return {
                ...item,
                actions: <RowData id={item.id} emptyExtensionData={emptyExtensionData}
                                  emptySiteLinkData={emptySiteLinkData} onOpen={onOpen}
                                  setWhichModel={setWhichModel} selectedDataFunction={selectedDataFunction}
                                  whichModel={whichModel}/>
            }
        })
        setRefactorMasterData(newData)
    }, [tableData]);


    useEffect(() => {
        if (masterTableData) {
            const data = masterTableData.filter((item) => item?.ad_group?.toLowerCase().includes(search.trim()))
            setRefactorMasterData(data.map((item) => {
                return {
                    ...item,
                    actions: <RowData id={item.id} emptyExtensionData={emptyExtensionData}
                                      emptySiteLinkData={emptySiteLinkData} onOpen={onOpen}
                                      setWhichModel={setWhichModel} selectedDataFunction={selectedDataFunction}
                                      whichModel={whichModel} pageIndex={pageIndex}/>
                }
            }))
        }
    }, [search])

    const [isErrorModalOpen, setErrorModal] = useState(false);


    if (!Array.isArray(masterTableData)) {
        return <Loading/>
    }

    return (
        <>
            {isOpen && <MyModal isOpen={isOpen} onClose={onClose} whichModel={whichModel}/>}
            <Box pb={6} display={"flex"} justifyContent={"space-between"}>
                <DownloadErrorModel
                    isOpen={isErrorModalOpen}
                    onClose={() => setErrorModal(false)}
                />
                {
                    !checkRole && <HStack>
                        <Button
                            size="sm"
                            variant="outline"
                            aria-label="View details"
                            icon={<FiEye/>}
                            colorScheme="blue"
                            py={5}
                            disabled={isMasterDownloadLoading}
                            onClick={() => downloadMaster()}
                        >
                            {isMasterDownloadLoading ? "Downloading..." : "Download Master"}
                        </Button>
                        <Button
                            size="sm"
                            variant="outline"
                            aria-label="View details"
                            icon={<FiEye/>}
                            colorScheme="red"
                            py={5}
                            disabled={isExtensionDownloadLoading}
                            onClick={() => downloadExtension()}
                        >
                            {isExtensionDownloadLoading ? "Downloading..." : "Download Extension"}
                        </Button>
                        <Button
                            size="sm"
                            variant="outline"
                            aria-label="View details"
                            icon={<FiEye/>}
                            colorScheme="red"
                            py={5}
                            disabled={isSiteLinkDownloadLoading}
                            onClick={() => downloadSiteLink()}
                        >
                            {isSiteLinkDownloadLoading ? "Downloading..." : "Download Sitelink"}
                        </Button>
                    </HStack>
                }
                <HStack justifyContent='flex-end'>
                    <Button
                        size="sm"
                        variant="outline"
                        aria-label="View details"
                        icon={<FiEye/>}
                        colorScheme="yellow"
                        py={5}
                        onClick={() => {
                            setWhichModel('Sitelink')
                            handleSiteLinkModel(onOpen, selectedDataFunction, emptySiteLinkData)
                        }}
                    >
                        Site Link
                        <Badge marginLeft="10px" colorScheme='red' rounded={"50%"}> {siteLinks.length} </Badge>
                    </Button>
                    <Button
                        size="sm"
                        variant="outline"
                        aria-label="View details"
                        icon={<FiEye/>}
                        colorScheme="red"
                        py={5}
                        onClick={() => {
                            setWhichModel('Extension')
                            handleExtensionModel(onOpen, selectedDataFunction, emptyExtensionData)
                        }}
                    >
                        Extension
                        <Badge marginLeft="10px" colorScheme='red'
                               rounded={"50%"}> {extension.call.length + extension.callout.length + extension.location.length} </Badge>
                    </Button>
                </HStack>
            </Box>
            <div className="search">
                <Stack spacing={4} width="50% !important" marginBottom={4}>
                    <InputGroup>
                        <InputLeftElement
                            pointerEvents="none"
                            children={<SearchIcon color="gray.300"/>}
                        />
                        <Input
                            name="search"
                            type="tel"
                            placeholder="Search"
                            onChange={(e) => {
                                setSearch(e.target.value)
                                setTablePageIndex(0)
                            }}
                        />
                    </InputGroup>
                </Stack>
            </div>
            <TableContainer>
                <Table className="tableData"  {...getTableProps()} variant="simple" size="sm">
                    <Thead backgroundColor="blue.50" height="55px">
                        {
                            headerGroups.map((headerGroup) => {
                                return (
                                    <Tr {...headerGroup.getHeaderGroupProps()}>
                                        {
                                            headerGroup.headers.map(column => (
                                                // Apply the header cell props
                                                <Th {...column.getHeaderProps()}>
                                                    {// Render the header
                                                        column.render('Header')
                                                    }
                                                </Th>
                                            ))
                                        }
                                    </Tr>
                                )
                            })
                        }
                    </Thead>
                    {
                        rows && rows.length > 0 && <Tbody {...getTableBodyProps()}>
                            {
                                page?.map((row, index) => {
                                    // if (index < 20) {
                                        prepareRow(row)
                                        return (
                                            <Tr {...row.getRowProps()}>
                                                {row.cells?.map(cell => {
                                                    return <Td p={5} {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                                                })}
                                            </Tr>
                                        )
                                    // }
                                })
                            }
                        </Tbody>
                    }
                </Table>
            </TableContainer>

            <Flex justifyContent="start" m={4} alignItems="center">
                <Flex>
                    <Tooltip label="Previous Page">
                        <IconButton
                            size="sm"
                            variant="ghost"
                            onClick={previousPage}
                            isDisabled={!canPreviousPage}
                            background={
                                !canPreviousPage
                                    ? "#F8F9FA !important"
                                    : "#79A9FF !important"
                            }
                            icon={
                                <GrLinkPrevious
                                    color={
                                        !canPreviousPage ? "#A7A9BD" : "#FFFFFF"
                                    }
                                    h={6}
                                    w={6}
                                />
                            }
                        />
                    </Tooltip>
                </Flex>
                <Flex alignItems="center" mx={3}>
                    <Text flexShrink="0">
                        {/* Page{" "} */}
                        <Text
                            borderColor="#79A9FF"
                            borderRadius={5}
                            color="#79A9FF"
                            px="18px"
                            py="3px"
                            borderWidth={2}
                            fontWeight="bold"
                            as="span"
                        >
                            {pageIndex + 1}
                        </Text>{" "}
                        <span style={{margin: "auto 8px", color: "#79A9FF"}}>
                            /
                        </span>
                        <Text p={1} color="#79A9FF" fontWeight="bold" as="span">
                            {pageOptions?.length}
                        </Text>
                    </Text>
                </Flex>
                <Flex>
                    <Tooltip label="Next Page">
                        <IconButton
                            size="sm"
                            variant="ghost"
                            onClick={nextPage}
                            isDisabled={!canNextPage}
                            background={
                                !canNextPage
                                    ? "#F8F9FA !important"
                                    : "#79A9FF !important"
                            }
                            icon={
                                <GrLinkNext
                                    color={!canNextPage ? "#A7A9BD" : "#FFFFFF"}
                                    h={6}
                                    w={6}
                                />
                            }
                        />
                    </Tooltip>
                </Flex>
            </Flex>
            <Flex width="100%" justify="flex-end" py={10}>
                <Button
                    mr={4}
                    onClick={prevStep}
                    colorScheme='blue' variant='outline' borderRadius={0} px={10}
                >
                    Back
                </Button>
                <Button mr={4} onClick={() => setSuccessModal(true)} colorScheme='green' variant='solid' borderRadius={0} px={10}>
                    Update
                </Button>
            </Flex>
            <SuccessModal
                isOpen={isSuccessModalOpen}
                onClose={() => onCloseSuccessModal()}
            />
        </>
    )
}

export default GoogleAdsTableData
