import * as Yup from "yup";

const editValidationSchema = Yup.object({
  firstName: Yup.string().required().label("First Name"),
  lastName: Yup.string().required().label("Last Name"),
  email: Yup.string().email().required().label("Email address"),
  roles: Yup.array().required().min(1).label("Roles"),
  clients: Yup.array().min(1).label("Clients"),
});

export default editValidationSchema;
