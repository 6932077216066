import { CloseIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import {
    Box, Button,
    Flex, Grid, Heading, Image, Input, Progress, Text, Tooltip
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import ReactPlayer from 'react-player';
import FileUploadIcon from "../../../../../../../assets/images/file-upload-icon.png";
import { TEXT_COLOR } from "../../../../../../../layout/constant/MenuList";

export function UploadVideoPreview({ values, setFieldValue, errors, touched, setErrors, onDrop }) {
        const [fileURL, setFileURL] = useState('')

    useEffect(() => {
        if (values.videoURL && values.videoURL) {
            const file = values.videoURL;
            const videoURL = file && `${process.env.REACT_APP_API_URL}/uploads/${file?.filename}`;
            setFileURL(videoURL)
            setErrors('videoURL', {})
        }
    }, [values])

    const acceptVideoFiles = {
        "video/mov": [".mov"],
        "video/mp4": [".mp4"],
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: acceptVideoFiles,
        maxSize: 1024 * 1024 * 1024,
        disabled: values.videoURL && true,
    });

    return (
        <>
            <Grid border='2px'
                borderColor={(errors.videoURL) ? 'red.500' : 'gray.200'}
                padding="20px" borderStyle="dashed">
                <Grid>
                    <div {...getRootProps()}>
                        <Input {...getInputProps()} />

                        <div className="file-upload-content">
                            <Image
                                boxSize="100px"
                                objectFit="cover"
                                src={FileUploadIcon}
                                alt="upload-icon"
                            />
                            <Heading
                                fontSize="xl"
                                mb={1}
                                color={TEXT_COLOR}
                                textAlign="center"
                            >
                                Drag and drop video
                                <Tooltip label={
                                    <Box>
                                        <Text color="#000"><b>Video file type:-</b> .mov or .mp4.</Text>
                                        <Text color="#000"><b>Ratio:-</b> 1:1 (for desktop or mobile) or 4:5 (for mobile only)</Text>
                                        <Text color="#000"><b>Video maximum file size:-</b> 4 GB</Text>
                                        <Text color="#000"><b>File name:-</b> Can't be longer than 50 characters</Text>
                                        <Text color="#000"><b>Duration:-</b> 1 second to 241 minutes</Text>
                                    </Box>} closeDelay={500} bg='#e2e8f0'>
                                    <InfoOutlineIcon marginLeft="2" height="15px" width="15px" marginBottom="3px" />
                                </Tooltip>
                            </Heading>
                            <Heading
                                fontSize="sm"
                                mb={1}
                                color={TEXT_COLOR}
                            >
                                or
                            </Heading>
                            <Button size="small" disabled={values.videoURL && true}>Browse</Button>
                        </div>
                    </div>
                </Grid>
            </Grid>
            {(errors.videoURL) &&
                <Text color="red.500" marginTop="1">
                    Video File is required
                </Text>
            }
            {values.videoURL &&
                < Grid className="file-box">
                    <Flex marginTop="5" marginBottom="5" justifyContent='center'>
                        <Box maxHeight='300px'>
                            <ReactPlayer
                                className='react-player'
                                url={fileURL}
                                width="300px"
                                height="170px"
                                controls
                            />
                        </Box>
                        <Grid>
                            <CloseIcon
                                marginLeft="-19px"
                                zIndex="999"
                                marginTop="4px"
                                padding="2px"
                                bg="rgba(255, 255, 255, 0.45)"
                                cursor="pointer"
                                className="close-icon"
                                size="small"
                                onClick={() => setFieldValue('videoURL', '')}
                            />
                        </Grid>
                    </Flex>
                    <Progress
                        className="loading"
                        value={100}
                    />
                </Grid>
            }

        </>
    );
}
