import {
    Box, Center,
    css,
    Flex,
    Grid,
    GridItem,
    Heading,
    Spinner,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    VStack
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {BiArrowBack} from "react-icons/bi";
import {useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import WithAppContext from "../../../../../../helpers/appContext";
import {useGetAdUpload} from "../../../../../../hooks/campaign-briefs/useGetAdUpload";
import {TEXT_COLOR} from "../../../../../../layout/constant/MenuList";
import "../../../../style/FacebookAdUpload.css";
import AuditLogsList from "../../AuditLogsList";
import TikTokVideoAdPreview from "./TikTokVideoAdPreview";
import TikTokVideoComments from "./TikTokVideoComments";
import TikTokAdDisplayVideoDetails from "./TikTokAdDisplayVideoDetails";
import FullPageLoader from "../../../../../../components/FullPageLoader";


const TikTokVideoAdUpload = ({context}) => {
    const [method, setMethod] = useState();
    const [url, setUrl] = useState();
    const [previewData, setPreviewData] = useState({});
    const [tabIndex, setTabIndex] = useState(0);
    const [playingPreview, setPlayingPreview] = useState(false);
    const [playingDetails, setPlayingDetails] = useState(false);
    const {appLoading} = context
    const navigate = useNavigate();
    const {id, tiktokId} = useParams();
    const clientId = useSelector((state) => state.client.clientId);
    const {data, refetch} = useGetAdUpload(clientId, id, tiktokId);
    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        if (tiktokId) {
            setMethod("PUT");
            setUrl(
                `/client/${clientId}/campaign-brief/${id}/ad-upload/${tiktokId}`
            );
        } else {
            setMethod("POST");
            setUrl(`/client/${clientId}/campaign-brief/${id}/ad-upload`);
        }
    }, [tiktokId, clientId]);

    return (
        <>
            {appLoading && (
                <FullPageLoader/>
            )}

            <Grid templateColumns="repeat(6, 1fr)" gap={4} className="fb-upload">
                <GridItem w="full" colSpan={{base: 6, lg: 6}} mb={{base: 3, lg: 0}}>
                    <Heading fontSize="sm" mb={7} color={TEXT_COLOR}>
                        <Flex
                            onClick={() => navigate(`/campaign-briefs/${id}`)}
                            cursor={"pointer"}
                        >
                            <Center marginRight={2}>
                                <BiArrowBack color="#A7A9BD"/>
                            </Center>
                            <Center>
                                <Text
                                    css={css({
                                        fontFamily: "Poppins",
                                        fontStyle: "normal",
                                        fontWeight: "600",
                                        fontSize: "12px",
                                        lineHeight: "15px",
                                        color: "#A7A9BD",
                                    })}
                                >
                                    Back to Campaign Brief
                                </Text>
                            </Center>
                        </Flex>
                    </Heading>
                    <Heading fontSize="4xl" mb={4} color={TEXT_COLOR}>
                        TikTok Ad Upload Video
                    </Heading>
                    <Tabs
                        onChange={(index) => {
                            setPlayingPreview(false)
                            setPlayingDetails(false)
                            setTabIndex(index)
                        }}
                        index={tabIndex}
                        size="sm"
                        w="full"
                        colorScheme="black"
                        overflow="hidden"
                    >
                        <TabList
                            overflowX="auto"
                            css={css({
                                scrollbarWidth: "none",
                                "::-webkit-scrollbar": {display: "none"},
                                WebkitOverflowScrolling: "touch",
                                boxShadow: "inset 0 -2px 0 rgba(0, 0, 0, 0.1)",
                                border: "0 none",
                            })}
                        >
                            <Tab whiteSpace="nowrap">Details</Tab>
                            <Tab whiteSpace="nowrap" isDisabled={!tiktokId}>Preview</Tab>
                            <Tab whiteSpace="nowrap" isDisabled={!tiktokId}>Comments</Tab>
                            <Tab whiteSpace="nowrap">Audit Logs</Tab>
                        </TabList>

                        <TabPanels>
                            <TabPanel>
                                <TikTokAdDisplayVideoDetails
                                    campaignId={id}
                                    handlePreviewData={setPreviewData}
                                    data={data?.adUpload}
                                    url={url}
                                    method={method}
                                    tabIndex={tabIndex}
                                    setTabIndex={setTabIndex}
                                    playingDetails={playingDetails}
                                    setPlayingDetails={setPlayingDetails}
                                />
                            </TabPanel>
                            <TabPanel>
                                {previewData &&
                                <TikTokVideoAdPreview
                                    data={previewData}
                                    playingPreview={playingPreview}
                                    setPlayingPreview={setPlayingPreview}
                                />
                                }
                            </TabPanel>
                            <TabPanel>
                                <TikTokVideoComments
                                    clientId={clientId}
                                    campaignId={id}
                                    dv360AdId={tiktokId}
                                />
                            </TabPanel>
                            <TabPanel>
                                <AuditLogsList/>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </GridItem>
            </Grid>
        </>
    );
};

export default WithAppContext(TikTokVideoAdUpload);
