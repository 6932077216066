import { createSlice } from "@reduxjs/toolkit";
import {
    dv360AdUploadInitialValues, dv360AdVideoInitialValue,
    dv360AudioAdUploadInitialValues
} from "../../pages/CampaignBriefs/constant/InitialValues";


const adsMasterConfig = [
    {
        name: "DV360",
        typeOfAds: [
            {
                name: "Static Image",
                image: "image.svg",
                type: "dv360",
                initialValues: dv360AdUploadInitialValues
            },
            {
                name: "Audio",
                image: "video.svg",
                type: "dv360_audio",
                initialValues: dv360AudioAdUploadInitialValues
            },
            {
                name: "Youtube Video",
                image: "video.svg",
                type: "dv360_youtube_video",
                initialValues: dv360AdVideoInitialValue
            },
            {
                name: "Video",
                image: "video.svg",
                type: "dv360_video",
                initialValues: dv360AdVideoInitialValue
            }
        ]
    }
]

const initialState = {
    editMediaType: null,
    selectedSocialMedia: null,
    mediaType: null,
    adsMasterConfig,
    formDetails: {},
    mediaDetails: [],
    handlePreviewData: null,
};

export const adUploadSlice = createSlice({
    name: "adUpload",
    initialState,
    reducers: {
        settingEditMediaType: (state, action) => {
            state.editMediaType = action.payload
        },
        selectSocialMedia: (state, action) => {
            state.selectedSocialMedia = action.payload
        },
        selectMediaType: (state, action) => {
            state.mediaType = action.payload
        },
        defaultMediaTypeNull: (state, action) => {
            state.mediaType = null
        },
        settingFormDetails: (state, action) => {
            state.formDetails = {...state.formDetails, ...action.payload}
        },
        settingFormMedia: (state, action) => {
            state.mediaDetails = action.payload
        },
        settingHandlePreviewData: (state, action) => {
            state.handlePreviewData = action.payload
        },
        deleteTheField: (state, action) => {
            state.formDetails = {};
            state.mediaDetails = [];
            state.handlePreviewData = null;
            state.selectedSocialMedia = null;
            state.mediaType =  null;
            state.editMediaType = null;
        }
    },
});


export const { settingEditMediaType, selectSocialMedia, selectMediaType, defaultMediaTypeNull, settingFormDetails, settingFormMedia, settingHandlePreviewData, deleteTheField } = adUploadSlice.actions;
// The reducer
export const adUploadReducer = adUploadSlice.reducer;
