import {Box, Heading, Grid, GridItem, Button} from "@chakra-ui/react";
import InputBox from "../../../../components/InputBox";
import { ADDITIONAL_QUESTIONS } from "../../../../constant";
import {TEXT_COLOR} from "../../../../layout/constant/MenuList";
import React from "react";
import {Flex} from "@chakra-ui/layout";
import {useParams} from "react-router-dom";

const AdditionalQuestionsStep = ({  errors, activeStep, steps, prevStep, nextStep   }) => {

    const {id} = useParams()

    return (
        <Box p={4}>
            <Box display="flex" justifyContent="space-between" mb={6}>
                <Heading fontSize="xl" color={"#757998"}>
                    Additional Questions
                </Heading>
                <Heading fontSize="md" color="#A7A9BD">
                    1 steps to complete
                </Heading>
            </Box>
            <Grid className="flex-for-mobile" templateColumns="repeat(12, 1fr)" gap={"8px"}>
                {ADDITIONAL_QUESTIONS.map((question) => {
                        if(question.label === "Analytics" || question.label === "Number of Reports?") {
                            return (
                                <GridItem colSpan={6} key={question.id}>
                                    <InputBox name={question.name} placeholder={question.label} />
                                </GridItem>
                            )
                        }
                        return (
                            <GridItem colSpan={12} key={question.id}>
                                <InputBox name={question.name} placeholder={question.label} />
                            </GridItem>
                        )
                }
                )}
            </Grid>
            <Flex width="100%" justify="flex-end" py={10}>
                <Button
                    isDisabled={activeStep === 0}
                    mr={4}
                    onClick={prevStep}
                    colorScheme='blue' variant='outline' borderRadius={0} px={10}
                >
                    Back
                </Button>
                            <Button
                                mr={4}
                                colorScheme='blue' variant='solid' borderRadius={0} px={10}
                                // display={id ? "none" : "block"}
                            >
                                Save Draft
                            </Button>
                {
                    id &&   <Button
                        type="submit"
                        mr={4}
                        colorScheme='green' variant='solid' borderRadius={0} px={10}
                    >
                        Update
                    </Button>
                }

                {
                    !id &&   <Button
                        type="submit"
                        mr={4}
                        colorScheme='green' variant='solid' borderRadius={0} px={10}
                    >
                        Submit
                    </Button>
                }

            </Flex>
        </Box>
    );
};

export default AdditionalQuestionsStep;
