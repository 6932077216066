export const SELECTED_DATA = "SELECTED_DATA"
export const SET_SITELINK_DATA = "SET_SITELINK_DATA"
export const SET_EXTENSION_DATA = "SET_EXTENSION_DATA"
export const EMPTY_DATA = "EMPTY_DATA"

export const EMPTY_SITELINK_DATA = "EMPTY_SITELINK_DATA"
export const EMPTY_EXTENSION_DATA = "EMPTY_EXTENSION_DATA"

export const SET_INITIAL_MASTERDATA = "SET_INITIAL_MASTERDATA"
export const SET_INITIAL_SITELINK = "SET_INITIAL_SITELINK"
export const SET_INITIAL_EXTENSION = "SET_INITIAL_EXTENSION"