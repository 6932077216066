import {CloseIcon, DownloadIcon, InfoOutlineIcon} from "@chakra-ui/icons";
import {
    Box, Button,
    Flex, Grid, Heading, Image, Input, Progress, Spacer, Text, Tooltip, useToast
} from "@chakra-ui/react";
import FileSaver from 'file-saver';
import React, {useCallback, useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import DefaultImageIcon from "../../../../../../../assets/images/default-image-icon.png";
import FileUploadIcon from "../../../../../../../assets/images/upload.svg";
import WithAppContext from "../../../../../../../helpers/appContext";
import {useUploadImage} from "../../../../../../../hooks/campaign-briefs/useUploadImage";
import {GREY_TEXT_COLOR, TEXT_COLOR, BLUE_BUTTON_COLOR} from "../../../../../../../layout/constant/MenuList";
import {
    DV360_IMAGE_SIZE,
    FB_IMAGE_SIZE,
    INSTAGRAM_VIDEO_SIZE,
    PINTEREST_IMAGE_SIZE
} from "../../../../../../../constant";
import ReactPlayer from "react-player";


const InstagramVideoAdFileUpload = ({values, setFieldValue, setErrors, errors, context, onDrop}) => {
    const [fileURL, setFileURL] = useState('')
    const {ErrorToaster} = context;

    useEffect(() => {
        if (values.videoURL && values.videoURL) {
            const file = values.videoURL;
            const videoURL = file && `${process.env.REACT_APP_API_URL}/uploads/${file?.filename}`;
            setFileURL(videoURL)
            setErrors('videoURL', {})
        }
    }, [values])

    const acceptVideoFiles = {
        "video/mov": [".mov"],
        "video/mp4": [".mp4"],
        "video/gif": [".gif"],
    };

    const {getRootProps, getInputProps, fileRejections} = useDropzone({
        onDrop,
        accept: acceptVideoFiles,
        maxSize: INSTAGRAM_VIDEO_SIZE,
        disabled: values.videoURL,
    });

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    // dropZone Errors
    useEffect(() => {
        fileRejections && fileRejections.map(({errors}) => {
            errors.map((err) => ErrorToaster(err.code + ',' + err.message));
        })
    }, [fileRejections])

    return (
        <>
            <Box className="upload-main" mb={6} >
                <Grid border='2px'
                      borderColor={(errors.videoURL) ? 'red.500' : 'gray.200'}
                      padding="20px" borderStyle="dashed">
                        <Grid>
                            <div {...getRootProps()}>
                                <Input {...getInputProps()} />

                                <div className="file-upload-content">
                                    <Image
                                        boxSize="100px"
                                        objectFit="cover"
                                        src={FileUploadIcon}
                                        alt="upload-icon"
                                    />
                                    <Heading
                                        fontSize="xl"
                                        display="flex"
                                        alignItems="center"
                                        color={GREY_TEXT_COLOR}
                                        my={4}
                                    >
                                        Drag video files to upload
                                        <Tooltip label={
                                            <Box>
                                                <Text color="#000"><b>Video file type:-</b> .mov, .mp4, .gif.</Text>
                                                <Text color="#000"><b>Ratio:-</b> 1:1 (for desktop or mobile) or 4:5 (for mobile
                                                    only)</Text>
                                                <Text color="#000"><b>Video maximum file size:-</b> 4 GB</Text>
                                                <Text color="#000"><b>File name:-</b> Can't be longer than 50 characters</Text>
                                                <Text color="#000"><b>Duration:-</b> 1 second to 241 minutes</Text>
                                            </Box>} closeDelay={500} bg='#e2e8f0'>
                                            <InfoOutlineIcon marginLeft="2" height="15px" width="15px" marginTop="2px"/>
                                        </Tooltip>
                                    </Heading>
                                    <Text
                                        fontSize="sm"
                                        mb={4}
                                        color={GREY_TEXT_COLOR}
                                    >
                                        - OR -
                                    </Text>
                                    <Button bgColor={BLUE_BUTTON_COLOR} color="white" size="small" px={10} py={2} disabled={values.videoURL && true} borderRadius={100}>Browse Files</Button>
                                </div>
                            </div>
                        </Grid>
                    {(errors.videoURL) &&
                    <Text color="red.500" marginTop="2" textAlign={"center"}>
                        Video File is required
                    </Text>
                    }
                </Grid>

                {values.videoURL &&
                < Grid className="file-box" mx={5}>
                    <Flex marginTop="5" marginBottom="5" justifyContent='center'>
                        <Box maxHeight='300px'>
                            <ReactPlayer
                                className='react-player'
                                url={fileURL}
                                width="300px"
                                height="170px"
                                controls
                            />
                        </Box>
                        <Grid>
                            <CloseIcon
                                marginLeft="-19px"
                                zIndex="999"
                                marginTop="4px"
                                padding="2px"
                                bg="rgba(255, 255, 255, 0.45)"
                                cursor="pointer"
                                className="close-icon"
                                size="small"
                                onClick={() => setFieldValue('videoURL', '')}
                            />
                        </Grid>
                    </Flex>
                    <Box marginTop={"-20px"}>
                        <Text textAlign={"right"} display={"block"} color={"green"}>100% Completed</Text>
                        <Progress
                            className="loading"
                            value={100}
                            colorScheme='green'
                            size='sm'
                        />
                    </Box>
                </Grid>
                }
            </Box>

        </>
    );
}

export default WithAppContext(InstagramVideoAdFileUpload)
