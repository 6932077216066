import { Grid, useToast } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UploadDV360AudioFile } from "./UploadDV360AudioFile"
import { UploadDV360AudioThumbnail } from "./UploadDV360AudioThumbnail";

let currentId = 0;

export function DV360AudioFileUpload({ valuesData, getHashArray, type, setFieldValue, errors, touched }) {
    const { audioFiles, audioThumbnail } = valuesData;
    const toast = useToast()
    const { id } = useParams();
    const [files, setFiles] = useState([]);
    const [imageFiles, setImageFiles] = useState([]);
    const [hashArray, setHashArray] = useState([]);

    const onDrop = useCallback(async (accFiles, rejFiles) => {
        accFiles.map((file) => {
            var fileType = file.type.split("/").shift();
            if (fileType === "audio") {
                const objectURL = URL.createObjectURL(file);
                const mySound = new Audio([objectURL]);
                if (file) {
                    mySound.addEventListener("canplaythrough", () => {
                        URL.revokeObjectURL(objectURL);
                        const mappedRej = rejFiles.map((r) => ({ ...r, id: ++currentId }));
                        if (mySound.duration <= 30) {
                            let data = {
                                file,
                                errors: [],
                                id: ++currentId,
                                duration: mySound.duration,
                            };
                            setFiles((curr) => [...curr, data, ...mappedRej]);
                        } else {
                            setFiles((curr) => [...curr, ...mappedRej]);
                            toast({
                                status: 'error',
                                variant: 'top-accent',
                                position: 'top-right',
                                description: 'Please upload valid audio.'
                            })
                        }
                    });
                }
            }
            else {
                const mappedAcc = accFiles.map((file) => ({
                    file,
                    errors: [],
                    id: ++currentId,
                }));

                const size = 10 * 1024 * 1024
                if (file.size <= size) {
                    const mappedRej = rejFiles.map((r) => ({ ...r, id: ++currentId }));
                    setImageFiles((curr) => [...curr, ...mappedAcc, ...mappedRej]);
                } else {
                    toast({
                        status: 'error',
                        variant: 'top-accent',
                        position: 'top-right',
                        description: 'Please upload valid image.'
                    })
                }
            }
        });
    }, []);

    useEffect(() => {
        if (audioFiles.length !== 0) {
            setFiles(audioFiles)
        }
        if (audioFiles.length !== 0) {
            setImageFiles(audioThumbnail)
        }
    }, [valuesData]);


    useEffect(() => {
        getHashArray(hashArray);
    }, [hashArray]);

    useEffect(() => {
        if (files.length >= 0) {
            setFieldValue('audioFiles', files);
        }
    }, [files]);

    useEffect(() => {
        if (imageFiles.length >= 0) {
            setFieldValue('audioThumbnail', imageFiles);
        }
    }, [imageFiles]);

    function onDelete(file) {
        setFiles((curr) => curr.filter((fw) => {
            if (fw.file) {
                return fw.file !== file
            } else {
                return fw !== file
            }
        }));
    }
    function onImageDelete(file) {
        setImageFiles((curr) => curr.filter((fw) => {
            if (fw.file) {
                return fw.file !== file
            } else {
                return fw !== file
            }
        }));
    }

    return (
        <>
            <Grid spacing={2} direction="column">
                <UploadDV360AudioFile  files={files} onDelete={onDelete} onDrop={onDrop} errors={errors} touched={touched} />
                <UploadDV360AudioThumbnail files={imageFiles} onDelete={onImageDelete} onDrop={onDrop} errors={errors} touched={touched} />
            </Grid>
        </>
    );
}
